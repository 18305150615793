import React, { Component } from 'react';
import { setGameCode, setGameState, setDBNames, setGameType } from '../actions/core';
import { firebaseConnect,  firestoreConnect, authIsReady } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import store from '../../store/store';
import PlayerLogin from '../components/PlayerLogin';
import VirusPlayer from '../../virusgame/player/components/VirusPlayer';
import PopulationPlayer from '../../populationgame/player/components/PopulationPlayer';
import TocPlayer from '../../tocgame/player/components/TocPlayer';
import PlanetPlayerGameScreen from '../../planetgame/view/player/PlanetPlayerGameScreen';
import InfoPlayerGameScreen from '../../infogame/player/InfoPlayerGameScreen';
import TeacherLogout from '../components/TeacherLogout';
import DimLoader from '../components/DimLoader';
import { clearCurrentMessage, setCurrentMessage } from '../actions/messages';
import getDBNames from '../../helpers/getDBNames';
import setOnlineStatus from '../../helpers/setOnlineStatus';
import { Redirect } from 'react-router-dom';

// Description: Container that controls all player info
// Parent: Top level forwarded to by Choose
// Children: VirusPlayer, PopulationPlayer, ToCPlayer, TeacherLogout, and PlayerLogin
// Props Used: 
//    auth (Object)
//    clearMessage
//    firebase (Object)
//    firestore (Object)
//    game (String)
//    gameCode (String)
//    gameState (String)
//    gameType (String)
//    history (Object)
//    meetings (String)
//    message (Object)
//    players (String)
//    setDBNames (Function)
//    setGameCode (Function)
//    setGameState (Function)
//    setGameType (Function)
//    setMessage (Function)

class PlayerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listenerSet: false
    };
  }

  // checks to see if game was pushed from Choose page.  goes back to home if not.
  
  UNSAFE_componentWillMount() {
    this.props.setGameState('loading');
    authIsReady(store).then(() => {
      if (this.props.auth.isEmpty) {
        this.props.setGameState('empty'); // not logged in.  can log in as player
      } else if (this.props.auth.isAnonymous) {
        // should check if has account, if yes set to other
        this.props.firestore.get({
          collection: 'status',
          doc: this.props.auth.uid
        })
        .then(doc => {
          if (this.props.gameType !== '' || (doc.exists && doc.data().gameType !== '')) {
            const gameType = this.props.gameType === '' ? doc.data().gameType : this.props.gameType;
            this.props.setGameType(gameType);
            this.props.setDBNames(getDBNames(gameType));
            
            
            this.props.firestore.get({
              collection: this.props.players,
              where: ['uid', '==', this.props.auth.uid]
            }).then(
              data => {
                
                if (!data.empty && data.docs[0].data().rejoin) {
                  this.props.setGameState('rejoin');
                }
              }
            ).catch((err) => console.log(err));
            this.props.setGameState('anonymous'); // already logged in as player
            this.setState({listenerSet: true});
            setOnlineStatus(this.props);
          } else {
            this.props.setGameState('empty');
          }
        });
      } else {
        this.props.setGameState('other'); // logged in as teacher or incorrectly
      }
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!nextState.listenerSet && nextProps.gameState === 'anonymous' && nextState.gameType !== '') {
      this.setState({listenerSet: true});
      setOnlineStatus(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  componentCleanup = () =>  {
    this.props.setGameState('empty');
    this.props.setGameCode('');
  }

  // after logging in set the state.
  loggedIn = () => {
    this.props.setGameState('anonymous');
    setOnlineStatus(this.props);  
  }

  // after logging out set the state
  loggedOut = () => {
    this.props.setGameState('empty');
  }

  // force player to rejoin page and display error message
  forceToRejoin = () => {
    this.props.history.push('/rejoin');
    this.props.setGameState('force');
  }

  // checks state and loads either PlayerLogin or Player
  render() {
    switch (this.props.gameState) {
      case 'anonymous':
        return (
          <div style={{height: '100%'}}>
            {this.props.gameType === 'vgame' &&
              <VirusPlayer
                history={this.props.history}
                firestore={this.props.firestore}
                firebase={this.props.firebase}
                auth={this.props.auth}
                loggedOut={this.loggedOut}
                game={this.props.game}
                meetings={this.props.meetings}
                players={this.props.players}
                message={this.props.message}
                clearMessage={this.props.clearMessage}
                setMessage={this.props.setMessage}
              />
            }

            {this.props.gameType === 'pgame' &&
              <PopulationPlayer
                firestore={this.props.firestore}
                firebase={this.props.firebase}
                auth={this.props.auth}
                loggedOut={this.loggedOut}
                setGameState={this.props.setGameState}
                gameCode={this.props.gameCode}
                game={this.props.game}
                players={this.props.players}
              />
            }

            {this.props.gameType === 'tgame' &&
              <TocPlayer
                firestore={this.props.firestore}
                firebase={this.props.firebase}
                auth={this.props.auth}
                loggedOut={this.loggedOut}
                game={this.props.game}
                players={this.props.players}
              />
            }

            {this.props.gameType === 'lgame' &&
              <PlanetPlayerGameScreen
                firebase={this.props.firebase}
                loggedOut={this.loggedOut}
                setGameCode={this.props.setGameCode}
              />
            } 

            {this.props.gameType === 'infogame' &&
              <InfoPlayerGameScreen
                firebase={this.props.firebase}
                loggedOut={this.loggedOut}
                setGameCode={this.props.setGameCode}
              />
            } 
          </div>
        );
    
      case 'rejoin':
        return <Redirect to="/rejoin" />;

      case 'loading':
        return <DimLoader message="Loading Page" />;

      default:
        return (
          <div style={{height: '100%'}}>
            <PlayerLogin
              done={this.loggedIn}
              cancel={() => this.props.history.push('/')}
              game={this.props.game}
              players={this.props.players}
              gameCode={this.props.gameCode}
              setGameCode={this.props.setGameCode}
              firebase={this.props.firebase}
              firestore={this.props.firestore}
              forceToRejoin={this.forceToRejoin}
              setDBNames={this.props.setDBNames}
              setGameType={this.props.setGameType}
            />

            {this.props.gameState === "other" &&
              <TeacherLogout firebase={this.props.firebase} history={this.props.history} setGameState={this.props.setGameState} />
            }
          </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    gameState: state.core.gameState,
    gameCode: state.core.gameCode,
    gameType: state.core.gameType,
    game: state.core.game,
    meetings: state.core.meetings,
    players: state.core.players,
    auth: state.firebase.auth,
    message: state.messages.currentMessage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGameState: (newState) => dispatch(setGameState(newState)),
    setGameCode: (newCode) => dispatch(setGameCode(newCode)),
    setGameType: (type) => dispatch(setGameType(type)),
    clearMessage: () => dispatch(clearCurrentMessage()),
    setDBNames: (names) => dispatch(setDBNames(names)),
    setMessage: (success, player) => dispatch(setCurrentMessage({success, player}))
  };
}

const enhance = compose(
  firestoreConnect(),
  firebaseConnect(), // withFirebase can also be used
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(PlayerContainer);
