import React from 'react';
import { Table } from 'semantic-ui-react';


const PlayersTable = (props) => {
    const {
        playersList, convertDate
    } = props;

    return (
        <div>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Game Type</Table.HeaderCell>
                        <Table.HeaderCell>Display Name</Table.HeaderCell>
                        <Table.HeaderCell>Game ID</Table.HeaderCell>
                        <Table.HeaderCell>Last Online</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {/* <Table.Row>
                        <Table.Cell>
                            <b>
                                Tragedy of the Commons
                            </b>
                        </Table.Cell>
                    </Table.Row> */}
                    {playersList.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {item.type}
                            </Table.Cell>
                            <Table.Cell>
                                {item.data().displayName}
                            </Table.Cell>
                            <Table.Cell>
                                {item.data().currentGameId}
                            </Table.Cell>
                            <Table.Cell>
                                {convertDate(item.data().lastLogin.seconds)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <div>
                Total number of players: {playersList.length}
            </div>
        </div>
    )
}

export default PlayersTable;