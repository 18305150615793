import React, { Component } from "react";
import {
  Header,
  Form,
  Divider,
  Modal,
  Button,
  Grid,
  Dropdown,
} from "semantic-ui-react";
import BasicGreyPage from "./BasicGreyPage";
import LogoutConfirmation from "./LogoutConfirmation";
import YesNoDialog from "./YesNoDialog";

// Description: Allows teacher to create a new game or choose an existing game
// Parent: TeacherContainer
// Children: None
// Props Used:
//      auth (Object)
//      firebase (Object)
//      firestore (Object)
//      gamesList (Array)
//      handleGameChange (Function)
//      history (Object)
//      name (String)

const top = (
  <Header as="h1" textAlign="center">
    Homepage
  </Header>
);

class TeacherCreateGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newGameId: "",
      oldGameId: "",
      gameChoice: "",
      gameError: false,
      typeError: false,
      disableButtons: false,
      showLogoutConfirmation: false,
      delete: this.props.auth.uid === "G17yqCcomxMlLy6PuuYEXny7qVI3",
      showDeleteDialog: false,
      deleteTimePeriod: "",
    };
  }

  // handles a change of the dropdown or text input field
  handleChange = (e, { name, value }) => {
    if (name === "oldgamecode") {
      this.setState({ oldGameId: value });
    } else if (name === "newgamecode") {
      this.setState({ newGameId: value.toLowerCase() });
    } else if (name === undefined) {
      this.setState({ gameChoice: value });
    }
  };

  // validates game in real time against database
  validateGame = (value, isExisting) => {
    return new Promise((resolve) => {
      this.props.firestore
        .get({
          collection: "games",
          doc: value,
        })
        .then((querydata) => {
          if (querydata.exists) {
            this.setState({ gameError: !isExisting });
            resolve(isExisting);
          } else {
            this.setState({ gameError: isExisting });
            resolve(!isExisting);
          }
        });
    });
  };

  // on submit it validates fields one new time, then signs in anonymously
  // and creates temp fields for player
  handleSubmit = (gameId, isExisting) => {
    this.setState({ disableButtons: true });
    this.validateGame(gameId, isExisting).then((gamevalidated) => {
      if (gamevalidated) {
        if (isExisting) {
          const data = { value: gameId };
          this.props.handleGameChange(null, data);
          return;
        }
        var game = "empty";
        var entry = {};
        switch (this.state.gameChoice) {
          case "vgame":
            game = "vgames";
            entry = {
              clock: this.props.firestore.FieldValue.serverTimestamp(),
              owner: this.props.auth.uid,
              gameState: "initializing",
              gameParams: {
                immuneProbability: 10,
                infectionRate: 90,
                averageLatency: 120,
                patientZero: "",
                hasImmuneCarriers: false,
              },
              pausedTime: 0,
              data: { 0: 0 },
              gameRestart: false,
            };
            break;

          // case 'pgame':
          //   game = 'pgames';
          //   entry = {
          //     clock: this.props.firestore.FieldValue.serverTimestamp(),
          //     owner: this.props.auth.uid,
          //     gameState: 'initializing',
          //     configurations: {
          //       "0": {
          //         stations: {}
          //       }
          //     },
          //     pausedTime: 0,
          //     populationData: { "0": 0 },
          //     histogramData: [],
          //     gameRestart: false,
          //     movementLoss: false,
          //     predation: false
          //   }
          //   break;

          case "tgame":
            game = "tgames";
            entry = {
              clock: this.props.firestore.FieldValue.serverTimestamp(),
              owner: this.props.auth.uid,
              gameState: "initializing",
              configurations: {
                0: {
                  stations: {},
                },
              },
              populationData: { 0: 0 },
              data: [],
              generation: false,
              histogramData: {},
              gameRestart: false,
              seasonNumber: 1,
              trading: false,
            };
            break;

          case "lgame":
            game = "lgames";
            entry = {
              clock: this.props.firestore.FieldValue.serverTimestamp(),
              owner: this.props.auth.uid,
              gameStatus: "initializing",
            };
            break;

          //   case 'infogame':
          //     game = 'infogames';
          //     entry = {
          //       clock: this.props.firestore.FieldValue.serverTimestamp(),
          //       owner: this.props.auth.uid,
          //       gameStatus: 'initializing'
          //     }
          //     break;

          //   default:
          //     break;
        }

        this.props.firestore
          .set(
            {
              collection: "games",
              doc: gameId,
            },
            {
              owner: this.props.auth.uid,
              type: this.state.gameChoice,
              clock: this.props.firestore.FieldValue.serverTimestamp(),
              gameState: "initializing",
              meets: [],
              players: [],
            }
          )
          .then((response) => {
            this.props.firestore
              .set(
                {
                  collection: game,
                  doc: gameId,
                },
                entry
              )
              .then(() => {
                //Set the dropdown for games to the value created
                const data = { value: gameId };
                this.props.handleGameChange(null, data);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      } else {
        this.setState({ disableButtons: false });
      }
    });
  };
  //Cleans database with data older than one month old by logging onto the psims gmail and clicking clean db
  cleanData = () => {
    // if ()
    let timeLimitMS = 0;
    switch (this.state.deleteTimePeriod) {
      case "1 month":
        timeLimitMS = 2.628e9;
        break;
      case "3 months":
        timeLimitMS = 7.884e9;
        break;
      case "1 year":
        timeLimitMS = 3.154e10;
        break;
      default:
        break;
    }

    const currentTime = Date.now();
    const firestore = this.props.firestore;
    firestore
      .collection("games")
      .get()
      .then((snapshot) => {
        return snapshot.forEach((s) => {
          let batch = firestore.batch();
          let clock = new Date(s.data().clock.seconds * 1000).getTime();
          if (currentTime - clock >= timeLimitMS) {
            batch.delete(firestore.collection("games").doc(s.id));
            batch.delete(firestore.collection(`${s.data().type}s`).doc(s.id));
            let initial = s.data().type.charAt(0);
            s.data().players.forEach((player) => {
              batch.delete(
                firestore.collection(`${initial}players`).doc(player)
              );
              batch.delete(firestore.collection("status").doc(player));
            });
            if (initial === "v") {
              s.data().meets.forEach((meet) => {
                batch.delete(
                  firestore.collection(`${initial}gamemeetings`).doc(meet)
                );
              });
            }
            batch.commit();
            console.log("Finished deletion");
          }
        });
      })
      .catch((er) => console.error(er));
    this.setState({ showDeleteDialog: false });
  };

  render() {
    const {
      newGameId,
      oldGameId,
      gameError,
      typeError,
      disableButtons,
      gameChoice,
    } = this.state;

    function descriptionModal(name, description, icon) {
      return (
        <Modal
          trigger={<Button floated="right" icon="info circle" size="mini" />}
          size="mini"
          header={name}
          content={description}
          closeIcon
        />
      );
    }

    const gameChoices = [
      {
        text: "Virus Game",
        value: "vgame",
        description: descriptionModal(
          "Virus Game",
          "Students go around meeting each other and discover there is a virus making people sick. They must then work together to uncover the details behind this epidemic."
        ),
      },
      // {
      //   text: "Population Game",
      //   value: "pgame",
      //   description: descriptionModal("Population Game", "Unavailable"),
      // },
      {
        text: "Toc Game",
        value: "tgame",
        description: descriptionModal(
          "Tragedy of the Commons",
          "The Fishing Game. Run a fishing boat and try not to overfish. Based on The Tragedy of the Commons."
        ),
      },
      {
        text: "Planet Game",
        value: "lgame",
        description: descriptionModal("Planet Pet", "Almost certainly broken!"),
      },
      // {
      //   text: "Information Game",
      //   value: "infogame",
      //   description: descriptionModal("Grouping Game", "standin"),
      // },
    ];

    const timeOptions = [
      {
        key: "1 month",
        text: "a month",
        value: "1 month",
      },
      {
        key: "3 months",
        text: "three months",
        value: "3 months",
      },
      {
        key: "1 year",
        text: "a year",
        value: "1 year",
      },
    ];

    const cleanDatabaseFields = (
      <div>
        <Grid>
          <Grid.Column
            width={8}
            textAlign="left"
            style={{ alignSelf: "center" }}
          >
            <Header as="h4" style={{ fontWeight: "normal" }}>
              Delete games older than
            </Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="left">
            <Dropdown
              placeholder="Select time period"
              fluid
              selection
              options={timeOptions}
              onChange={(event, data) =>
                this.setState({ deleteTimePeriod: data.value })
              }
            />
          </Grid.Column>
        </Grid>
      </div>
    );

    const center = (
      <Form onSubmit={(e) => e.preventDefault()}>
        <Header as="h2">Create New Game</Header>

        {gameError && (
          <Header as="h5" color="red">
            Game code already in use.
          </Header>
        )}

        {typeError && (
          <Header as="h5" color="red">
            Please select a game type.
          </Header>
        )}

        <Form.Dropdown
          fluid
          size="big"
          placeholder="Choose a Game Type"
          selection
          onChange={this.handleChange}
          options={gameChoices}
          value={gameChoice}
        />

        <Form.Group inline>
          <Form.Input
            fluid
            className="inline-input"
            error={gameError}
            size="big"
            icon="gamepad"
            iconPosition="left"
            placeholder="Game Code"
            name="newgamecode"
            value={newGameId}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Button
          disabled={newGameId === "" || gameChoice === "" || disableButtons}
          size="big"
          content="Create Game"
          onClick={() => this.handleSubmit(newGameId, false)}
          fluid
        />

        <Divider horizontal>
          <Header as="h4">OR</Header>
        </Divider>

        <Header as="h2">Choose Existing Game</Header>

        <Form.Dropdown
          selection
          fluid
          size="big"
          name="oldgamecode"
          placeholder="Select existing game"
          options={this.props.myGamesList}
          onChange={this.handleChange}
          value={oldGameId}
        />

        <Form.Button
          disabled={oldGameId === "" || disableButtons}
          size="big"
          content="Choose Game"
          onClick={() => this.handleSubmit(oldGameId, true)}
          fluid
        />

        <Header as="h5">
          <span style={{ fontWeight: "normal" }}>Logged in as &nbsp;</span>
          {this.props.name}
        </Header>

        {this.props.isAdmin && (
          <Form.Button
            size="big"
            content="Clean Database"
            onClick={() => this.setState({ showDeleteDialog: true })}
            negative
            fluid
          />
        )}

        {this.props.isAdmin && (
          <Form.Button
            size="big"
            content="View Database"
            onClick={() => this.props.history.push("/admin")}
            negative
            fluid
          />
        )}

        <Form.Button
          size="big"
          content="Log Out"
          onClick={() => this.setState({ showLogoutConfirmation: true })}
          negative
          fluid
        />
      </Form>
    );

    return (
      <div style={{ height: "100%" }}>
        {this.state.showLogoutConfirmation && (
          <LogoutConfirmation
            logoutAction={() => {
              this.props.firebase.logout();
              this.props.history.push("/");
            }}
            backAction={() => this.setState({ showLogoutConfirmation: false })}
          />
        )}
        <BasicGreyPage top={top} center={center} />

        {this.state.showDeleteDialog && (
          <YesNoDialog
            header="Clean database"
            description={cleanDatabaseFields}
            yesText="Clear"
            yesAction={this.cleanData}
            noText="Cancel"
            noAction={() => this.setState({ showDeleteDialog: false })}
            width="420px"
          />
        )}
      </div>
    );
  }
}

export default TeacherCreateGame;
