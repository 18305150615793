import React, { Component } from 'react';
import { Header, Item } from 'semantic-ui-react';
import StoreItem from './StoreItem';
import YesNoDialog from '../../../core/components/YesNoDialog';

// Description: Handles player transactions with boat store
// Parent: TocPlayer
// Children: StoreItem
// Props Used:
//      currentBoat (Object)
//      db (Object) 
//      money (Int)
//      players (String)
//      purchases (Object)
//      uid (String)

class BoatStore extends Component{
  constructor(props) {
      super(props);
      this.state = {
          pauseScan: false,
          camera: false,
          readerLoaded: false,
          errorQR: false,
          atStation: false,
          showScanDialogue: false,
          tickingTime: 5,
          fishGain: 0,
          numFishRequested: 10,
          showPurchaseConfirmDialog: false,
          currentPurchase: "",
      };
      this.meetingTimer = null;
      this.pauseTime = 20;
      this.scanEnergyGain = 5;
  };

  convertPurchaseToItemName = (purchase) => {
    switch(purchase) {
      case "small":
        return "Small Boat";
      case "medium":
        return "Medium Boat";
      case "large":
        return "Large Boat";
      case "trawler":
        return "Trawler upgrade";
      case "research":
        return "Research upgrade";
      default:
        return "";
    }
  }

  handlePurchase = (netSize, alreadyPurchased) => {
      if (!alreadyPurchased) {
          this.setState({ showPurchaseConfirmDialog: true, currentPurchase: netSize });
      } else {
          this.handleClick(netSize);
      }
  }

  handleClick = (netSize) => {
      this.setState({ showPurchaseConfirmDialog: false, currentPurchase: "" });

      const playerRef = this.props.db
        .collection(this.props.players)
        .doc(this.props.uid);

      this.props.db.runTransaction(transaction => {
          return transaction.get(playerRef).then(doc => {
              const player = doc.data();

              if (player !== null) {
                let playerMoney = player.fishCaught;
                let purchases = player.store;
                let boat = player.boat;
                if (netSize === 'medium') {
                  if (!purchases.medBoat) {
                    playerMoney = playerMoney - 20;
                    purchases.medBoat = true;
                  }
                  boat.size = 'medium';
                }
                else if (netSize === 'large') {
                  if (!purchases.largeBoat) {
                    playerMoney = playerMoney - 50;
                    purchases.largeBoat = true;
                  }
                  boat.size = 'large';
                }
                else if (netSize === 'trawler') {
                  if (!purchases.trawler) {
                    playerMoney = playerMoney - 30;
                    purchases.trawler = true;
                  }
                  boat.trawler = !boat.trawler;
                }
                else if (netSize === 'research' ) {
                  if (!purchases.fishRadar) {
                    playerMoney = playerMoney - 30;
                    purchases.fishRadar = true;
                  }
                  boat.research = !boat.research;
                }
                else if (netSize === 'small'){
                  boat.size = "small";
                }
                transaction.update(playerRef, {
                    boat: boat,
                    fishCaught: playerMoney,
                    store: purchases
                });
              }
          });
      });
  };



  render() {
    return (
      <div>
        {this.state.showPurchaseConfirmDialog && (
          <YesNoDialog
            header="Purchase Confirmation"
            description={"Are you sure you want to purchase the "
                          + this.convertPurchaseToItemName(this.state.currentPurchase)
                          + "? This item cannot be resold in the future."}
            yesText="Confirm"
            yesAction={() => {this.handleClick(this.state.currentPurchase)}}
            noText="Cancel"
            noAction={() => {this.setState({ showPurchaseConfirmDialog: false, currentPurchase: "" })}}
            width="420px"
          />
        )}

        <Header>The Boat Store</Header>
        <Item.Group divided>
          <StoreItem
            name="Small Boat"
            description="A nice starter boat"
            price="0"
            speed="2"
            capacity="5"
            opCost="20"
            onSelect={() => this.handlePurchase("small", this.props.purchases.smallBoat)}
            available={true}
            buttonText={this.props.purchases.smallBoat ? (this.props.currentBoat.size === "small" ? "Equipped" : "Equip") : "Buy"}
            size="tiny"
            color="teal"
          />
          <StoreItem
            name="Medium Boat"
            description="An upgrade from small. Capable boat."
            price="20"
            speed="3"
            capacity="10"
            opCost="20"
            onSelect={() => this.handlePurchase("medium", this.props.purchases.medBoat)}
            available={(this.props.money >= 20 || this.props.purchases.medBoat)}
            buttonText={this.props.purchases.medBoat ? (this.props.currentBoat.size === "medium" ? "Equipped" : "Equip") : "Buy"}
            size="large"
            color="violet"
          />
          <StoreItem
            name="Large Boat"
            description="A powerhouse boat."
            price="50"
            speed="4"
            capacity="20"
            opCost="20"
            onSelect={() => this.handlePurchase("large", this.props.purchases.largeBoat)}
            available={this.props.money >= 50 || this.props.purchases.largeBoat}
            buttonText={this.props.purchases.largeBoat ? (this.props.currentBoat.size === "large" ? "Equipped" : "Equip") : "Buy"}
            size="huge"
            color="red"
          />
          <StoreItem
            name="Trawler"
            description="A more effective fishing net, improves fishing speed by 50%."
            price="30"
            speed="150% of standard"
            capacity="unchanged"
            opCost="unchanged"
            onSelect={() => this.handlePurchase("trawler", this.props.purchases.trawler)}
            available={this.props.money >= 30 || this.props.purchases.trawler}
            buttonText={this.props.purchases.trawler ? (this.props.currentBoat.trawler ? "Unequip" : "Equip") : "Buy"}
            size="big"
            color="orange"
          />
          <StoreItem
            name="Research"
            description="Add fishery research capability to your fishing boat. Reveals information about the fisheries you visit."
            price="30"
            speed="unchanged"
            capacity="unchanged"
            opCost="+5"
            onSelect={() => this.handlePurchase("research", this.props.purchases.fishRadar)}
            available={this.props.money >= 30 || this.props.purchases.fishRadar}
            buttonText={this.props.purchases.fishRadar ? (this.props.currentBoat.research ? "Unequip" : "Equip") : "Buy"}
            size="big"
            color="green"
          />
        </Item.Group>
      </div>
    );

  }


}

export default BoatStore;
