import { element } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Grid, GridColumn, GridRow } from 'semantic-ui-carousel-react';

import { Icon, Grid, GridColumn, GridRow  } from 'semantic-ui-react';

/**
 * Single planet cards
 * Parent: PlanetCardsHub
 * Children: None
 *
 * @param {object} planet the planet object associated with this card
 * @param {function} onClick handler function to select a planet
 */

class PlanetCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const collectedRegions = [];
		const regionElements = [],
			sensors = this.props.player.activeSensors.filter(
				sensor => sensor.planet === this.props.planetIndex
			),
			sensorIcons = {
				rain: { color: 'blue', name: 'rain' },
				temp: { color: 'red', name: 'thermometer' },
				gas: { color: 'yellow', name: 'cloud' },
				animal: { color: 'green', name: 'paw' },
				population: { color: 'green', name: 'paw' }
			};
		for (let i = 0; i < this.props.planet.numRegions; i++) {
			const regionIcons = [];
			const maxSensors = [];
			// add all sensors in this region
			regionIcons.push(
				...sensors
					.filter(sensor => sensor.region === i)
					.map((sensor, index) => {
						console.log("Why",sensor.dataType);
						const iconOptions = sensorIcons[sensor.dataType];
						return (
							<Icon
								circular
								inverted
								key={index}
								color={iconOptions.color}
								name={iconOptions.name}
								size="small"
							/>
						);
					})
			);

			// add all animals
			const regionAnimals = this.props.planet.regions[i].animals,
				playerAnimals = Object.keys(regionAnimals).filter(
					animal =>
						this.props.player.animals.indexOf(animal) !== -1 && regionAnimals[animal].amount > 0
				);
			for (let j = 0; j < playerAnimals.length; j++) {
				regionIcons.push(
					<span key={`${i}${j}`}>
						<Icon circular inverted color="purple" name="paw" size="tiny" />
					</span>
				);
			}

			//Find the longest collected sensors of each type for each region
			for (let type of ["temp","rain", "gas"]) {
				const collectedSensors = []
				collectedSensors.push(
					...this.props.player.collectedSensors
						.filter(sensor => sensor.planet === this.props.planetIndex)
						.filter(sensor => sensor.region === i)
						.filter(sensor => sensor.dataType === type)
						
				);
				
				let longestSensor = null;
				let longestMonth = -1;
				for (let sensor of collectedSensors){
					if (sensor.endMonth - sensor.startMonth >= longestMonth) {
						longestSensor = sensor;
						longestMonth = sensor.endMonth - sensor.startMonth;
					}
				}

				if(longestSensor !== null) {
					const iconOptions = sensorIcons[longestSensor.dataType]
					maxSensors.push(
						<div key={`elements${i}`}>
							&emsp;
							<Icon
								circular
								inverted
								color={iconOptions.color}
								name={iconOptions.name}
								size="small"
							/>
							{`month ${longestSensor.startMonth} - ${longestSensor.endMonth}`} 
						</div>
					)
				}

			}

			
			if (maxSensors.length !== 0) {
				collectedRegions.push(
					<div key={`elements${i}`}>
						<li>{`Region ${i + 1}`}
							&emsp;{maxSensors.map(icon => icon)}
						</li>
					</div>
				)
			}

			if (regionIcons.length !== 0) {
				regionElements.push(
					<div key={`elements${i}`}>
						<li>{`Region ${i + 1}`}
							<br />&emsp;{regionIcons.map(icon => icon)}
						</li>
					</div>
				);
			}
		}

		if(regionElements.length === 0) {
			regionElements.push(
				<div>
					&emsp;No sensors deployed
				</div>
			)
		}

		if(collectedRegions.length === 0) {
			collectedRegions.push(
				<div>
					&emsp;No data collected
				</div>
			)
		}

		

		return (
			
				<Grid columns = "equal" divided>
					<GridRow>
						<GridColumn>
							Deployed Sensors:
							{regionElements.map(element => element)}
						</GridColumn>

						<GridColumn>
							Longest Sensor Data Collected:
							{collectedRegions.map(element => element)}
						</GridColumn>
					</GridRow>
				</Grid>
			
			
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	player: state.planetGame.player,
	planet: state.planetGame.world.planets[ownProps.planetIndex]
});

export default connect(mapStateToProps)(PlanetCard);
