import React, { Component } from "react";
import { Message,Icon,Grid,Label} from "semantic-ui-react";

// Description: Displays Game Info and handles status change
// Parent: TeacherControlScreen
// Children: None
// Props Used:
//      gameCode (String)
//      gameState (String)
//      gameType (String)
//      handleStateButton (Function)

class TeacherGameInfo extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    render(){
        return(
        <div>
           <Message style={{ marginLeft: "0.7em", marginRight: "0.7em" }}>
                <br/>
                <br/>
                <Label attached="top" as="h3" color="black" size="big">
                    Game Information
                </Label>

                <Grid columns = {2} divided>
                    <Grid.Row>

                    <Grid.Column>
                        <div align = "left">
                            <p>
                                <Icon name = "game"/>
                                <b>Type:</b>
                                {" "+this.props.gameType}
                            </p>
                            <p>
                                <Icon name = "keyboard"/>
                                <b>Code:</b>
                                {" "+this.props.gameCode}
                            </p>
                            <p>
                                <Icon name = "users"/>
                                <b>Status:</b>
                                {" "+this.props.gameState}
                            </p>
                        </div>
                    </Grid.Column>

                    <Grid.Column>
                        <br/>
                        {this.props.handleStateButton}
                    </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Message>
        </div>
        )
    }
}

export default TeacherGameInfo;