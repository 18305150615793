import React, { Component } from 'react';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Header, Button, Dropdown } from 'semantic-ui-react';
import BasicGreyPage from '../components/BasicGreyPage';
import GamesTable from '../components/GamesTable';
import PlayersTable from '../components/PlayersTable';
import YesNoDialog from '../components/YesNoDialog';

// const weekTimeLimit = 6.048e+8;
// const monthTimeLimit = 2.628e+9;
// const yearTimeLimit = 3.154e+10;

class AdminContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            virusGames: [],
            virusPlayers: [],
            tocGames: [],
            tocPlayers: [],
            selectedType: "",
            showFilterDialog: false,
            filterTimePeriod: "All",
            filterGameType: "All",
        };
    }

    // set listener for rejoined players
    UNSAFE_componentWillMount() {
        if (this.props.auth.isEmpty || this.props.auth.isAnonymous) {
			// should check if has account, if yes set to other
			this.props.history.push({
				pathname: '/'
			}); // go home
		}

        this.props.firestore.setListener(
            {
                collection: "tgames",
                orderBy: "lastPause",
            },
            doc => this.listenToArray(doc, "ToC"),
        );
        this.props.firestore.setListener(
            {
                collection: "vgames",
                orderBy: "lastPause",
            },
            doc => this.listenToArray(doc, "Virus"),
        );
        this.props.firestore.setListener(
            {
                collection: "tplayers",
                orderBy: "lastLogin"
            },
            doc => this.listenToPlayerArray(doc, "ToC"),
        );
        this.props.firestore.setListener(
            {
                collection: "vplayers",
                orderBy: "lastLogin",
            },
            doc => this.listenToPlayerArray(doc, "Virus"),
        );
    }

    listenToArray = (data, gameType) => {
        let gamesList = [];
        data.forEach(item => {
            item.type = gameType;
            let lastPauseTime = item.data().lastPause;
            // let currTime = new Date();
            // let timeLimitMS = 6.048e+8;
            if (lastPauseTime) {
            //     let lastPause = new Date(lastPauseTime.seconds * 1000).getTime();
            //     if (currTime - lastPause <= timeLimitMS) {
            //         gamesList.push(item);
                gamesList.unshift(item);
            }   
            // }
        });
        switch (gameType) {
            case "ToC":
                this.setState({ tocGames: gamesList });
                break;
            case "Virus":
                this.setState({ virusGames: gamesList });
                break;
            default:
                break;
        }
    }

    listenToPlayerArray = (data, gameType) => {
        let playersList = [];
        data.forEach(item => {
            item.type = gameType;
            let lastLoginTime = item.data().lastLogin;
            // let currTime = new Date();
            // let timeLimitMS = 6.048e+8;
            if (lastLoginTime) {
                // let lastLogin = new Date(lastLoginTime.seconds * 1000).getTime();
                // if (currTime - lastLogin <= monthTimeLimit) {
                //     playersList.unshift(item);
                //     console.log(item.data());
                // }   
                playersList.unshift(item);
            }
        });
        switch (gameType) {
            case "ToC":
                this.setState({ tocPlayers: playersList });
                break;
            case "Virus":
                this.setState({ virusPlayers: playersList });
                break;
            default:
                break;
        }
    }

    getNewData = () => {
        let timeLimitMS = 0;
        switch(this.state.filterTimePeriod) {
            case "All":
                timeLimitMS = 3.154e+11;
                break;
            case "week":
                timeLimitMS = 6.048e+8;
                break;
            case "month":
                timeLimitMS = 2.628e+9;
                break;
            case "3 months":
                timeLimitMS = 7.884e+9;
                break;
            case "year":
                timeLimitMS = 3.154e+10;
                break;
            default:
                break;
        }

        const currentTime = Date.now();
        const newDate = new Date(currentTime - timeLimitMS);
        console.log(currentTime + timeLimitMS)
        this.props.firestore.get(
            {
                collection: "tgames",
                where: ['lastPause', ">=", newDate]
            }).then((data) => {
                this.listenToArray(data, "ToC")
                console.log(data.docs);
        })
        this.props.firestore.get(
            {
                collection: "vgames",
                where: ['lastPause', ">=", newDate]
            }).then((data) => {
                this.listenToArray(data, "Virus")
                console.log(data.docs);
        })
        this.setState({ showFilterDialog: false });
    }

    convertSecondsToDate = seconds => {
        let date = new Date(seconds * 1000);
        return date.toString();
    }

    selectType = (e, data) => {
        console.log(data.value);
        this.setState({ selectedType: data.value });
    }

    render() {
        const dataTypeOptions = [
            {
                key: "Games",
                text: "Games",
                value: "Games",
            },
            {
                key: "Players",
                text: "Players",
                value: "Players",
            },
        ]

        const timeOptions = [
            {
                key: "week",
                text: "week",
                value: "week"
            },
            {
              key: "month",
              text: "month",
              value: "month"
            },
            {
              key: "3 months",
              text: "three months",
              value: "3 months"
            },
            {
              key: "year",
              text: "year",
              value: "year"
            },
            {
                key: "All",
                text: "All",
                value: "All"
            }
        ]
        
        const gameOptions = [
            {
                key: "Virus",
                text: "Virus",
                value: "Virus"
            },
            {
                key: "ToC",
                text: "ToC",
                value: "ToC"
            },
            {
                key: "All",
                text: "All",
                value: "All"
            },
        ]

        let gamesList = [];
        let playersList = [];
        
        switch (this.state.filterGameType) {
            case "All":
                gamesList = this.state.virusGames.concat(this.state.tocGames);
                playersList = this.state.virusPlayers.concat(this.state.tocPlayers);
                break;
            case "Virus":
                gamesList = this.state.virusGames;
                playersList = this.state.virusPlayers;
                break;
            case "ToC":
                gamesList = this.state.tocGames;
                playersList = this.state.tocPlayers;
                break;
            default:
                break;
        }

        const center = (
            <div>
                <Dropdown 
                    placeholder="Select data type" 
                    selection 
                    options={dataTypeOptions}
                    onChange={this.selectType} />
                <Button 
                    icon="filter" 
                    size="large" 
                    floated="right" 
                    onClick={()=>{this.setState({ showFilterDialog: true})}}    
                />
                {this.state.selectedType === "Games" && (
                    <GamesTable 
                        gamesList={gamesList} 
                        convertDate={this.convertSecondsToDate} 
                    />
                )}
                {this.state.selectedType === "Players" && (
                    <PlayersTable 
                        playersList={playersList} 
                        convertDate={this.convertSecondsToDate} 
                    />
                )}
                
            </div>
        )

        const filterDatabaseFields = (
            <div>
                <Grid>
                <Grid.Row>
                    <Grid.Column width={8} textAlign="left" style={{ alignSelf: 'center' }}>
                        <Header as="h4" style={{ fontWeight: 'normal'}}>
                        Show items for this game type
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="left">
                        <Dropdown
                        placeholder="Select game type"
                        fluid
                        selection
                        options={gameOptions}
                        onChange={(event, data) => this.setState({ filterGameType: data.value })}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8} textAlign="left" style={{ alignSelf: 'center' }}>
                        <Header as="h4" style={{ fontWeight: 'normal'}}>
                        Show items in the past
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="left">
                        <Dropdown
                        placeholder="Select time period"
                        fluid
                        selection
                        options={timeOptions}
                        onChange={(event, data) => this.setState({ filterTimePeriod: data.value })}
                        />
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </div>
        );

        return(
            <div>
                <BasicGreyPage center={center} />
                {this.state.showFilterDialog && (
                    <YesNoDialog
                        header="Add filters"
                        description={filterDatabaseFields}
                        yesText="Confirm"
                        yesAction={this.getNewData}
                        noText="Cancel"
                        noAction={() => this.setState({ showFilterDialog: false })}
                        width="420px"
                    />
                )}   
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    };
}

export default compose(
    firebaseConnect(),
    connect(mapStateToProps, null),
    firestoreConnect((props, store) => [
        {
            collection: 'games'
        }
    ])
)(AdminContainer);