import React, { Component } from 'react';
import { connect } from 'react-redux';
import { placeSensor, retrieveSensor } from '../../playerApi';

import { Button, Popup, List, Grid, Card, Header, Icon } from 'semantic-ui-react';
import { SENSOR_ICONS } from '../../constants';
//import { Icon } from 'semantic-ui-carousel-react';

/**
 * A panel providing sensor placement/retrival functionality for the player
 * Parent: PlanetInteractionmenu
 * Children: None
 *
 * @param {number} planet the index of the current planet
 * @param {number} region the index of the selected region
 */

class RegionSensors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedType: '',
			popupMessage: ''
		};
	}

	selectType = value => this.setState({ selectedType: value });

	atCapacity = () => this.props.player.activeSensors.length >= this.props.player.sensorsCapacity;

	togglePopup = () => {
		let message;
		if (this.state.popupMessage !== '') message = '';
		else if (this.state.selectedType === '') message = 'Please select a sensor type.';
		else if (this.atCapacity()) message = 'Sensor limit reached!';
		else message = '';
		this.setState({ popupMessage: message });
	};

	render() {
		const disabled = this.props.regionIndex === -1;

		return (
			<div>
				<Grid
					columns={2}
					textAlign="center"
					style={{ minHeight: '100%', margin: '0' }}
					verticalAlign="middle"
				>
					<Grid.Row>
						<Header>
							Sensors Left: {this.props.player.sensorsCapacity - this.props.player.activeSensors.length}
						</Header>
					</Grid.Row>
					<Grid.Row>
						
						<Header>Add Sensors: </Header>
						<Grid.Column width={15}>
							<div style={{ marginBottom: '10px' }}>
								<Button
									size="medium"
									compact
									basic={this.state.selectedType !== 'temp'}
									color="red"
									content="Temp"
									onClick={() => this.selectType('temp')}
								/>
								<Button
									size="medium"
									compact
									basic={this.state.selectedType !== 'rain'}
									color="blue"
									content="Rain"
									onClick={() => this.selectType('rain')}
								/>
								<Button
									size="medium"
									compact
									basic={this.state.selectedType !== 'gas'}
									color="yellow"
									content="Gas"
									onClick={() => this.selectType('gas')}
								/>
							</div>
							<Popup
								trigger={
									<Button
										size="small"
										icon="plus"
										color="green"
										onClick={() => {
											this.togglePopup();
											if (
												!this.atCapacity() &&
												this.props.regionIndex !== -1 &&
												this.state.selectedType !== ''
											) {
												placeSensor(
													this.props.planetIndex,
													this.props.regionIndex,
													this.state.selectedType
												);
												this.setState({ selectedType: '' }); //resetting sensor type
											}
										}}
									/>
								}
								content={this.state.popupMessage}
								open={this.state.popupMessage !== ''}
								disabled={disabled}
								position="top right"
								style={{
									color: 'red'
								}}
							/>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Header>Current Sensors:</Header>
					</Grid.Row>
					<Grid.Row>
						<Card>
							<List divided size="large" verticalAlign="middle">
								{this.props.player.activeSensors
									.filter(
										sensor =>
											sensor.planet === this.props.planetIndex &&
											sensor.region === this.props.regionIndex
									)
									.map((sensor, index) => (
										<List.Item key={index}>
											<List.Content floated="right">
												<Button
													size="small"
													icon="hand paper"
													color="green"
													onClick={() => retrieveSensor(sensor.timeId)}
													disabled={this.props.currentMonth - sensor.startMonth === 0}
													sensor={sensor}
												/>
											</List.Content>
											<List.Content>
												<Icon 
													circular
													inverted
													color={SENSOR_ICONS[sensor.dataType].color}
													name={SENSOR_ICONS[sensor.dataType].name}
													size="tiny"
												/>
												{sensor.dataType} sensor ({this.props.currentMonth - sensor.startMonth}{' '}
												months)
											</List.Content>
										</List.Item>
									))}
							</List>
						</Card>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	player: state.planetGame.player,
	currentMonth: state.planetGame.world.currentMonth
});

export default connect(mapStateToProps)(RegionSensors);

// render() {

//   const disabled = this.props.regionIndex === null;
//   return (
//     <div>
//       <Grid columns={2} textAlign="center" style={{ minHeight: '100%', margin: '0' }} verticalAlign="middle">
//         <Grid.Row>
//           <Grid.Column width={12}>
//               <span>
//                   <Button basic color='blue' content='Rain' onClick={() => this.selectType('rain')}/>
//                   <Button basic color='red' content='Temp' onClick={() => this.selectType('temp')}/>
//                   <Button basic color='green' content='Gas' onClick={() => this.selectType('gas')}/>
//               </span>
//             {/* <Dropdown
//               placeholder="Choose sensor type"
//               fluid
//               selection
//               disabled={disabled}
//               options={this.state.sensorType}
//               onChange={this.selectType}
//             /> */}
//           </Grid.Column>
//           <Grid.Column width={3}>
//             <Popup
//               trigger={<Button
//                 size="small"
//                 icon='plus'
//                 onClick={this.placeSensor}
//                 color={this.state.placeClicked}
//               />}
//               content={this.popUpMessage}
//               on='click'
//               disabled={disabled}
//               open={this.state.popUpOpen}
//               onClose={this.handleClose}
//               onOpen={this.handleOpen}
//               position='top right'
//               style={popUpStyle}
//             />
//           </Grid.Column>
//         </Grid.Row>

//         <Grid.Row>
//           <Card>
//             <List divided size='large' verticalAlign='middle'>
//                 {this.state.sensorsList.map((sensor) => (
//                   <List.Item>
//                       <List.Content floated='right'>
//                         <Button
//                           size="small"
//                           // content="Remove Sensor"
//                           icon="hand paper"
//                           onClick={this.removeSensor}
//                           color={this.state.removeClicked}
//                           sensor={sensor}
//                         />
//                         </List.Content>
//                       <List.Content>
//                         {sensor.dataType} sensor ({this.state.curMonth - sensor.startMonth} Months)
//                       </List.Content>
//                   </List.Item>
//                 )
//               )}
//             </List>
//           </Card>
//         </Grid.Row>
//       </Grid>
//     </div>
//   );
// }
