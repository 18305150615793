export const ignoreXFunctionCalls = (fn, x) => {
    let count = 0;
    return (args) => {
      count++;
      if (count <= x) {
        return count;
      } else {
        return fn(args);
      }
    };
}

const ignoreFirstFunctionCall = (fn) => {
    return ignoreXFunctionCalls(fn, 1);
}

export default ignoreFirstFunctionCall;