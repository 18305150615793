import React, { Component } from 'react';
import { Statistic } from 'semantic-ui-react';
import BasicGreyPage from '../../../core/components/BasicGreyPage';
import QrComponents from './QrComponents';
import RemoteComponents from './RemoteComponents';
import BoatStore from './BoatStore'
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import LogoutConfirmation from '../../../core/components/LogoutConfirmation';
import YesNoDialog from '../../../core/components/YesNoDialog';
import DimIcon from '../../../core/components/DimIcon';
import firebase from 'firebase';
import { isLoaded } from 'react-redux-firebase';
import TradeQRComponents from './TradeQRComponents';

// Description: Loads all player page components for ToC
// Parent: PlayerContainer
// Children: BoatStore, TradeQRComponents, QrComponents
// Props Used:
//      auth (Object)
//      firebase (Object)
//      firestore (Object)
//      game (String)
//      loggedOut (Function)
//      players (String)

const db = firebase.firestore();

class TocPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doubleNotify: true,
            playerData: null,
            gameState: null,
            gameCode: null,
            gameListener: false,
            currentConfiguration: '0',
            buttonPressed: false,
            showLogoutConfirmation: false,
            seasonNumber: 1,
            trading: false,
            qrMode: false,
            stations: {}
        };
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
        this.playerListen();
    }

    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        this.props.firestore.unsetListener({
            collection: this.props.game,
            doc: this.state.gameCode,
        });
        this.props.firestore.unsetListener({
            collection: this.props.players,
            doc: this.props.auth.uid,
        });
    };

    playerListen = () => {
        this.props.firestore.setListener(
            {
                collection: this.props.players,
                doc: this.props.auth.uid,
            },
            playerdata => {
                if (
                    playerdata.data() !== null &&
                    playerdata.data() !== undefined
                ) {
                    const newPlayerData = playerdata.data();
                    this.setState({
                        playerData: newPlayerData,
                        gameCode: newPlayerData.currentGameId,
                        doubleNotify: newPlayerData.doubleAgent && this.state.doubleNotify
                    });
                }
                if (!this.state.gameListener) {
                  db.collection(this.props.game).doc(this.state.gameCode).get().then(doc => {
                    this.setState({seasonNumber: doc.data().seasonNumber});
                    this.setState({ gameListener: true });
                    this.gameListen(this.state.gameCode);
                  });

                }
            },
        );
    };

    gameListen = gameCode => {
        if(!gameCode){
            this.props.firebase.logout();
            this.props.loggedOut();
            return;
        } 
        this.props.firestore.setListener(
            {
                collection: this.props.game,
                doc: gameCode,
            },
            gamedata => {
                this.setState({
                    gameState: gamedata.data().gameState,
                    trading: gamedata.data().trading,
                    qrMode:!gamedata.data().remotePlay,
                    stations:gamedata.data().configurations[this.state.currentConfiguration].stations || {}
                });
            },
        );
    };

    render() {
        if (this.state.showLogoutConfirmation) {
            return (
                <LogoutConfirmation
                    logoutAction={() => {
                        this.setState({ buttonPressed: true });
                        this.props.firebase.logout();
                        this.props.loggedOut();
                    }}
                    backAction={() =>
                        this.setState({ showLogoutConfirmation: false })
                    }
                />
            );
        }

        if (!this.state.buttonPressed) {
            return (
                <YesNoDialog
                    header="Enter Game"
                    description={
                        'You are about to enter the game with code ' +
                        this.state.gameCode +
                        '.  Would you like to continue or log out?'
                    }
                    yesText="Continue"
                    yesAction={() => {
                        this.setState({ buttonPressed: true });
                    }}
                    noText="Log Out"
                    noAction={() =>
                        this.setState({ showLogoutConfirmation: true })
                    }
                />
            );
        }

        if (
            this.state.gameCode === '0' ||
            !isLoaded(this.props.auth) ||
            this.state.gameState === 'initializing' ||
            this.state.gameState === 'unknown'
        ) {
            return (
                <DimIcon 
                    message="Waiting for Game to Start"
                    iconName="pause" 
                />
            );
                
        }

        if (this.state.playerData.gameOver) {
            return (
                <DimIcon 
                    message="You had too little fish at the beginning of the season" 
                    iconName="user close"
                />
            );
                
        }

        if (this.state.gameState !== 'running') {
            let center = <div>
              <Statistic
              label="Fish Caught"
              value={this.state.playerData
                  ? this.state.playerData.fishCaught
                  : null}
              />
              <br />
              <Statistic
                label=""
                value={this.state.playerData
                    ? (this.state.playerData.boat.size + " " + (this.state.playerData.boat.research ? "research" : "") +
                     " " + (this.state.playerData.boat.trawler ? "trawler" : "Boat")
                    )
                    : null}
                size="tiny"
              />
              <br />
              <BoatStore
                  players={this.props.players}
                  purchases={this.state.playerData.store}
                  currentBoat={this.state.playerData.boat}
                  money={this.state.playerData.fishCaught}
                  uid={this.props.auth.uid}
                  db={db}
              />

              {this.state.trading && (
                <div>
                    <br />
                    <TradeQRComponents
                        players={this.props.players}
                        uid={this.props.auth.uid}
                        fishCaught={this.state.playerData.fishCaught}
                        db={db}    
                    />
                </div>
                
              )}
              
            </div>

            return <BasicGreyPage
                top={<div style={{ marginBottom: '5vh' }} />}
                center={center}
                bottom={<div style={{ marginBottom: '15vh' }} />}
            />;
        }


        const center = (
            <div>
              <Statistic
                label="Time Left"
                value={this.state.playerData
                    ? this.state.playerData.timeLeft
                    : null}
              />
              <Statistic
                label="Fish Caught"
                value={this.state.playerData
                    ? this.state.playerData.fishCaught
                    : null}
              />
              {this.state.qrMode ?
              <QrComponents
                  game={this.props.game}
                  gameCode={this.state.gameCode}
                  players={this.props.players}
                  uid={this.props.auth.uid}
                  currentConfiguration={this.state.currentConfiguration}
                  generationPause={false}
                  timeLeft={this.state.playerData.timeLeft}
                  boat={this.state.playerData.boat}
                  population={this.state.playerData.population}
                  fishCaught={this.state.playerData.fishCaught}
                  db={db}/>
                  : 
                <RemoteComponents
                  game={this.props.game}
                  gameCode={this.state.gameCode}
                  players={this.props.players}
                  uid={this.props.auth.uid}
                  currentConfiguration={this.state.currentConfiguration}
                  generationPause={false}
                  timeLeft={this.state.playerData.timeLeft}
                  boat={this.state.playerData.boat}
                  population={this.state.playerData.population}
                  fishCaught={this.state.playerData.fishCaught}
                  lastStation={this.state.playerData.lastStation}
                  firestore={this.props.firestore}
                  stations={this.state.stations}
                  db={db}/>
              }
              <br />

              <Statistic
                label="Your Boat"
                value={this.state.playerData
                    ? (this.state.playerData.boat.size + " " + (this.state.playerData.boat.research ? "research" : "") +
                     " " + (this.state.playerData.boat.trawler ? "trawler" : "Boat")
                    )
                    : null}
                size="tiny"
              />
              <br />

            </div>
        );

        return (
            <div style={{ height: '100%' }}>

                {this.state.doubleNotify && (
                    <SingleButtonDialog
                        description={
                            'You are the double agent'
                        }
                        buttonText="Close"
                        buttonAction={() =>
                          this.setState({ doubleNotify: false})
                      }
                    />
                )}

                <BasicGreyPage
                    top={<div style={{ marginBottom: '5vh' }} />}
                    center={center}
                    bottom={<div style={{ marginBottom: '15vh' }} />}
                />
            </div>
        );
    }
}

export default TocPlayer;
