export const SET_CURRENT_MESSAGE = 'SET_CURRENT_MESSAGE';
export const CLEAR_CURRENT_MESSAGE = 'CLEAR_CURRENT_MESSAGE';

const setCurrentMessage = (message) => {
    return {
        type: SET_CURRENT_MESSAGE,
        payload: message
    };
};

const clearCurrentMessage = () => {
    return {
        type: CLEAR_CURRENT_MESSAGE
    };
};

export { setCurrentMessage, clearCurrentMessage };