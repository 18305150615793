import React from 'react';
import { Table } from 'semantic-ui-react';


const GamesTable = (props) => {
    const {
        gamesList, convertDate
    } = props;

    return (
        <div>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Game Type</Table.HeaderCell>
                        <Table.HeaderCell>Game ID</Table.HeaderCell>
                        <Table.HeaderCell>Last Played</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {/* <Table.Row>
                        <Table.Cell>
                            <b>
                                Tragedy of the Commons
                            </b>
                        </Table.Cell>
                    </Table.Row> */}
                    {gamesList.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {item.type}
                            </Table.Cell>
                            <Table.Cell>
                                {item.id}
                            </Table.Cell>
                            <Table.Cell>
                                {convertDate(item.data().lastPause.seconds)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <div>
                Total number of games: {gamesList.length}
            </div>
        </div>
    )
}

export default GamesTable;