import React from 'react';
import { Header, Dropdown, Grid, Icon } from 'semantic-ui-react';

// Description: Renders the patient zero label and dropdown
// Parent: VirusTeacherGameScreen
// Children: None
// Props Used: 
//		disabled (Bool)
//		p0Absent (Bool)
//		errorAction (Function)
//		immunePlayers (Array)
//		list (Array)
//		onChange (Function)
//		value (String)

const PatientZeroSelector = props => {
	const { value, disabled, list, onChange, p0Absent, errorAction, immunePlayers } = props;
	const option =
		list &&
		list
			.filter(item => {
				let isImunne = false;
				immunePlayers.forEach(person => {
					if (person.name === item.name) {
						isImunne = true;
					}
				});
				return !isImunne;
			})
			.map(item => ({ text: item.name, value: item.id }));

	return (
		<Grid>
			<Grid.Column
				width={p0Absent ? 5 : 7}
				textAlign="left"
				style={{ alignSelf: 'center' }}
			>
				<Header as="h4" style={{ fontWeight: 'normal' }}>
					Patient Zero
				</Header>
			</Grid.Column>

			{p0Absent && (
				<Grid.Column width={2} textAlign="right">
					<Icon
						style={{ cursor: 'pointer', marginTop: '0.7em' }}
						name="exclamation triangle"
						color="red"
						onClick={() => errorAction()}
					/>
				</Grid.Column>
			)}

			<Grid.Column width={9} textAlign="left">
				<Dropdown
					fluid
					placeholder="Select a player"
					options={option}
					selection
					value={value}
					onChange={(e, { value }) => onChange(value)}
					disabled={disabled}
					error={p0Absent}
				/>
			</Grid.Column>
		</Grid>
	);
};

export default PatientZeroSelector;
