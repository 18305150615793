import React, { Component } from 'react';
import LineChart from '../../../core/components/LineChart';

// Description: Tracks student data 
// Parent: VirusTeacherGameScreen
// Children: LineChart
// Props Used:
//		firestore (Object)
//		game (String)
//		gameCode (String)
//		numSickPlayers (Int)

class StudentData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sickTime: 10,
			data: {},
			firstLoad: true,
			categories: [],
			series: []
		};
	}

	convertDataToAxes = (data) => {
		var x = [];
		var y = [];
		Object.keys(data).forEach(key => {
			x.push(parseInt(key));
			y.push(data[key]);
		});
		return [x, [{name: 'number', data: y}]];
	}

	//A listener for the game
	UNSAFE_componentWillMount() {
		this.props.firestore.setListener(
			{
				collection: this.props.game,
				doc: this.props.gameCode,
				storeAs: 'studentData'
			},
			doc => {
				console.log(doc.data().data);
				let axes = this.convertDataToAxes(doc.data().data);
				this.setState({
					data: doc.data().data,
					categories: axes[0],
					series: axes[1],
					sickTime: Object.keys(doc.data().data).length * 10
				});
				//if the game is running, insert data into the database every ten seconds
				if (this.state.firstLoad) {
					if (doc.data().gameState === 'running') {
						this.timer = setInterval(() => {
							console.log(this.state.data);
							//this.setState({[this.state.sickTime]: this.props.numSickPlayers})
							// var timeseries = this.state.data.concat(
							// 	this.props.numSickPlayers
							// );
							var timeSeries = Object.assign({}, this.state.data);
							timeSeries[this.state.sickTime] = this.props.numSickPlayers;
							let axes = this.convertDataToAxes(timeSeries);
							console.log(timeSeries)
							this.setState({ data: timeSeries, categories: axes[0], series: axes[1], firstLoad: false });

							this.props.firestore.update(
								{
									collection: this.props.game,
									doc: this.props.gameCode
								},
								{
									data: this.state.data
								}
							);
							this.setState({ sickTime: this.state.sickTime + 10 });
						}, 10000);
						this.setState({ firstLoad: false });
					}
				}
				//Clear timer if changed to a state that's not running
				if (!(doc.data().gameState === 'running')) {
					if (this.timer) {
						clearInterval(this.timer);
						this.setState({ firstLoad: true });
					}
				}
				//If the restart game button was pressed, reset the data
				if (doc.data().gameRestart) {
					if (this.timer) {
						clearInterval(this.timer)
					}
					this.setState({ sickTime: 10, data: {}, firstLoad: true });
					this.props.firestore.update(
						{
							collection: this.props.game,
							doc: this.props.gameCode
						},
						{
							gameRestart: false
						}
					);
				}
			}
		);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		this.props.firestore.unsetListener({
			collection: this.props.game,
			doc: this.props.gameCode,
			storeAs: 'studentData'
		});
	}

	render() {
		return (
			<div style={{ width: '100%', height: '35vh' }}>
				<LineChart
					categories={this.state.categories}
					series={this.state.series}
					// layout={{
					// 	xaxis: {
					// 		title: {
					// 			text: 'Time (10s of seconds)'
					// 		}
					// 	},
					// 	yaxis: {
					// 		title: {
					// 			text: 'Number of sick players'
					// 		}
					// 	}
					// }}
					xtitle="Time (seconds)"
					ytitle="Number of sick players"
					// marker={{ color: 'black' }}
					// mode="lines+points"
				/>
			</div>

		);
	}
}

export default StudentData;
