import React, { Component } from 'react';
// import { Grid, Header} from 'semantic-ui-react';
import YesNoDialog from '../../../core/components/YesNoDialog';
import SliderWithField from '../../../core/components/SliderWithField';


class TradeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fishToGive: parseInt(this.props.fishCaught / 2),
        };
    }

    render() {
        const tradeFields = (
            <div>
                <SliderWithField
                    min={0}
                    max={this.props.fishCaught}
                    field="Amount of fish being given"
                    label="fish"
                    leftText="0"
                    rightText={this.props.fishCaught.toString()}
                    value={this.state.fishToGive}
                    onChange={val => this.setState({ fishToGive: val })}
                />
            </div>
        );
        return (
            <div>
                <YesNoDialog
                    header="Give fish to another player"
                    description={tradeFields}
                    yesText="Confirm"
                    yesAction={() => {this.props.yesAction(this.state.fishToGive)}}
                    noText="Cancel"
                    noAction={this.props.noAction}
                    width="420px"
                />
            </div>
        );
    }
}

export default TradeDialog;