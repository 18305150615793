import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAnimalPopulation, updateAnimalPopulation } from '../../playerApi';

import { Button, Header, Icon, Menu, Label } from 'semantic-ui-react';
import { Accordion } from 'semantic-ui-react';

/**
 * A panel providing both animal and sensor placement/removal functionality for the player
 * Parent: PlanetInteractionMenu
 * Children: None
 *
 * @param {number} planetIndex the index of the current planet
 * @param {number} regionIndex the index of the selected region
 */

class RegionAnimals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			animalName: '',
			processing: false
		};
	}

	// Handler for choosing an animal in accordion
	chooseAnimal = async (e, { value }) => {
		await getAnimalPopulation(value, this.props.planetIndex, this.props.regionIndex);
		this.setState({ animalName: value });
	};

	componentDidMount = () => {
		this.refreshPopulations();
	};

	// Updates the animal population in the region whenever the month changes
	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.world.currentMonth !== this.props.world.currentMonth) this.refreshPopulations();
	};

	refreshPopulations = () => {
		Object.keys(this.props.regionAnimals).forEach(animalName =>
			getAnimalPopulation(animalName, this.props.planetIndex, this.props.regionIndex)
		);
	};

	// Convenience function for calling updateAnimalPopulation
	updatePopulation = async amount => {
		this.setState({ processing: true });
		await updateAnimalPopulation(
			this.state.animalName,
			this.props.planetIndex,
			this.props.regionIndex,
			amount
		);
		this.setState({ processing: false });
	};

	render = () => {
		const animalName = this.state.animalName;
		const regionPopulation = Math.floor(this.props.regionAnimals[animalName]?.amount ?? 0);
		const shipPopulation = Math.floor(this.props.animals[animalName]?.populationInShip ?? 0);
		const goalPopulation = Math.floor(this.props.animals[animalName]?.goalPopulation ?? 0);

		const disableButtons = this.props.regionIndex === -1 || this.state.animalName === '' || this.state.processing;

		return (
			<div style={{ height: '100%' }}>
				<Header>Current Animals</Header>
				<Accordion styled width="100%">
					{Object.values(this.props.animals).map((animal, index) => (
						<div>
							<Accordion.Title
								active={this.state.animalName === animal.name}
								key={index}
								onClick={() => this.setState({ animalName: animal.name })}
							>
								<Header>
									{animal.name} on Region:{' '}
									{Math.floor(this.props.regionAnimals[animal.name]?.amount ?? 0)}
									<Icon name="dropdown" />
								</Header>
							</Accordion.Title>
							<Accordion.Content fluid active={this.state.animalName === animal.name}>
								<div>
									<Button
										basic
										color="red"
										size="medium"
										icon="double angle left"
										disabled={disableButtons || regionPopulation <= 0}
										onClick={() => this.updatePopulation(-Math.min(100, regionPopulation))}
									/>
									<Button
										basic
										color="red"
										size="medium"
										icon="angle left"
										disabled={disableButtons || regionPopulation <= 0}
										onClick={() => this.updatePopulation(-Math.min(10, regionPopulation))}
									/>
									<Menu size="medium" compact>
										<Menu.Item size={'small'}>{regionPopulation}</Menu.Item>
									</Menu>
									<Button
										basic
										color="blue"
										size="medium"
										icon="angle right"
										disabled={disableButtons || shipPopulation <= 0}
										onClick={() => this.updatePopulation(Math.min(10, shipPopulation))}
									/>
									<Button
										basic
										color="blue"
										size="medium"
										icon="double angle right"
										disabled={disableButtons || shipPopulation <= 0}
										onClick={() => this.updatePopulation(Math.min(100, shipPopulation))}
									/>
								</div>
								<br />
								<Label size="medium">
									On Ship: <Label.Detail>{shipPopulation}</Label.Detail>
								</Label>
								<Label size="medium">
									Goal: <Label.Detail>{goalPopulation}</Label.Detail>
								</Label>
							</Accordion.Content>
						</div>
					))}
				</Accordion>
			</div>
		);
	};
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.contracts.animals,
	regionAnimals:
		ownProps.regionIndex === -1
			? {}
			: state.planetGame.world.planets[ownProps.planetIndex].regions[ownProps.regionIndex].animals,
	player: state.planetGame.player,
	world: state.planetGame.world
});

export default connect(mapStateToProps)(RegionAnimals);
