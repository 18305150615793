import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Popup } from 'semantic-ui-react';
import { Menu } from 'semantic-ui-react';

/**
 * The menu bar at the top of the teacher dashboard. (same as planet TeacherMenu)
 * Parent: InfoTeacherGameScreen
 * Children: None
 *
 * @param {function} onHomeClick toggle the prompt to go back to the home page
 * @param {function} onLogoutClick toggle the prompt to log out
 */

class TeacherMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Menu inverted style={{ height: '5%' }}>
				<Menu.Item>
					<img src={'./psimsturtles.png'} alt="Psims Turtles" />
					Psims
				</Menu.Item>

				<Menu.Menu position="right">
					<Menu.Item>
						<Popup
							content="Documentation"
							trigger={
								<Button
									circular
									icon="info"
									color="blue"
									onClick={() =>
										window.open(
											'https://education.mit.edu/wp-content/uploads/2019/07/pSimsVirusGettingStarted.pdf',
											'_blank'
										)
									}
								/>
							}
						/>
					</Menu.Item>

					<Menu.Item>
						<Popup
							content="Home"
							trigger={
								<Button circular icon="home" color="yellow" onClick={this.props.onHomeClick} />
							}
						/>
					</Menu.Item>

					<Menu.Item>
						<Popup
							content="Logout"
							trigger={
								<Button circular icon="sign out" color="red" onClick={this.props.onLogoutClick} />
							}
						/>
					</Menu.Item>
				</Menu.Menu>
			</Menu>
		);
	}
}

export default TeacherMenu;
