import firebase from 'firebase';
import store from '../store/store';
import { GAME_STATUS } from './constants';
import { infoPlayerActions } from './slices/infoPlayerSlice';
import { gameActions } from './slices/gameSlice';



/* 
TIP: to fold all the functions that your cursor is not in, 
—— press ctrl + K, then ctrl 1 ——
(to unfold all, ctrl + K, then ctrl + J) (cmd on Mac)
It'll make this file a lot easier to navigate. Trust me.
*/


// all unsubscribe functions to be called when leaving a page, typically for listeners
const unsubscribes = [];
window.onbeforeunload = () => {
	for (const unsubscribe of unsubscribes) unsubscribe();
	return null;
};

/*
NOTE: All functions are structured like so: 
1) perform the desired interacations with the database
2) update the Redux state accordingly ONLY IF the database was successfully updated
*/

// In the future this can indicate any provider that we support
const dbProvider = 'firebase';

/**************** Convenience ****************/

// Convenience for Redux
const dispatch = store.dispatch;
let state = store.getState();
let gameState = state.infoGame;
unsubscribes.push(
	store.subscribe(() => {
		state = store.getState();
		gameState = state.infoGame;
	})
);

// Convenience for Firebase
const firestore = firebase.firestore;
const games = firestore().collection('infogames');


// Frequent calls
const getGameCode = () => state.core.gameCode;

const getPlayerId = () => gameState.player.id;


/**************** Joining ****************/

/**
 * Adds a player into the game in the database and handles joining
 * @param {string} playerName the name for this player
 * @returns whether or not the player was successfully created
 */
export const joinPlayer = async playerName => {
	console.log('[infoPlayerApi] running createPlayer');
	
	if (
		playerName === undefined ||
		playerName === '' ||
		(await !games
			.doc(getGameCode())
			.collection('players')
			.where('displayName', '==', playerName)
			.get()
			.then(querySnapshot => querySnapshot.empty))
	)
		return false;

	const playerId = firebase.auth().currentUser.uid;
	const player = {
		displayName: playerName,
		shape: "none",
		approve: false,
		denied: false,
		rejoin: false,
		notes: ""
	};

	// update database
	switch (dbProvider) {
		case 'firebase':
			// add player to game
			let success =
				(await games
					.doc(getGameCode())
					.collection('players')
					.doc(playerId)
					.set(player)
					.then(() => true)
					.catch(error => console.error(error))) &&
				(await games
					.doc(getGameCode())
					.update({ players: firestore.FieldValue.increment(1) })
					.then(() => true)
					.catch(error => console.error(error)));

			// update separate list of players in game
			success =
				success === true &&
				(await firestore()
					.collection('games')
					.doc(getGameCode())
					.update({
						players: firestore.FieldValue.arrayUnion(playerId)
					})
					.then(() => true)
					.catch(error => console.error(error)));

			return success === true;
		default:
			return false;
	}
};


/**
 * Handles a player logging back into a game (by entering name and game code)
 * @param {string} playerName the name for this player
 * @returns whether or not the player was successfully created
 */
export const rejoinPlayer = async playerName => {
	console.log('[infoPlayerApi] running rejoinPlayer');
	if (playerName === undefined || playerName === '') return false;
	const playerId = firebase.auth().currentUser.uid;

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.where('displayName', '==', playerName)
				.get()
				.then(async querySnapshot => {
					if (!querySnapshot.empty)
						return await querySnapshot.docs[0].ref
							.update({
								uid: playerId,
								rejoin: true
							})
							.then(() => true);
				})
				.catch(error => console.error(error));

			return success === true;
		default:
			return false;
	}
};


/**
 * Gets the state (e.g. running, paused, etc.) of the current game
 */
export const getGameStatus = async () => {
	console.log('[infoPlayerApi] running getGameStatus');
	let state;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			state = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => docSnapshot.get('gameStatus'))
				.catch(error => console.error(error));
			break;
		default:
			state = undefined;
	}

	// perform Redux action
	dispatch(gameActions.setGameStatus(state));
};



/**
 * Attempt to rejoin the specified game code while player info is cached
 * @returns '' if login was successful, otherwise 'player' or 'code' to indicate where it failed
 */
export const attemptJoin = async () => {
	console.log('[infoPlayerApi] running attemptJoin');
	let id = '';

	switch (dbProvider) {
		case 'firebase':
			const currentId = firebase.auth().currentUser.uid;
			const exists = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => docSnapshot.exists);
			if (!exists) return 'code';

			await games
				.doc(getGameCode())
				.collection('players')
				.doc(currentId)
				.get()
				.then(docSnapshot => {
					// new player
					if (docSnapshot.exists) id = currentId;
				});
			if (id === '') {
				// rejoining player
				const querySnapshot = await games
					.doc(getGameCode())
					.collection('players')
					.where('rejoin', '==', true)
					.where('uid', '==', currentId)
					.get()
					.then(querySnapshot => querySnapshot);
				if (!querySnapshot.empty) id = querySnapshot.docs[0].id;
				else return 'player';
			}

			// at this point, id is guaranteed to be nonempty
			break;
		default:
			break;
	}

	dispatch(infoPlayerActions.setId(id));
	return 'valid';
};


/**
 * Set the listener for game status: 'initializing', 'paused', 'running' (once at game start)
 */
export const listenToGameStatus = async () => {
	console.log('[infoPlayerApi] running setGameStatusListener');

	switch (dbProvider) {
		case 'firebase':
			await games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update game state if changed
				const newGameStatus = docSnapshot.get('gameStatus');
				if (newGameStatus !== gameState.game.gameStatus) {
					if (gameState.game.gameStatus === GAME_STATUS.INITIALIZING) {
						// game finished initializing, get data now
						setUpGame();
					}
					dispatch(gameActions.setGameStatus(newGameStatus));
				}
			});
			return;
		default:
			return;
	}
};


/**
 *  Set the player's shape once the game finishes initializing
 */
export const initializeShape = async () => {
	console.log('[infoPlayerApi] running initializeShape');
	switch (dbProvider) {
		case 'firebase':
			
			await games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update game state if changed
				const newGameStatus = docSnapshot.get('gameStatus');
				if (newGameStatus !== gameState.game.gameStatus) {
					if (gameState.game.gameStatus === GAME_STATUS.INITIALIZING) {
						// game finished initializing, get data now
						setUpGame();
					}
					dispatch(gameActions.setGameStatus(newGameStatus));
				}
			});

			return;
		default:
			return;
	}
}


/**
 * Sets listeners for game state information (month number and players)
 */
const setListeners = async () => {
	console.log('[infoPlayerApi] running setListeners');
	let otherPlayers;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			

			// get players
			otherPlayers = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot =>
					colSnapshot.docs
						.filter(doc => doc.id !== getPlayerId())
						.map(doc => ({
							name: doc.get('displayName'),
							id: doc.id
						}))
				)
				.catch(error => console.error(error));

			// set listener for and players
			const gameUnsub = games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				
				// update players if changed
				const numPlayers = docSnapshot.get('players');
				if (numPlayers !== gameState.game.players.length - 1) {
					const playersList = await docSnapshot.ref
						.collection('players')
						.get()
						.then(colSnapshot =>
							colSnapshot.docs
								.filter(doc => doc.id !== getPlayerId())
								.map(doc => ({
									name: doc.get('displayName'),
									id: doc.id
								}))
						)
						.catch(error => console.error(error));
					dispatch(gameActions.setPlayers(playersList));
				}

			});

		
			unsubscribes.push(gameUnsub);
			
			break;
		default:
			otherPlayers = [];
	}

	// perform Redux action
	dispatch(gameActions.setPlayers(otherPlayers));
};


/**
 * The function to call that will set up everything a player needs to start playing (once at game start)
 */
export const setUpGame = async () => {
	await getGameData();
	await setListeners();
};


/**************** Getters ****************/

/**
 * Fetches the current player's data (when: once at game start)
 */
export const getPlayerData = async () => {
	console.log('[infoPlayerApi] running getPlayerData');
	let data;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			data = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.get()
				.then(docSnapshot => ({
					id: docSnapshot.id,
					name: docSnapshot.get('displayName'),
					shape: docSnapshot.get('shape'),
					notes: docSnapshot.get('notes'),
					shownInfo: docSnapshot.get('shownInfo')
				}))
				.catch(error => console.error(error));
			break;
		default:
			break;
	}

	// perform Redux action
	if (data !== undefined) dispatch(infoPlayerActions.setData(data));
};


/**
 * Fetches all data for the current game (when: once at game start)
 */
const getGameData = async () => {
	dispatch(infoPlayerActions.reset());
	await getPlayerData();
};


