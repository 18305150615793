import React, { Component } from 'react';
import { Header, Checkbox, Button } from 'semantic-ui-react';
import TimeDisplay from '../../../core/components/TimeDisplay';
import GameInformation from '../../../core/components/GameInformation';
import PlayerListPopup from '../../../core/components/PlayerListPopup';
import { getRandomInt } from '../../../helpers/randomNums';

class PopulationGameInformation extends Component {
    constructor(props) {
        super(props);
        // generationNumber, predation, and movementLoss are not included in the state here
        // because they are being passed in as props; these props get updated whenever 
        // we update the game reference in firestore, so the rerender should happen automatically
        this.state = {
            generationsInProgress: false
        };
    }

    handleGenerationPause = () => {
        this.props.resetFeedingTimers();
        this.props.stopTimer();
        return this.props.firestore.update(
            {
                collection: this.props.game,
                doc: this.props.gameCode,
            },
            {
                generation: true,
            },
        );
    };

    handleGenerationUnpause = () => {
        this.props.startTimer();

        return this.props.firestore.update(
            {
                collection: this.props.game,
                doc: this.props.gameCode,
            },
            {
                generation: false,
            },
        );
    };

    handleStartNewGenerationForAll = () => {
        this.setState({ generationsInProgress: true });
        this.props.resetTimer();
        this.props.firestore
            .get({
                collection: this.props.players,
                where: ['currentGameId', '==', this.props.gameCode],
            })
            .then(data => {
                if (!data.empty) {
                    const batch = this.props.db.batch();
                    const playerCol = this.props.db.collection(this.props.players);
                    var totalPopulation = 0;
                    var populationCounts = [];
                    for (const playerDoc of data.docs) {
                        const playerDocRef = playerCol.doc(playerDoc.id);
                        const {
                            energy,
                            population,
                            populationData,
                        } = playerDoc.data();
                        const multiplier = 6;
                        const newAmount = Math.round(
                            (multiplier * (energy - 50) * population) / 100,
                        );
                        const newPopulation = population + newAmount;

                        populationCounts.push(newPopulation);
                        totalPopulation += newPopulation;
                        const newGeneration =
                            this.props.generationNumber + '';

                        batch.update(playerDocRef, {
                            energy: 50,
                            population: newPopulation,
                            populationData: {
                                ...populationData,
                                [newGeneration]: newPopulation,
                            },
                            newGenerationFlag: population,
                            lastStation: null
                        });
                    }
                    const newGeneration = this.props.generationNumber;
                    batch.update(this.props.db.collection(this.props.game).doc(this.props.gameCode), {
                        populationData: {
                            ...this.props.getPopulationData(),
                            [newGeneration + '']: totalPopulation
                        },
                        histogramData: populationCounts
                    });
                    batch.commit()
                        .then(resp => {
                            console.log(resp);
                            this.setState({ generationsInProgress: false });
                        })
                        .catch(err => {
                            console.log(err);
                            this.setState({ generationsInProgress: false });
                        });
                }
            });
    };

    handleMovementCheckboxChange = () => {
        return this.props.firestore.update(
            {
                collection: this.props.game,
                doc: this.props.gameCode,
            },
            {
                movementLoss: !this.props.movementLoss
            },
        );
    };

    handlePredationCheckboxChange = () => {
        const stations = this.props.stations;
        const keys = Object.keys(stations);
        var noRisk = [];
        keys.forEach((key, index) => {
            if (stations[key].risk === 0) {
                noRisk.push(key);
            }
        });
        // cases
        // 1 station, noRisk > 0
        // 2+ stations, noRisk == stations.length
        const stationRef = this.props.db
            .collection(this.props.game)
            .doc(this.props.gameCode);

        const batch = this.props.db.batch();
        if (keys.length === 1 && noRisk.length > 0) {
            noRisk.forEach((key, index) => {
                const station = stations[key];

                batch.update(stationRef, {
                    [`configurations.${
                        this.props.currentConfiguration
                        }.stations.${key}`]: {
                        name: station.name,
                        rate: station.rate,
                        limit: station.limit,
                        amount: station.limit,
                        risk: getRandomInt(50, 70),
                        lastFeedTime: null,
                    },
                });
            });
        } else if (keys.length > 1) {
            if (noRisk.length === keys.length) {
                const index = getRandomInt(0, noRisk.length - 1);
                const station = stations[noRisk[index]];
                batch.update(stationRef, {
                    [`configurations.${
                        this.props.currentConfiguration
                        }.stations.${noRisk[index]}`]: {
                        name: station.name,
                        rate: station.rate,
                        limit: station.limit,
                        amount: station.limit,
                        risk: getRandomInt(50, 70),
                        lastFeedTime: null,
                    },
                });
            } else if (noRisk.length === 0) {
                const index = getRandomInt(0, keys.length - 1);
                const station = stations[keys[index]];
                batch.update(stationRef, {
                    [`configurations.${
                        this.props.currentConfiguration
                        }.stations.${keys[index]}`]: {
                        name: station.name,
                        rate: station.rate,
                        limit: station.limit,
                        amount: station.limit,
                        risk: 0,
                        lastFeedTime: null,
                    },
                });
            }
        }

        batch.commit().then(res => {
            return this.props.firestore.update(
                {
                    collection: this.props.game,
                    doc: this.props.gameCode,
                },
                {
                    predation: !this.props.predation
                }
            );
        });
    }

    render() {
        const playerLists = (
            <div>
                <Header as="h3" textAlign="left" style={{ marginTop: '' }}>
                    Generation {this.props.generationNumber}
                    <TimeDisplay minutes={("0" + (Math.floor(this.props.timerTime / 60000) % 60)).slice(-2)}
                        seconds={("0" + (Math.floor(this.props.timerTime / 1000) % 60)).slice(-2)} />
                </Header>
                <br />
                <PlayerListPopup
                    list={this.props.totalPlayers}
                    filter="total"
                />
                <div style={{ textAlign: 'left', marginTop: '10px' }}>
                    <Checkbox
                        toggle
                        label={<label>Energy loss due to movement</label>}
                        checked={this.props.movementLoss}
                        onChange={this.handleMovementCheckboxChange}
                    />
                </div>
                <div style={{ textAlign: 'left', marginTop: '5px' }}>
                    <Checkbox
                        toggle
                        label={<label>Predation</label>}
                        checked={this.props.predation}
                        onChange={this.handlePredationCheckboxChange}
                    />
                </div>
                {this.props.gameState !== 'initializing' && (
                    <div style={{ marginTop: '15px' }}>
                        {this.props.generation ? (
                            <div>
                                <Button
                                    content="Unpause Generations"
                                    onClick={this.handleGenerationUnpause}
                                    color="blue"
                                    disabled={
                                        this.props.gameState === 'paused' ||
                                        this.state.generationsInProgress
                                    }
                                />
                                <Button
                                    content="Next Generation"
                                    onClick={
                                        this.handleStartNewGenerationForAll
                                    }
                                    color="blue"
                                    disabled={
                                        this.props.gameState === 'paused' ||
                                        this.state.generationsInProgress
                                    }
                                />
                            </div>
                        ) : (
                                <Button
                                    content="Pause for Generations"
                                    onClick={this.handleGenerationPause}
                                    color="blue"
                                    disabled={this.props.gameState === 'paused'}
                                />
                            )}

                    </div>

                )}
            </div>
        );

        return (
            <GameInformation
                gameCode={this.props.gameCode}
                gameState={this.props.gameState}
                content={playerLists}
            />
        );
    }
}

export default PopulationGameInformation;