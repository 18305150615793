import React, { Component } from 'react';
import { Button, Label, Item } from 'semantic-ui-react';

import bucket from './assets/bucket.svg';
import money from './assets/money.svg';
import stopwatch from './assets/stopwatch.svg';

// Description: general layout for individual boat store items
// Parent: BoatStore
// Children: None
// Props Used:
//		available (Bool)
//		buttonText (String)
//		capacity (String)
//		color (String)
//		description (String)
//		name (String)
//		onSelect (Function)
//		opCost (String) ?
//		price (String)
//		size (String)
//		speed (String) ?

class StoreItem extends Component {
	render() {
		return (
			<Item>
				<Item.Content style={{ textAlign: 'left' }}>
					<Item.Header>{this.props.name}</Item.Header>
					<Item.Meta>{this.props.description}</Item.Meta>
					{/* <Item.Description>
						Capacity: {this.props.capacity + ' '}
						Speed: {this.props.speed + ' '}
						Operating Cost: {this.props.opCost}
					</Item.Description> */}
					{/* Needs to be cleaned up */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							textAlign: 'center'
						}}
					>
						<div>
							<img
								src={bucket}
								style={{ height: '75px', padding: '10px' }}
								alt="Bucket"
							/>
							<div>Capacity: {this.props.capacity + ' '}</div>
						</div>
						<div>
							<img
								src={stopwatch}
								style={{ height: '75px', padding: '10px' }}
								alt="Stopwatch"
							/>
							<div>Speed: {this.props.speed + ' '}</div>
						</div>
						<div>
							<img
								src={money}
								style={{ height: '75px', padding: '10px' }}
								alt="money"
							/>
							<div>Ongoing Cost: {this.props.opCost + ' '}</div>
						</div>
					</div>
					<Item.Extra>
						<Label>Price: {this.props.price}</Label>
						<Button
							floated="right"
							disabled={!this.props.available}
							size={this.props.size}
							color={this.props.color}
							onClick={this.props.onSelect}
						>
							{this.props.buttonText}
						</Button>
					</Item.Extra>
				</Item.Content>
			</Item>
		);
	}
}

export default StoreItem;
