import React from 'react';
import YesNoDialog from './YesNoDialog';

// logout confirmation dialog
const LogoutConfirmation = (props) => {
    const {
        logoutAction, backAction
    } = props;

    return (
        <YesNoDialog 
            header="Log Out?"
            description="You are about to leave this page. Are you sure you want to log out?"
            yesText="Log Out"
            yesAction={logoutAction}
            noText="Cancel"
            noAction={backAction}
        />
    );
}

export default LogoutConfirmation;