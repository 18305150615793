import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGameData, setSetting, resetGame, toggleQr, toggleStore, toggleSendData} from '../../teacherApi';
import { GAME_STATUS } from '../../constants';

import { Label, Message, Header, Grid, Button, Popup, Dimmer, Card, Checkbox } from 'semantic-ui-react';
import Carousel from 'semantic-ui-carousel-react';
import TeacherMenu from './TeacherMenu';
import PlanetGameSettings from './PlanetGameSettings';
// import PlanetTeacherMonitor from './PlanetTeacherMonitor';
import TeacherTimer from './TeacherTimer';
import YesNoDialog from '../../../core/components/YesNoDialog';
import TeacherGameInfo from '../../../core/components/TeacherGameInfo';
import TeacherStudentInfo from '../../../core/components/TeacherStudentInfo';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import Writerqr from '../../../core/components/Writerqr';

/**
 * The dashboard for the teacher to control the game with.
 * Parent: TeacherContainer
 * Children: TeacherGameInfo, TeacherStudentInfo, TeacherMonitor
 *
 * @param {string} gameCode the game code of the selected game
 */

const STATUS_BUTTON_OPTIONS = {
	initializing: {
		label: 'Not Started',
		icon: 'exclamation'
	},
	paused: {
		label: 'Paused',
		icon: 'play'
	},
	running: {
		label: 'In Play',
		icon: 'pause'
	}
};

const SETTING_OPTIONS = [
	{
		name: 'numPlanets',
		label: 'Number of Planets',
		min: 1,
		max: 10,
		default: 4
	},
	{
		name: 'numRegions',
		label: 'Regions per Planet',
		min: 1,
		max: 4,
		default: 3
	},
	{
		name: 'numSensors',
		label: 'Sensors per Player',
		min: 2,
		max: 6,
		default: 4
	},
	{ 
		name: 'startingCoin',
		label: 'Coins Players Start with',
		min: 0,
		max: 200,
		default: 100
	},
	{
		name: 'monthDuration',
		label: 'Seconds per Month',
		min: 5,
		max: 20,
		default: 10
	}
];

class PlanetTeacherGameScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rejoinedPlayers: [],
			totalPlayers: [],
			showQrCodes: false,
			showAllQr: false,
			showPlayerError: false,
			showLogoutConfirmation: false,
			showHomeConfirmation: false,
			processRunning: false // disable buttons if true
		};

		this.handleStatusButton.bind(this);
		this.handleRestartButton.bind(this);
	}

	componentDidMount() {
		getGameData();
	}

	handleStatusButton = async () => {
		if (this.props.gameStatus === GAME_STATUS.PAUSED && this.props.players.length === 0) {
			this.setState({ showPlayerError: true });
			return;
		}
		this.setState({ processRunning: true });
		await setSetting(
			'gameStatus',
			this.props.gameStatus === GAME_STATUS.PAUSED ? GAME_STATUS.RUNNING : GAME_STATUS.PAUSED,
			true
		);
		this.setState({ processRunning: false });
	};

	handleRestartButton = async () => {
		this.setState({ processRunning: true });
		await resetGame();
		this.setState({ processRunning: false });
	};

	toggleViewQrCodes = () => this.setState(state => ({ showQrCodes: !state.showQrCodes }));
	toggleAllQrCodes = () => this.setState(state => ({ showAllQr: !state.showAllQr }));

	render() {
		const { gameCode, gameStatus, qrOn, storeOn, sendDataOn, players } = this.props;
		const statusButton = STATUS_BUTTON_OPTIONS[gameStatus];

		const playerError = this.state.showPlayerError && (
			<SingleButtonDialog
				description="You need at least one player before starting the game."
				buttonText="Close"
				buttonAction={() => this.setState({ showPlayerError: false })}
			/>
		);

		const logoutPrompt = this.state.showLogoutConfirmation && (
			<YesNoDialog
				header={'Logout Confirmation'}
				description={'Are you sure you wish to logout?'}
				yesText={'Yes'}
				yesAction={() => {
					this.setState({ showLogoutConfirmation: false });
					this.props.firebase.logout();
					this.props.history.push('/');
				}}
				noText="Close"
				noAction={() => {
					this.setState({ showLogoutConfirmation: false });
				}}
			/>
		);

		const homePrompt = this.state.showHomeConfirmation && (
			<YesNoDialog
				header={'Home Confirmation'}
				description={'Are you sure you wish to return to the Homepage?'}
				yesText={'Yes'}
				yesAction={() => {
					this.setState({ showHomeConfirmation: false });
					this.props.history.push('/');
				}}
				noText="Close"
				noAction={() => {
					this.setState({ showHomeConfirmation: false });
				}}
			/>
		);

		const qrPopup = this.state.showQrCodes && (
			<Dimmer active page>
				<Card style={{ width: '600px' }}>
					<Card.Content
						textAlign="left"
						style={{ alignSelf: 'center', width: '100%', textAlign: 'center', margin: '0 auto' }}
					>
						<br />
						<Card.Description
							content={
								<Carousel
									elements={this.props.planets.map((planetName, index) => ({
										render: () => (
											<div>
												<Header content={planetName} />
												<Header content={"Planet " + (index+1).toString()}/>
												<br />
												<Writerqr value={index.toString()} size={500} level={'Q'} includemargin={true} />
												<Button label="Print Code" icon="print" onClick={() => window.print()} />
											</div>
										)
									}))}
									animation="fade"
									showNextPrev={this.props.planets.length > 1}
									showIndicators="true"
								/>
							}
						/>
						<br />
						<Button content="Close" onClick={this.toggleViewQrCodes} fluid />
					</Card.Content>
				</Card>
			</Dimmer>
		);

		const allQr = [];
		for(let i = 0; i < 10; i++){
			allQr.push(
				<div>
					<Header content={"Planet " + (i+1).toString()} />
					<br />
					<Writerqr value={i.toString()} size={500} level={'Q'} includeMargin={true} />
					<Button label="Print Code" icon="print" onClick={() => window.print()} />
				</div>
			)
		}

		const allQrPopup = this.state.showAllQr && (
			<Dimmer active page>
				<Card style={{ width: '600px' }}>
					<Card.Content
						textAlign="left"
						style={{ alignSelf: 'center', width: '100%', textAlign: 'center', margin: '0 auto' }}
					>
						<br />
						<Card.Description
							content={
								<Carousel
									elements={allQr.map((qr) => ({
										render: () => (
											qr
										)
									}))}
									animation="fade"
									showNextPrev="true"
									showIndicators="true"
								/>
							}
						/>
						<br />
						<Button content="Close" onClick={this.toggleAllQrCodes} fluid />
					</Card.Content>
				</Card>
			</Dimmer>
		);

		return (
			<div style={{ height: '100vh' }}>
				<TeacherMenu
					onHomeClick={() => this.setState({ showHomeConfirmation: true })}
					onLogoutClick={() => this.setState({ showLogoutConfirmation: true })}
				/>

				<Grid stackable style={{ height: '95%' }}>
					<Grid.Column color="grey" width={4} stretched>
						<Grid.Row>
							<TeacherGameInfo
								gameCode={gameCode}
								gameState={gameStatus}
								gameType={'Planet Game'}
								handleStateButton={
									statusButton === undefined ? null : (
										<Popup
											content={`Currently: ${statusButton.label}`}
											trigger={
												<Button
													icon={statusButton.icon}
													onClick={this.handleStatusButton}
													disabled={this.state.processRunning}
													color="green"
													size="massive"
													circular
												/>
											}
										/>
									)
								}
							/>
						</Grid.Row>

						<Grid.Row>
							<TeacherStudentInfo
								rejoinedPlayers={[]}
								approveCallback={this.props.approveCallback}
								rejectCallback={this.props.rejectCallback}
								totalPlayers={players}
							/>
						</Grid.Row>

						<Grid.Row>
							<Button
								content="RESTART"
								color="red"
								onClick={this.handleRestartButton}
								disabled={gameStatus === GAME_STATUS.INITIALIZING || this.state.processRunning}
							/>
						</Grid.Row>
					</Grid.Column>

					<Grid.Column color="violet" width={12} stretched>
						<div style={{ height: '100%' }}>
							{playerError}
							{logoutPrompt}
							{homePrompt}
							{qrPopup}
							{allQrPopup}

							<Message
								style={{
									marginLeft: '0.7em',
									marginRight: '0.7em',
									height: '100%',
									overflow: 'auto'
								}}
							>
								<br />
								<br />
								<Label attached="top" as="h3" color="black" size="big">
									Game Screen
								</Label>

								<Grid stackable style={{ height: '70vh' }}>
									<Grid.Row columns={1}>
										<Grid.Column rows={1}>
											{gameStatus === GAME_STATUS.INITIALIZING ? (
												<Grid.Row>
													<Message
														style={{
															marginLeft: '0.7em',
															marginRight: '0.7em',
															marginBottom: '1.0em'
														}}
													>
														<PlanetGameSettings settings={SETTING_OPTIONS} />
													</Message>
													<Popup
														content={`All ten Printable QR codes associated with each planet`}
														trigger={
															<Button
																content="View All QR Codes"
																color="blue"
																onClick={this.toggleAllQrCodes}
															/>
														}
													/>
												</Grid.Row>
											) : (
												<Grid.Row>
													<Message
														style={{
															marginLeft: '0.7em',
															marginRight: '0.7em',
															marginBottom: '1.0em'
														}}
													>
														<br />
														<div>
															<Header textAlign="left" as="h3">
																Student Monitor (WIP)
															</Header>
															{/* <PlanetTeacherMonitor /> */}
														</div>

														<div style={{ marginTop: '15px' }}>
															{/*<Popup
																content={`Store currently ${storeOn ? 'enabled' : 'disabled'}`}
																trigger={
																	<Button
																		content={`Turn Store ${storeOn ? 'off' : 'on'}`}
																		color="purple"
																		onClick={toggleStore}
																	/>
																}
															/> */}
															<Checkbox
																toggle
																checked={this.props.storeOn}
																onClick={toggleStore}
																label="Toggle Store off/on"
															/>
															
														</div>

														<div style={{ marginTop: '15px' }}>
															<Checkbox
																toggle
																checked={this.props.sendDataOn}
																onClick={toggleSendData}
																label="Toggle Data Trading off/on"
															/>
														</div>

														<div style={{ marginTop: '15px' }}>
															<Checkbox
																toggle
																checked={this.props.qrOn}
																onClick={toggleQr}
																label="Toggle Qr mode off/on"
															/>
															<br></br>
															<br></br>
															<Popup
																content={`Printable QR codes associated with each planet in current game`}
																trigger={
																	<Button
																		content="View QR Codes"
																		color="blue"
																		disabled={!qrOn}
																		onClick={this.toggleViewQrCodes}
																	/>
																}
															/>
															<Popup
																content={`All ten Printable QR codes associated with each planet`}
																trigger={
																	<Button
																		content="View All QR Codes"
																		color="blue"
																		disabled={!qrOn}
																		onClick={this.toggleAllQrCodes}
																	/>
																}
															/>
														</div>

														
													</Message>
													
													<TeacherTimer />
												</Grid.Row>
											)}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Message>
						</div>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	gameStatus: state.planetGame.settings.gameStatus,
	qrOn: state.planetGame.settings.qrOn,
	storeOn: state.planetGame.settings.storeOn,
	sendDataOn: state.planetGame.settings.sendDataOn,
	players: state.planetGame.settings.players,
	planets: state.planetGame.settings.planets
});

export default connect(mapStateToProps)(PlanetTeacherGameScreen);
