import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHAPE_IMG, PLAYER_GROUPS } from '../constants';

import { Header, Card, Image, Dimmer, Button, Input } from 'semantic-ui-react';
// import { Button, Responsive, Input, Card, Dimmer } from 'semantic-ui-react';


/**
 * The main player page showing their role(shape) information
 * 
 * Parent: InfoPlayerGameScreen
 * Children: None
 * 
 */

class NoteCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        //const {shape} = this.props;

        return (
            <>
                <Header>Take notes here:</Header>
                <input type="text" id="playerNotes" placeholder=""></input>
                <Button
								
								size="large"
								placeholder="save"
								color="red"
				/>
                {/* <Card
                    centered={true}>
                    <h1>wow here are my notes</h1>

                </Card> */}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    notes: state.infoGame.player.notes,
});

export default (NoteCard);