import React from 'react';
import Chart from 'react-apexcharts';

// Description: Generates a line graph from provided data.
// 

const LineGraph = props => {
	const { title, subtitle, xtitle, ytitle, categories, series } = props;

	return (
		<Chart
			options={{
				chart: {
					id: 'linechart'
				},
				title: {
					text: title
				},
				subtitle: {
					text: subtitle
				},
				xaxis: {
					// type: 'numeric',
					title: {
						text: xtitle
          			},
					categories: categories,
					forceNiceScale: true,
					tickAmount: 'dataPoints',
					tickPlacement: 'between',
				},
				yaxis: {
					labels: {
						formatter: function (value) {
						if (value % 1 === 0) {return value;}
						},
						axisBorder: {
							show: true,
						}
					},
					forceNiceScale: true,
						title: {
							text: ytitle
						}
					},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				}
			}}	
			series={series}
			type="line"
			width={'100%'}
			height={'100%'}
		/>
	);
};

export default LineGraph;
