import React, { Component } from 'react';
import { connect } from 'react-redux';

import AnimalCard from './AnimalCard';
import Carousel from 'semantic-ui-carousel-react';

/**
 * Contracts page, where a user can view all of their contracts and complete them.
 * Parent: PlanetPlayerGamescreen
 * Children: AnimalCard
 */

class Contracts extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Carousel
				elements={this.props.animals.map(animalName => ({
					render: () => <AnimalCard animalName={animalName} />
				}))}
				animation="fade"
				showNextPrev={this.props.animals.length > 1}
				showIndicators="true"
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.player.animals
});

export default connect(mapStateToProps)(Contracts);
