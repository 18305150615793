import { combineReducers } from "redux";
import worldReducer from './worldSlice';
import playerReducer from './playerSlice';
import contractsReducer from './contractsSlice';
import settingsReducer from './settingsSlice';

const planetGameReducer = combineReducers({
	world: worldReducer,
  	player: playerReducer,
	contracts: contractsReducer,
	settings: settingsReducer
});

export default planetGameReducer;