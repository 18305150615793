import React, { Component } from 'react';
import { Header, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BasicGreyPage from './BasicGreyPage';
import getDBNames from '../../helpers/getDBNames';
import '../styles.scss';
import setOnlineStatus from '../../helpers/setOnlineStatus';
import { rejoinPlayer } from '../../planetgame/playerApi';

const top = (
  <div style={{marginBottom: '1em'}}>
    <Header as="h1" textAlign="center">Rejoin Game</Header>
    <label>
      Want to join a new game?
      <br />
      <Link to="/player">Join game</Link>
    </label>
  </div>
);

var approved = false;

class Rejoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      message: '',
      nameError: false,
      codeError: false,
      disableButtons: false
    };
  }

  componentCleanup = () => {
    //if auth exists, when the you close the browser, or go into another route, this gets rid of the rejoin from the host screen.
    if (!this.props.auth.isEmpty && this.props.players !== '') {
      this.props.firestore
        .get({
          collection: this.props.players,
          where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', this.state.name]]
        })
        .then((player) => {
          if (!player.empty) {
            this.props.firestore.update(
              {
                collection: this.props.players,
                doc: player.docs[0].id
              },
              {
                rejoin: this.props.gameState === "error",
                uid: ''
              }).then(response => {
                if (!approved) {
                  this.props.firebase.logout();
                }
              });
          }
        });
    }
    if (this.props.players !== '') this.unlisten();
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  // handles changes when typing in game and name fields
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      let newValue;
      if (name === 'gamecode') {
        newValue = value.toLowerCase();
        this.props.setGameCode(newValue);
      }
      if (name === 'name') {
        if (value.length > 0) {
          newValue = value[0].toUpperCase() + value.substr(1).toLowerCase();
          newValue = newValue.replace(/[^a-zA-Z]+/g, '');
          newValue = newValue.slice(0, 16);
        }
        this.setState({ name: newValue });
      }
    });
  };

  // validates player name in real time against database
  validateName = (value) => {
    return new Promise((resolve) => {
      if (value.length === 0) {
        this.setState({ nameError: true });
        resolve(false);
      } else {
        this.props.firestore
          .get({
            collection: this.props.players,
            where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', value]]
          })
          .then((querydata) => {
            if (querydata.size === 0 && this.state.name.length > 0) {
              this.setState({ nameError: true });
              resolve(false);
            } else {
              this.setState({ nameError: false });
              resolve(true);
            }
          });
      }
    });
  }



  // validates game in real time against database
validateGame = (value) => {
    return new Promise((resolve) => {
      if (value.length === 0) {
        this.setState({ codeError: true });
        resolve(false);
      } else {
        this.props.firestore
          .get({
            collection: 'games',
            doc: value
          })
          .then((querydata) => {
            if (!querydata.exists) {
              this.setState({ codeError: true });
              resolve(false);
            } else {
              this.setState({ codeError: false });
              this.props.setDBNames(getDBNames(querydata.data().type));
              this.props.setGameType(querydata.data().type);
              resolve(true);
            }
          });
      }
    });
  }

  // on submit it signs in and validates fields one new time
  // and creates temp fields for player
  handleSubmit = () => {
    this.setState({disableButtons: true});
    this.props.firebase.auth().signInAnonymously()
      .then((data) => {
        this.validateGame(this.props.gameCode).then((gamevalidated) => { 
          if (!this.state.codeError) {
            if (this.props.game === "lgames") {  // lgames player rejoin
							rejoinPlayer(this.state.name)
                .then((value) => {
                  if (value) {
                    this.props.done();
                  } else {
                    this.setState({ nameError: true, disableButtons: false});
                    this.props.setGameState("empty");
                    this.props.firebase.logout();
                  }
                });
            } else {
              this.validateName(this.state.name).then((namevalidated) => { 
              if (!(this.state.codeError || this.state.nameError)) {
                //Set up listener
                this.checkStatus();
                this.props.firestore
                  .get({
                    collection: this.props.players,
                    where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', this.state.name]]
                  })
                  .then((player) => {
                    let rejoining = false;
                    player.forEach((doc) => {
                      rejoining = doc.data().rejoin;
                    });
                    //Check if someone is already rejoining
                    if (!rejoining) {
                      //sign in and set rejoin to true and new uid
                      this.props.firestore.update(
                        {
                          collection: this.props.players,
                          doc: player.docs[0].id
                        },
                        {
                          rejoin: true,
                          uid: data.user.uid,
                          lastLogin: this.props.firestore.FieldValue.serverTimestamp(),
                        });
                    } else {
                      this.props.setGameState("error");
                    }
                  })
                  .then(this.props.setGameState("loading")); // waiting for approval
              } else {
                this.props.firebase.logout();
                this.props.setGameState("empty");
                this.setState({ disableButtons: false });
              }
            });
          }
        } else {
          this.props.firebase.logout();
          this.props.setGameState("empty");
          this.setState({ disableButtons: false });
        }
      });
    });
  }

  loggedOut = () => {
    this.props.setGameState('empty');
  }

  //unset the listener
  unlisten = () => {
    this.props.firestore
      .unsetListener({
        collection: this.props.players,
        where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', this.state.name]]
      });
  }

  checkStatus = () => {
    //Listner to current player
    this.props.firestore
      .setListener({
        collection: this.props.players,
        where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', this.state.name]]
      },
        player => {
          //If denied, change deny back to false and change to the empty state.
          if (player.size === 1 && player.docs[0].data().denied) {
            console.log("denied");
            this.props.firestore.update({
              collection: this.props.players,
              doc: player.docs[0].id
            },
              {
                denied: false
              }).then((stat) => {
                this.props.firebase.logout();
              }
              );
            this.unlisten();
            this.props.setGameState('denied');
            return;
          }

          //If approved, replace player with new uid, replace meetings with the new uid
          if (player.size === 1 && player.docs[0].data().approve) {
            console.log("approved");
            setOnlineStatus(this.props);
            approved = true;
            const playerData = player.docs[0].data();
            playerData.approve = false;
            const playerId = player.docs[0].id;
            const promises = [];

            promises.push(this.props.firestore.delete({
              collection: this.props.players,
              doc: playerId
            }));
            
            promises.push(this.props.firestore.set(
              {
                collection: this.props.players,
                doc: playerData.uid
              },
              {
                ...playerData,
                uid: ''
              }
            ));

            promises.push(new Promise((resolve) => {
              this.props.firestore
                .get({
                  collection: "games",
                  doc: this.props.gameCode
                }).then(game => {
                  let newList = game.data().players.filter((player) => player !== playerId);
                  this.props.firestore.update({
                    collection: "games",
                    doc: this.props.gameCode
                  }, {
                      players:[...newList, playerData.uid]
                    }).then(() => resolve(true));
                });
            }))

            if (this.props.gameType === "vgame") {
              promises.push(this.props.firestore
                .get({
                  collection: this.props.meetings,
                  where: [
                    ["gameId", "==", this.props.gameCode],
                    ["myid", "==", playerId]
                  ]
                }).then((player) => {
                  player.forEach((doc) => {
                    this.props.firestore.update({
                      collection: this.props.meetings,
                      doc: doc.id
                    },
                      {
                        myid: playerData.uid
                      });
                  })
                }));

              promises.push(this.props.firestore
                .get({
                  collection: this.props.meetings,
                  where: [
                    ["gameId", "==", this.props.gameCode],
                    ["otherId", "==", playerId]
                  ]
                }).then((player) => {
                  player.forEach((doc) => {
                    this.props.firestore.update({
                      collection: this.props.meetings,
                      doc: doc.id
                    },
                      {
                        otherId: playerData.uid
                      });
                  })
                }));

              promises.push(new Promise((resolve) => {
                this.props.firestore
                  .get({
                    collection: this.props.game,
                    doc: this.props.gameCode
                  }).then(game => {
                    if (game.data().gameParams.patientZero === playerId) {
                      this.props.firestore.update({
                        collection: this.props.game,
                        doc: this.props.gameCode
                      }, {
                          gameParams: {
                            ...game.data().gameParams,
                            patientZero: playerData.uid
                          }
                        }).then(() => resolve(true));
                    } else {
                      resolve(false);
                    }
                  });
              }));
            }

              

            Promise.all(promises).then(() => this.props.done());
          }
        });
  }

  render() {
    let center = (
      <Form onSubmit={this.handleSubmit} widths="equal">
        {(this.state.codeError || this.state.nameError) &&
          <Header as="h5" color="red">
            {this.state.codeError && <span>Game not found.<br /></span>}
            {this.state.nameError && <span>Name is empty or was not used to join the game.</span>}
          </Header>
        }
        <Form.Group inline>
          <Form.Input
            fluid
            className="inline-input"
            error={this.state.codeError}
            size="big"
            icon="gamepad"
            iconPosition="left"
            placeholder="Game Code"
            name="gamecode"
            value={this.props.gameCode}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Input
            fluid
            className="inline-input"
            error={this.state.nameError}
            size="big"
            icon="user circle"
            iconPosition="left"
            placeholder="Display Name"
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Button 
          disabled={this.state.name === '' || this.props.gameCode === '' || this.state.disableButtons} 
          size="big" 
          content="Rejoin" 
          fluid 
        />
        <Form.Button
          disabled={this.state.disableButtons}
          onClick={this.props.cancel}
          size="big"
          content="Home"
          negative fluid
        />
      </Form>
    );

    return <BasicGreyPage pageClass="rejoin" top={top} center={center} />;
  }
}

export default Rejoin;