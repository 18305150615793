import React from 'react';
import Chart from 'react-apexcharts';

// Description: Displays student data
// Parent: StudentData
// Children: None
// Props Used:
//		data (Object)
//		xtitle (String)
//		ytitle (String)
//		categories (Array)
//		series (Array)

const LineChart = props => {
	// const { data, xtitle, ytitle, categories, series } = props;
	const { xtitle, ytitle, categories, series } = props;
	
	return (
		<Chart
			options={{
				chart: {
					id: 'linechart'
				},
				xaxis: {
					type: 'numeric',
					title: {
						text: xtitle
          			},
					categories: categories,
					forceNiceScale: true,
					labels: {
						formatter: function (value) {
							return Math.floor(value)
						}
					},
					tickAmount: 10,
					tickPlacement: 'between',
				},
				yaxis: {
					labels: {
						formatter: function (value) {
						if (value % 1 === 0) {return value;}
					},
					axisBorder: {
						show: true,
					}
				},
				forceNiceScale: true,
					title: {
						text: ytitle
					}
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				}
			}}	
			series={series}
			type="line"
			width={'100%'}
			height={'100%'}
		/>
	);
};

export default LineChart;
