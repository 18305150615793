import React, { Component } from "react";
import { Accordion, Icon } from "semantic-ui-react";

class SingleElementAccordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: -1
        };
    }

    handleClick = (e, props) => {
        const { index } = props
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { title, content } = this.props;
        return (
            <Accordion fluid>
                <Accordion.Title className="accordion-title" active={this.state.activeIndex === 0} index={0} onClick={this.handleClick} >
                    <Icon name='dropdown' />
                    {title}
                </Accordion.Title>
                {this.state.activeIndex === 0 && 
                    <Accordion.Content active={true} style={{margin: '0 auto', marginBottom: '15px'}}>
                        {content}
                    </Accordion.Content>
                }
            </Accordion>
        );
    }
}


export default SingleElementAccordion;