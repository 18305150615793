import React, { Component } from 'react';
import { Button, Popup } from 'semantic-ui-react';

// Description: Contains the start/pause/resume, new game, and logout buttons
// Parents: VirusTeacherButtons, TocTeacherGameScreen
// Children: None
// Props Used:
//      gameInfo (Object)
//      handleInitialization (Function)
//      handlePause (Function)
//      handleRunning (Function)
//      name (String)

class TeacherButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRestart: false,
      showNewGameConfirmation: false,
      showLogoutConfirmation: false,
      disabled: false
    };
  }

  render() {
    return (
      <div>

        {this.props.gameInfo.gameState === 'initializing' ? (
          <Popup content="Currently: Not Started" trigger={
            <Button 
              circular icon = "exclamation"
              onClick={() => {
                this.props.handleInitialization();
              }} 
              color="green"
              size = "massive"
              disabled={this.state.disabled}
            />
          }/>
        ) : (

        this.props.gameInfo.gameState === 'paused' ? (
          <Popup content="Currently: Paused" trigger={
            <Button 
              circular icon = "play"
              onClick={() => {
                this.props.handleRunning();
              }} 
              color="green" 
              size = "massive"
              disabled={this.state.disabled}
            />
          }/>
        ) : (
          <Popup content="Currently: In Play" trigger={
            <Button 
              circular icon = "pause"
              onClick={() => {
                this.props.handlePause()
              }} 
              color="green"
              size = "massive"
              disabled={this.state.disabled} 
            />
          }/>
        )
        )}  
      </div> 
    );
  }
}

export default TeacherButtons;
