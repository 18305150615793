import React, { Component } from 'react';
import { Header, Button} from 'semantic-ui-react';
import { firebaseConnect } from 'react-redux-firebase';
import BasicGreyPage from '../components/BasicGreyPage';
import SingleButtonDialog from '../components/SingleButtonDialog';
import Logo from '../components/Logo';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { version } from '../../../package.json';

// Description: Displays available games and then buttons to login as player or teacher
// Parent: Top level component
// Children: No children but forwards to PlayerContainer or TeacherContainer accordingly
// State: Has state with game, players and meetings that are passed along via history push

//accesses the version stored in package.json
const versionNumber = version;

const top = (
  <div>
    <Logo />
    <Header as="h1" style={{color: 'white', marginTop: '0px'}}>{"pSims v" + versionNumber}</Header>
    <br />
  </div>
);

const bottom = (
  <div>
  <div style={{position:"fixed", bottom:"0px", right:"0px"}}>
    <Button
      size = "tiny"
      content = "Refresh"
      color = "grey"
      onClick={()=>{window.location.reload(true)}}
    />
  </div>
  <div style={{position:"fixed", bottom:"0px", left:"0px"}}>
    <Button
      size = "tiny"
      content = "About"
      color = "grey"
      onClick={()=>{window.location="/info.html"}}
    />
  </div>
  </div>
)

class ChooseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      needRefresh: false
    };
  }

  //Check to see if the version is up-to-date
  componentDidMount() {
    this.props.firebase.ref('version').on('value', (snap) => {
      let deployedVersion = snap.val()
      if (versionNumber > deployedVersion) {
        this.props.firebase.ref('version').set(versionNumber);
      } else if (versionNumber < deployedVersion) {
        this.setState({needRefresh: true});
      } else {
        console.log("you're up to date");
        // maybe remove this in the future, all it does is print to the console. 
      }
    });
  }

  // handles the dropdown menu change
  handleChange = (e, { value }) => {
    this.setState({ value, choice: true });
    this.setGameValues(value);
  };

  // renders dropdown and buttons
  render() {
    const { firebase } = this.props;
    const center = (
      <div className="choose">
        <Button
          fluid
          size="big"
          color="green"
          content="Join a Game"
          icon="gamepad"
          onClick={() => {
            this.props.history.push({
              pathname: '/player'
            });
          }}
        />
        <Button
          fluid
          style={{marginTop: '0.5em'}}
          size="big"
          content="Host a Game"
          icon="group"
          onClick={() => {
            if (
              this.props.auth.providerData &&
              this.props.auth.providerData.length > 0 &&
              this.props.auth.providerData[0].providerId === 'google.com'
            ) {
              this.props.history.push({
                pathname: '/teacher'
              });
            } else {
              firebase.login({ provider: 'google', type: 'popup' }).then((response) => {
                this.props.history.push({
                  pathname: '/teacher'
                });
              });
            }
          }}
        />
      </div>
    );

    return (
      <div style={{height: '100%', backgroundColor: 'black'}}>
        {this.state.needRefresh && (
            <SingleButtonDialog
                description={
                    'You need to reload this page from source to get the latest version'
                }
                buttonText="Close"
                buttonAction={() =>
                  this.setState({ needRefresh: false})
              }
            />
        )

        }
        <BasicGreyPage top={top} center={center} bottom={bottom}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
}

export default compose(
  firebaseConnect(), // withFirebase can also be used
  connect(mapStateToProps, null)
)(ChooseContainer);
