import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendData } from '../../playerApi';

import { Input, Button, Header, Grid } from 'semantic-ui-react';
import { selectColor } from '../../constants';

/**
 * A component where players can send data to other players.
 * Parent: PlayerInteractionMenu
 * Children: None
 */

class PlayerDataSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPlayerId: '',
			selectedSensorId: '',
			sent: false
		};
	}

	chooseData = (e, { value }) => this.setState({ selectedSensorId: value });

	choosePlayer = (e, { value }) =>
		this.setState({
			selectedPlayerId: this.props.players.find(player => player.name === value)?.id ?? ''
		});
	
	handleSend = () => {
		sendData(this.state.selectedSensorId, this.state.selectedPlayerId);
		this.setState({ sent: true, selectedSensorId: '' });

		setTimeout(() => this.setState({ sent: false }), 2000);
	}

	render() {
		return (
			<div style={{ margin: '10px' }}>
				<Header size="medium">Select Data to Send</Header>

				<Grid columns={3}>
					{this.props.sensors
						.filter(sensor => sensor.origin === undefined)
						.map(sensor => (
							<Grid.Column style={{ padding: '8px' }}>
								<Button
									active={this.state.selectedSensorId === sensor.timeId}
									size="small"
									content={`${this.props.planets[sensor.planet].name} ${sensor.region+1} ${sensor.dataType} month ${sensor.startMonth}-${sensor.endMonth}`}
									onClick={this.chooseData}
									fluid
									color= {this.state.selectedSensorId === sensor.timeId ? selectColor : null} 
									value={sensor.timeId}
								/>
							</Grid.Column>
						))}
				</Grid>

				<Header size="medium">Choose Recipient </Header>
				<Input
					icon="users"
					iconPosition="left"
					list="players"
					placeholder="Choose player..."
					onChange={this.choosePlayer}
				/>
				<datalist id="players">
					{this.props.players.map(player => (
						<option value={player.name} />
					))}
				</datalist>

				<br />
				<br />
				<Button
					disabled={this.state.selectedPlayerId === '' || this.state.selectedSensorId === '' || this.state.sent}
					size="big"
					color="green"
					content={this.state.sent ? '   Sent' : '   Send'}
					icon="send right"
					onClick={this.handleSend}
					fluid
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	players: state.planetGame.world.players,
	sensors: state.planetGame.player.collectedSensors,
	planets: state.planetGame.world.planets
});

export default connect(mapStateToProps)(PlayerDataSelect);
