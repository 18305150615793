import React, { Component } from "react";
import PlayerListPopup from "../../../core/components/PlayerListPopup";

// Description: handles monitoring of patient zero and total players
// Parent: VirusTeacherGameScreen
// Children: PlayerListPopup
// Props Used:
//      firestore
//      gameCode
//      patientZero
//      players
//      gameInfo
//      showP0Absent
//      gameInfo
//      meetings
//      setTotals
//      setPatientZero
//      setP0AbsentShownTrue
//      setMeetLength

class VirusTeacherMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sickPlayers: 0,
      immunePlayers: 0,
      totalPlayers: 0
    };
  }

  // set listener for rejoined players
  UNSAFE_componentWillMount() {
    //listen for meetings with p0 to monitor and display
    console.log(this.props);
    this.setp0MeetingsListener();

    // Try to get rid of this!
    this.props.firestore.setListener(
      {
        collection: this.props.players,
        where: ["currentGameId", "==", this.props.gameCode]
      },
      doc =>
        this.listenToArray(doc).then(() => {
          this.setP0Listener(this.props.patientZero);
        })
    );
  }

  // unset listener for rejoined players
  componentCleanup = () => {
    this.props.firestore.unsetListener({
      collection: this.props.players,
      where: ["currentGameId", "==", this.props.gameCode]
    });

    this.props.firestore.unsetListener({
      collection: "status"
    });

    this.unsetP0Listener(this.props.patientZero);
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup); // remove the event handler for normal unmounting
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.gameInfo.gameParams.patientZero !==
      this.props.gameInfo.gameParams.patientZero
    ) {
      this.unsetP0Listener(this.props.patientZero);
      this.setP0Listener(nextProps.gameInfo.gameParams.patientZero);
      this.props.setPatientZero(nextProps.gameInfo.gameParams.patientZero);
      // this.setState({ patientZero: nextProps.gameInfo.gameParams.patientZero });
      this.unsetp0MeetingsListener(); //these are undefined - in teacher monitor now
      this.setp0MeetingsListener();
    }
  }

  //Listen to P0 and see if he/she is absent or status: offline
  setP0Listener = p0 => {
    if (p0 === "") return;
    this.props.firestore.setListener(
      {
        collection: "status",
        doc: p0
      },
      doc => {
        if (
          doc.data() !== undefined &&
          !(
            this.props.gameInfo.gameState === "initializing" ||
            (this.props.gameInfo.gameState === "paused" &&
              this.props.gameInfo.p0Restart)
          )
        ) {
          const isNotOnline = doc.data().state !== "online";
          if (this.props.p0Absent !== isNotOnline) {
            this.props.setP0AbsentState(
              isNotOnline,
              this.props.p0AbsentShown ? false : isNotOnline
            );
            if (this.props.p0Absent && !this.props.p0AbsentShown) {
              this.props.setP0AbsentShownTrue();
            }
          }
        }
      }
    );
  };

  unsetP0Listener = p0 => {
    if (p0 === "") return;
    this.props.firestore.unsetListener({
      collection: "status",
      doc: p0
    });
  };

  setp0MeetingsListener = () => {
    this.props.firestore.setListener(
      {
        collection: this.props.meetings,
        where: [
          ["gameId", "==", this.props.gameCode],
          ["myid", "==", this.props.patientZero]
        ]
      },
      doc => {
        if (doc) {
          this.props.setMeetLength(doc.docs.length);
        }
      }
    );
  };

  unsetp0MeetingsListener = () => {
    this.props.firestore.unsetListener({
      collection: this.props.meetings,
      where: [
        ["gameId", "==", this.props.gameCode],
        ["myid", "==", this.props.patientZero]
      ]
    });
  };
  // unpacks the item into an array with id for each element
  listenToArray = data => {
    return new Promise(resolve => {
      var rejoined = [];
      var sick = [];
      var immune = [];
      var total = [];
      var promises = [];

      data.forEach(item => {
        promises.push(
          new Promise(resolve => {
            this.props.firestore.setListener(
              {
                collection: "status",
                doc: item.id
              },
              status => {
                total = total.filter(
                  player => player.name !== item.data().displayName
                );
                sick = sick.filter(
                  player => player.name !== item.data().displayName
                );
                immune = immune.filter(
                  player => player.name !== item.data().displayName
                );
                rejoined = rejoined.filter(
                  player => player.name !== item.data().displayName
                );

                const active =
                  status.data() === undefined
                    ? false
                    : status.data().state === "online";
                total.push({
                  name: item.data().displayName,
                  id: item.id,
                  active: active
                });
                if (item.data().rejoin)
                  rejoined.push({
                    name: item.data().displayName,
                    id: item.id,
                    active: active
                  });
                if (item.data().sickState)
                  sick.push({
                    name: item.data().displayName,
                    id: item.id,
                    active: active
                  });
                if (item.data().immune)
                  immune.push({
                    name: item.data().displayName,
                    id: item.id,
                    active: active
                  });
                resolve(true);
              }
            );
          })
        );
      });

      Promise.all(promises).then(() => {
        this.setState({
          sickPlayers: sick,
          immunePlayers: immune,
          totalPlayers: total
        });
        this.props.setTotals(rejoined, sick, immune, total);
        resolve(true);
      });
    });
  };

  render() {
    return (
      <div>
        <PlayerListPopup list={this.state.totalPlayers} filter="total" />
        <PlayerListPopup list={this.state.sickPlayers} filter="sick" />
        <PlayerListPopup list={this.state.immunePlayers} filter="immune" />
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default VirusTeacherMonitor;
