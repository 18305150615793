import React from 'react';
import { Statistic } from 'semantic-ui-react';

const TimeDisplay = (props) => {
  const {minutes, seconds } = props;

  return (
      <div>
        <Statistic.Group size="small" >
          <Statistic label="min" value={minutes} />
          <Statistic label="sec" value={seconds} />
        </Statistic.Group>
      </div>
    );

}

export default TimeDisplay;
