import React from 'react';
// import Plot from 'react-plotly.js';
import Chart from 'react-apexcharts';

const Histogram = props => {
	// const { data, xtitle, ytitle } = props;
	const { data } = props;

	var categories = [];
	var series = [];
	Object.keys(data).forEach(key => {
		categories.push(key);
		series.push(data[key]);
	});

	return (
		<Chart
			options={{chart: {
				id: 'horizontal-bar'
				},
				xaxis: {
					categories: categories,
					title: {
						text: props.xtitle
					}
				},
				yaxis: {
					title: {
						text: props.ytitle
					}
				},
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},
			}}
			series={[
				{
					name: 'number',
					data: series
				}
			]}
			type="bar"
			width={'100%'}
			height={'100%'}
		/>
	);
}

export default Histogram;
