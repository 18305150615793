import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import store from './store/store';

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

let hasRendered = false;

const App = () => {
  if (!hasRendered) {
    hasRendered = true;
    return jsx;
  } else {
    return <p>Loading...</p>;
  }
};

export default App;
