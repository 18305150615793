import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { SENSOR_ICONS } from '../../constants';

/**
 * A circle indicating a planet in the planet navigation map.
 * Parent: PlanetNavigation
 * Children: None
 *
 * @param {number} planetIndex the index of the planet represented by this component
 */

class PlanetCircle extends Component {
	// Useless constructor?
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		// styling components for position/appearance
		const { x, y, size, color } = this.props.planet.style;
		const componentStyle = {
			top: `${y}px`,
			left: `${x}px`
		};
		const circleStyle = {
			width: `${size}px`,
			height: `${size}px`,
			backgroundColor: color
		};

		// determine which icons to show
		const showIcons = new Set();
		this.props.player.activeSensors
			.filter(sensor => sensor.planet === this.props.planetIndex)
			.forEach(sensor => showIcons.add(sensor.dataType));
		for (const animalName of this.props.player.animals) {
			const regions = this.props.planet.regions;
			let animalFound = false;
			for (const region of regions) {
				const population = region.animals[animalName]?.amount;
				if (population !== undefined && population > 0) {
					animalFound = true;
					break;
				}
			}
			if (animalFound) {
				showIcons.add('animal');
				break;
			}
		}

		return (
			<div className="planet-circle" style={componentStyle} onClick={this.props.handleClick}>
				<span>{this.props.planet.name}</span>
				<div className="circle" style={circleStyle} />
				<div className="icons-container">
					{Array.from(showIcons).map((icon,index) => {
					return (
						<Icon key={index}
							circular
							inverted
							color={SENSOR_ICONS[icon].color}
							name={SENSOR_ICONS[icon].name}
							size="tiny"
						/>
					)})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	player: state.planetGame.player,
	planet: state.planetGame.world.planets[ownProps.planetIndex]
});

export default connect(mapStateToProps)(PlanetCircle);
