import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSetting } from '../../teacherApi';

import { Header } from 'semantic-ui-react';
import SliderWithField from '../../../core/components/SliderWithField';

/**
 * The component displaying all the settings during initialization of a game
 * Parent: PlanetTeacherGameScreen
 * Children: None
 *
 * @param {array} settings the setting options with associated min, max, default value, and label
 */

class PlanetGameSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<Header textAlign="left" as="h3">
					Initial Settings
				</Header>
				{this.props.settings.map((setting, index) => (
					<SliderWithField
						key={index}
						field={setting.label}
						label="#"
						min={setting.min}
						max={setting.max}
						step={setting.max > 50 ? 10 : 1} 
						value={this.props[setting.name]}
						leftText={setting.min}
						rightText={setting.max}
						onChange={value => setSetting(setting.name, value)}
					/>
				))}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	numPlanets: state.planetGame.settings.numPlanets,
	numRegions: state.planetGame.settings.numRegions,
	monthDuration: state.planetGame.settings.monthDuration,
	startingCoin: state.planetGame.settings.startingCoin,
	numSensors: state.planetGame.settings.numSensors
});

export default connect(mapStateToProps)(PlanetGameSettings);
