const PRODUCTION = true;

const firebaseconfig = PRODUCTION
  ? {
      apiKey: "AIzaSyD1hKp4lCU4m2LHzMu8sal-u-ms8wEoKEc",
      authDomain: "psims-1a56d.firebaseapp.com",
      databaseURL: "https://psims-1a56d.firebaseio.com",
      projectId: "psims-1a56d",
      storageBucket: "psims-1a56d.appspot.com",
      messagingSenderId: "961944661251",
      appId: "1:961944661251:web:ac96774e731f8ee2908d36",
      measurementId: "G-N5HJS4SW6P",
    }
  : {
      apiKey: "AIzaSyAGd-Y-sphyljqL2iGjQs7q3G8kncaM6es",
      authDomain: "virusgame-shared.firebaseapp.com",
      databaseURL: "https://virusgame-shared.firebaseio.com",
      projectId: "virusgame-shared",
      storageBucket: "virusgame-shared.appspot.com",
      messagingSenderId: "152577664285",
    };

export default firebaseconfig;
