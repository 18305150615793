import React, { Component } from "react";
import {Button, Grid, Menu, Popup} from "semantic-ui-react";
import TeacherGameInfo from '../components/TeacherGameInfo';
import TeacherStudentInfo from '../components/TeacherStudentInfo';

// Description: General screen for teacher controls
// Parent: VirusTeacherGameScreen
// Children: TeacherGameInfo, TeacherStudentInfo
// Props Used:
//      approveCallback (Function)
//      gameCode (String)
//      gameScreen (Div)
//      gameState (String)
//      gameType (String)
//      handleDoc (Function)
//      handleHome (Function)
//      handleLogout (Function)
//      handleStateButton (Button)
//      handleRestartButton (Button)
//      rejectCallback (Function)
//      rejoinedPlayers (Array)
//      totalPlayers (Array)

class TeacherControlScreen extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    render(){
        return(
        <div style = {{height:"100vh"}}>

          {/* Menu with documentation, home, and signout buttons*/}
          <Menu inverted style = {{height:"5%"}}>
            <Menu.Item>
              <img src={'./psimsturtles.png'} alt="Psims Turtles"/>
              Psims
            </Menu.Item>

            <Menu.Menu position='right'>
              <Menu.Item>
                <Popup content="Documentation" trigger={<Button circular icon="info" color = "blue" onClick = {this.props.handleDoc}/>}/>
              </Menu.Item>

              <Menu.Item>
                <Popup content="Home" trigger={<Button circular icon="home" color = "yellow" onClick = {this.props.handleHome}/>}/>
              </Menu.Item>

              <Menu.Item>
                <Popup content="Logout" trigger={<Button circular icon="sign-out" color = "red" onClick = {this.props.handleLogout}/>}/>
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          {/* Side panel with game info, player info, and restart options*/}
          <Grid stackable style = {{height:"95%"}}>
            <Grid.Column color="grey" width={4} stretched>

              <Grid.Row>
                <TeacherGameInfo 
                  gameCode={this.props.gameCode} 
                  gameState={this.props.gameState} 
                  gameType={this.props.gameType} 
                  handleStateButton={this.props.handleStateButton}
                  />
              </Grid.Row>

              <Grid.Row>
                <TeacherStudentInfo 
                  rejoinedPlayers={this.props.rejoinedPlayers}
                  approveCallback={this.props.approveCallback}
                  rejectCallback={this.props.rejectCallback}
                  totalPlayers={this.props.totalPlayers}
                />
              </Grid.Row>

              <Grid.Row>
                {this.props.handleRestartButton}
              </Grid.Row>

            </Grid.Column>

          {/* Column for general game screen*/}
            <Grid.Column color="violet" width={12} stretched>

              {this.props.gameScreen}
            </Grid.Column>
          </Grid>
        </div>
        )
    }
}

export default TeacherControlScreen;