import React from 'react';
import { Table } from 'semantic-ui-react'

// Description: Generates student leaderboard based on fish caught
// Parent: TocTeacherGameScreen
// Children: None
// Props Used:
//      players (Array)

const LeaderBoard = (props) => {
    let orderedPlayers = props.players.sort((a,b) =>  b.fish - a.fish);
    //orderedPlayers = orderedPlayers.slice(0,5); //Reduce the leaderboard to top 5
    if(props.players.length > 0){
        return (
            <Table striped> 
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Rank</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Fish Caught</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    {orderedPlayers.map((p, index) =>{
                        return(
                            <Table.Row key={index}>
                                <Table.Cell>{index+1}</Table.Cell>
                                <Table.Cell>{p.name}</Table.Cell>
                                <Table.Cell>{p.fish}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }
    else{
        return (<div style={{marginTop: '5px'}}>No data available</div>)
    }
    
}

export default LeaderBoard;