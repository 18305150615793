import React, { Component } from 'react';

class PopulationPlayerStatisticsTable extends Component {
    render() {
        if (this.props.data.rows) {
            let dataColumns = this.props.data.columns;
            let dataRows = this.props.data.rows;

            let tableHeaders = (
                <thead>
                    <tr>
                        {dataColumns.map(function(column, key) {
                            return <th key={key}>{column}</th>;
                        })}
                    </tr>
                </thead>
            );

            let tableBody = dataRows.map(function(row, outerKey) {
                return (
                    <tr key={outerKey}>
                        {dataColumns.map(function(column, key) {
                            return <td key={key}>{row[column]}</td>;
                        })}
                    </tr>
                );
            });

            return (
                <table
                    className="table table-bordered table-hover"
                    width="100%"
                >
                    {tableHeaders}
                    <tbody>{tableBody}</tbody>
                </table>
            );
        }
        return null;
    }
}

export default PopulationPlayerStatisticsTable;
