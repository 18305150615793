export const GAME_STATUS = {
	INITIALIZING: 'initializing',
	RUNNING: 'running',
	PAUSED: 'paused'
}


export const PLAYER_GROUPS = {
	0: 'circle',
	1: 'triangle',
	2: 'square',
	3: 'star'
}


export const SLIDER_SETTING =[
	{
		name: 'numGroups',
		label: 'Number of Player Groups',
		min: 2,
		max: 4,
	},

];

export const SHAPE_IMG = {
	
	0: 'https://i.imgur.com/1xfic4P.png',
	1: "https://i.pinimg.com/564x/4e/8f/4f/4e8f4fff0af3c18a7205ad8b457e4738.jpg",
	2: "https://i.pinimg.com/564x/3f/83/5f/3f835f2eacf98b59a38e5dcf130e7a61.jpg",
	3: "https://i.pinimg.com/564x/a3/f3/f3/a3f3f3e327fe8c2b03b6147e39da9d3d.jpg",
	
};