// sets the database entry in status/ to online or offline based on the user state
const setOnlineStatus = (props) => {
  var userStatusDatabaseRef = props.firebase.database().ref('/status/' + props.auth.uid);
  
  var isOfflineForDatabase = {
      state: 'offline',
      gameType: props.gameType,
      gameCode: props.gameCode,
      last_changed: props.firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
      state: 'online',
      gameType: props.gameType,
      gameCode: props.gameCode,
      last_changed: props.firebase.database.ServerValue.TIMESTAMP,
  };

  var userStatusFirestoreRef = props.firestore.doc('/status/' + props.auth.uid);

  // Firestore uses a different server timestamp value, so we'll 
  // create two more constants for Firestore state.
  var isOfflineForFirestore = {
      state: 'offline',
      gameType: props.gameType,
      gameCode: props.gameCode,
      last_changed: props.firestore.FieldValue.serverTimestamp(),
  };

  var isOnlineForFirestore = {
      state: 'online',
      gameType: props.gameType,
      gameCode: props.gameCode,
      last_changed: props.firestore.FieldValue.serverTimestamp(),
  };

  props.firebase.database().ref('.info/connected').on('value', function(snapshot) {
      if (snapshot.val() === false) {
          // Instead of simply returning, we'll also set Firestore's state
          // to 'offline'. This ensures that our Firestore cache is aware
          // of the switch to 'offline.'
          userStatusFirestoreRef.set(isOfflineForFirestore);
          return;
      };

      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
          userStatusDatabaseRef.set(isOnlineForDatabase);

          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.set(isOnlineForFirestore);
      });
  });
}

export default setOnlineStatus;