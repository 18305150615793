import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import NotFoundPage from '../core/containers/NotFoundPage';
import ChooseContainer from '../core/containers/ChooseContainer';
import PlayerContainer from '../core/containers/PlayerContainer';
import TeacherContainer from '../core/containers/TeacherContainer';
import RejoinContainer from '../core/containers/RejoinContainer';
import AdminContainer from '../core/containers/AdminContainer';
import '../core/styles.scss';

// import PrivateRoute from './PrivateRoute'; import PublicRoute from
// './PublicRoute';

export const history = createHistory();

const AppRouter = () => (
  <Router history={history}>
    <div style={{height: "100%"}}>
      <Switch>
        <Route path="/" component={ChooseContainer} exact />
        <Route path="/teacher" component={TeacherContainer} />
        <Route path="/player" component={PlayerContainer} />
        <Route path="/rejoin" component={RejoinContainer} />
        <Route path="/admin" component={AdminContainer} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
