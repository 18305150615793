import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import '../dimloaderstyles.scss';

// loading circle with text
const DimLoader = (props) => {
    const {
        message
    } = props;

    return (
        <div>
            <Dimmer active page>
                <div style={{textAlign: 'center'}}>
                    <div className="circle" >
                        <div className="dimloader" />
                    </div>    
                    
                    <br />
                    
                    <div style={{maxWidth: '250px'}}>
                        <h1 color="white" style={{fontWeight: "normal"}}>{message}</h1>
                    </div>
                </div>
            </Dimmer>
            
        </div>
    );
}

export default DimLoader;