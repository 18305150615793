import React from 'react';
import YesNoDialog from './YesNoDialog';

const StudentLogout = (props) => {
    const {
        firebase, history, setGameState
    } = props;

    return (
        <YesNoDialog 
            header="You're still logged in to a game!"
            description="Do you want to go back to your current game or logout and rejoin a game?"
            yesText="Log Out and Rejoin"
            yesAction={() => {
                firebase.logout();
                setGameState('empty');
            }}
            noText="Back to Game"
            noAction={() => history.push('/player')}
        />
    );
}

export default StudentLogout;