import React from 'react';
import { Card, Button, Dimmer } from 'semantic-ui-react';
import '../dialogstyles.scss';

// simple dialog with a single button and text
const SingleButtonDialog = (props) => {
    const {
        description, buttonText, buttonAction
    } = props;

    return (
        <div>
            <Dimmer active page>
                <Card style={{ width: props.width ? props.width : '320px'}} >
                    <Card.Content textAlign="left" style={{ alignSelf: 'center', width: '100%' }}>
                        <br />
                        <Card.Description className="description" content={description} />
                        <br />
                        <Button
                            content={buttonText}
                            onClick={buttonAction}
                            fluid
                        />
                    </Card.Content>
                </Card>
            </Dimmer>
        </div>
    );
}

export default SingleButtonDialog;