export const GAME_STATUS = {
	INITIALIZING: 'initializing',
	RUNNING: 'running',
	PAUSED: 'paused'
}

export const STORE_PRICES = {
	ADD_SENSOR: 60,
	ADD_CONTRACT: 60, 
	ADD_ALLIANCE: 100 //unused. delete? -jonah 03/2022
};

export const SENSOR_ICONS = {
	rain: { color: 'blue', name: 'rain' },
	temp: { color: 'red', name: 'thermometer' },
	gas: { color: 'green', name: 'cloud' },
	animal: { color: 'purple', name: 'paw' },
	population: { color: 'purple', name: 'paw' }
};

export const selectColor = "blue";

