import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import CreateGraphs from './CreateGraphs';
import SavedGraphs from './SavedGraphs';

import { selectColor } from '../../constants';
/**
 * The screen where players can create, view, and save graphs of different kinds based on data from collected sensors.
 * Parent: PlanetPlayerGameScreen
 * Children: CreateGraphs, SavedGraphs
 */

class GraphingScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const panes = [
			{
				menuItem: 'Create Graph',
				render: () => (
					<Tab.Pane>
						<CreateGraphs />
					</Tab.Pane>
				)
			},

			{
				menuItem: 'Saved Graphs',
				render: () => (
					<Tab.Pane>
						<SavedGraphs />
					</Tab.Pane>
				)
			}
		];

		return (
			<div>
				<Tab panes={panes} menu={{ color: selectColor, secondary: true, pointing: true }} />
			</div>
		);
	}
}

export default GraphingScreen;
