import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHAPE_IMG, PLAYER_GROUPS } from '../constants';

import { Header, Card, Image, Dimmer } from 'semantic-ui-react';


/**
 * The main player page showing their role(shape) information
 * 
 * Parent: InfoPlayerGameScreen
 * Children: None
 * 
 */

class RoleCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {shape} = this.props;

        return (
            <>
                <Header>Your role is: {PLAYER_GROUPS[shape]}</Header>
                <Card
                    centered={true}>
                    <Image
                        src = {SHAPE_IMG[shape]} 
                        size='large'
                        centered={true}
                        wrapped ui={false}
                        bordered>
                    </Image>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    shape: state.infoGame.player.shape,
});

export default connect(mapStateToProps)(RoleCard);