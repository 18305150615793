import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addContract, incSensorsCap } from '../../playerApi';
import { STORE_PRICES } from '../../constants';

import { Grid } from 'semantic-ui-react';
import { Button, Header, Icon, Card, Label } from 'semantic-ui-react';

/**
 * The store screen where players can use the coins they earned from completing
 * contracts to purchase additional contracts, sensors, or form alliances with
 * other players.
 * Parent: PlanetPlayerGameScreen
 * Children: None
 */

class PlanetGameStore extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div style={{ margin: '10px', textAlign: 'center', padding: '10px' }}>
				<Header size="medium">Welcome to the store</Header>

				<Grid columns={2} centered>
					<Grid.Row>
						<Grid.Column>
							<Button icon labelPosition="left" color="blue">
								<Icon name="file outline" />
								{this.props.player.points}
							</Button>
						</Grid.Column>

						<Grid.Column>
							<Button icon labelPosition="left" color="yellow">
								<Icon name="bullseye" />
								{this.props.player.coins}
							</Button>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Card.Group>
							<Card>
								<Card.Content>
									<Icon bordered inverted color="teal" name="paw" floated="right" />
									<br />
									<Card.Header>Purchase Contract</Card.Header>
									<Card.Meta>Gain another animal contract</Card.Meta>
								</Card.Content>
								<Card.Content extra>
									<Button
										as="div"
										labelPosition="left"
										disabled={this.props.player.coins < STORE_PRICES.ADD_CONTRACT}
									>
										<Label as="a" basic>
											{STORE_PRICES.ADD_CONTRACT} <Icon name="target" />
										</Label>
										<Button
											icon
											onClick={() => addContract(true)}
										>
											{' Buy '}
										</Button>
									</Button>
								</Card.Content>
							</Card>
							<Card>
								<Card.Content>
									<Icon bordered inverted color="teal" name="paw" floated="right" />
									<br />
									<Card.Header>Purchase Sensor</Card.Header>
									<Card.Meta>Increases your sensor capacity by 1</Card.Meta>
									<Card.Description> </Card.Description>
								</Card.Content>
								<Card.Content extra>
									<Button
										as="div"
										labelPosition="left"
										disabled={this.props.player.coins < STORE_PRICES.ADD_SENSOR}
									>
										<Label as="a" basic>
											{STORE_PRICES.ADD_SENSOR} <Icon name="target" />
										</Label>
										<Button
											icon
											onClick={incSensorsCap}
										>
											{' Buy '}
										</Button>
									</Button>
								</Card.Content>
							</Card>
							{/* <Card>
								<Card.Content>
									<Icon bordered inverted color="teal" name="paw" floated="right" />
									<br />
									<Card.Header>Form an alliance</Card.Header>
									<Card.Meta>
										Pick a player to share data with <br />
										Note: The player would also have to purchase this power up
									</Card.Meta>
									<Card.Description> </Card.Description>
								</Card.Content>
								<Card.Content extra>
									{ NOTE: not implemented so comment this out- <Button
										as="div"
										labelPosition="left"
										disabled={this.props.player.coins < STORE_PRICES.ADD_ALLIANCE}
									>
										<Label as="a" basic>
											{STORE_PRICES.ADD_ALLIANCE} <Icon name="target" />
										</Label>
										<Button
											icon
											onClick={() => {}}
										>
											{' Buy '}
										</Button>
									</Button> }
								</Card.Content>
							</Card> */}
						</Card.Group>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	player: state.planetGame.player
});

export default connect(mapStateToProps)(PlanetGameStore);
