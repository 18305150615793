import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

// Simple page with white raised segment, and room for content above and below the segment
const BasicGreyPage = (props) => {
    const {
        pageClass, top, center, bottom, compact, style
    } = props;

    return (
        <div className={pageClass} style={{ height: '100%' }}>
            <Grid textAlign="center" style={{ minHeight: '100%', margin: '0' }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: '450px', marginLeft: '14px', marginRight: '14px', marginTop: '14px' }}>
                    {top}

                    <Segment className="bgp-segment" raised compact={compact} style={style}> 
                        {center}
                    </Segment>

                    {bottom}
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default BasicGreyPage;