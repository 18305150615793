import React from "react";
import { List, Segment, Button } from "semantic-ui-react";

const JoinRequestList = (props) => {
    const {
        rejoinedPlayers,
        approveCallback,
        rejectCallback,
    } = props;

    const idList = rejoinedPlayers.map((item, index) => (
        item.id
    ));

    return(
        <Segment textAlign="left" style={{maxHeight: '100px', overflowY: 'scroll'}}>
            {(rejoinedPlayers.length > 0) ? (
                <div>
                    <List relaxed verticalAlign="middle">
                        {rejoinedPlayers.map((item, index) => (
                            <List.Item>
                                <List.Content floated="right">
                                    <Button.Group size="mini">
                                        <Button 
                                            circular icon = "check"
                                            onClick={() => {
                                                approveCallback(item.id);
                                            }} 
                                            color="green"
                                        />
                                        <Button 
                                            circular icon = "cancel"
                                            onClick={() => {
                                                rejectCallback(item.id);
                                            }} 
                                            color="red"
                                        />
                                    </Button.Group>
                                </List.Content>
                                <List.Content style={{height: "100%"}} verticalAlign="middle">{item.name}</List.Content>
                            </List.Item>
                        ))}
                    </List>
                    <hr/>
                    <Button.Group floated="right" size="mini">
                        <Button 
                            color="green"
                            onClick={() => {
                                var id;
                                for (id of idList) {
                                    approveCallback(id);
                                }
                            }}
                        >
                            Approve all
                        </Button>
                        <Button 
                            color="red"
                            onClick={() => {
                                var id;
                                for (id of idList) {
                                    rejectCallback(id);
                                }
                            }}    
                        >
                            Reject all
                        </Button>
                    </Button.Group>
                </div>
            ) : 
            <span>
                There are currently no join requests for this game.    
            </span>}
        </Segment>
    )
}

export default JoinRequestList;