import firebase from 'firebase';
import { collection, getDocs } from "firebase/firestore";
// import { doc } from 'prettier';
import store from '../store/store';
import { GAME_STATUS, PLAYER_GROUPS } from './constants';
import {gameActions} from './slices/gameSlice';




// all unsubscribe functions to be called when leaving a page, typically for listeners
const unsubscribes = [];
window.onbeforeunload = () => {
	for (const unsubscribe of unsubscribes) unsubscribe();
	return null;
};

const dbProvider = 'firebase';

// Convenience for Redux
const dispatch = store.dispatch;
let state = store.getState();
let gameState = state.infoGame;
unsubscribes.push(
	store.subscribe(() => {
		state = store.getState();
		gameState = state.infoGame;
	})
);

// Convenience for Firebase
const firestore = firebase.firestore;
const games = firestore().collection('infogames');

// Frequent calls
const getGameCode = () => state.core.gameCode;

/**************** Getters ****************/

/**
 * Gets all the settings for the current game and set a listener for changes to players list
 */
export const getGameData = async () => {
	console.log('[infoteacherApi] running getgameData');
	let settings = {},
		players = [];

	// retrieve from database
	switch (dbProvider) {
		case 'firebase':
			// getting settings
			settings = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => {
					// grab existing field for each property included in the initial Redux state
					const settingOptions = Object.keys(gameState.game).filter(
							setting => setting !== 'players'
						),
						existingSettings = {};

					for (const setting of settingOptions)
						existingSettings[setting] = docSnapshot.get(setting);

					return existingSettings;
				})
				.catch(error => console.error(error));

			// get players
			players = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot => colSnapshot.docs.map(doc => doc.data()))
				.catch(error => console.error(error));

			// set listener for players
			const unsubscribe = games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update players if changed
				if (docSnapshot.get('players') !== gameState.game.players.length) {
					const playersList = await docSnapshot.ref
						.collection('players')
						.get()
						.then(colSnapshot => colSnapshot.docs.map(doc => doc.data()))
						.catch(error => console.error(error));
					dispatch(gameActions.setPlayers(playersList));
				}
			});
			unsubscribes.push(unsubscribe);

			break;
		default:
			break;
	}

	// perform Redux action
	for (const [setting, value] of Object.entries(settings)) {
		if (value !== undefined)
			dispatch(gameActions.setSetting({ setting: setting, value: value }));
	}
	dispatch(gameActions.setPlayers(players));
};



/**************** Setters ****************/

/**
 * Update a setting's value (gameStatus, numPlanets, numRegions, monthDuration)
 * @param {string} setting the name of the setting to change
 * @param {any} value the value to assign
 * @param {boolean} updateDatabase whether or not to update the database's record of this setting
 */
export const setSetting = async (setting, value, updateDatabase = false) => {
	console.log('[infoTeacherApi] running setSetting');
	// update database
	if (updateDatabase) {
		switch (dbProvider) {
			case 'firebase':
				let success;
				if (gameState.game.gameStatus === GAME_STATUS.INITIALIZING)
					success = await initializeGame();
				
				
				success =
					success !== false &&
					(await games
						.doc(getGameCode())
						.update({ [setting]: value })
						.then(() => true)
						.catch(error => console.error(error)));
				if (success === true) break;
				else return;
			default:
				break;
		}
	}

	// perform Redux action
	dispatch(gameActions.setSetting({ setting: setting, value: value }));
};

/**
 * Initialize the game by generating the planets
 * @returns whether or not the game was successfully initialized on the backend
 */
const initializeGame = async () => {
	console.log('[infoTeacherApi] running initializeGame');
	//await resetGame();
	//console.log('[teacherApi] successfully reset');
	
	// update database
	let success,
		players = [],
		playerIds = [];
		
	switch (dbProvider) {
		case 'firebase':
			// initialize game settings
			
			success = 
				(await games
					.doc(getGameCode())
					.set({
						gameStatus: GAME_STATUS.PAUSED,
						numGroups: gameState.game.numGroups
					})
					.then(() => true)
					.catch(error => console.error(error)));

			players = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot => colSnapshot.docs.map(doc => doc.data()))
				.catch(error => console.error(error));
				
			playerIds = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot => colSnapshot.docs.map(doc => doc.id))
				.catch(error => console.error(error));

			console.log(playerIds);

			for (let i = 0; i < playerIds.length; i++) {
				//players[i].shape = PLAYER_GROUPS[i%gameState.game.numGroups];
				success = await games
					.doc(getGameCode())
					.collection('players')
					.doc(playerIds[i])
					.update({
						//players[i]
						shape: i%gameState.game.numGroups,
						shownInfo: PLAYER_GROUPS[i%gameState.game.numGroups]
					}
					)
					.then(() => true)
					.catch(error => {
						console.error(error);
						return false;
					});
				if (success === false) return false;
			}

			for (let i = 0; i < playerIds.length; i++) {
				players[i].shape = i%gameState.game.numGroups;
				players[i].shownInfo = PLAYER_GROUPS[i%gameState.game.numGroups];
			}
			

			/*await games
				.doc(getGameCode())
				.collection('players')
				.onSnapshot((snapshot) => {
					snapshot.forEach((doc) =>
					{
						console.log(doc.id);
						playerIds.push(doc.id);
					}
					)
				});*/

			

			if (success === true) break;
			else return false;
		default:
			return false;
	}

	// perform Redux action
	dispatch(gameActions.setPlayers(players));
	return true;
};

/**
 * Remove any existing documents associated with the current game
 */
export const resetGame = async () => {
	switch (dbProvider) {
		case 'firebase':
			const game = games.doc(getGameCode());

            
			// reset all players
			await Promise.all(
				await game
					.collection('players')
					.get()
					.then(colSnapshot =>
						colSnapshot.docs.map(docSnapshot =>
							docSnapshot.ref.update({
								shape: "",
								shownInfo: ""
							})
						)
					)
			);
            
			

			await game.update({
				gameStatus: GAME_STATUS.INITIALIZING,
			});
			break;
		default:
			break;
	}

	dispatch(gameActions.reset());
};


