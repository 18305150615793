import { createSlice } from '@reduxjs/toolkit';
import { GAME_STATUS } from '../constants';

/*
Structure of the settings slice of the global state (Redux)
Used for teacher

state: {
	gameStatus: string, 
	numPlanets: number,
	numRegions: number,
	monthDuration: number,
	numSensors: number,
	startingCoin: number,
	currentMonth: number,
	players: (player object)[], // see playerSlice
	planets: string[] // planet names
}
*/

const INITIAL_STATE = {
	gameStatus: '',
	numPlanets: 4,
	numRegions: 3,
	monthDuration: 10,
	numSensors: 4,
	startingCoin: 100,
	currentMonth: 0,
	qrOn: false,
	sendDataOn: true,
	storeOn: true,
	players: [],
	planets: []
};

const slice = createSlice({
	name: 'settings',
	initialState: { ...INITIAL_STATE },
	reducers: {
		setSetting: (state, action) => {
			/* payload: {
				setting: string,
				value: any
			} */
			const { setting, value } = action.payload;
			console.log(`[settings/setSetting] setting ${setting} to ${value}`);
			state[setting] = value;
		},

		setPlayers: (state, action) => {
			// payload: (player object)[]
			const players = action.payload.map(player => ({ ...player, name: player.displayName }));
			console.log('[settings/setPlayers] setting list of players to', players);
			state.players = players;
		},

		setPlanets: (state, action) => {
			// payload: string[]
			console.log('[settings/setPlanets] setting planets to', action.payload);
			state.planets = action.payload;
		},

		incrementMonth: (state, action) => {
			// payload: none
			console.log(`[settings/incrementMonth] increasing month by 1 to ${state.currentMonth + 1}`);
			state.currentMonth += 1;
		},

		reset: (state, action) => {
			// payload: none
			console.log('[settings/reset] resetting settings');
			for (const setting in INITIAL_STATE)
				if (setting !== 'players') state[setting] = INITIAL_STATE[setting];
			state.gameStatus = GAME_STATUS.INITIALIZING;
		},

		addContract: (state, action) => {
			/* payload: {
				playerId: string,
				animalName: string
			} */
			const { playerId, animalName } = action.payload;
			console.log(`[settings/addContract] adding ${animalName} to player with id ${playerId}`);
			state.players.find(player => player.id === playerId).animals.push(animalName);
		},

		toggleQr: (state, action) => {
			// payload: none
			console.log(`[settings/toggleQr] ${!state.qrOn ? 'enabling' : 'disabling'} QR navigation`);
			state.qrOn = !state.qrOn;
		},

		toggleStore: (state, action) => {
			// payload: none
			console.log(`[settings/toggleStore] ${!state.storeOn ? 'enabling' : 'disabling'} store`);
			state.storeOn = !state.storeOn;
		},

		toggleSendData: (state, action) => {
			// payload: none
			console.log(`[settings/toggleSendData] ${!state.sendDataOn ? 'enabling' : 'disabling'} store`);
			state.sendDataOn = !state.sendDataOn;
		}
	}
});

export const teacherActions = slice.actions;

export default slice.reducer;
