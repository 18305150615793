import firebase from 'firebase';
// import { doc } from 'prettier';
import store from '../store/store';
import { GAME_STATUS } from './constants';
import { teacherActions } from './slices/settingsSlice';

/* 
TIP: to fold all the functions that your cursor is not in, 
—— press ctrl + K, then ctrl 1 ——
(to unfold all, ctrl + K, then ctrl + J) (cmd on Mac)
It'll make this file a lot easier to navigate. Trust me.
*/

/* 
TIP: to fold all the functions that your cursor is not in, 
—— press ctrl + K, then ctrl 1 ——
(to unfold all, ctrl + K, then ctrl + J) (cmd on Mac)
It'll make this file a lot easier to navigate. Trust me.
*/

// all unsubscribe functions to be called when leaving a page, typically for listeners
const unsubscribes = [];
window.onbeforeunload = () => {
	for (const unsubscribe of unsubscribes) unsubscribe();
	return null;
};

// In the future this can indicate any provider that we support
const dbProvider = 'firebase';

/**************** Convenience ****************/

// Convenience for Redux
const dispatch = store.dispatch;
let state = store.getState();
let gameState = state.planetGame;
unsubscribes.push(
	store.subscribe(() => {
		state = store.getState();
		gameState = state.planetGame;
	})
);

// Convenience for Firebase
const firestore = firebase.firestore;
const games = firestore().collection('lgames');

// Frequent calls
const getGameCode = () => state.core.gameCode;

/**************** Getters ****************/

/**
 * Gets all the settings for the current game and set a listener for changes to players list
 */
export const getGameData = async () => {
	console.log('[teacherApi] running getgameData');
	let settings = {},
		players = [];

	// retrieve from database
	switch (dbProvider) {
		case 'firebase':
			// getting settings
			settings = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => {
					// grab existing field for each property included in the initial Redux state
					const settingOptions = Object.keys(gameState.settings).filter(
							setting => setting !== 'players'
						),
						existingSettings = {};

					for (const setting of settingOptions)
						existingSettings[setting] = docSnapshot.get(setting);

					return existingSettings;
				})
				.catch(error => console.error(error));

			// get players
			players = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot => colSnapshot.docs.map(doc => doc.data()))
				.catch(error => console.error(error));

			// set listener for players
			const unsubscribe = games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update players if changed
				if (docSnapshot.get('players') !== gameState.settings.players.length) {
					const playersList = await docSnapshot.ref
						.collection('players')
						.get()
						.then(colSnapshot => colSnapshot.docs.map(doc => doc.data()))
						.catch(error => console.error(error));
					dispatch(teacherActions.setPlayers(playersList));
				}
			});
			unsubscribes.push(unsubscribe);

			break;
		default:
			break;
	}

	// perform Redux action
	for (const [setting, value] of Object.entries(settings)) {
		if (value !== undefined)
			dispatch(teacherActions.setSetting({ setting: setting, value: value }));
	}
	dispatch(teacherActions.setPlayers(players));
};

/**************** Setters ****************/

/**
 * Update a setting's value (gameStatus, numPlanets, numRegions, monthDuration)
 * @param {string} setting the name of the setting to change
 * @param {any} value the value to assign
 * @param {boolean} updateDatabase whether or not to update the database's record of this setting
 */
export const setSetting = async (setting, value, updateDatabase = false) => {
	console.log('[teacherApi] running setSetting');

	// update database
	if (updateDatabase) {
		switch (dbProvider) {
			case 'firebase':
				let success;
				if (gameState.settings.gameStatus === GAME_STATUS.INITIALIZING)
					success = await initializeGame();

				success =
					success !== false &&
					(await games
						.doc(getGameCode())
						.update({ [setting]: value })
						.then(() => true)
						.catch(error => console.error(error)));

				if (success === true) break;
				else return;
			default:
				break;
		}
	}

	// perform Redux action
	dispatch(teacherActions.setSetting({ setting: setting, value: value }));
};

/**
 * Initialize the game by generating the planets
 * @returns whether or not the game was successfully initialized on the backend
 */
const initializeGame = async () => {
	console.log('[teacherApi] running initializeGame');
	//await resetGame();
	//console.log('[teacherApi] successfully reset');
	const planets = generatePlanets(gameState.settings.numPlanets, gameState.settings.numRegions);

	// update database
	let success;
	switch (dbProvider) {
		case 'firebase':
			// add all planets into database
			for (let i = 0; i < planets.length; i++) {
				success = await games
					.doc(getGameCode())
					.collection('planets')
					.doc(String(i))
					.set(planets[i])
					.then(() => true)
					.catch(error => {
						console.error(error);
						return false;
					});
				if (success === false) return false;
			}

			// initialize other settings
			success =
				success === true &&
				(await games
					.doc(getGameCode())
					.set({
						gameStatus: GAME_STATUS.PAUSED,
						currentMonth: 0,
						monthDuration: gameState.settings.monthDuration,
						numSensors: gameState.settings.numSensors,
						startingCoin: gameState.settings.startingCoin,
						planets: planets.map(planet => planet.name),
						qrOn: false,
						sendDataOn: true,
						storeOn: true
					})
					.then(() => true)
					.catch(error => console.error(error)));

			if (success === true) break;
			else return false;
		default:
			return false;
	}

	// perform Redux action
	dispatch(teacherActions.setPlanets(planets.map(planet => planet.name)));
	return true;
};

/**
 * Generate planets with their weather, gas composition, and styling
 * @param {number} numPlanets number of planets to generate in the game
 * @param {number} numRegions number of regions per planet
 * @returns an array of planet objects
 */
const generatePlanets = (numPlanets, numRegions) => {
	/////////////////////// helpers ///////////////////////
	// constants
	const GASES = ['CO2', 'Oxygen', 'Hydrogen', 'Nitrogen', 'Argon'],
		MAX_RAIN_VALUE = 200,
		MIN_TEMP_VALUE = -20,
		MAX_TEMP_VALUE = 150,
		NUM_YEARS = 30; // number of years of data to generate (which the game cycles through)


	const randomName = (length = 2) => {
		// random syllables
		const syllables = ['ya','cal','fal','ja','pan',
						   'be','el','gle','per','tem',
						   'bir','di','hil','im','rin',
						   'for','ko','so','to','tro',
						   'chu','lu','sun','vu','zu'];
		let s = '';
		for (let i = 0; i < length; i++)
			s += syllables[Math.floor(Math.random() * syllables.length)];
		return s[0].toUpperCase() + s.slice(1);
	};

	const randomInt = max => Math.floor(Math.random() * max);

	const generateGases = () => {
		const percentages = [];
		const gasComp = {};
		let remaining = 100 - GASES.length + 1;

		// generate random nonzero percentages that total 100
		for (let i = 0; i < GASES.length - 1; i++) {
			const percentage = randomInt(remaining) + 1;
			percentages.push(percentage);
			remaining -= percentage - 1;
		}
		percentages.push(remaining);

		// randomly assign those percentages to gases
		const remainingGases = [...GASES];
		while (remainingGases.length > 0) {
			const index = randomInt(remainingGases.length);
			gasComp[remainingGases[index]] = percentages.pop();
			remainingGases.splice(index, 1);
		}
		return gasComp;
	};

	const generateWeather = () => {
		/* 
		The idea here is to have a base set of values for 12 months and to repeat those values
		year with slight variance added to each value (for both rain and temperature).
		*/
		const locations = ['North', 'South', 'East', 'West', 'Central']; //NEW
		const regions = {};
		for (let i = 0; i < numRegions; i++) {
			// rain
			let baseSeasonalRain = Array(4)
					.fill({})
					.map(() => randomInt(MAX_RAIN_VALUE)), // one value for each season
				baseMonthlyRain = baseSeasonalRain.flatMap(val => [
					val,
					Math.max(val + (randomInt(51) - 25), 0),
					Math.max(val + (randomInt(51) - 25), 0)
				]), // three value for each season, ± 25 from seasonal values
				rainData = Array(NUM_YEARS)
					.fill({})
					.flatMap(() => baseMonthlyRain.map(val => Math.max(val + (randomInt(11) - 5), 0))); // twelve values for every year, ± 5 from monthly values

			// temp (same process, except this time allowing negative values)
			let baseSeasonalTemp = Array(4)
					.fill({})
					.map(() => randomInt(MAX_TEMP_VALUE - MIN_TEMP_VALUE) + MIN_TEMP_VALUE),
				baseMonthlyTemp = baseSeasonalTemp.flatMap(val => [
					val,
					val + (randomInt(51) - 25),
					val + (randomInt(51) - 25)
				]),
				tempData = Array(NUM_YEARS)
					.fill({})
					.flatMap(() => baseMonthlyTemp.map(val => val + (randomInt(11) - 5)));

			let name = (i === 2 && numRegions === 3) ? locations[4] : locations[i]; //NEW
			regions[String(i)] = {
				name: name,  //NEW
				rain: rainData,
				temp: tempData
			};
		}

		return regions;
	};

	const generateStyle = existingPlanets => {
		/*
		 Note: zone means the entire gray area; range means the range of possible viable 
		 coordinates for the circle without being cut off by the bottom/right edge
		 */
		const ZONE_WIDTH = 392,
			MAX_PLANET_WIDTH = 50,
			RANGE_WIDTH = ZONE_WIDTH - MAX_PLANET_WIDTH,
			OTHER_CONTENT_HEIGHT = 36, // everything except the circle itself
			HALF_OF_RANGE = RANGE_WIDTH / 2,
			MAX_DISTANCE = 1.4 * HALF_OF_RANGE,
			ANGLE_INTERVAL = (2 * Math.PI) / numPlanets;

		// generate position (ensure no overlap) and appearance
		let style;
		do {
			const r = randomInt(MAX_DISTANCE); // distance from center
			style = {
				x: HALF_OF_RANGE + Math.round(r * Math.cos(ANGLE_INTERVAL * existingPlanets.length)),
				y: HALF_OF_RANGE + Math.round(r * Math.sin(ANGLE_INTERVAL * existingPlanets.length)),
				size: Math.round(Math.random() * 15) + 35
			};
		} while (
			// whether or not style is out of bounds or overlaps with a prevStyle
			style.x < 0 ||
			style.x > RANGE_WIDTH ||
			style.y < 0 ||
			style.y > RANGE_WIDTH - OTHER_CONTENT_HEIGHT - 10 ||
			existingPlanets.find(prevPlanet => {
				const prevStyle = prevPlanet.style;
				return (
					style.x > prevStyle.x - style.size &&
					style.x < prevStyle.x + prevStyle.size &&
					style.y > prevStyle.y - style.size - OTHER_CONTENT_HEIGHT &&
					style.y < prevStyle.y + prevStyle.size + OTHER_CONTENT_HEIGHT
				);
			}) !== undefined
		);

		// generate random color
		const rgb = [],
			rgbOptions = [255, 0, Math.floor(Math.random() * 256)];
		for (let i = 0; i < 3; i++) {
			const index = Math.floor(Math.random() * rgbOptions.length);
			rgb.push(rgbOptions[index]);
			rgbOptions.splice(index, 1);
		}
		style.color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;

		return style;
	};

	/////////////////////// overall process ///////////////////////
	console.log(`[teacherApi] Generating ${numPlanets} planets with ${numRegions} regions`);

	const planets = [];
	for (let i = 0; i < numPlanets; i++) {
		const planet = {};
		do {
			planet.name = randomName();
		} while (planets.find(prevPlanet => prevPlanet.name === planet.name) !== undefined);

		planet.gasComp = generateGases();
		planet.regions = generateWeather();
		planet.style = generateStyle(planets);

		planets.push({
			...planet,
			animals: {},
			numRegions: numRegions
		});
	}

	return planets;
};

/**
 * Remove any existing documents associated with the current game
 */
export const resetGame = async () => {
	switch (dbProvider) {
		case 'firebase':
			const game = games.doc(getGameCode());
			// delete all planets
			await Promise.all(
				await game
					.collection('planets')
					.get()
					.then(colSnapshot => colSnapshot.docs.map(docSnapshot => docSnapshot.ref.delete()))
			);

			// reset all players
			await Promise.all(
				await game
					.collection('players')
					.get()
					.then(colSnapshot =>
						colSnapshot.docs.map(docSnapshot =>
							docSnapshot.ref.update({
								coins: gameState.settings.startingCoin,
								sensorsBought: 0,
								contractsBought: 0,
								contractsDone: 0,
								animals: [],
								sensorsCapacity: gameState.settings.numSensors,
								activeSensors: [],
								collectedSensors: [],
								savedGraphs: [],
								approve: false,
								denied: false,
								rejoin: false
							})
						)
					)
			);

			// delete all animals
			await Promise.all(
				await game
					.collection('animals')
					.get()
					.then(colSnapshot => colSnapshot.docs.map(docSnapshot => docSnapshot.ref.delete()))
			);

			await game.update({
				gameStatus: GAME_STATUS.INITIALIZING,
				currentMonth: 0,
				numSensors: firestore.FieldValue.delete(),
				startingCoin: firestore.FieldValue.delete(),
				monthDuration: firestore.FieldValue.delete(),
				planets: firestore.FieldValue.delete(),
				qrOn: firestore.FieldValue.delete(),
				storeOn: firestore.FieldValue.delete(),
				sendDataOn: firestore.FieldValue.delete()
			});
			break;
		default:
			break;
	}

	dispatch(teacherActions.reset());
};

/**
 * Increments the month of the current game by one
 */
export const incrementMonth = async () => {
	console.log('[teacherApi] running incrementMonth');

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.update({
					currentMonth: firestore.FieldValue.increment(1)
				})
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(teacherActions.incrementMonth());
};

/**
 * Toggles whether the QR mode for the game is enabled or not
 */
export const toggleQr = async () => {
	console.log('[teacherApi] running toggleQr');

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.update({ qrOn: !gameState.settings.qrOn })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(teacherActions.toggleQr());
};

/**
 * Toggles whether the store for the game is enabled or not
 */
 export const toggleStore = async () => {
	console.log('[teacherApi] running toggleStore');

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.update({ storeOn: !gameState.settings.storeOn })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(teacherActions.toggleStore());
};

/**
 * Toggles whether data trading for the game is enabled or not
 */
 export const toggleSendData = async () => {
	console.log('[teacherApi] running toggleSendData');

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.update({ sendDataOn: !gameState.settings.sendDataOn })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(teacherActions.toggleSendData());
};
