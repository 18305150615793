import React from 'react';
import { Card, Dimmer } from 'semantic-ui-react';
import '../dialogstyles.scss';

// simple dialog with text that cannot be closed
const NoCloseDialog = (props) => {
    const {
        description
    } = props;

    return (
        <div>
            <Dimmer active page>
                <Card style={{ width: props.width ? props.width : '320px'}} >
                    <Card.Content textAlign="left">
                        <br/>
                        <Card.Description className="description" content={description} />
                        <br />
                    </Card.Content>
                </Card>
            </Dimmer>
        </div>
    );
}

export default NoCloseDialog;