import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GAME_STATUS } from '../../constants';
import { incrementMonth } from '../../teacherApi';

// Description: Maintains a visible timer when the game is running and increments the current game month.
// Parent: PlanetTeacherGameScreen
// Children: None
// Props Used:

/**
 * Maintain a visible timer when the game is running and increments the game month
 * Parent: PlanetTeacherGameScreen
 * Children: None
 *
 * @param {string} gameStatus status of the current game
 */

class TeacherTimer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seconds: props.monthDuration,
			running: false
		};
	}

	componentDidMount = () => {
		const running = this.props.gameStatus === GAME_STATUS.RUNNING;
		if (running) this.startTimer();
	};

	componentDidUpdate = () => {
		if (this.props.gameStatus === GAME_STATUS.RUNNING) {
			if (!this.state.running) this.startTimer();
		} else if (this.state.running) this.setState({ running: false });
	};

	tick = () => {
		if (!this.state.running) return;

		if (this.state.seconds === 1) {
			incrementMonth();
			this.setState({ seconds: this.props.monthDuration });
		} else {
			this.setState(state => ({
				seconds: state.seconds - 1
			}));
		}

		setTimeout(this.tick, 1000);
	};

	startTimer = () => {
		this.setState({ running: true });
		setTimeout(this.tick, 1000);
	};

	render() {
		if (this.props.gameStatus === GAME_STATUS.INITIALIZING) return null;

		return (
			<div>
				<p>
					Seconds: {this.state.seconds} {this.props.gameStatus === GAME_STATUS.PAUSED && '(Paused)'}
				</p>
				<p>Current Month: {this.props.currentMonth}</p>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	gameStatus: state.planetGame.settings.gameStatus,
	currentMonth: state.planetGame.settings.currentMonth,
	monthDuration: state.planetGame.settings.monthDuration
});

export default connect(mapStateToProps)(TeacherTimer);
