import React from 'react';
import { Card, Button, Dimmer } from 'semantic-ui-react';
import '../dialogstyles.scss';

//  simple dialog with header, text, and positive/negative buttons
const YesNoDialog = (props) => {
    const {
        header, description, yesText, noText, yesAction, noAction
    } = props;

    return (
        <div>
            <Dimmer active page>
                <Card style={{width: props.width ? props.width : '320px'}} >
                    <Card.Content textAlign="left">
                        <br />
                        <Card.Header content={header} />
                        <br/>
                        <Card.Description className="description" content={description} />
                        <br />
                    </Card.Content>
                    {yesText !==undefined &&
                    <Card.Content extra>
                        <Button.Group widths={2}>
                            <Button 
                                color="green" 
                                content={yesText} 
                                onClick={yesAction}
                                basic
                            />
                            <Button 
                                color="red"
                                content={noText}
                                onClick={noAction}
                                basic
                            />
                        </Button.Group>
                    </Card.Content>
                    }
                </Card>
            </Dimmer>
        </div>
    );
}

export default YesNoDialog;