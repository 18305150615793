import React, { Component } from 'react';
import BasicGreyPage from '../../../core/components/BasicGreyPage';
import TeacherButtons from '../../../core/components/TeacherButtons';
import YesNoDialog from '../../../core/components/YesNoDialog';
import PopulationGameInformation from './PopulationGameInformation';
import StationDetails from './StationDetails';
import PlayerStatisticsDisplay from './PlayerStatisticsDisplay';
import firebase from 'firebase';
import '../styles.scss';
import SingleButtonDialog from './../../../core/components/SingleButtonDialog';

const db = firebase.firestore();
const initPopulation = 10;

class PopulationTeacherGameScreen extends Component {
    gameLoaded = null;

    constructor(props) {
        super(props);
        this.state = {
            currentConfiguration: 0,
            stations: props.gameInfo.configurations[0].stations,
            showPlayerError: false,
            rejoinedPlayers: [],
            totalPlayers: [],
            generation: props.gameInfo.generation,
            generationNumber: Object.keys(props.gameInfo.populationData).length,
            predation: props.gameInfo.predation,
            movementLoss: props.gameInfo.movementLoss,
            populationData: props.gameInfo.populationData,
            histogramData: props.gameInfo.histogramData,
            populationDataTable: null,
            timerStart: 0,
            timerTime: 0,
        };
    }

    // set listener for rejoined players
    UNSAFE_componentWillMount() {
        this.props.firestore.setListener(
            {
                collection: this.props.players,
                where: ['currentGameId', '==', this.props.gameCode],
            },
            doc => this.listenToArray(doc),
        );
        this.gameLoaded = this.gameListen()

        this.props.firestore
            .setListener({
                collection: this.props.players,
                where: ['currentGameId', '==', this.props.gameCode],
            },
            data => {
                let newRows = [];
                if (!data.empty) {
                    for (const playerDoc of data.docs) {
                        const {
                            displayName,
                            energy,
                            population,
                        } = playerDoc.data();

                        const rowObject = {};
                        rowObject['Name'] = displayName;
                        rowObject['EnergyLevel'] = energy;
                        rowObject['PopulationCount'] = population;

                        newRows.push(rowObject);
                    }
                    newRows.sort((a, b) => {
                        let popKeyA = a.PopulationCount;
                        let popKeyB = b.PopulationCount;

                        if (popKeyA < popKeyB) return 1;
                        if (popKeyA > popKeyB) return -1;
                        return 0;
                    });
                }

                let tableData = {
                    columns: ['Name', 'EnergyLevel', 'PopulationCount'],
                    rows: newRows,
                };
                this.setState({populationDataTable: tableData})
            });
    }

    // unset listener for rejoined players
    componentCleanup = () => {
        this.props.firestore.unsetListener({
            collection: this.props.players,
            where: ['currentGameId', '==', this.props.gameCode],
        });

        if (this.gameLoaded) {
            this.props.firestore.unsetListener({
                collection: this.props.game,
                doc: this.props.gameCode,
            });
        }

        this.props.firestore.unsetListener({
            collection: 'status',
        });

        this.stopTimer();
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
        if (this.props.gameInfo.gameState === 'running' && !this.props.gameInfo.generation){
          this.startTimer();
        }
    }

    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
    }

    listenToArray = data => {
        return new Promise(resolve => {
            return new Promise(resolve => {
                var rejoined = [];
                var total = [];
                var promises = [];

                data.forEach(item => {
                    promises.push(
                        new Promise(resolve => {
                            this.props.firestore.setListener(
                                {
                                    collection: 'status',
                                    doc: item.id,
                                },
                                status => {
                                    total = total.filter(player => player.name !== item.data().displayName);
                                    rejoined = rejoined.filter(player => player.name !== item.data().displayName);

                                    const active = status.data() === undefined ? false : status.data().state === 'online';
                                    total.push({
                                        name: item.data().displayName,
                                        id: item.id,
                                        active: active,
                                    });
                                    if (item.data().rejoin)
                                        rejoined.push({
                                            name: item.data().displayName,
                                            id: item.id,
                                            active: active,
                                        });
                                    resolve(true);
                                },
                            );
                        }),
                    );
                });

                Promise.all(promises).then(() => {
                    this.setState({
                        rejoinedPlayers: rejoined,
                        totalPlayers: total,
                    });
                    resolve(true);
                });
            });
        });
    };

    gameListen = () => {
        return this.props.firestore.setListener(
            {
                collection: this.props.game,
                doc: this.props.gameCode,
            },
            gamedata => {
                const data = gamedata.data();
                this.setState({
                    gameState: data.gameState,
                    generation: data.generation,
                    histogramData: data.histogramData,
                    populationData: data.populationData,
                    generationNumber: Object.keys(data.populationData).length,
                    predation: data.predation,
                    movementLoss: data.movementLoss,
                    stations: data.configurations[this.state.currentConfiguration].stations
                });
            },
        );
    };

    getCurrentConfiguration = () => {
        return this.props.gameInfo.configurations[
            '' + this.state.currentConfiguration
        ];
    };

    startTimer = () => {
      this.setState({
        timerTime: this.state.timerTime,
        timerStart: Date.now() - this.state.timerTime
      });
      this.timer = setInterval(() => {
        this.setState({
          timerTime: Date.now() - this.state.timerStart
        });
      }, 100);
    };

    stopTimer = () => {
      clearInterval(this.timer);
    }

    resetTimer = () => {
      this.setState({
            timerTime: 0,
            timerStart: 0
        });
    }

    // handles running the game upon button press
    handleRunning = () => {
        const newPaused = Math.round(
            this.props.gameInfo.pausedTime +
            ((Date.now() - this.props.gameInfo.lastPause.toMillis()) / 1000), //added toMillis()) / 1000));
        );

        //start timer
        this.startTimer();

        return this.props.firestore
            .update(
                {
                    collection: this.props.game,
                    doc: this.props.gameCode,
                },
                {
                    gameState: 'running',
                    pausedTime: newPaused,
                },
            )
            .then(() => {
                this.props.firestore.update(
                    {
                        collection: 'games',
                        doc: this.props.gameCode,
                    },
                    {
                        gameState: 'running',
                    },
                );
            });
    };

    handleInitialization = () => {
        const promise1 = new Promise((resolve) => {
            this.props.firestore.get({
                collection: 'games',
                doc: this.props.gameCode
            }).then(game => {
                if(game.data().players.length <= 0){
                    this.setState({ showPlayerError: true });
                    resolve(false)
                    return;
                }
                this.props.firestore
                .update(
                    {
                        collection: this.props.game,
                        doc: this.props.gameCode,
                    },
                    {
                        gameState: 'paused',
                        lastPause: this.props.firestore.FieldValue.serverTimestamp(),
                        generation: false,
                    },
                )
                .then(response => {
                    this.props.firestore.update(
                        {
                            collection: 'games',
                            doc: this.props.gameCode,
                        },
                        {
                            gameState: 'paused',
                        },
                    );
                });
                resolve(true);
                return;
            })
        })
        const promise2 = this.props.firestore.update({
                collection: this.props.game,
                doc: this.props.gameCode,
            },
            {
                populationData: {
                    '0': initPopulation * this.state.totalPlayers.length,
                },
            },
        )

        

        return Promise.all([promise1, promise2])
    };

    // handles pausing the game upon button press
    handlePause = () => {
        this.resetFeedingTimers();
        this.stopTimer();

        return this.props.firestore
            .update(
                {
                    collection: this.props.game,
                    doc: this.props.gameCode,
                },
                {
                    gameState: 'paused',
                    lastPause: this.props.firestore.FieldValue.serverTimestamp(),
                    generation: false,
                },
            )
            .then(response => {
                this.props.firestore.update(
                    {
                        collection: 'games',
                        doc: this.props.gameCode,
                    },
                    {
                        gameState: 'paused',
                    },
                );
            });
    };

    resetFeedingTimers = () => {
        const stationRef = this.props.firestore
            .collection(this.props.game)
            .doc(this.props.gameCode);

        return stationRef.get().then(doc => {
            const stationNum = doc.data().configurations[
                this.state.currentConfiguration
            ].stations;

            Object.keys(stationNum).forEach(el => {
                const station = doc.data().configurations[
                    this.state.currentConfiguration
                ].stations[el];

                stationRef.update({
                    [`configurations.${
                       this.state.currentConfiguration
                    }.stations.${el}`]: {
                        name: station.name,
                        rate: station.rate,
                        limit: station.limit,
                        amount: station.amount,
                        risk: station.risk,
                        lastFeedTime: null
                    }
                });
            });
        });
    }

    // handles selecting a new game upon button press
    /*handleNewGame = () => {
        this.stopTimer();
        this.resetTimer();
        return new Promise(resolve => {
            if (this.props.gameInfo.gameState !== 'initializing') {
                this.handlePause().then(response => {
                    this.props.exitGame();
                    resolve(true);
                });
            } else {
                this.props.exitGame();
                resolve(true);
            }
        });
    };*/

    //On new game button press, reset the game
    handleReset = () => {
        this.stopTimer();
        this.resetTimer();
        let firestore = this.props.firestore;
        return firestore.collection('games').doc(this.props.gameCode).get()
        .then((snapshot) => {
            let batch = firestore.batch();
            let initial = snapshot.data().type.charAt(0);
            snapshot.data().players.forEach((player) =>{
                batch.delete(firestore.collection(`${initial}players`).doc(player));
                batch.delete(firestore.collection('status').doc(player));
            })
            batch.update(firestore.collection('games').doc(snapshot.id), {players:[], gameState:"initializing"})
            batch.update(firestore.collection(this.props.game).doc(this.props.gameCode), {gameState:"initializing", populationData: { '0': 0 }})
            if(initial === 'v')
            {
                snapshot.data().meets.forEach((meet) => {
                   batch.delete(firestore.collection(`${initial}gamemeetings`).doc(meet));
                })
                batch.update(firestore.collection('games').doc(snapshot.id), {meets:[]})
            }
            batch.commit();
            console.log("Finished deletion");
        }).catch((er) => console.error(er));
    }

    // handles restarting the game
    handleRestart = () => {
        this.stopTimer();
        this.resetTimer();
        const promise1 = this.props.firestore
            .update(
                {
                    collection: this.props.game,
                    doc: this.props.gameCode,
                },
                {
                    gameState: 'paused',
                    generation: false,
                    clock: this.props.firestore.FieldValue.serverTimestamp(),
                    pausedTime: 0,
                    populationData: { '0': 0 },
                    histogramData: [],
                    gameRestart: true,
                },
            )
            .then(response => {
                this.props.firestore.update(
                    {
                        collection: 'games',
                        doc: this.props.gameCode,
                    },
                    {
                        gameState: 'paused',
                    },
                );
            });

        const promise2 = new Promise(resolve => {
            this.props.firestore
                .get({
                    collection: this.props.players,
                    where: ['currentGameId', '==', this.props.gameCode],
                })
                .then(allPlayersQuery => {
                    Promise.all([
                        allPlayersQuery.forEach(doc => {
                            return this.props.firestore.update(
                                {
                                    collection: this.props.players,
                                    doc: doc.id,
                                },
                                {
                                    energy: 50,
                                    population: initPopulation,
                                    populationData: {
                                        '0': initPopulation,
                                    },
                                    newGenerationFlag: null,
                                },
                            );
                        }),
                    ]).then(result => resolve(true));
                });
        });

        const stationRef = db
            .collection(this.props.game)
            .doc(this.props.gameCode);

        const promise3 = stationRef.get().then(doc => {
                const stationNum = doc.data().configurations[
                    this.state.currentConfiguration
                ].stations;

                Object.keys(stationNum).forEach(el => {
                    const station = doc.data().configurations[
                        this.state.currentConfiguration
                    ].stations[el];

                    stationRef.update({
                        [`configurations.${
                            this.state.currentConfiguration
                            }.stations.${el}`]: {
                            name: station.name,
                            rate: station.rate,
                            limit: station.limit,
                            amount: station.limit,
                            risk: station.risk,
                            lastFeedTime: null,
                        },
                    });
                });
            });

        return Promise.all([promise1, promise2, promise3]);
    };

    removeRejoiningPlayer = id => {
        this.setState({
            rejoinedPlayers: this.state.rejoinedPlayers.filter(
                item => item.id !== id,
            ),
        });
    };

    //Remove the rejoin person and set approve to true. Hence, letting them in.
    approve = id => {
        this.removeRejoiningPlayer(id);
        console.log(this.state.rejoinedPlayers);
        this.props.firestore.update(
            {
                collection: this.props.players,
                doc: id,
            },
            {
                rejoin: false,
                approve: true,
            },
        );
    };

    //Deny the rejoin, set the player denied to true, and everything else false
    reject = id => {
        this.removeRejoiningPlayer(id);
        console.log(this.state.rejoinedPlayers);
        this.props.firestore.update(
            {
                collection: this.props.players,
                doc: id,
            },
            {
                rejoin: false,
                approve: false,
                denied: true,
            },
        );
    };

    render() {
        const {
            name,
            game,
            gameInfo,
            gameCode,
            players,
            firestore,
        } = this.props;


        const center = (
            <div>
                <PopulationGameInformation
                    game={game}
                    gameCode={gameCode}
                    players={players}
                    firestore={firestore}
                    totalPlayers={this.state.totalPlayers}
                    timerStart={this.state.timerStart}
                    timerTime={this.state.timerTime}
                    generationNumber={this.state.generationNumber}
                    movementLoss={this.state.movementLoss}
                    predation={this.state.predation}
                    gameState={this.state.gameState}
                    generation={this.state.generation}
                    currentConfiguration={this.state.currentConfiguration}
                    stations={this.state.stations}
                    startTimer={this.startTimer}
                    stopTimer={this.stopTimer}
                    resetTimer={this.resetTimer}
                    resetFeedingTimers={this.resetFeedingTimers}
                    getPopulationData={() => { return this.state.populationData }}
                    db={db}
                />

                <br />
                
                {/* for now, StationDetails includes the download data button, but this can be changed */}
                <StationDetails 
                    game={game}
                    gameCode={gameCode}
                    players={players}
                    firestore={firestore}
                    gameState={this.state.gameState}
                    stations={this.state.stations}
                    predation={this.state.predation}
                    currentConfiguration={this.state.currentConfiguration}
                />

                <PlayerStatisticsDisplay 
                    gameState={this.state.gameState}
                    populationData={this.state.populationData}
                    histogramData={this.state.histogramData}
                    populationDataTable={this.state.populationDataTable}
                />
            </div>
        );

        //Data for

        return (
            <div style={{ height: '100%' }}>

                {this.state.showPlayerError && 
                    <SingleButtonDialog
                        description="You need at least one player before starting the game."
                        buttonText="Close"
                        buttonAction={() => this.setState({showPlayerError: false})}
                    />
                }

                {this.state.rejoinedPlayers.length > 0 && (
                    <YesNoDialog
                        header="Rejoin Request"
                        description={
                            this.state.rejoinedPlayers[0].name +
                            ' would like to rejoin the game. Would you like to accept or reject?'
                        }
                        yesText="Accept"
                        yesAction={() =>
                            this.approve(this.state.rejoinedPlayers[0].id)
                        }
                        noText="Reject"
                        noAction={() =>
                            this.reject(this.state.rejoinedPlayers[0].id)
                        }
                    />
                )}

                <BasicGreyPage
                    top={<div style={{ marginBottom: '5vh' }} />}
                    center={center}
                    bottom={<div style={{ marginBottom: '15vh' }} />}
                />

                <TeacherButtons
                    name={name}
                    gameInfo={gameInfo}
                    handleInitialization={this.handleInitialization}
                    handlePause={this.handlePause}
                    handleNewGame={this.handleReset}
                    handleRestart={this.handleRestart}
                    handleRunning={this.handleRunning}
                    logout={() => {
                        this.props.firebase.logout();
                        this.props.history.push('/');
                    }}
                />
            </div>
        );
    }
}

export default PopulationTeacherGameScreen;
