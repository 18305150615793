import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import firebase from 'firebase';
import 'firebase/firestore';
import firebaseconfig from '../firebaseconfig/firebaseconfig';
import messagesReducer from '../core/reducers/messages';
import coreReducer from '../core/reducers/core'
import planetGameReducer from '../planetgame/slices/planetGameSlice';
import { enableMapSet } from '@reduxjs/toolkit/node_modules/immer';
import infoGameReducer from '../infogame/slices/infoGameSlice';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
enableMapSet();

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

firebase.initializeApp(firebaseconfig);

const settings = {}; // { timestampsInSnapshots: true };  needed for new date
const firestoreDb = firebase.firestore();
firestoreDb.settings(settings);

// export const firestoreDb;

// Add BOTH store enhancers when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase)
)(createStore);

// Add firebase and firestore to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  messages: messagesReducer,
  core: coreReducer,
	planetGame: planetGameReducer,
  infoGame: infoGameReducer
});

const initialState = {};

const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;