import React, {Component} from 'react';
import {connect} from 'react-redux';

import Carousel from 'semantic-ui-carousel-react';


/**
 * Partial contract page, where only one of the specified animal need is displayed for all of the player's owned animals
 * Parent: CreateGraphs
 * Children: None
 * @param {string} sensorType: the specific data type needs to be displayed
 */

class PartialAnimalCard extends Component {
    constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {animals, contracts} = this.props;
		console.log(animals);
		return (
			<Carousel
				elements={animals.map(name => ({
					render: () => {
						let sensorType = this.props.sensorType;
						let description = [];
						let animal = contracts[name];
						console.log(animal);
						switch(sensorType) {
							case "gas":
								Object.entries(animal.gasTol).map((entry, index) => (
									description.push(`${entry[0]}: ${entry[1][0]}% to ${entry[1][1]}%`)
								))
								break;
							case "temp":
								description = [`${animal.tempTol[0]}°C to ${animal.tempTol[1]}°C`];
								break;
							case "rain":
								description = [`${animal.rainTol[0]} cm to ${animal.rainTol[1]} cm`];
								break;
							default:
								break;
						}

						return (
							
							<div>
								<b> {`${name}'s ${this.props.sensorType} needs:`}</b>
								{description.map(line => <li>{line}</li>)}
							</div>
							
							
						)
					}
				}))}
				animation="fade"
				showNextPrev={this.props.animals.length > 1}
				showIndicators="true"
			/>
		);
	}

}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.player.animals,
	contracts: state.planetGame.contracts.animals
});

export default connect(mapStateToProps)(PartialAnimalCard);