import React from 'react';
import YesNoDialog from './YesNoDialog';

// Description: warning that teacher is still logged into their account
// Parent: PlayerContainer
// Children: None
// Props Used:
//      firebase (Object)
//      history (Object)
//      setGameState (Function)

const TeacherLogout = (props) => {
    const {
        firebase, history, setGameState
    } = props;

    return (
        <YesNoDialog 
            header="You're still logged in as a teacher!"
            description="Do you want to go back to the home page or log out and join a game as a student?"
            yesText="Log Out and Join"
            yesAction={() => {
                firebase.logout();
                setGameState('empty');
            }}
            noText="Back to Home"
            noAction={() => history.push('/')}
        />
    );
}

export default TeacherLogout;