import React from 'react';
// import TocPlayerStatisticsTable from './TocPlayerStatisticsTable';
import LineChart from '../../../core/components/LineChart';
import Histogram from '../../../core/components/Histogram';
import SingleElementAccordion from '../../../core/components/SingleElementAccordion';

const PlayerStatisticsDisplay = (props) => {
    const histogramData = {};
    props.players.forEach(key => {
        histogramData[key.name] = key.fish;
    });

    const categories = [];
    if (props.stationData.length > 0) {
        let season = 1;
        props.stationData[0]['data'].forEach((key, index) => {
        categories.push(season);
        season += 0.5;
        });
    } 

    return (
        <div>
            {/* <SingleElementAccordion
                title="Player Statistics"
                content={
                    <TocPlayerStatisticsTable
                        data={props.populationDataTable}
                    />
                }
            /> */}
            {props.gameState !== 'initializing' && (
                <div>
                    <SingleElementAccordion
                        title="Population Graph"
                        content={
                            <div style={{ height: '35vh', width: '100%' }}>
                                <LineChart
                                    data={props.stationData}
                                    categories={categories}
                                    series={props.stationData}
                                    ytitle="Fish Population"
                                    xtitle="Season"
                                    // layout={{
                                    //     xaxis: {
                                    //         title: {
                                    //             text: 'Generation',
                                    //         },
                                    //     },
                                    //     yaxis: {
                                    //         title: {
                                    //             text:
                                    //                 'Total Population Count',
                                    //         },
                                    //     },
                                    // }}
                                    marker={{ color: 'black' }}
                                    mode="lines+points"
                                />
                            </div>
                        }
                    />
                    <SingleElementAccordion
                        title="Player Fish Amount Histogram"
                        content={
                            <div style={{ height: '35vh', width: '100%' }}>
                                <Histogram
                                    layout={{
                                        xaxis: {
                                            title: {
                                                text: 'Population Size',
                                            },
                                        },
                                        yaxis: {
                                            title: {
                                                text: 'Number of Players',
                                            },
                                        },
                                    }}
                                    marker={{
                                        line: {
                                            color: 'rgba(0, 0, 0, 1)',
                                            width: 1,
                                        },
                                    }}
                                    data={histogramData}
                                    xtitle="Amount of Fish"
                                    ytitle="Player Name"
                                />
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default PlayerStatisticsDisplay;