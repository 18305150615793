import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGameStatus, attemptJoin, setUpGame, listenToGameStatus } from '../../playerApi';

import { Button, Responsive, Input, Card, Dimmer } from 'semantic-ui-react';
import BasicGreyPage from '../../../core/components/BasicGreyPage';
import PlanetGameStore from './PlanetGameStore';
import PlanetInteractionMenu from './PlanetInteractionMenu';
import PlayerInteractionMenu from './PlayerInteractionMenu';
import GraphingScreen from './GraphingScreen';
import YesNoDialog from '../../../core/components/YesNoDialog';
import LogoutConfirmation from '../../../core/components/LogoutConfirmation';
import DimIcon from '../../../core/components/DimIcon';
import PlanetNavigation from './PlanetNavigation';

import Contracts from '../../view/player/Contracts';
import '../styles.scss';
import { GAME_STATUS } from '../../constants';

/**
 * The container component for the Planet Animals game
 * Parent: PlayerContainer
 * Children: PlanetInteractionMenu, PlanetCardsHub, PlanetGameStore, Contracts,
 * PlayerInteractionMenu
 *
 * @param {object} firebase used for properly logging out
 * @param {function} loggedOut handler for successfully logging out
 */

class PlanetPlayerGameScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screen: 'home',
			showLogoutConfirmation: false,
			planetIndex: -1,
			joinStatus: this.props.gameCode !== '' ? 'valid' : '', // 'valid', 'code', 'player', ''
			inGame: false,
		};
	}

	componentDidMount = () => {
		if (this.state.joinStatus === 'valid') this.retrieveGame();
	};

	setScreenPlanet = planetIndex => {
		this.setState({
			screen: 'planet',
			planetIndex: planetIndex
		});
	};

	handleGameCodeInput = (e, { value }) => this.props.setGameCode(value.toLowerCase());

	// Tab color based on current screen
	selectColor = page => {
		if (page === this.state.screen) {
			switch (page) {
				case 'home':
					return 'green';
				case 'contracts':
					return 'blue';
				case 'graphs':
					return 'red';
				case 'store':
					return 'yellow';
				case 'player':
					return 'purple';
				default:
					return 'grey';
			}
		} else {
			return 'grey';
		}
	};

	// Verifies that the player is in the specified game code and if successful, gets game status
	retrieveGame = async () => {
		const result = await attemptJoin();
		this.setState({ joinStatus: result });
		if (result === 'valid') await getGameStatus();
	};

	// Gets the game data and sets the appropriate listeners before entering the game
	enterGame = async () => {
		listenToGameStatus();
		if (this.props.world.gameStatus !== GAME_STATUS.INITIALIZING) await setUpGame();
		this.setState({ inGame: true });
	};

	render() {
		if (this.state.showLogoutConfirmation) {
			// logout
			return (
				<LogoutConfirmation
					logoutAction={() => {
						this.setState({ buttonPressed: true });
						this.props.firebase.logout();
						this.props.loggedOut();
					}}
					backAction={() => this.setState({ showLogoutConfirmation: false })}
				/>
			);
		} else if (!this.state.inGame) {
			// still in joining process
			if (this.state.joinStatus === 'valid') {
				// confirm join game
				return (
					<YesNoDialog
						header="Enter Game"
						description={`You are about to enter the game with code ${this.props.gameCode}. Would you like to continue or log out?`}
						yesText="Continue"
						yesAction={this.enterGame}
						noText="Log Out"
						noAction={() => this.setState({ showLogoutConfirmation: true })}
					/>
				);
			} else {
				// prompt for game code
				let message;
				if (this.state.joinStatus === 'code')
					message = 'Error: game code invalid. Please try entering the game code again';
				else if (this.state.joinStatus === 'player')
					message = 'Error: player not found in game. Please try entering the game code again';
				else message = 'Please enter the game code to rejoin game.';
				return (
					<Dimmer active page>
						<Card style={{ width: '320px' }}>
							<Card.Content textAlign="left">
								<br />
								<Card.Header content={'Enter Game'} />
								<br />
								<Card.Description className="description" content={message} />
								<br />
							</Card.Content>

							<Card.Content>
								<Input
									fluid
									className="inline-input"
									size="big"
									icon="gamepad"
									iconPosition="left"
									placeholder="Game Code"
									name="gamecode"
									value={this.props.gameCode}
									onChange={this.handleGameCodeInput}
								/>
								<Button.Group widths={2}>
									<Button color="green" content={'Continue'} onClick={this.retrieveGame} />

									<Button
										color="red"
										content={'Log Out'}
										onClick={() => this.setState({ showLogoutConfirmation: true })}
										basic
									/>
								</Button.Group>
							</Card.Content>
						</Card>
					</Dimmer>
				);
			}
		} else {
			// in game
			const gameStatus = this.props.world.gameStatus;
			const dataOn = this.props.world.sendDataOn;
			const storeOn = this.props.world.storeOn;
			if (
				gameStatus === GAME_STATUS.INITIALIZING ||
				gameStatus === GAME_STATUS.PAUSED ||
				gameStatus === 'undefined'
			) {
				// waiting
				console.log(`Current game status: ${this.props.world.gameStatus}`);
				
				return <DimIcon message="Waiting for Game to Start" iconName="pause" />;
			} else {
				// **DISPLAY GAME HERE**
				let main;
				console.log(`store: ${this.props.storeOn}`);
				switch (this.state.screen) {
					case 'contracts':
						main = <Contracts />;
						break;
					case 'graphs':
						main = <GraphingScreen />;
						break;
					case 'store':
						main = <PlanetGameStore />;
						break;
					case 'planet':
						main = <PlanetInteractionMenu planetIndex={this.state.planetIndex} />;
						break;
					case 'player':
						main = <PlayerInteractionMenu />;
						break;
					default:
						// home
						main = <PlanetNavigation setPlanet={this.setScreenPlanet} />;
				}

				const center = (
					<div>
						<Button.Group fluid>
							<Button
								onClick={() => this.setState({ screen: 'home' })}
								size="large"
								icon="globe"
								color={this.selectColor('home')}
							/>
							<Button
								onClick={() => this.setState({ screen: 'contracts' })}
								size="large"
								icon="paw"
								color={this.selectColor('contracts')}
							/>
							<Button
								onClick={() => this.setState({ screen: 'graphs' })}
								size="large"
								icon="line graph"
								color={this.selectColor('graphs')}
							/>
							<Button
								onClick={() => this.setState({ screen: 'player' })}
								size="large"
								icon="users"
								color={this.selectColor('player')}
								disabled={!this.props.world.sendDataOn}
							/>
							<Button
								onClick={() => this.setState({ screen: 'store' })}
								size="large"
								icon="shopping cart"
								color={this.selectColor('store')}
								disabled={!this.props.world.storeOn}
							/>
						</Button.Group>

						{main}
					</div>
				);

				return (
					<div style={{ height: '100%' }}>
						<Responsive as={BasicGreyPage} minWidth={320} center={center} />
					</div>
				);
			}
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.contracts.animals,
	player: state.planetGame.player,
	world: state.planetGame.world,
	gameCode: state.core.gameCode,
	settings : state.planetGame.settings,
	storeOn: state.planetGame.world.storeOn
});

export default connect(mapStateToProps)(PlanetPlayerGameScreen);
