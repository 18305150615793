import React, { Component } from "react";
import TeacherButtons from "../../../core/components/TeacherButtons";
import YesNoDialog from "../../../core/components/YesNoDialog";
// import TocGameInformation from "./TocGameInformation";
import StationDetails from "./StationDetails";
import LeaderBoard from "./LeaderBoard";
import firebase from "firebase";
import "../styles.scss";
import SingleButtonDialog from "../../../core/components/SingleButtonDialog";
import PlayerStatisticsDisplay from "./PlayerStatisticsDisplay";
import TeacherRestart from "../../../core/components/TeacherRestart.js";
import TeacherControlScreen from "../../../core/components/TeacherControlScreen";
import { Label, Message, Header, Grid, Button, Modal, Checkbox } from "semantic-ui-react";

// Description: Loads all teacher page components for ToC
// Parent: TeacherContainer
// Children: TocGameInformation, StationDetails, TeacherButtons, LeaderBoard, PlayerStatisticsDisplay, TeacherRestart, TeacherControlScreen
// Props Used:
//      firebase (Object)
//      firestore (Object)
//      game (String)
//      gameCode (String)
//      gameInfo (Object)
//      history (Object)
//      players (Array)

const db = firebase.firestore();

class TocTeacherGameScreen extends Component {
  gameLoaded = null;

  constructor(props) {
    super(props);
    this.state = {
      currentConfiguration: 0,
      stations: props.gameInfo.configurations[0].stations,
      showPlayerError: false,
      showLogoutConfirmation: false,
      showHomeConfirmation: false,
      rejoinedPlayers: [],
      totalPlayers: [],
      seasonNumber: 1,
      doubleAgent: "",
      playersDone: false,
      stationData: [],
      trading: false,
      remotePlay: false
    };

    // no other function seems to have this? keeping it for now, but may delete later.
    this.handleRegeneration = this.handleRegeneration.bind(this);
    this.changeDoubleAgent = this.changeDoubleAgent.bind(this);
  }

  // set listener for rejoined players
  UNSAFE_componentWillMount() {
    this.props.firestore.setListener(
      {
        collection: this.props.players,
        where: ["currentGameId", "==", this.props.gameCode]
      },
      doc => this.listenToArray(doc)
    );
    this.gameLoaded = this.gameListen();
  }

  // unset listener for rejoined players
  componentCleanup = () => {
    this.props.firestore.unsetListener({
      collection: this.props.players,
      where: ["currentGameId", "==", this.props.gameCode]
    });

    if (this.gameLoaded) {
      this.props.firestore.unsetListener({
        collection: this.props.game,
        doc: this.props.gameCode
      });
    }

    this.props.firestore.unsetListener({
      collection: "status"
    });
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup); // remove the event handler for normal unmounting
  }

  listenToArray = data => {
    return new Promise(resolve => {
      return new Promise(resolve => {
        let rejoined = [];
        let total = [];
        let promises = [];
        let doubleAgent = this.state.doubleAgent;
        let zeroTimePlayers = [];
        data.forEach(item => {
          promises.push(
            new Promise(resolve => {
              this.props.firestore.setListener(
                {
                  collection: "status",
                  doc: item.id
                },
                status => {
                  total = total.filter(
                    player => player.name !== item.data().displayName
                  );
                  rejoined = rejoined.filter(
                    player => player.name !== item.data().displayName
                  );
                  zeroTimePlayers = zeroTimePlayers.filter(
                    player => player.name !== item.data().displayName
                  );

                  // Why was 'blank' defined?
                  // let blank = item.data().timeLeft <= 0 ? zeroTimePlayers.push({name: item.data().displayName,id: item.id,}) : ''
                  const active =
                    status.data() === undefined
                      ? false
                      : status.data().state === "online";
                  if (!doubleAgent)
                    doubleAgent = item.data().doubleAgent ? item.id : "";
                  total.push({
                    name: item.data().displayName,
                    id: item.id,
                    fish: item.data().fishCaught,
                    active: active
                  });
                  if (item.data().rejoin)
                    rejoined.push({
                      name: item.data().displayName,
                      id: item.id,
                      fish: item.data().fishCaught,
                      active: active
                    });
                  resolve(true);
                }
              );
            })
          );
        });

        Promise.all(promises).then(() => {
          if (
            data.size !== 0 &&
            data.size === zeroTimePlayers.length &&
            this.state.gameState === "running"
          ) {
            this.handlePause();
            this.setState({
              playersDone: true
            });
            this.handleNextSeasonForAll();
          }
          this.setState({
            rejoinedPlayers: rejoined,
            totalPlayers: total,
            doubleAgent
          });
          resolve(true);
        });
      });
    });
  };

  isDataEqual = (state1, state2) => {
    if (state1.length !== state2.length) {
      return false;
    }
    for (var index = 0; index < state1.length; index++) {
      if (state1[index]["data"].length !== state2[index]["data"].length) {
        return false;
      }
      for (var index2 = 0; index2 < state1[index]["data"].length; index2++) {
        if (state1[index]["data"][index2] !== state2[index]["data"][index2]) {
          return false;
        }
      }
    }
    return true;
  };

  gameListen = () => {
    return this.props.firestore.setListener(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      gamedata => {
        const data = gamedata.data();
        const stations =
          data.configurations[this.state.currentConfiguration].stations;
        var prevPopulationData = data.data;
        const newStationData = [];
        Object.keys(stations).forEach((key, index) => {
          var prevData = [];
          var currentAmount = stations[key]["amount"];
          if (prevPopulationData.length < Object.keys(stations).length) {
            prevData.push(currentAmount);
          } else {
            prevData = prevPopulationData[index]["data"].slice(
              0,
              prevPopulationData[index]["data"].length - 1
            );
            if (data.generation) {
              prevData = prevPopulationData[index]["data"];
              var regenAmount = prevData[prevData.length - 1] * 2;
              if (regenAmount > 500) {
                regenAmount = 500;
              }
              prevData = prevData.concat([regenAmount, regenAmount]);
            } else {
              prevData.push(stations[key]["amount"]);
            }
          }

          newStationData.push({
            name: stations[key]["name"],
            type: "line",
            data: prevData
          });
        });

        if (!this.isDataEqual(newStationData, prevPopulationData)) {
          this.props.firestore.update(
            {
              collection: this.props.game,
              doc: this.props.gameCode
            },
            {
              data: newStationData,
              generation: false
            }
          );
        }

        this.setState({
          gameState: data.gameState,
          generation: data.generation,
          seasonNumber: data.seasonNumber,
          histogramData: data.histogramData,
          populationData: data.populationData,
          // generationNumber: Object.keys(data.populationData).length,
          predation: data.predation,
          movementLoss: data.movementLoss,
          stations: stations,
          stationData: newStationData,
          trading: data.trading,
          remotePlay: data.remotePlay || false
        });
      }
    );
  };

  getCurrentConfiguration = () => {
    return this.props.gameInfo.configurations[
      "" + this.state.currentConfiguration
    ];
  };

  // handles running the game upon button press
  handleRunning = () => {
    const newPaused = Math.round(
      this.props.gameInfo.pausedTime +
        (Date.now() - this.props.gameInfo.lastPause.toMillis()) / 1000 // added toMillis with firebase 7.5
    );

    return this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: "running",
          pausedTime: newPaused
        }
      )
      .then(() => {
        this.props.firestore.update(
          {
            collection: "games",
            doc: this.props.gameCode
          },
          {
            gameState: "running"
          }
        );
      });
  };

  handleInitialization = () => {
    const promise = new Promise(resolve => {
      this.props.firestore
        .get({
          collection: "games",
          doc: this.props.gameCode
        })
        .then(game => {
          if (game.data().players.length <= 0) {
            this.setState({ showPlayerError: true });
            resolve(false);
            return;
          }
          this.handlePause();
          resolve(true);
          return;
        });
    });
    return promise;
  };

  handleReset = clear => {
    let firestore = this.props.firestore;
    return firestore
      .collection("games")
      .doc(this.props.gameCode)
      .get()
      .then(snapshot => {
        let batch = firestore.batch();
        let initial = snapshot.data().type.charAt(0);
        snapshot.data().players.forEach(player => {
          batch.delete(firestore.collection(`${initial}players`).doc(player));
          batch.delete(firestore.collection("status").doc(player));
        });
        batch.update(firestore.collection("games").doc(snapshot.id), {
          players: [],
          gameState: "initializing"
        });

        batch.update(
          firestore.collection(this.props.game).doc(this.props.gameCode),
          {
            gameState: "initializing",
            generation: false,
            seasonNumber: 1,
            clock: this.props.firestore.FieldValue.serverTimestamp(),
            pausedTime: 0,
            gameRestart: true,
            data: [],
            histogramData: {},
            trading: false,
            remotePlay: false
          }
        );

        if (clear) {
          batch.update(
            firestore.collection(this.props.game).doc(this.props.gameCode),
            {
              configurations: {
                "0": {
                  stations: {}
                }
              }
            }
          );
        } else {
          const stationRef = db
            .collection(this.props.game)
            .doc(this.props.gameCode);

          stationRef.get().then(doc => {
            const stationNum = doc.data().configurations[
              this.state.currentConfiguration
            ].stations;

            Object.keys(stationNum).forEach(el => {
              const station = doc.data().configurations[
                this.state.currentConfiguration
              ].stations[el];

              stationRef.update({
                [`configurations.${this.state.currentConfiguration}.stations.${el}`]: {
                  name: station.name,
                  rate: station.rate,
                  limit: station.limit,
                  amount: station.limit,
                  risk: station.risk,
                  lastFeedTime: null,
                  currentPlayers: []
                }
              });
            });
          });
        }

        if (initial === "v") {
          snapshot.data().meets.forEach(meet => {
            batch.delete(
              firestore.collection(`${initial}gamemeetings`).doc(meet)
            );
          });
          batch.update(firestore.collection("games").doc(snapshot.id), {
            meets: []
          });
        }
        batch.commit();
        console.log("Finished deletion");
      })
      .catch(er => console.error(er));
  };

  // handles pausing the game upon button press
  handlePause = () => {
    return this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: "paused",
          lastPause: this.props.firestore.FieldValue.serverTimestamp(),
          generation: false
        }
      )
      .then(response => {
        this.props.firestore.update(
          {
            collection: "games",
            doc: this.props.gameCode
          },
          {
            gameState: "paused"
          }
        );
      });
  };

  // handles selecting a new game upon button press
  /* handleNewGame = () => {
        return new Promise(resolve => {
            if (this.props.gameInfo.gameState !== 'initializing') {
                this.handlePause().then(response => {
                    this.props.exitGame();
                    resolve(true);
                });
            } else {
                this.props.exitGame();
                resolve(true);
            }
        });
    };*/

  // handles restarting the game with new game button press
  handleRestart = () => {
    const promise1 = this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: "paused",
          generation: false,
          seasonNumber: 1,
          clock: this.props.firestore.FieldValue.serverTimestamp(),
          pausedTime: 0,
          gameRestart: true,
          data: [],
          histogramData: {},
          trading: false,
        }
      )
      .then(response => {
        this.props.firestore.update(
          {
            collection: "games",
            doc: this.props.gameCode
          },
          {
            gameState: "paused"
          }
        );
      });

    const promise2 = new Promise(resolve => {
      this.props.firestore
        .get({
          collection: this.props.players,
          where: ["currentGameId", "==", this.props.gameCode]
        })
        .then(allPlayersQuery => {
          Promise.all([
            allPlayersQuery.forEach(doc => {
              return this.props.firestore.update(
                {
                  collection: this.props.players,
                  doc: doc.id
                },
                {
                  fishCaught: 20,
                  timeLeft: 20,
                  boat: {
                    research: false,
                    size: "small",
                    trawler: false
                  },
                  store: {
                    fishRadar: false,
                    largeBoat: false,
                    medBoat: false,
                    smallBoat: true,
                    trawler: false
                  },
                  gameOver: false,
                  lastStation: 0
                }
              );
            })
          ]).then(result => resolve(true));
        });
    });

    const stationRef = db.collection(this.props.game).doc(this.props.gameCode);

    const promise3 = stationRef.get().then(doc => {
      const stationNum = doc.data().configurations[
        this.state.currentConfiguration
      ].stations;

      Object.keys(stationNum).forEach(el => {
        const station = doc.data().configurations[
          this.state.currentConfiguration
        ].stations[el];

        stationRef.update({
          [`configurations.${this.state.currentConfiguration}.stations.${el}`]: {
            name: station.name,
            rate: station.rate,
            limit: station.limit,
            amount: station.limit,
            risk: station.risk,
            lastFeedTime: null,
            currentPlayers: [],
          }
        });
      });
    });

    return Promise.all([promise1, promise2, promise3]);
  };

  removeRejoiningPlayer = id => {
    this.setState({
      rejoinedPlayers: this.state.rejoinedPlayers.filter(item => item.id !== id)
    });
  };

  //Remove the rejoin person and set approve to true. Hence, letting them in.
  approve = id => {
    this.removeRejoiningPlayer(id);
    console.log(this.state.rejoinedPlayers);
    this.props.firestore.update(
      {
        collection: this.props.players,
        doc: id
      },
      {
        rejoin: false,
        approve: true
      }
    );
  };

  //Deny the rejoin, set the player denied to true, and everything else false
  reject = id => {
    this.removeRejoiningPlayer(id);
    console.log(this.state.rejoinedPlayers);
    this.props.firestore.update(
      {
        collection: this.props.players,
        doc: id
      },
      {
        rejoin: false,
        approve: false,
        denied: true
      }
    );
  };

  changeDoubleAgent = player => {
    if (this.state.doubleAgent) {
      this.props.firestore.update(
        {
          collection: this.props.players,
          doc: this.state.doubleAgent
        },
        {
          doubleAgent: false
        }
      );
    }
    this.props.firestore.update(
      {
        collection: this.props.players,
        doc: player
      },
      {
        doubleAgent: true
      }
    );
    this.setState({ doubleAgent: player });
  };

  // multiplies station amount by a multiplier (hardcoded as 2 for now)
  // pass in stationKey, find in firebase, and update with regenerated amount
  handleRegeneration = stationKey => {
    // look for stationKey in this.state.stations
    let currentAmount = this.state.stations[stationKey].amount;

    // calculate regenerated amount
    let regeneratedAmount = Math.floor(currentAmount * 2);

    //cap at 500
    if (regeneratedAmount > 500) {
      regeneratedAmount = 500;
    }

    // set amount to regenerated amount
    this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        [`configurations.${this.state.currentConfiguration}.stations.${stationKey}`]: {
          name: this.state.stations[stationKey].name,
          rate: this.state.stations[stationKey].rate,
          limit: this.state.stations[stationKey].limit,
          amount: regeneratedAmount,
          risk: this.state.stations[stationKey].risk,
          lastFeedTime: null,
          currentPlayers: this.state.stations[stationKey].currentPlayers || []
        }
      }
    );
  };

  handleNextSeasonForAll = () => {
    // increment seasons
    const promise1 = this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        seasonNumber: this.state.seasonNumber + 1,
        generation: true
      }
    );

    // Subtract required amount for each season and reset time left
    const promise2 = new Promise(resolve => {
      this.props.firestore
        .get({
          collection: this.props.players,
          where: ["currentGameId", "==", this.props.gameCode]
        })
        .then(allPlayersQuery => {
          Promise.all([
            allPlayersQuery.forEach(doc => {
              let gameOver = false;
              if (doc.data().fishCaught - 20 < -20) {
                gameOver = true;
              }
              return this.props.firestore.update(
                {
                  collection: this.props.players,
                  doc: doc.id
                },
                {
                  fishCaught: doc.data().fishCaught - 20,
                  timeLeft: 20,
                  gameOver: gameOver
                }
              );
            })
          ]).then(result => resolve(true));
        });
    });

    //Regenerate stations on next season
    const promise3 = Object.keys(this.state.stations).forEach(id => {
      this.handleRegeneration(id);
    });

    return Promise.all([promise1, promise2, promise3]);
  };

  toggleTrading = () => {
    this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        trading: !this.state.trading
      }
    );
  };

  handleTogglePlay = (e, { checked }) => {
    //console.log('Change', checked)
    this.setState({remotePlay:checked});
    this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        remotePlay: checked
      }
    );
    //this.toggle()
  }

  render() {
    const { name, game, gameInfo, gameCode, players, firestore } = this.props;

    const gameScreen = (
      <div style={{ height: "100%" }}>
        {/*Dialog Popups */}
        {this.state.showPlayerError && (
          <SingleButtonDialog
            description="You need at least one player before starting the game."
            buttonText="Close"
            buttonAction={() => this.setState({ showPlayerError: false })}
          />
        )}

        {this.state.playersDone && (
          <SingleButtonDialog
            description={"All the players have finished fishing"}
            buttonText="Close"
            buttonAction={() => this.setState({ playersDone: false })}
            width="300px"
            height="400px"
          />
        )}

        {this.state.showLogoutConfirmation && (
          <YesNoDialog
            header={"Logout Confirmation"}
            description={"Are you sure you wish to logout?"}
            yesText={"Yes"}
            yesAction={() => {
              this.setState({ showLogoutConfirmation: false });
              this.props.firebase.logout();
              this.props.history.push("/");
            }}
            noText="Close"
            noAction={() => {
              this.setState({ showLogoutConfirmation: false });
            }}
          />
        )}

        {this.state.showHomeConfirmation && (
          <YesNoDialog
            header={"Home Confirmation"}
            description={"Are you sure you wish to return to the Homepage?"}
            yesText={"Yes"}
            yesAction={() => {
              this.setState({ showHomeConfirmation: false });
              this.props.history.push("/");
            }}
            noText="Close"
            noAction={() => {
              this.setState({ showHomeConfirmation: false });
            }}
          />
        )}

        {/* Game Screen Container*/}
        <Message
          style={{
            marginLeft: "0.7em",
            marginRight: "0.7em",
            height: "100%",
            overflow: "auto"
          }}
        >
          <br />
          <br />
          <Label attached="top" as="h3" color="black" size="big">
            Game Screen
          </Label>

          <Grid stackable style={{ height: "70vh" }}>
            <Grid.Row columns={3}>
              {/*Game Season Info */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Season Control
                  </Header>

                  <br />
                  <Message>Season #{this.state.seasonNumber}</Message>

                  {this.state.gameState !== "initializing" && (
                    <div style={{ marginTop: "15px" }}>
                      <Button
                        content="Next Season"
                        onClick={this.handleNextSeasonForAll}
                        color="blue"
                        disabled={this.state.gameState !== "paused"}
                      />
                      <Button
                        content={
                          this.state.trading
                            ? "Turn off Trading"
                            : "Turn on Trading"
                        }
                        onClick={this.toggleTrading}
                        color="blue"
                        disabled={this.state.gameState !== "paused" || this.state.remotePlay === true}
                      />
                    </div>
                  )}

                  {/*<TocGameInformation
                                    gameCode={gameCode}
                                    totalPlayers={this.state.totalPlayers}
                                    // generationNumber={this.state.generationNumber}
                                    gameState={this.state.gameState}
                                    seasonNumber={this.state.seasonNumber}
                                    handleNextSeasonForAll={this.handleNextSeasonForAll}
                                    trading={this.state.trading}
                                    toggleTrading={this.toggleTrading}
                                />*/}
                </Message>
              </Grid.Column>

              {/*Stations */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Station Details
                  </Header>

                  <br />
                  {/* for now, StationDetails includes the download data button, but this can be changed */}
                  <StationDetails
                    game={game}
                    gameCode={gameCode}
                    players={players}
                    firestore={firestore}
                    gameState={this.state.gameState}
                    stations={this.state.stations}
                    currentConfiguration={this.state.currentConfiguration}
                    regenerate={this.handleRegeneration} // regeneration function
                  />
                </Message>
              </Grid.Column>

              {/* Player Statistics */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Player Statistics
                  </Header>

                  <br />
                  <PlayerStatisticsDisplay
                    gameState={this.state.gameState}
                    players={this.state.totalPlayers}
                    stationData={this.state.stationData}
                  />

                  <br />
                  <Modal trigger={<Button>Show Leaderboard</Button>}>
                    <Modal.Header>Leaderboard</Modal.Header>

                    <Modal.Content>
                      <LeaderBoard players={this.state.totalPlayers} />
                    </Modal.Content>
                  </Modal>
                </Message>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              {/*Remote Control */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                   Local vs Remote Play
                  </Header>

                  <br />
                  <Message>Select Local (face to face) vs Remote (online) Play</Message>

                 
                    <div style={{ marginTop: "15px" }}>
                      <div>Local <Checkbox label="Remote" checked={this.state.remotePlay} disabled={this.state.gameState !== "initializing"} onChange={this.handleTogglePlay}  toggle></Checkbox></div>
                    </div>
                  
                </Message>
              </Grid.Column>
              </Grid.Row>
          </Grid>
        </Message>
      </div>
    );
    return (
      <TeacherControlScreen
        approveCallback={this.approve}
        gameCode={this.props.gameCode}
        gameScreen={gameScreen}
        gameState={gameInfo.gameState}
        gameType={"Tragedy of the Commons"}
        handleDoc={() => {
          window.open(
            "https://education.mit.edu/wp-content/uploads/2020/01/TOC_Documentation.pdf",
            "_blank"
          );
        }}
        handleHome={() => {
          this.setState({ showHomeConfirmation: true });
        }}
        handleLogout={() => {
          this.setState({ showLogoutConfirmation: true });
        }}
        handleStateButton={
          <TeacherButtons
            name={name}
            gameInfo={gameInfo}
            handleInitialization={this.handleInitialization}
            handlePause={this.handlePause}
            handleRunning={this.handleRunning}
          />
        }
        handleRestartButton={
          <TeacherRestart
            rerun={this.handleRestart}
            reinitialize={() => {
              this.handleReset(false);
            }}
            clear={() => {
              this.handleReset(true);
            }}
            game={this.props.game}
            gameState={this.props.gameInfo.gameState}
          />
        }
        rejectCallback={this.reject}
        rejoinedPlayers={this.state.rejoinedPlayers}
        totalPlayers={this.state.totalPlayers}
      />
    );
  }
}

export default TocTeacherGameScreen;
