import React, { Component } from "react";
import { isLoaded } from "react-redux-firebase";
import DetectRTC from "detectrtc";
import Sound from "react-sound";
import QrReader from "react-qr-reader";
import QrCode from "qrcode.react";
import Avatar from "avataaars";

import MeetingNotification from "./MeetingNotification";
import BasicGreyPage from "../../../core/components/BasicGreyPage";
import DimIcon from "../../../core/components/DimIcon";
import NoCloseDialog from "../../../core/components/NoCloseDialog";
import SingleButtonDialog from "../../../core/components/SingleButtonDialog";
import YesNoDialog from "../../../core/components/YesNoDialog";
import ScrollList from "../../../core/components/ScrollList";
import LogoutConfirmation from "../../../core/components/LogoutConfirmation";
import { Button, Header, Responsive, Modal } from "semantic-ui-react";
import { getRandomInt as getRandomIntInclusive } from "../../../helpers/randomNums";
import ignoreFirstFunctionCall from "../../../helpers/ignoreFirstFunctionCall";

import "../styles.scss";
import styles from "./VirusPlayer.module.css";

import firebase from "firebase";

export const topType = [
  "LongHairBigHair",
  "LongHairBob",
  "LongHairBun",
  "LongHairCurly",
  "LongHairCurvy",
  "LongHairDreads",
  "LongHairFro",
  "LongHairFroBand",
  "LongHairNotTooLong",
  "LongHairMiaWallace",
  "LongHairStraight",
  "LongHairStraight2",
  "LongHairStraightStrand",
  "ShortHairDreads01",
  "ShortHairDreads02",
  "ShortHairFrizzle",
  "ShortHairShaggyMullet",
  "ShortHairShortCurly",
  "ShortHairShortFlat",
  "ShortHairShortRound",
  "ShortHairShortWaved",
  "ShortHairSides",
  "ShortHairTheCaesar",
  "ShortHairTheCaesarSidePart",
];
export const hairColor = [
  "Auburn",
  "Black",
  "BlondeGolden",
  "Brown",
  "BrownDark",
  "PastelPink",
  "Platinum",
  "Red",
];
export const clotheType = [
  "CollarSweater",
  "GraphicShirt",
  "Hoodie",
  "Overall",
  "ShirtCrewNeck",
  "ShirtScoopNeck",
  "ShirtVNeck",
];
export const clotheColor = [
  "Blue01",
  "Blue02",
  "Blue03",
  "Gray01",
  "Gray02",
  "Heather",
  "PastelBlue",
  "PastelGreen",
  "PastelOrange",
  "PastelRed",
  "PastelYellow",
  "Pink",
  "Red",
  "White",
];
export const mouthType = ["Default", "Eating", "Smile", "Tongue", "Twinkle"];
export const skinColor = ["Tanned", "Yellow", "Pale", "Light", "Brown", "DarkBrown", "Black"];

const firestore = firebase.firestore;
const db = firestore();

const GAME_STATE_CONSTANTS = {
  initializing: "initializing",
  paused: "paused",
  running: "running",
};

const webcamDialog = (
  <NoCloseDialog description="You need to enable webcam permissions for your browser. If permissions are already enabled, reenter the game and try again." />
);
// Firestore listens for vplayers uid and on vgames gameid

// Description: Controls virus player page and interactions
// Parent: PlayerContainer
// Children: MeetingNotification and GameDisplay
// Props Used:
//      auth (Object)
//      clearMessage (Function)
//      firebase (Object)
//      firestore (Object)
//      game (String)
//      loggedOut (Function)
//      message (Object)
//      players (String)
//      setMessage (Function)

class VirusPlayer extends Component {
  // stores game data listener
  gameLoaded = null;

  constructor(props) {
    super(props);
    // this.sicktick = null;
    this.state = {
      gameData: {
        gameParams: {
          infectionRate: 0,
          incubationTime: 0,
          hasImmuneCarriers: false,
        },
        gameState: "",
        clock: new firestore.Timestamp(0, 0),
        pausedTime: 0,
      },
      uid: "",
      meetings: [],
      soundPlaying: Sound.status.STOPPED,
      playerData: {
        avatar: [
          {
            clotheColor: 0,
            clotheType: 0,
            hairColor: 0,
            mouthType: 0,
            skinColor: 0,
            topType: 0,
          },
        ],
        sickTime: -1,
        sickState: false,
        displayName: "",
        currentGameId: "",
      },
      isScanMode: false,
      soundUrl: "/beep.mp3",
      hasCameraPermission: false,
      error: "",
      meetingNotification: {
        type: "", // success || duplicate
        playerMet: "",
      },
      buttonPressed: false,
      showLogoutConfirmation: false,
      timeOffset: 0,
    };
  }

  unsubscribeFromGame = null;
  unsubscribeFromPlayer = null;
  unsubscribeFromMeetings = null;
  gettingSickTimer = null;

  setIsScanMode = (isScanMode) => {
    this.setState({ isScanMode });
  };

  setIsMeetingsOpen(isMeetingsOpen) {
    this.setState({ isMeetingsOpen });
  }

  // set sound states and listen for player data changes
  // which in turn listens for game data changes
  componentDidMount() {
    const { uid } = this.props.auth;

    if (!uid) {
      // error handle if no user is logged in
      this.props.firebase.logout();
    }

    this.setState({ uid });
    window.addEventListener("beforeunload", this.componentCleanup);
    this.setupSound();

    // set listener on player document
    this.unsubscribeFromPlayer = db
      .collection("vplayers")
      .doc(uid)
      .onSnapshot((doc) => {
        const playerData = doc.data();

        if (!playerData) {
          // error handle if no user/player data does not exist
          // e.g. teacher clears games and deletes user data
          this.props.firebase.logout();
          this.props.history.push({
            pathname: "/",
          });
        }

        this.setState({ playerData });

        // set listener on game document
        if (!this.unsubscribeFromGame) {
          const gameCode = playerData.currentGameId;
          this.unsubscribeFromGame = db
            .collection("vgames")
            .doc(gameCode)
            .onSnapshot((doc) => {
              // console.log(doc.data());
              this.setState({ gameData: doc.data() });
            });
        }

        if (!this.unsubscribeFromMeetings) {
          const meetingNotificationHandler = ignoreFirstFunctionCall((querySnapshot) => {
            const change = querySnapshot.docChanges()[0];
            if (change && change.type === 'added') {
              const playerMet = change.doc.data().otherName;
              this.setState({
                meetingNotification: {
                  type: 'success',
                  playerMet
                }
              });
            }
          });
          const gameCode = playerData.currentGameId;
          this.unsubscribeFromMeetings = db
            .collection("vgamemeetings")
            .where("gameId", "==", gameCode)
            .where("myid", "==", uid)
            .onSnapshot((querySnapshot) => {
              const meetings = [];
              meetingNotificationHandler(querySnapshot);
              querySnapshot.forEach((doc) => {
                meetings.push({ name: doc.data().otherName });
              });
              this.setState({ meetings });
            });
        }
      });
    // brings up camera briefly to get and check permissions

    var offsetRef = this.props.firebase.database().ref(".info/serverTimeOffset");
    offsetRef.on("value", (snap) => {
      this.setState({ timeOffset: snap.val() });
    });

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        DetectRTC.load(() => {
          console.log("RTC");
          this.setState({ hasCameraPermission: DetectRTC.isWebsiteHasWebcamPermissions });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        });
      })
      .catch((error) => {
        // Check for camera errors other than not allowed or permission denied
        if (error.name !== "NotAllowedError" && error.name !== "PermissionDeniedError") {
          this.setState({
            error: error,
          });
        }
      });
  }

  // on any state changes set alarm for when get sick if needed
  componentDidUpdate() {
    this.updateGettingSickTimer();
  }

  updateGettingSickTimer() {
    const { gameState, clock, pausedTime } = this.state.gameData;
    const { sickState: isSick, sickTime } = this.state.playerData;
    const { uid } = this.state;
    // @todo: no need to reset timer everytime but it works, optimize later
    clearTimeout(this.gettingSickTimer);
    if (gameState === GAME_STATE_CONSTANTS.running && !isSick && sickTime >= 0) {
      const startTime = clock.seconds * 1000;
      const finalSickTime = startTime - Date.now() + (pausedTime + sickTime) * 1000;
      this.gettingSickTimer = setTimeout(() => {
        this.playSound("/beep.mp3");
        db.collection("vplayers").doc(uid).update({ sickState: true });
      }, finalSickTime);
    }
  }

  // unset listeners and set status to inactive when the component is done
  componentCleanup = () => {
    this.unsubscribeFromPlayer && this.unsubscribeFromPlayer();
    this.unsubscribeFromGame && this.unsubscribeFromGame();
    this.unsubscribeFromMeetings && this.unsubscribeFromMeetings();
    this.gettingSickTimer && clearTimeout(this.gettingSickTimer);
  };

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup); // remove the event handler for normal unmounting
  }

  handleBarCodeScanned = async (otherId) => {
    if (!otherId) {
      return;
    }

    this.setIsScanMode(false);

    const { currentGameId: gameCode, displayName: myName } = this.state.playerData;
    const myId = this.state.uid;
    const querySnapshot = await db
      .collection("vgamemeetings")
      .where("gameId", "==", gameCode)
      .where("myid", "==", myId)
      .where("otherId", "==", otherId)
      .get();
    const hasAlreadyMet = querySnapshot.size !== 0;
    if (hasAlreadyMet) {
      const playerMet = querySnapshot.docs[0].data().otherName;
      this.setState({
        meetingNotification: {
          type: "duplicate",
          playerMet,
        },
      });
    } else {
      const otherPlayerData = await this.getPlayerData(otherId);
      const playerMet = otherPlayerData.displayName;

      const { displayName: otherName } = otherPlayerData;
      const addedAt = new Date();

      const batch = db.batch();
      const meetingOne = db.collection("vgamemeetings").doc();
      const meetingTwo = db.collection("vgamemeetings").doc();

      this.setState({
        meetingNotification: {
          type: "success",
          playerMet,
        },
      });
      this.playSound('/alert.mp3');

      batch.set(meetingOne, {
        addedAt,
        duplicate: false,
        gameId: gameCode,
        myid: myId,
        otherId,
        otherName,
      });
      batch.set(meetingTwo, {
        addedAt,
        duplicate: false,
        gameId: gameCode,
        myid: otherId,
        otherId: myId,
        otherName: myName,
      });
      batch.commit();

      const { playerData: myPlayerData } = this.state;
      let { gameParams, clock } = this.state.gameData;
      gameParams.clock = clock;
      this.calculateInfectionSpreadOneWay(myId, myPlayerData, otherId, otherPlayerData, gameParams);
      this.calculateInfectionSpreadOneWay(otherId, otherPlayerData, myId, myPlayerData, gameParams);
    }
  };

  /**
   * aId is not used but will keep it as an argument for the sake of symmetry
   */
  calculateInfectionSpreadOneWay = async (aId, aPlayerData, bId, bPlayerData, gameParams) => {
    const { infectionRate, averageLatency: incubationTime, clock, hasImmuneCarriers } = gameParams;
    const { sickState: aIsSick, sickTime: aSickTime } = aPlayerData;
    const { immune: bIsImmune, sickState: bIsSick, sickTime: bSickTime } = bPlayerData;
    const aIsInfected = aSickTime >= 0;
    const bIsInfected = bSickTime >= 0;
    const absoluteStartTime = clock.seconds * 1000;

    if (aIsSick || aIsInfected) {
      const isInfectionSuccessful = getRandomIntInclusive(1, 100) <= infectionRate;
      if (!bIsImmune && !bIsSick && !bIsInfected && isInfectionSuccessful) {
        const sickTimeOffset = Math.round(
          (parseInt(incubationTime) * getRandomIntInclusive(90, 120)) / 100
        );
        const currentGameTime = Math.round((Date.now() - absoluteStartTime) / 1000);
        const finalSickTimeInSeconds = currentGameTime + sickTimeOffset;
        await db.collection("vplayers").doc(bId).update({ sickTime: finalSickTimeInSeconds });
      }

      if (hasImmuneCarriers && bIsImmune) {
        const currentGameTime = Math.round((Date.now() - absoluteStartTime) / 1000);
        const tenYearsInSeconds = 10 * 365 * 24 * 3600;
        const finalSickTimeInSeconds = currentGameTime + tenYearsInSeconds;
        await db.collection("vplayers").doc(bId).update({ sickTime: finalSickTimeInSeconds });
      }
    }
  };

  getPlayerData = async (playerId) => {
    const doc = await db.collection("vplayers").doc(playerId).get();
    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  };

  // allows multiple sounds and makes sure sound is stopped when loaded
  setupSound = () => {
    window.soundManager.setup({
      ignoreMobileRestrictions: true,
    });
    window.soundManager.onready(() => {
      this.setState({ soundPlaying: Sound.status.STOPPED });
    });
  };

  // plays sound which is silent by default for initial sound played
  playSound = (soundUrl = "/silence.mp3") => {
    this.setState({ soundUrl, soundPlaying: Sound.status.PLAYING });
  };

  render() {
    const {
      hasCameraPermission,
      isScanMode,
      isMeetingsOpen,
      error,
      showLogoutConfirmation,
    } = this.state;

    let { meetings, playerData, gameData, uid, meetingNotification } = this.state;
    const { displayName, sickState: isSick, avatar, currentGameId: gameCode } = playerData;
    const avatarIndices = avatar[0];
    const { gameState } = gameData;

    //console.log(uid)

    if (error !== "") {
      return (
        <SingleButtonDialog
          description={"Your camera has an error of " + this.state.error + "."}
          buttonText="Back"
          buttonAction={() => {
            this.props.firebase.logout();
            this.props.loggedOut();
          }}
        />
      );
    }

    if (showLogoutConfirmation) {
      return (
        <LogoutConfirmation
          logoutAction={() => {
            this.setState({ buttonPressed: true });
            this.props.firebase.logout();
            this.props.loggedOut();
          }}
          backAction={() => this.setState({ showLogoutConfirmation: false })}
        />
      );
    }

    if (!this.state.buttonPressed) {
      return (
        <YesNoDialog
          header="Enter Game"
          description={
            "You are about to enter the game with code " +
            gameCode +
            ".  Would you like to continue or log out?"
          }
          yesText="Continue"
          yesAction={() => {
            this.setState({ buttonPressed: true });
          }}
          noText="Log Out"
          noAction={() => this.setState({ showLogoutConfirmation: true })}
        />
      );
    }

    if (!isLoaded(this.props.auth) || gameState === "initializing" || gameState === "unknown") {
      return (
        <div>
          <DimIcon message="Waiting for Game to Start" iconName="pause" />
          <Sound
            url={this.state.soundUrl}
            playStatus={this.state.soundPlaying}
            loop={false}
            onFinishedPlaying={() => {
              this.setState({ soundPlaying: Sound.status.STOPPED });
            }}
          />
        </div>
      );
    }

    if (gameState !== "running") {
      return (
        <div>
          <DimIcon message="Game Paused by Teacher" iconName="pause" />
          <Sound
            url={this.state.soundUrl}
            playStatus={this.state.soundPlaying}
            loop={false}
            onFinishedPlaying={() => {
              this.setState({ soundPlaying: Sound.status.STOPPED });
            }}
          />
        </div>
      );
    }

    const list = meetings.length === 0 ? [] : meetings;

    const center = (
      <div className={styles.container}>
        {!hasCameraPermission && webcamDialog}
        <div className={styles.interactionSection}>
          <Button.Group widths={2}>
            <Button
              onClick={() => this.setIsScanMode(true)}
              size="medium"
              icon="camera"
              color={isScanMode ? "green" : "grey"}
              content="Meet"
            />
            <Button
              onClick={() => this.setIsScanMode(false)}
              size="medium"
              icon="qrcode"
              color={isScanMode ? "grey" : "green"}
              content="Be Met"
            />
          </Button.Group>

          {isScanMode ? (
            <QrReader
              // style={{width: '100%'}}
              onScan={this.handleBarCodeScanned}
              facingMode={"environment"}
              constraints={{ aspectRatio: 1, facingMode: { ideal: "environment" }, delay: 500 }}
            />
          ) : (
              <QrCode style={{ marginTop: "2em" }} value={uid || ""} />
            )}
        </div>

        <div className={styles.profileSection}>
          <Avatar
            style={{
              minWidth: "36.5vh",
              height: "200px",
              backgroundColor: playerData.sickState ? "red" : "white",
            }}
            avatarStyle="Transparent"
            accessoriesType="Blank"
            facialHairType="Blank"
            eyeType={isSick ? "Cry" : "Default"}
            eyebrowType={isSick ? "SadConcerned" : "Default"}
            hairColor={
              playerData.avatar[0].hairColor === "Red"
                ? "Red"
                : hairColor[playerData.avatar[0].hairColor]
            }
            topType={topType[playerData.avatar[0].topType]}
            clotheType={clotheType[avatarIndices.clotheType]}
            clotheColor={clotheColor[avatarIndices.clotheColor]}
            mouthType={mouthType[avatarIndices.mouthType]}
            skinColor={skinColor[avatarIndices.skinColor]}
          />
          <Header>{displayName}</Header>
          <Header as="h3" color={isSick ? "red" : "green"}>
            {isSick ? "Sick" : "Healthy"}
          </Header>
          <Header as="h5">
            {list.length}
            &nbsp;
            {list.length > 1 || list.length === 0 ? "Meetings" : "Meeting"}
          </Header>
        </div>
        <div className={styles.meetingSection}>
          <Button onClick={() => this.setIsMeetingsOpen(true)}>View My Meetings</Button>

          <Modal
            open={isMeetingsOpen}
            closeIcon={true}
            onClose={() => this.setIsMeetingsOpen(false)}>
            <Modal.Header>My Meetings</Modal.Header>
            <Modal.Content>
              <ScrollList list={meetings} />
            </Modal.Content>
          </Modal>
        </div>

        <Sound
          url={this.state.soundUrl}
          playStatus={this.state.soundPlaying}
          loop={false}
          onFinishedPlaying={() => {
            this.setState({ soundPlaying: Sound.status.STOPPED });
          }}
        />

        <Modal
          open={!!meetingNotification.playerMet}
          onClose={() => {
            this.setState({
              meetingNotification: {
                type: "",
                playerMet: "",
              },
            });
          }}>
          <Modal.Header>
            {meetingNotification.type === "success" ? "Meeting Successful!" : "We Meet Again!"}
          </Modal.Header>
          <Modal.Content>
            {meetingNotification.type === "success"
              ? `You met ${meetingNotification.playerMet}`
              : `You already met ${meetingNotification.playerMet}`}
          </Modal.Content>
        </Modal>
        {Object.keys(this.props.message).length !== 0 && (
          <MeetingNotification
            name={this.props.message.player}
            clearMessage={this.props.clearMessage}
            success={this.props.message.success}
          />
        )}
      </div>
    );

    return (
      <div style={{ height: "100%" }}>
        <Responsive as={BasicGreyPage} minWidth={320} center={center} compact={true} />
      </div>
    );
  }
}

export default VirusPlayer;
