import React, { Component } from 'react';
import { Header, Grid, Input, Icon, Button } from 'semantic-ui-react';
import YesNoDialog from '../../../core/components/YesNoDialog';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import SliderWithField from '../../../core/components/SliderWithField';
import Writerqr from '../../../core/components/Writerqr';
import { getRandomInt } from '../../../helpers/randomNums';
import { Modal } from 'semantic-ui-react';
// import { constants } from 'fs';
// import xlsx from 'xlsx';
// import { spread } from 'q';

const defaultStationRate = 10;
const defaultStationLimit = 10;

class StationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stationNameError: false,
            showNewStationDialog: false,
            showEditStationDialog: false,
            showDeleteStationDialog: false,
            showQRDialog: false,
            showMassQRDialog: false,
            stationKey: '',
            stationName: '',
            massStations: [],
            stationRate: defaultStationRate,
            stationLimit: defaultStationLimit,
            stationAmount: defaultStationLimit,
            stationRisk: 0
        }
    }

    // collectivePrintQRCodes = () => {
    //     forE;
    // };

    qrDialog = id => {
        return (
            <div>
                <Header content={this.state.stationName} />
                <br />
                <Writerqr
                    value={id}
                    size={500}
                    level={'Q'}
                    includeMargin={true}
                />
                <Button label="Print Code" icon="print" onClick={() => window.print()} />
            </div>
        );
    };

    combineAllQRCodes = () => {
        // need to add print button like above
        return (
            // (

            // ),
            // header,
            // button
            this.state.massStations.map((station, index) => {
                return (
                    <div>
                        <Header content={station.name} />
                        <br />
                        <Writerqr
                            value={station.id}
                            size={500}
                            level={'Q'}
                            includeMargin={true}
                            overflow-y={'scroll'}
                        />
                        <br />
                        <br />
                    </div>
                );
            })
        );
    };

    createStation = () => {
        const stations = this.props.stations;
        const keys = Object.keys(stations);
        var duplicate = false;
        keys.forEach((key, index) => {
            if (stations[key].name === this.state.stationName) {
                duplicate = true;
            }
        });

        if (!duplicate && this.state.stationName) {
            this.props.firestore
                .update(
                    {
                        collection: this.props.game,
                        doc: this.props.gameCode,
                    },
                    {
                        [`configurations.${
                            this.props.currentConfiguration
                        }.stations.${Date.now()}`]: {
                            name: this.state.stationName,
                            rate: this.state.stationRate,
                            limit: this.state.stationLimit,
                            amount: this.state.stationLimit,
                            risk: getRandomInt(0, 100) > 50 ? getRandomInt(50, 70) : 0,
                            lastFeedTime: null,
                        },
                    },
                )
                .then(result => this.closeNewStationDialog());
        } else {
            this.setState({ stationNameError: true });
        }
    };

    editStation = () => {
        if (this.state.stationKey) {
            this.props.firestore
                .update(
                    {
                        collection: this.props.game,
                        doc: this.props.gameCode,
                    },
                    {
                        [`configurations.${
                            this.props.currentConfiguration
                        }.stations.${this.state.stationKey}`]: {
                            name: this.state.stationName,
                            rate: this.state.stationRate,
                            limit: this.state.stationLimit,
                            amount: this.props.gameState === "initializing" ?
                                this.state.stationLimit
                                : Math.min(this.state.stationAmount, this.state.stationLimit),
                            risk: this.state.stationRisk,
                            lastFeedTime: null,
                        },
                    },
                )
                .then(result => this.closeEditStationDialog());
        }
    };

    deleteStation = () => {
        if (this.state.stationKey) {
            this.props.firestore
                .update(
                    {
                        collection: this.props.game,
                        doc: this.props.gameCode,
                    },
                    {
                        [`configurations.${
                            this.props.currentConfiguration
                        }.stations.${
                            this.state.stationKey
                        }`]: this.props.firestore.FieldValue.delete(),
                    },
                )
                .then(result => this.closeDeleteStationDialog());
        }
    };

    closeNewStationDialog = () => {
        this.setState({
            showNewStationDialog: false,
            stationNameError: false,
            stationKey: '',
            stationName: '',
            stationLimit: defaultStationLimit,
            stationRate: defaultStationRate,
            stationAmount: defaultStationLimit,
        });
    };

    showEditStationDialog = key => {
        if (key) {
            const station = this.props.stations[key];
            this.setState({
                showEditStationDialog: true,
                stationKey: key,
                stationName: station.name,
                stationLimit: station.limit,
                stationRate: station.rate,
                stationAmount: station.amount,
            });
        }
    };

    closeEditStationDialog = () => {
        this.setState({
            showEditStationDialog: false,
            stationKey: '',
            stationName: '',
            stationLimit: defaultStationLimit,
            stationRate: defaultStationRate,
            stationAmount: defaultStationLimit,
            stationRisk: 0,
        });
    };

    showQRDialog = id => {
        this.setState({
            showQRDialog: true,
            stationKey: id,
            stationName: this.props.stations[id].name,
        });
    };

    closeQRDialog = () => {
        this.setState({
            showQRDialog: false,
            stationKey: '',
            stationName: '',
        });
    };

    showMassQRDialog = stations => {
        this.setState({
            showMassQRDialog: true,
            massStations: stations
        });
    };

    closeMassQRDialog = () => {
        this.setState({
            showMassQRDialog: false,
            massStations: []
        });
    };

    showDeleteStationDialog = id => {
        this.setState({
            showDeleteStationDialog: true,
            stationKey: id,
            stationName: this.props.stations[id].name,
        });
    };

    closeDeleteStationDialog = () => {
        this.setState({
            showDeleteStationDialog: false,
            stationKey: '',
            stationName: '',
        });
    };

    handleDownloadData = () => {
        const spreadSheetData = [];
        let maxGen = 0;

        this.props.firestore
            .get({
                collection: this.props.players,
                where: ['currentGameId', '==', this.props.gameCode],
            })
            .then(data => {
                if (!data.empty) {
                    for (const playerDoc of data.docs) {
                        const {
                            displayName,
                            populationData,
                        } = playerDoc.data();
                        const playerRow = [displayName];

                        maxGen = Math.max(
                            maxGen,
                            Object.keys(populationData).length,
                        );

                        for (
                            let i = 0;
                            i < Object.keys(populationData).length;
                            i++
                        ) {
                            playerRow.push(populationData[i]);
                        }
                        spreadSheetData.push(playerRow);
                    }
                }
                const header = ['Name'];

                for (let i = 0; i < maxGen; i++) {
                    header.push(`Generation ${i}`);
                }

                console.log(spreadSheetData, header);
                spreadSheetData.splice(0, 0, header);

                // const wb = xlsx.utils.book_new();
                // const ws = xlsx.utils.aoa_to_sheet(spreadSheetData);
                // xlsx.utils.book_append_sheet(wb, ws, 'Student Data');

                // xlsx.writeFile(wb, `Student_Data_${Date.now()}.xlsx`);
            });
    };

    render() {
        const allQRCodes = [];

        Object.keys(this.props.stations).map((key, index) => {
            return allQRCodes.push({
                id: key,
                name: this.props.stations[key].name
            });
        });

        const stationFields = (
            <div>
                {this.state.stationNameError && (
                    <Header as="h4" color="red" textAlign="center">
                        The station name is empty or already in use.
                    </Header>
                )}

                <Grid>
                    <Grid.Column width={7} textAlign="left" style={{ alignSelf: 'center' }}>
                        <Header as="h4" style={{ fontWeight: 'normal' }}>
                            Station Name
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={9} textAlign="left">
                        <Input
                            fluid
                            value={this.state.stationName}
                            onChange={(e, { value }) => this.setState({ stationName: value })}
                            disabled={this.state.showEditStationDialog}
                            error={this.state.stationNameError}
                        />
                    </Grid.Column>
                </Grid>

                <SliderWithField
                    min={0}
                    max={4000}
                    field="Maximum Food Available"
                    label="pcs."
                    leftText="0"
                    rightText="4000"
                    value={this.state.stationLimit}
                    onChange={val => this.setState({ stationLimit: val })}
                />
                <SliderWithField
                    min={0}
                    max={4000}
                    field="Food Rate"
                    label="pcs./sec"
                    leftText="0"
                    rightText="4000"
                    value={this.state.stationRate}
                    onChange={val => this.setState({ stationRate: val })}
                />
            </div>
        );
        return (
            <div>
                {this.state.showNewStationDialog && (
                    <YesNoDialog
                        header="Create New Feeding Station"
                        description={stationFields}
                        yesText="Create"
                        yesAction={this.createStation}
                        noText="Cancel"
                        noAction={this.closeNewStationDialog}
                        width="420px"
                    />
                )}

                {this.state.showEditStationDialog && (
                    <YesNoDialog
                        header="Edit Feeding Station"
                        description={stationFields}
                        yesText="Edit"
                        yesAction={this.editStation}
                        noText="Cancel"
                        noAction={this.closeEditStationDialog}
                        width="420px"
                    />
                )}

                {this.state.showQRDialog && (
                    <SingleButtonDialog
                        description={this.qrDialog(this.state.stationKey)}
                        buttonText="Close"
                        buttonAction={this.closeQRDialog}
                        width="600px"
                        height="700px"
                    />
                )}

                {this.state.showMassQRDialog && (
                    <SingleButtonDialog
                        description={this.combineAllQRCodes()}
                        buttonText="Close"
                        buttonAction={this.closeMassQRDialog}
                        width="600px"
                    />
                )}

                {this.state.showDeleteStationDialog && (
                    <YesNoDialog
                        header="Delete Feeding Station"
                        description={
                            'Are you sure you want to delete feeding station ' +
                            this.state.stationName +
                            '?'
                        }
                        yesText="Delete"
                        yesAction={this.deleteStation}
                        noText="Cancel"
                        noAction={this.closeDeleteStationDialog}
                    />
                )}

                {Object.keys(this.props.stations).map((key, index) => (
                    <div key={index}>
                        ({key}){' ' + this.props.stations[key].name + ' '}
                        {this.props.stations[key].rate + ' '}
                        {this.props.stations[key].limit + ' '}
                        {this.props.stations[key].amount + ' '}
                        <Icon
                            name="edit"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.showEditStationDialog(key)}
                        />
                        <Icon
                            name="qrcode"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.showQRDialog(key)}
                        />
                        <Icon
                            name="trash"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.showDeleteStationDialog(key)}
                        />
                        {this.props.predation && (
                            <Icon
                                name="circle"
                                color={ this.props.stations[key].risk === 0 ? 'green' : 'red' }
                            />
                        )}
                    </div>
                    // .push(key)
                ))}

                <div style={{ marginTop: '10px', marginBottom: '15px' }}>
                    <Button
                        content="Add a new station"
                        onClick={() => this.setState({ showNewStationDialog: true })}
                    />
                    <Button
                        content="Download Data"
                        onClick={this.handleDownloadData}
                    />
                </div>

                <div>
                    <Modal
                      trigger={<Button><Icon name="qrcode" style={{ cursor: 'pointer' }} /></Button>}
                      header='All Station QR Codes'
                      content={this.combineAllQRCodes()}
                      actions={[{ key: 'done', content: 'Done', positive: true }]}
                    />
                </div>
            </div>
        );
    }
}

export default StationDetails;
