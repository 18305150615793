import React from 'react';
import Chart from 'react-apexcharts';

// Description: Generates a pie chart from provided data.
//

const PieGraph = props => {

  const { title, subtitle, series, labels } = props;

  return (
    <Chart 
			options={{
				chart: {
					type: 'pie'
				},
        series: series,
        labels: labels.map(label => String(label)),
        title: {
					text: title
				},
        subtitle: {
					text: subtitle
				}
      }}
      series={series}
      type="pie"
      width={'100%'}
			height={'100%'} />
  );

}

export default PieGraph;