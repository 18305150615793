import React, { Component } from 'react';
import { Header, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BasicGreyPage from './BasicGreyPage';
import getDBNames from '../../helpers/getDBNames';
import { joinPlayer } from '../../planetgame/playerApi';
import {joinPlayer as infoJoinPlayer} from '../../infogame/infoPlayerApi';

// Description: 
//      Displays info for players to login including game id and name
//      Both are checked in real time against database
// Parent: PlayerContainer
// Children: None
// Props Used:
//      cancel (Function)
//      done (Function?)
//      firebase (Object)
//      firestore (Object)
//      forceToRejoin (Function)
//      game (String)
//      gameCode (String)
//      players (String)
//      setDBNames (Function)
//      setGameCode (Function)
//      setGameType (Function)

const top = (
  <div style={{marginBottom: '1em'}}>
    <Header as="h1" textAlign="center">Join Game</Header>
    <label>
      Want to rejoin a game you logged out of?
      <br />
      <Link to="/rejoin">Rejoin game</Link>
    </label>
  </div>
);

class PlayerLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      message: '',
      nameError: false,
      codeError: false,
      disableButtons: false
    };
  }

  // handles changes when typing in game and name fields
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      let newValue;
      switch (name) {
        case 'gamecode':
          newValue = value.toLowerCase();
          this.props.setGameCode(newValue);
          break;

        case 'name':
          if (value.length > 0) {
            newValue = value[0].toUpperCase() + value.substr(1).toLowerCase();
            newValue = newValue.replace(/[^a-zA-Z]+/g, '');
            newValue = newValue.slice(0, 16);
          }
          this.setState({ name: newValue });
          break;

        default:
          break;
      }
    });
  };

  // validates player name in real time against database
  validateName = (value) => {
    return new Promise((resolve) => {
      if (value.length === 0) {
        this.setState({ nameError: true });
        resolve(false);
      } else {
        this.props.firestore
          .get({
            collection: this.props.players,
            where: [['currentGameId', '==', this.props.gameCode], ['displayName', '==', value]]
          })
          .then((querydata) => {
            if (querydata.size === 0 && this.state.name.length > 0) {
              this.setState({ nameError: false });
              resolve(true);

            } else {
              this.setState({ nameError: true });
              resolve(false);
            }
          }).catch((err) => console.log(err));
      }
    });
  }

  // validates game in real time against database
  validateGame = (value) => {
    return new Promise((resolve) => {
      if (value.length === 0) {
        this.setState({ codeError: true });
        resolve(false);
      } else {
        this.props.firestore
          .get({
            collection: 'games',
            doc: value
          })
          .then((querydata) => {
            if (!querydata.exists) {
              this.setState({ codeError: true });
              resolve(false);
            } else if (querydata.data().gameState !== 'initializing' && querydata.data().type !== 'tgame') {
              this.props.forceToRejoin();
            } else {
              this.setState({ codeError: false });
              this.props.setDBNames(getDBNames(querydata.data().type));
              this.props.setGameType(querydata.data().type);
              resolve(true);
            }
          }).catch((err)=>console.log(err));
      }
    });
  }

  // try to submit form data
  handleSubmit = () => {
    this.setState({ disableButtons: true });
    //Logins in first to seperate security rules
    this.props.firebase
      .auth()
      .signInAnonymously()
      .then((data) => {
        this.validateGame(this.props.gameCode).then((gamevalidated) => {
					if (!this.state.codeError) {
						if (this.props.game === "lgames") {  // lgames player login
							joinPlayer(this.state.name)
								.then((value) => {
									if (value) {
										this.props.done();
									} else {
										this.setState({ nameError: true, disableButtons: false });
										this.props.firebase.logout();
									}
								});
						}
            else if(this.props.game === "infogames") {  // infogames player login
							infoJoinPlayer(this.state.name)
								.then((value) => {
									if (value) {
										this.props.done();
									} else {
										this.setState({ nameError: true, disableButtons: false });
										this.props.firebase.logout();
									}
								});
						} else {
							this.validateName(this.state.name).then((namevalidated) => {
								if (!(this.state.nameError || this.state.codeError)) {
									var entry = {};
									switch (this.props.game) {
										case 'vgames':
											entry = {
												// this part should be passed in by PlayerContainer -
												// since it is game specific
												currentGameId: this.props.gameCode,
												sickTime: -1,
												sickState: false,
												immune: false,
												//Avatar type is randomized with amount of choices it has
												avatar: [{}],
												displayName: this.state.name,
												rejoin: false,
												uid: '',
												approve: false,
												denied: false,
												lastLogin: this.props.firestore.FieldValue.serverTimestamp(),
											}
											break;
										case 'pgames':
											const initPopulation = 10;
											entry = {
												currentGameId: this.props.gameCode,
												displayName: this.state.name,
												energy: 50,
												population: initPopulation,
												populationData: {
													'0': initPopulation
												},
												lastStation: null,
												newGenerationFlag: null,
												rejoin: false,
												uid: '',
												approve: false,
												denied: false,
												lastLogin: this.props.firestore.FieldValue.serverTimestamp(),
											}
											break;

										case 'tgames':
											entry = {
												currentGameId: this.props.gameCode,
												displayName: this.state.name,
												timeLeft: 20,
												fishCaught: 20,
												boat: {
													size: "small",
													trawler: false,
													research: false
												},
												store: {
													smallBoat: true,
													medBoat: false,
													largeBoat: false,
													fishRadar: false,
													trawler: false
												},
												doubleAgent: false,
												rejoin: false,
												uid: '',
												approve: false,
												denied: false,
												gameOver: false,
												lastLogin: this.props.firestore.FieldValue.serverTimestamp(),
												lastStation: 0
											}
											break;

										default:
											break;
									}
									this.props.firestore.set(
										{
											collection: this.props.players,
											doc: data.user.uid
										},
										entry
									).then(() => {
										this.props.firestore.get(
											{
												collection: "games",
												doc: this.props.gameCode
											}).then((doc) => {
												this.props.firestore.update(
													{
														collection: "games",
														doc: this.props.gameCode
													},
													{
														players: this.props.firestore.FieldValue.arrayUnion(data.user.uid)
													}).then(this.props.done());
											})
									})
								} else {
									this.setState({ disableButtons: false });
									this.props.firebase.logout();
								}
							});
						}
					} else {
						this.setState({ disableButtons: false });
						this.props.firebase.logout();
					}
				})
					.catch(error => console.log(error));
    });
  }

  render() {
    let center = (
      <Form onSubmit={this.handleSubmit} widths="equal">
        {(this.state.codeError || this.state.nameError) &&
          <Header as="h5" color="red">
            {this.state.codeError && <span>Game not found.<br /></span>}
            {this.state.nameError && <span>Name is empty or is already in use. Please rejoin if you were previously logged in.</span>}
          </Header>
        }
        <Form.Group inline>
          <Form.Input
            fluid
            className="inline-input"
            error={this.state.codeError}
            size="big"
            icon="gamepad"
            iconPosition="left"
            placeholder="Game Code"
            name="gamecode"
            value={this.props.gameCode}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Input
            fluid
            className="inline-input"
            error={this.state.nameError}
            size="big"
            icon="user circle"
            iconPosition="left"
            placeholder="Display Name"
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Button
          disabled={this.state.name === '' || this.props.gameCode === '' || this.state.disableButtons}
          size="big"
          content="Join"
          fluid
        />
        <Form.Button
          disabled={this.state.disableButtons}
          onClick={this.props.cancel}
          size="big"
          content="Home"
          negative fluid
        />
      </Form>
    );

    return <BasicGreyPage pageClass="playerlogin" top={top} center={center} />;
  }
}

export default PlayerLogin;
