import * as types from '../actions/core';

const defaultState = {
    gameCode: '',
    gameType: '',
    gameState: 'empty',
    game: '',
    players: '',
    meetings: ''
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SET_GAME_CODE:
            return {
                ...state,
                gameCode: action.payload
            };
        case types.SET_GAME_STATE:
            return {
                ...state,
                gameState: action.payload
            };
        case types.SET_DB_NAMES:
            return {
                ...state,
                game: action.payload.game,
                players: action.payload.players,
                meetings: action.payload.meetings
            }
        case types.SET_GAME_TYPE: {
            return {
                ...state, 
                gameType: action.payload
            };
        }
        default:
            return state;
    }
};
