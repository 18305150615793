import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { discardGraph } from '../../playerApi';

import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import PieGraph from './PieChart';
import Histogram from './Histogram';
import { Dropdown, Button } from 'semantic-ui-react';

/** @type {Map<string, string} Maps graph type to the name of the icon */
const GraphIcons = new Map([
	['line', 'chart line'],
	['bar', 'chart bar'],
	['pie', 'chart pie'],
	['hist', 'chart bar outline'],
]);

/**
 * @typedef {object} GraphData 
 * @property {string} dataType 	- The type of the data collected 
 * @property {string} graphType - The type of the graph to display the data
 * @property {string} title			- The title of the graph
 * @property {string} subtitle	- The subtitle of the graph
 * @property {number} timeId		- The unique ID of this graph data
 * @property {object} x 				- The data to display on the x-axis
 * @property {object[]} x.data  - The sequence of x coordinates (corresponds with y.data)
 * @property {string} x.label		- The label for the x-axis
 * @property {object} y					- The data to display on the y-axis
 * @property {object[]} y.data  - The sequence of y coordinates (corresponds with x.data)
 * @property {string} y.label		- The label for the y-axis
 */

/**
 * A component for rendering one graph with graph data that is given as a
 * property. This graph component will automatically determine which graph
 * type to display.
 */
class RenderedGraph extends Component {
	render () {
		/** @type {GraphData} */
		const graph = this.props.graph;
		// Use default style unless a containerStyle property was provided
		const containerStyle = this.props.containerStyle || { height: '35vh', width: '100%' };
		// If no graph is given, put an empty space until graph data is given
		if (graph === undefined || !graph) {
			return (
				<div style={containerStyle}></div>
			);
		}

		switch (graph?.graphType) {
			case 'line':
				return (
					<div style={containerStyle}>
						<LineGraph
							categories={graph.x.data}
							series={graph.y.data}
							xtitle={graph.x.label}
							ytitle={graph.y.label}
							title={graph.title}
							subtitle={graph.subtitle}
						/>
					</div>
				);
			case 'bar':
				return (
					<div style={containerStyle}>
						<BarGraph
							categories={graph.x.data}
							series={graph.y.data}
							xtitle={graph.x.label}
							ytitle={graph.y.label}
							title={graph.title}
							subtitle={graph.subtitle}
						/>
					</div>
				);
			case 'pie':
				return (
					<div style={containerStyle}>
						<PieGraph 
							labels={graph.x.data} 
							series={graph.y.data[0].data} 
							title={graph.title} 
							subtitle={graph.subtitle} 
						/>
					</div>
				);
			case 'hist':
				// graphIcon = "chart bar outline";
				return (
					<div style={containerStyle}>
						<Histogram 
							binSize={1} 
							data={graph.y.data[0].data} 
							xtitle={graph.y.label} 
							title={graph.title} 
							subtitle={graph.subtitle} 
						/>
					</div>
				);
			default:
				return (
					<div style={containerStyle}></div>
				);
		}
	}
}

RenderedGraph.propTypes = {
	// The data for the graph
	graph: PropTypes.object,
	// Optional custom styling for the div containing the graph, such as custom sizing
	containerStyle: PropTypes.object,
};

/**
 * This is a self-contained graph viewer that, given a list of 
 * graph data, will display a dropdown menu and the corresponding
 * rendered graph.
 */
class SingleGraphViewer extends Component {

	constructor (props) {
		super (props);
		this.state = {
			graphId: -1,
		};
	}

	/**
	 * Whenever the drop down is selected, update state and rerender the displayed graph
	 * @param {*} e - Event Data
	 * @param {{value: string}} param1 - The numeric ID of the graph to display
	 */
	onSelectGraph = (e, { value }) => {
		this.setState({
			graphId: parseInt(value),
		});
	}

	/**
	 * When the trash symbol is clicked on a single graph viewer, delete the graph using
	 * the Student API service.
	 */
	discardSavedGraph = async () => {
		// Don't do anything if the graphId is not set
		if (this.state.graphId === -1) return;
		await discardGraph(this.state.graphId);
		this.setState({ graphId: -1});
	}

	render () {
		/** @type {GraphData[]} A list of graph data */
		const savedGraphs = this.props.savedGraphs;

		const graph = savedGraphs.find(graph => graph.timeId === this.state.graphId);
		// a null graph means there is no graph with this graphId (often bc deleted elsewhere)
		if (!graph && this.state.graphId != -1) 
			this.setState({graphId: -1})
		
		// The options to display in the dropdown menu, where the values is the graph's timeId
		const savedGraphOptions = savedGraphs.map(graph => {
			return ({
				text: `${graph.title} ${graph.subtitle}`,
				value: `${graph.timeId}`,
				icon: GraphIcons.get(graph.graphType)
			});
		});
		return (
			<React.Fragment>
				<div style={{display: 'flex', gap: '3px'}}>
					<Dropdown selection fluid
						placeholder="Choose Graph"
						options={savedGraphOptions}
						onChange={this.onSelectGraph}
					/>

					{this.props.hideable && (
						<Button
							icon="minus"
							onClick={this.props.hideGraph}
						/>
					)}
					<Button negative
						icon="trash"
						onClick={this.discardSavedGraph}
						disabled={this.state.graphId === -1}
					/>
				</div>
				<RenderedGraph graph={graph} />
			</React.Fragment>
		);
	}
}

SingleGraphViewer.propTypes = {
	// A list of the student's saved graphs
	savedGraphs: PropTypes.arrayOf(PropTypes.object),
	// Called when hiding this current graph viewer
	hideGraph: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
	savedGraphs: state.planetGame.player.savedGraphs
});
export default connect(mapStateToProps)(SingleGraphViewer);