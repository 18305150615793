import React, { Component } from 'react';
import { connect } from 'react-redux';

import { List, Header } from 'semantic-ui-react';

/**
 * A component where players can see the data received from other players
 * Parent: PlayerInteractionMenu
 * Children: None
 */

class RetrieveData extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div style={{ margin: '10px' }}>
				<Header size="medium">Data Inbox</Header>
				<List celled>
					{this.props.receivedSensors.map(sensor => (
						<List.Item>
							<List.Icon name="mail" />
							<List.Content>
								<List.Header>{`${sensor.origin.playerName} sent a ${sensor.dataType} sensor from planet ${this.props.planets[sensor.planet].name} region ${sensor.region+1}`}</List.Header>
							</List.Content>
						</List.Item>
					))}
				</List>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	receivedSensors: state.planetGame.player.collectedSensors.filter(
		sensor => sensor.origin !== undefined
	),
	planets: state.planetGame.world.planets
});

export default connect(mapStateToProps)(RetrieveData);
