import React, { Component } from "react";
import {
  Button,
  Message,
  Form,
  Radio,
  Grid,
  Popup,
  Label
} from "semantic-ui-react";
import YesNoDialog from "./YesNoDialog";

// Description: Radio buttons for teacher restart options
// Parent: TeacherControlScreen
// Children: None
// Props Used:
//      clear (Function)
//      reinitialize (Function)
//      rerun (Function)

const V_GAME = "vgames";
const T_GAME = "tgames";

class TeacherRestart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restartChoice: "Rerun",
      buttonDescription:
        this.props.game === V_GAME
          ? "Rerun will keep players and game properties the same but reset all meetings. Commonly used to quickly restart while keeping everything constant."
          : "Rerun will keep players and stations, but revert player fish amounts and station fish populations to their initial amounts. Commonly used to quickly restart while keeping everything constant.",
      showRestartConfirmation: false,
      buttonColor: "blue"
    };

    this.handleRerunChange = this.handleRerunChange.bind(this);
    this.handleReinitChange = this.handleReinitChange.bind(this);
    this.handleClearChange = this.handleClearChange.bind(this);
    this.handleRestart = this.handleRestart.bind(this);
  }

  handleRerunChange = () => {
    this.setState({ restartChoice: "Rerun" });
    this.setState({
      buttonDescription:
        this.props.game === V_GAME
          ? "Rerun will keep players and game properties the same but reset all meetings. Commonly used to quickly restart while keeping everything constant."
          : "Rerun will keep players and stations, but revert player fish amounts and station fish populations to their initial amounts. Commonly used to quickly restart while keeping everything constant."
    });
    this.setState({ buttonColor: "blue" });
  };

  handleReinitChange = () => {
    this.setState({ restartChoice: "Reinit" });
    this.setState({
      buttonDescription:
        this.props.game === V_GAME
          ? "Reinitialize will keep players the same but reset game properties and meetings. Commonly used to reassign Patient 0."
          : "Clear players will reset players and revert the game to its initial state at Season 1. Commonly used to start a new game with the same stations but new players."
    });
    this.setState({ buttonColor: "yellow" });
  };

  handleClearChange = () => {
    this.setState({ restartChoice: "Clear" });
    this.setState({
      buttonDescription:
        this.props.game === V_GAME
          ? "Clear will reset players, game properties, and meetings. Commonly used to start entirely new game without changing game code."
          : "Clear will reset players, stations and game properties. Commonly used to start entirely new game without changing game code."
    });
    this.setState({ buttonColor: "red" });
  };

  handleRestart = () => {
    console.log(this.props.gameState);
    switch (this.state.restartChoice) {
      case "Rerun":
        this.props.rerun();
        break;

      case "Reinit":
        this.props.reinitialize();
        break;

      case "Clear":
        this.props.clear();
        break;
      default:
    }
  };

  render() {
    return (
      <div>
        <Message style={{ marginLeft: "0.7em", marginRight: "0.7em" }}>
          <br />
          <br />
          <Label attached="top" as="h3" color="black" size="big">
            Restart Game Controls
          </Label>

          <Grid columns={2} divided>
            <Grid.Row>
              {/* Radio Buttons that change the effect of the restart button */}
              <Grid.Column>
                <div align="left">
                  <br />
                  <Form>
                    <Form.Field>
                      <Radio
                        label="Rerun"
                        name="radioGroup"
                        value="Rerun"
                        disabled={this.props.gameState === "initializing"}
                        checked={this.state.restartChoice === "Rerun"}
                        onChange={this.handleRerunChange}
                      />
                    </Form.Field>
                    {this.props.game === V_GAME && (
                      <Form.Field>
                        <Radio
                          label={"Reinitialize Game"}
                          name="radioGroup"
                          value="Reinit"
                          disabled={this.props.gameState === "initializing"}
                          checked={this.state.restartChoice === "Reinit"}
                          onChange={this.handleReinitChange}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <Radio
                        label="Clear"
                        name="radioGroup"
                        value="Clear"
                        disabled={this.props.gameState === "initializing"}
                        checked={this.state.restartChoice === "Clear"}
                        onChange={this.handleClearChange}
                      />
                    </Form.Field>
                  </Form>
                </div>
              </Grid.Column>

              {/* Restart Button */}
              <Grid.Column>
                <br />
                <Popup
                  content={this.state.restartChoice}
                  trigger={
                    <Button
                      circular
                      icon="alternate undo"
                      size="massive"
                      color={this.state.buttonColor}
                      onClick={() => {
                        if (this.props.gameState !== "initializing") {
                          this.setState({ showRestartConfirmation: true });
                        }
                      }}
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Message>

        {/*Restart Confirmation */}
        {this.state.showRestartConfirmation && (
          <YesNoDialog
            header={
              this.state.restartChoice === "Reinit" &&
              this.props.game === T_GAME
                ? "Clear Players Confirmation"
                : this.state.restartChoice + " Game Confirmation"
            }
            description={<div>{this.state.buttonDescription}</div>}
            yesText={this.state.restartChoice}
            yesAction={() => {
              this.setState({ showRestartConfirmation: false });
              this.handleRestart();
            }}
            noText="Close"
            noAction={() => {
              this.setState({ showRestartConfirmation: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default TeacherRestart;
