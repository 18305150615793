export const SET_GAME_STATE = 'SET_GAME_STATE';
export const SET_GAME_CODE = 'SET_GAME_CODE';
export const SET_DB_NAMES = 'SET_DB_NAMES';
export const SET_GAME_TYPE = 'SET_GAME_TYPE';

const setGameCode = (gameCode) => {
    return {
        type: SET_GAME_CODE,
        payload: gameCode
    };
};

const setGameState = (gameState) => {
    return {
        type: SET_GAME_STATE,
        payload: gameState
    };
};

const setDBNames = (names) => {
    return {
        type: SET_DB_NAMES,
        payload: names
    };
}

const setGameType = (type) => {
    return {
        type: SET_GAME_TYPE,
        payload: type
    };
}

export { setGameCode, setGameState, setDBNames, setGameType };