import React from 'react';
import PopulationPlayerStatisticsTable from './PopulationPlayerStatisticsTable';
import LineChart from '../../../core/components/LineChart';
import Histogram from '../../../core/components/Histogram';
import SingleElementAccordion from '../../../core/components/SingleElementAccordion';

const PlayerStatisticsDisplay = (props) => {

    return (
        <div>
            <SingleElementAccordion
                title="Player Statistics"
                content={
                    <PopulationPlayerStatisticsTable
                        data={props.populationDataTable}
                    />
                }
            />
            {props.gameState !== 'initializing' && (
                <div>
                    <SingleElementAccordion
                        title="Population Graph"
                        content={
                            <div style={{ height: '35vh', width: '100%' }}>
                                {/* Will no longer work, need to update props */}
                                <LineChart
                                    data={props.populationData}
                                    layout={{
                                        xaxis: {
                                            title: {
                                                text: 'Generation',
                                            },
                                        },
                                        yaxis: {
                                            title: {
                                                text:
                                                    'Total Population Count',
                                            },
                                        },
                                    }}
                                    marker={{ color: 'black' }}
                                    mode="lines+points"
                                />
                            </div>
                        }
                    />
                    <SingleElementAccordion
                        title="Population Histogram"
                        content={
                            <div style={{ height: '35vh', width: '100%' }}>
                                <Histogram
                                    data={props.histogramData}
                                    layout={{
                                        xaxis: {
                                            title: {
                                                text: 'Population Size',
                                            },
                                        },
                                        yaxis: {
                                            title: {
                                                text: 'Number of Players',
                                            },
                                        },
                                    }}
                                    marker={{
                                        line: {
                                            color: 'rgba(0, 0, 0, 1)',
                                            width: 1,
                                        },
                                    }}
                                />
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default PlayerStatisticsDisplay;