import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGameStatus, attemptJoin, setUpGame, listenToGameStatus, getPlayerData} from '../infoPlayerApi';

import { Button, Responsive, Input, Card, Dimmer } from 'semantic-ui-react';
import BasicGreyPage from './../../core/components/BasicGreyPage';
import YesNoDialog from './../../core/components/YesNoDialog';
import LogoutConfirmation from './../../core/components/LogoutConfirmation';
import DimIcon from './../../core/components/DimIcon';
import RoleCard from './RoleCard';
import NoteCard from './NoteCard';
import InfoSharingScreen from './InfoSharingScreen';

import { GAME_STATUS } from '../constants';

/**
 * The container component for the Planet Animals game
 * Parent: PlayerContainer
 * Children: 
 *
 * @param {object} firebase used for properly logging out
 * @param {function} loggedOut handler for successfully logging out
 */

class InfoPlayerGameScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screen: 'role',
			showLogoutConfirmation: false,
			joinStatus: this.props.gameCode !== '' ? 'valid' : '', // 'valid', 'code', 'player', ''
			inGame: false,
		};
	}

	componentDidMount = () => {
		if (this.state.joinStatus === 'valid') this.retrieveGame();
	};

	
	handleGameCodeInput = (e, { value }) => this.props.setGameCode(value.toLowerCase());

	// Verifies that the player is in the specified game code and if successful, gets game status
	retrieveGame = async () => {
		const result = await attemptJoin();
		this.setState({ joinStatus: result });
		if (result === 'valid') await getGameStatus();
	};

	// Gets the game data and sets the appropriate listeners before entering the game
	enterGame = async () => {
		listenToGameStatus();
		if (this.props.game.gameStatus !== GAME_STATUS.INITIALIZING) await setUpGame();
		this.setState({ inGame: true });
	};
	
	render() {
		if (this.state.showLogoutConfirmation) {
			// logout
			return (
				<LogoutConfirmation
					logoutAction={() => {
						this.setState({ buttonPressed: true });
						this.props.firebase.logout();
						this.props.loggedOut();
					}}
					backAction={() => this.setState({ showLogoutConfirmation: false })}
				/>
			);
		} else if (!this.state.inGame) {
			// still in joining process
			if (this.state.joinStatus === 'valid') {
				// confirm join game
				return (
					<YesNoDialog
						header="Enter Game"
						description={`You are about to enter the game with code ${this.props.gameCode}. Would you like to continue or log out?`}
						yesText="Continue"
						yesAction={this.enterGame}
						noText="Log Out"
						noAction={() => this.setState({ showLogoutConfirmation: true })}
					/>
				);
			} else {
				// prompt for game code
				let message;
				if (this.state.joinStatus === 'code')
					message = 'Error: game code invalid. Please try entering the game code again';
				else if (this.state.joinStatus === 'player')
					message = 'Error: player not found in game. Please try entering the game code again';
				else message = 'Please enter the game code to rejoin game.';
				return (
					<Dimmer active page>
						<Card style={{ width: '320px' }}>
							<Card.Content textAlign="left">
								<br />
								<Card.Header content={'Enter Game'} />
								<br />
								<Card.Description className="description" content={message} />
								<br />
							</Card.Content>

							<Card.Content>
								<Input
									fluid
									className="inline-input"
									size="big"
									icon="gamepad"
									iconPosition="left"
									placeholder="Game Code"
									name="gamecode"
									value={this.props.gameCode}
									onChange={this.handleGameCodeInput}
								/>
								<Button.Group widths={2}>
									<Button color="green" content={'Continue'} onClick={this.retrieveGame} />

									<Button
										color="red"
										content={'Log Out'}
										onClick={() => this.setState({ showLogoutConfirmation: true })}
										basic
									/>
								</Button.Group>
							</Card.Content>
						</Card>
					</Dimmer>
				);
			}
		} else {
			// in game
			const gameStatus = this.props.game.gameStatus;
			if (
				gameStatus === GAME_STATUS.INITIALIZING ||
				//gameStatus === GAME_STATUS.PAUSED ||
				gameStatus === 'undefined'
			) {
				// waiting
				console.log(`Current game status: ${this.props.game.gameStatus}`);
				
				return <DimIcon message="Waiting for Game to Start" iconName="pause" />;
			} else if(gameStatus === GAME_STATUS.PAUSED){
				getPlayerData();
				return <DimIcon message="Waiting for Game to Start" iconName="pause" />;
			}
			else {
				let main;
				switch (this.state.screen) {
					case 'shape':
						main = <RoleCard />;
						break;
					case 'scan':
						main = <InfoSharingScreen />;
						break;
					case 'notes':
						main = <NoteCard />;
						break;
					default:
						// home
						main = <RoleCard />;
				}

				const center = (
					<div>
						<Button.Group fluid>
							<Button
								onClick={() => this.setState({ screen: 'role' })}
								size="large"
								icon="address card outline"
								color="blue"
							/>
							<Button
								onClick={() => this.setState({ screen: 'scan' })}
								size="large"
								icon="qrcode"
								color="green"
							/> 
							<Button
								onClick={() => this.setState({ screen: 'notes' })}
								size="large"
								icon="qrcode"
								color="red"
							/>
							
						</Button.Group>
						{main}
						
					</div>
				); 

				return (
					<div style={{ height: '100%' }}>
						<Responsive as={BasicGreyPage} minWidth={320} center={center} />
					</div>
				);
			}
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	game: state.infoGame.game,
	gameCode: state.core.gameCode,
	shape: state.infoGame.player.shape,
	notes: state.infoGame.player.notes,
	
});

export default connect(mapStateToProps)(InfoPlayerGameScreen);
