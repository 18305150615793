import React from 'react';
import QrReader from 'react-qr-reader';
// import QrReader from '../../libraries/qrjs/index.js';

// Description: renders qr reader
// Parent: Readercontainer
// Children: None
// Props Used:
// has default props but takes handle scan function and handle error function
const constraints = { aspectRatio: 1, facingMode: {ideal: 'environment'}, delay: 500 };

// TODO: fix camera height
const Readerqr = props => (
    <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
        <QrReader
            delay={props.delay}
            onError={props.handleError}
            onScan={props.handleScan}
            style={{ width: '100%' }}
            onLoad={props.doneLoading}
            facingMode={'environment'}
            constraints={constraints}
        />
    </div>
);

Readerqr.defaultProps = {
    delay: 500,
    handleScan: () => {
        console.log('handlescan');
    },
    handleError: (err) => {
        console.error(err);
    },
    lastResult: 'none'
};

export default Readerqr;
