import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
// import ScrollList from '../../../core/components/ScrollList';

// Description: 
//		displays the player icon and number of this.props.filter players
//		and has a popup that contains the ScrollList
// Parent: VirusTeacherGameScreen
// Child: ScrollList
// Props Used:
//		status (String)
//		totalPlayers (Array)
//		value (Int)

class PatientZeroPopup extends Component {
	render() {
		const header = this.props.status + ' : ';
		let online = 'no';
		let sick = 'no';
		let nummeetings = 0;

		let actives = {};
		let sickPlayers = [];

		if (this.props.status === 'Online') {
			for (var i = 0; i < this.props.totalPlayers.length; i++) {
				actives[this.props.totalPlayers[i].id] = this.props.totalPlayers[i].active;
			}
			if (actives[this.props.value]) {
				online = 'yes';
			} else {
				online = 'Patient zero not selected';
			}
		}

		if (this.props.status === 'Sick') {
			for (var j = 0; j < this.props.totalPlayers.length; j++) {
				sickPlayers.push(this.props.totalPlayers[j].id);
			}
			if (sickPlayers.indexOf(this.props.value) > -1) {
				sick = 'yes';
			}
		}

		if (this.props.status === 'Players Met') {
			// console.log('Players met');
			// console.log(this.props.list);
			nummeetings = this.props.value;
		}

		return (
			<div>
				<Grid>
					<Grid.Column className="no-padding-col" width={1}>
						{this.props.status === 'Online' ? <Icon name="user" /> : ''}
						{this.props.status === 'Sick' ? (
							<Icon name="thermometer half" />
						) : (
							''
						)}
						{this.props.status === 'Players Met' ? <Icon name="users" /> : ''}
					</Grid.Column>

					<Grid.Column
						className="no-padding-col"
						width={13}
						mobile={11}
						textAlign="left"
					>
						{this.props.status === 'Online' && header + online}
						{this.props.status === 'Sick' && header + sick}
						{this.props.status === 'Players Met' && header + nummeetings}
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

export default PatientZeroPopup;
