import React, { Component } from "react";

import { connect } from 'react-redux';
import firebase from "firebase";
import { Label, Message, Header, Grid, Button, Popup, Dimmer, Card, Checkbox } from 'semantic-ui-react';
import { getGameData, setSetting, resetGame } from "../infoTeacherApi.js"; 
import { GAME_STATUS, SLIDER_SETTING } from "../constants.js";

import TeacherMenu from "./TeacherMenu";
import TeacherButtons from "../../core/components/TeacherButtons";
import TeacherRestart from "../../core/components/TeacherRestart.js";
import TeacherControlScreen from "../../core/components/TeacherControlScreen";
import YesNoDialog from '../../core/components/YesNoDialog';
import TeacherGameInfo from '../../core/components/TeacherGameInfo';
import TeacherStudentInfo from '../../core/components/TeacherStudentInfo';
import SingleButtonDialog from '../../core/components/SingleButtonDialog';
import InfoGameSettings from "./InfoGameSettings.js";

const STATUS_BUTTON_OPTIONS = {
	initializing: {
		label: 'Not Started',
		icon: 'exclamation'
	},
	paused: {
		label: 'Paused',
		icon: 'play'
	},
	running: {
		label: 'In Play',
		icon: 'pause'
	}
};


/**
 * The dashboard for the teacher to control the game with.
 * Parent: TeacherContainer
 * Children: TeacherGameInfo, TeacherStudentInfo, TeacherMonitor
 *
 * @param {string} gameCode the game code of the selected game
 */
class InfoTeacherGameScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rejoinedPlayers: [],
			totalPlayers: [],
			showPlayerError: false,
			showLogoutConfirmation: false,
			showHomeConfirmation: false,
			processRunning: false // disable buttons if true
		};

		this.handleStatusButton.bind(this);
		this.handleRestartButton.bind(this);
	}

	componentDidMount() {
		getGameData();
	}

	handleStatusButton = async () => {
		if (this.props.gameStatus === GAME_STATUS.PAUSED && this.props.players.length === 0) {
			this.setState({ showPlayerError: true });
			return;
		}
		this.setState({ processRunning: true });
		await setSetting(
			'gameStatus',
			this.props.gameStatus === GAME_STATUS.PAUSED ? GAME_STATUS.RUNNING : GAME_STATUS.PAUSED,
			true
		);
		this.setState({ processRunning: false });
	};

	handleRestartButton = async () => {
		this.setState({ processRunning: true });
		await resetGame();
		this.setState({ processRunning: false });
	};
	
	

    render() {
		const { gameCode, gameStatus, players} = this.props;
		const statusButton = STATUS_BUTTON_OPTIONS[gameStatus];


		const playerError = this.state.showPlayerError && (
			<SingleButtonDialog
				description="You need at least one player before starting the game."
				buttonText="Close"
				buttonAction={() => this.setState({ showPlayerError: false })}
			/>
		);

		const logoutPrompt = this.state.showLogoutConfirmation && (
			<YesNoDialog
				header={'Logout Confirmation'}
				description={'Are you sure you wish to logout?'}
				yesText={'Yes'}
				yesAction={() => {
					this.setState({ showLogoutConfirmation: false });
					this.props.firebase.logout();
					this.props.history.push('/');
				}}
				noText="Close"
				noAction={() => {
					this.setState({ showLogoutConfirmation: false });
				}}
			/>
		);

		const homePrompt = this.state.showHomeConfirmation && (
			<YesNoDialog
				header={'Home Confirmation'}
				description={'Are you sure you wish to return to the Homepage?'}
				yesText={'Yes'}
				yesAction={() => {
					this.setState({ showHomeConfirmation: false });
					this.props.history.push('/');
				}}
				noText="Close"
				noAction={() => {
					this.setState({ showHomeConfirmation: false });
				}}
			/>
		);
		

		return (
			/*
			<div style={{ height: '100vh' }}>
				<header>
					Info Game
				</header>
			</div>*/
			
			<div style={{ height: '100vh' }}>
				<TeacherMenu
					onHomeClick={() => this.setState({ showHomeConfirmation: true })}
					onLogoutClick={() => this.setState({ showLogoutConfirmation: true })}
				/>	

				<Grid stackable style={{ height: '95%' }}>
					<Grid.Column color="grey" width={4} stretched>
						<Grid.Row>
							<TeacherGameInfo
								gameCode={gameCode}
								gameState={gameStatus}
								gameType={'Info Game'}
								handleStateButton={
									statusButton === undefined ? null : (
										<Popup
											content={`Currently: ${statusButton.label}`}
											trigger={
												<Button
													icon={statusButton.icon}
													onClick={this.handleStatusButton}
													disabled={this.state.processRunning}
													color="green"
													size="massive"
													circular
												/>
											}
										/>
									)
								}
							/>
						</Grid.Row>

						<Grid.Row>
							<TeacherStudentInfo
								rejoinedPlayers={[]}
								approveCallback={this.props.approveCallback}
								rejectCallback={this.props.rejectCallback}
								totalPlayers={players}
							/>
						</Grid.Row>

						<Grid.Row>
							<Button
								content="RESTART"
								color="red"
								onClick={this.handleRestartButton}
								disabled={gameStatus === GAME_STATUS.INITIALIZING || this.state.processRunning}
							/>
						</Grid.Row>
					</Grid.Column>

					<Grid.Column color="violet" width={12} stretched>
						<div style={{ height: '100%' }}>
							{playerError}
							{logoutPrompt}
							{homePrompt}

							<Message
								style={{
									marginLeft: '0.7em',
									marginRight: '0.7em',
									height: '100%',
									overflow: 'auto'
								}}
							>
								<br />
								<br />
								<Label attached="top" as="h3" color="black" size="big">
									Game Screen
								</Label>
								

								<Grid stackable style={{ height: '70vh' }}>
									<Grid.Row columns={1}>
										<Grid.Column rows={1}>
											{gameStatus === GAME_STATUS.INITIALIZING ? (
												<Grid.Row>
													<Message
														style={{
															marginLeft: '0.7em',
															marginRight: '0.7em',
															marginBottom: '1.0em'
														}}
													>
														<InfoGameSettings settings={SLIDER_SETTING} />
													</Message>
													
												</Grid.Row>
											) : (
												<Grid.Row>
													<Message
														style={{
															marginLeft: '0.7em',
															marginRight: '0.7em',
															marginBottom: '1.0em'
														}}
													>
														<br />
														<div>
															<Header textAlign="left" as="h3">
																Student Monitor (WIP)
															</Header>
															{/* <PlanetTeacherMonitor /> */}
														</div>
														
													</Message>
													
												</Grid.Row>
											)}
										</Grid.Column>
									</Grid.Row>
								</Grid>
								
							</Message>
						</div>
					</Grid.Column>
				</Grid>
			</div>

			
		);
	}
	
}

const mapStateToProps = (state, ownProps) => ({
	gameStatus: state.infoGame.game.gameStatus,
	players: state.infoGame.game.players
});

export default connect(mapStateToProps)(InfoTeacherGameScreen);