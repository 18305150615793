import { createSlice } from '@reduxjs/toolkit';

/*
Structure of the contracts slice of the global state (Redux)
Used for player

state: {
	animals: [key: string]: { // key is animal name
		{
			name: string,
			playerId: string, 
			gasTol: [key: string]: number[], // key is gas name
			rainTol: number[], 
			tempTol: number[], 
			goalPopulation: number, 
			populationInShip: number (decimal, not integer)
		} 
	}
}
*/

const slice = createSlice({
	name: 'contracts',
	initialState: {
		animals: {}
	},
	reducers: {
		addAnimal: (state, action) => {
			/* payload: {
				name: string,
				playerId: string, 
				gasTol: [key: string]: number[], 
				rainTol: number[], 
				tempTol: number[], 
				goalPopulation: number, 
				populationInShip: number (decimal, not integer)
			} */
			const obj = action.payload;
			console.log('[contracts/addAnimal] reading and storing animal:', obj, 'to', state.animals);
			state.animals[obj.name] = obj;
		},

		removeAnimal: (state, action) => {
			// payload: string (animal name)
			console.log(`[contracts/removeAnimal] removing animal ${action.payload} from`, state);
			delete state.animals[action.payload];
		},

		updateShipPopulation: (state, action) => {
			/* payload: {
				animalName: string,
				change: number
			} */
			const { animalName, change } = action.payload;
			console.log(
				`[contracts/updateShipPopulation] incrementing ${animalName}'s population on ship by ${change}`
			);
			state.animals[animalName].populationInShip += change;
		},

		reset: (state, action) => {
			// payload: none
			console.log('[contracts/reset] clearing out all existing contracts');
			state.animals = {};
		}
	}
});

export const contractActions = slice.actions;

export default slice.reducer;
