import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Header } from 'semantic-ui-react';
import AnimalCardJIT from './AnimalCardJIT';
import Carousel from 'semantic-ui-carousel-react';
import SingleGraphViewer from './SingleGraphViewer.js';

/**
 * Displays any of a player's saved graphs
 * Parent: GraphingScreen
 * Children: None
 */

class SavedGraphs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphId: '',
			/** @type {number} The running number of graphs created, to give unique keys */
			graphCount: 1,
			/** @type {number[]} The current sequence of graph keys, used to track updates and deletes */
			graphKeys: [0]
		};
	}

	/**
	 * Click handler for the button that appends a new graph.
	 * Updates the graph
	 */
	 addGraph = () => {
		this.setState({
			graphCount: this.state.graphCount + 1,
			graphKeys: [...this.state.graphKeys, this.state.graphCount],
		});
	}

	/**
	 * Hides the graph with the given key to remove.
	 * @param {number} graphKey The key of the SingleGraphViewer to remove
	 */
	 hideGraph = (graphKey) => {
		this.setState({
			graphKeys: this.state.graphKeys.filter(k => k !== graphKey),
		})
	}
	
	render() {
		// Maps each key to a SingleGraphViewer component with a unique key
		const renderedGraphs = this.state.graphKeys
			.map((graphKey, index) => (
				<SingleGraphViewer 
					key={`Graph-Number-${graphKey}`}
					hideable={this.state.graphKeys.length > 1}
					hideGraph={() => this.hideGraph(graphKey)}
				/>
			));

		return (
			<React.Fragment>
				{/* Animal Card JIT */}
				<Carousel
					elements={this.props.animals.map(animalName => ({
						render: () => <AnimalCardJIT animalName={animalName} />
					}))}
					animation="fade"
					showNextPrev={this.props.animals.length > 1}
					showIndicators="true"
				/>
				<Header size="medium">Browse Saved Graphs</Header>
				{renderedGraphs}
				{/* The Button to add a new graph to the tab */}
				<Button
					size="big"
					color="green"
					content="Add Graph to Compare"
					icon="plus"
					onClick={this.addGraph}
					fluid
				/>
			</React.Fragment>
		);
	}
};

SavedGraphs.propTypes = {
	// A list of the student's saved graphs
	savedGraphs: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (state, ownProps) => ({
	savedGraphs: state.planetGame.player.savedGraphs,
	animals: state.planetGame.player.animals
});

export default connect(mapStateToProps)(SavedGraphs);
