import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import PlayerDataSelect from './SendData';
import RetrieveData from './RetrieveData';
import { selectColor } from '../../constants';

/**
 * The screen where players can interact with other players by sending and receiving data from sensors.
 * Parent: PlanetPlayerGameScreen
 * Children: None
 */

class PlayerInteractionMenu extends Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		const panes = [
			{
				menuItem: 'Send Data',
				render: () => (
					<Tab.Pane>
						<PlayerDataSelect />
					</Tab.Pane>
				)
			},

			{
				menuItem: 'Receive Data',
				render: () => (
					<Tab.Pane>
						<RetrieveData />
					</Tab.Pane>
				)
			}
		];

		return (
			<>
				<Tab panes={panes} menu={{ color: selectColor, secondary: true, pointing: true }} />
			</>
		);
	}
}

export default PlayerInteractionMenu;
