import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHAPE_IMG, PLAYER_GROUPS } from '../constants';


import { Header, Card, Image, Dimmer, Tab, Modal, Button, Container } from 'semantic-ui-react';
import QrReader from 'react-qr-reader';
import Writerqr from '../../core/components/Writerqr';


/**
 * The main player page showing their role(shape) information
 * 
 * Parent: InfoPlayerGameScreen
 * Children: None
 * 
 */

class InfoSharingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayScanned: false,
            scanId: "none",
        }
    }

    handleScan = scanId => {
        if(scanId !== null){
            this.setState({
                displayScanned: true,
                scanId: scanId
            })
        }
        
		console.log(`Scanned: ${scanId}`);
	};

	handleError = error => console.error(error);

    render() {
        const {shape, shownInfo} = this.props;

        const panes = [
			{
				menuItem: 'Scan',
				render: () => (
					<QrReader
                        onScan={this.handleScan}
                        onError={this.handleError}
                        facingMode="environment"
                        constraints={{ aspectRatio: 1, facingMode: { ideal: 'environment' }, delay: 500 }}
                    />
				)
			},
		
			{
				menuItem: 'Qr',
				render: () => (
					<div style={{ width: '100%', height: '100%', margin: 'auto' }}> 
                        <Header> Your Qr Code</Header>
                        <Writerqr value={shownInfo.toString()} size={390} level={'Q'} includeMargin={true} />
                    </div>
				)
			}
		];

        const qr = (
			<div className="qr-container">
				<Tab panes={panes} menu={{color:'grey', inverted: true}} />
			</div>
		)

        return (
            <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
                {qr}


                

                <Modal
					dimmer="blurring"
					size="tiny"
					open={this.state.displayScanned === true}
					
				>
					<Modal.Header>Scanned Info</Modal.Header>
					<Modal.Content
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{this.state.scanId}
					</Modal.Content>
					
					
					<Modal.Actions>
						
						<Button
							onClick={() => {
								this.setState({ 
                                    displayScanned: false,
                                    scanId: "none",  
                                });
							}}
						>
							Back
						</Button>
					</Modal.Actions>
					
				</Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    shape: state.infoGame.player.shape,
    shownInfo: state.infoGame.player.shownInfo
});

export default connect(mapStateToProps)(InfoSharingScreen);