import React, { Component } from 'react';
import { Grid, Header, Icon, Dimmer, Card, Button } from 'semantic-ui-react';
import ScrollList from './ScrollList';
import SingleButtonDialog from './SingleButtonDialog';

// Description: displays the player icon and number of this.props.filter players
// Parent: TocGameInformation, VirusteacherMonitor
// Children: ScrollList
// Props Used:
//		filter (String)
//		list (Array)
//		gameState (String)

class PlayerListPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,
			showTimeNotif: true,
		};
	}

	render() {
		var totalTimeLeft = 0
		var itemList = this.props.list;


		if (this.props.filter === 'time') {
			itemList.sort((a, b) => {return b.timeLeft - a.timeLeft });
			itemList.forEach((item, index) => {
				totalTimeLeft += item.timeLeft;
			})
		}
		
		totalTimeLeft = Math.round((totalTimeLeft / itemList.length) * 10 ) / 10;
		if (totalTimeLeft <= 2 && !this.state.showTimeNotif && this.props.gameState === 'paused') {
			this.setState({ showTimeNotif: true });
		}
		
		const header = this.props.filter === 'time' ?
			'Average season time left: ' + totalTimeLeft :
			this.props.list.length +
			' ' +
			this.props.filter +
			' player' +
			(this.props.list.length !== 1 ? 's' : '');

		return (
			<div>
				<Grid>
					<Grid.Column className="no-padding-col" width={1}>
						<Icon name={this.props.filter === "time" ? "clock" : "user"} />
					</Grid.Column>

					<Grid.Column
						className="no-padding-col"
						width={13}
						mobile={11}
						textAlign="left"
					>
						{header}
					</Grid.Column>

					<Grid.Column
						className="no-padding-col"
						width={1}
						style={{ alignSelf: 'center' }}
					>
						<Icon
							name="info circle"
							style={{ cursor: 'pointer' }}
							onClick={() => this.setState({ showPopup: true })}
						/>
					</Grid.Column>
				</Grid>

				{this.state.showPopup && (
					<div>
						<Dimmer active page>
							<Card style={{ width: '320px' }}>
								<Card.Content textAlign="left">
									<Header as="h3" style={{ marginTop: '0.5em' }}>
										<span style={{ fontWeight: 'normal' }}>{header}</span>
									</Header>
									<ScrollList list={itemList} showActive={true} filter={this.props.filter} />
									<br />
									<Button
										content="Close"
										onClick={() => this.setState({ showPopup: false })}
										fluid
									/>
								</Card.Content>
							</Card>
						</Dimmer>
					</div>
				)}
				{(this.props.list.length > 0 && this.state.showTimeNotif && totalTimeLeft === 0 
					&& this.props.filter === 'time' && this.props.gameState === 'running') && (
                    <SingleButtonDialog
                        description={
							'Most players have expended their allotted fishing time for this season. Consider pausing ' +
							'the game soon so you can start the next season.'
                        }
                        buttonText="Close"
                        buttonAction={() =>
                          this.setState({ showTimeNotif: false})
                      }
                    />
                )}
			</div>
		);
	}
}

export default PlayerListPopup;
