import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Tab } from 'semantic-ui-react';
import RegionSensors from './RegionSensors';
import RegionAnimals from './RegionAnimals';

import { selectColor } from '../../constants';

/**
 * The screen where players can add/remove animals or add/place sensors
 * Parent: PlanetPlayerGameScreen
 * Children: None
 */

class PlanetInteractionMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0
		};
	}

	onTabChange = (event, { activeIndex }) => this.setState({ activeIndex: activeIndex });

	render() {
		const panes = Array(this.props.planet.numRegions)
			.fill({})
			.map((obj, index) => ({
				menuItem: {content: `${this.state.activeIndex === index ? 'Region ' : ' '}${index + 1}`, color:selectColor},
				render: () => (
					<div>
						<br></br>
						<Tab
							menu={{ color: selectColor, secondary: true, pointing: true}}
							widths={2}
							panes={[
								{
									menuItem: {content: 'Sensors', inverted: true, },
									render: () => (
										<RegionSensors planetIndex={this.props.planetIndex} regionIndex={index} />
									)
								},
								{
									menuItem: 'Animals',
									render: () => (
										<RegionAnimals planetIndex={this.props.planetIndex} regionIndex={index} />
									)
								}
							]}
						/>
					</div>
				)
			}));

		return (
			
			<div style={{ height: '100%', margin: '10px' }}>
				<Header content = {"You are on planet " + this.props.planet.name}/>
				<Tab panes={panes} onTabChange={this.onTabChange} />
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.contracts.animals,
	player: state.planetGame.player,
	planet: state.planetGame.world.planets[ownProps.planetIndex]
});

export default connect(mapStateToProps)(PlanetInteractionMenu);
