import React, { Component } from "react";
import { Label, Message, Header, Grid } from "semantic-ui-react";
import SingleButtonDialog from "../../../core/components/SingleButtonDialog";
import YesNoDialog from "../../../core/components/YesNoDialog";
import SliderWithField from "../../../core/components/SliderWithField";
import PatientZeroPopup from "./PatientZeroPopup";
import "../styles.scss";
//import TeacherSimulateMeetings from './TeacherSimulateMeetings';
import PatientZeroSelector from "./PatientZeroSelector";
import StudentData from "./StudentData";
import VirusTeacherButtons from "./VirusTeacherButtons";
import VirusTeacherRestart from "./VirusTeacherRestart";
import VirusTeacherMonitor from "./VirusTeacherMonitor";
import TeacherControlScreen from "../../../core/components/TeacherControlScreen";
import "../styles.scss";
import { Checkbox } from "semantic-ui-react";

// Description: shows the game screen with controls for the parameters and real time information about players
// Parent: TeacherContainer
// Children: PatientZeroSelector, StudentData, VirusTeacherButtons, VirusTeacherMonitor, TeacherControlScreen, VirusTeacherRestart
// Props Used:
//      firebase (Object)
//      firestore (Object)
//      game (String)
//      gameCode (String)
//      gameInfo (Object)
//      history (Object)
//      meetings (String)
//      name (String)
//      players (String)
//      setShowPOError
//      setShowPlayerError

class VirusTeacherGameScreen extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      immuneProbability: props.gameInfo.gameParams.immuneProbability,
      infectionRate: props.gameInfo.gameParams.infectionRate,
      averageLatency: props.gameInfo.gameParams.averageLatency,
      patientZero: props.gameInfo.gameParams.patientZero,
      hasImmuneCarriers: props.gameInfo.gameParams.hasImmuneCarriers || false,
      showPlayerError: false,
      showP0Error: false,
      rejoinedPlayers: [],
      sickPlayers: [],
      immunePlayers: [],
      totalPlayers: [],
      p0Absent: false,
      showP0Absent: false,
      p0AbsentShown: false,
      meetLength: 0,
      checked: false,
      resetting: false,
      showLogoutConfirmation: false,
      showHomeConfirmation: false
    };
  }

  removeRejoiningPlayer = id => {
    this.setState({
      rejoinedPlayers: this.state.rejoinedPlayers.filter(item => item.id !== id)
    });
  };

  //Remove the rejoin person and set approve to true. Hence, letting them in.
  approve = id => {
    this.removeRejoiningPlayer(id);
    console.log(this.state.rejoinedPlayers);
    this.props.firestore.update(
      {
        collection: this.props.players,
        doc: id
      },
      {
        rejoin: false,
        approve: true
      }
    );
  };

  //Deny the rejoin, set the player denied to true, and everything else false
  reject = id => {
    this.removeRejoiningPlayer(id);
    console.log(this.state.rejoinedPlayers);
    this.props.firestore.update(
      {
        collection: this.props.players,
        doc: id
      },
      {
        rejoin: false,
        approve: false,
        denied: true
      }
    );
  };

  setP0AbsentFalse = () => {
    this.setState({ p0Absent: false, showP0Absent: false });
  };

  setShowP0AbsentFalse = () => {
    this.setState({ showP0Absent: false });
  };

  setP0AbsentState = (p0Absent, showP0Absent) => {
    this.setState({
      p0Absent,
      showP0Absent
    });
  };

  setP0AbsentShownTrue = () => {
    this.setState({ p0AbsentShown: true });
  };

  setMeetLength = meetLength => {
    this.setState({ meetLength });
  };

  resetMeetLength = () => {
    this.setState({ meetLength: 0 });
  };

  setResetting = resetting => {
    this.setState({ resetting: resetting });
  };

  setTotals = (rejoinedPlayers, sickPlayers, immunePlayers, totalPlayers) => {
    this.setState({
      rejoinedPlayers,
      sickPlayers,
      immunePlayers,
      totalPlayers
    });
  };

  setPatientZero = patientZero => {
    this.setState({
      patientZero
    });
  };

  setShowPlayerError = () => {
    this.setState({ showPlayerError: true });
  };

  setShowP0Error = () => {
    this.setState({ showP0Error: true });
  };

  setP0IdleFalse = () => {
    this.setState({ p0Idle: false });
  };

  render() {
    const { name, game, gameInfo, gameCode, firestore } = this.props;

    const p0Disabled = gameInfo.p0Restart;
    const p0 = this.state.totalPlayers.find(
      item => item.id === this.state.patientZero
    );
    const disabled = gameInfo.gameState !== "initializing";

    const gameScreen = (
      <div style={{ height: "100%" }}>
        {/* Dialog Popups*/}
        {this.state.showPlayerError && (
          <SingleButtonDialog
            description="You need at least one player before starting the game."
            buttonText="Close"
            buttonAction={() => this.setState({ showPlayerError: false })}
          />
        )}

        {this.state.showP0Error && (
          <SingleButtonDialog
            description="You need to choose a patient zero before starting the game."
            buttonText="Close"
            buttonAction={() => this.setState({ showP0Error: false })}
          />
        )}

        {/* {this.state.rejoinedPlayers.length > 0 && (
          <YesNoDialog
            header="Rejoin Request"
            description={
              this.state.rejoinedPlayers[0].name +
              " would like to rejoin the game. Would you like to accept or reject?"
            }
            yesText="Accept"
            yesAction={() => this.approve(this.state.rejoinedPlayers[0].id)}
            noText="Reject"
            noAction={() => this.reject(this.state.rejoinedPlayers[0].id)}
          />
        )} */}

        {this.state.resetting && (
          <YesNoDialog
            header="Please Wait for Game to Reset"
            description={
              <div>
                Please Wait. This will disappear when the game is ready.
              </div>
            }
          />
        )}

        {this.state.showLogoutConfirmation && (
          <YesNoDialog
            header={"Logout Confirmation"}
            description={"Are you sure you wish to logout?"}
            yesText={"Yes"}
            yesAction={() => {
              this.setState({ showLogoutConfirmation: false });
              this.props.firebase.logout();
              this.props.history.push("/");
            }}
            noText="Close"
            noAction={() => {
              this.setState({ showLogoutConfirmation: false });
            }}
          />
        )}

        {this.state.showHomeConfirmation && (
          <YesNoDialog
            header={"Home Confirmation"}
            description={"Are you sure you wish to return to the Homepage?"}
            yesText={"Yes"}
            yesAction={() => {
              this.setState({ showHomeConfirmation: false });
              this.props.history.push("/");
            }}
            noText="Close"
            noAction={() => {
              this.setState({ showHomeConfirmation: false });
            }}
          />
        )}

        {/* Game Screen Container*/}
        <Message
          style={{
            marginLeft: "0.7em",
            marginRight: "0.7em",
            height: "100%",
            overflow: "auto"
          }}
        >
          <br />
          <br />
          <Label attached="top" as="h3" color="black" size="big">
            Game Screen
          </Label>

          <Grid stackable style={{ height: "70vh" }}>
            <Grid.Row columns={2}>
              {/* Player Statuses*/}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Statuses
                  </Header>

                  <br />
                  <VirusTeacherMonitor
                    firestore={this.props.firestore}
                    players={this.props.players}
                    gameCode={this.props.gameCode}
                    patientZero={this.state.patientZero}
                    p0Absent={this.state.p0Absent}
                    showP0Absent={this.state.showP0Absent}
                    gameInfo={this.props.gameInfo}
                    meetings={this.props.meetings}
                    setTotals={this.setTotals}
                    setPatientZero={this.setPatientZero}
                    setP0AbsentState={this.setP0AbsentState}
                    setP0AbsentShownTrue={this.setP0AbsentShownTrue}
                    setMeetLength={this.setMeetLength}
                  />
                </Message>
              </Grid.Column>

              {/* Patient Zero Info*/}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Patient Zero
                  </Header>

                  <br />
                  <PatientZeroSelector
                    list={this.state.totalPlayers}
                    immunePlayers={this.state.immunePlayers}
                    value={this.state.patientZero}
                    onChange={value => this.setState({ patientZero: value })}
                    disabled={p0Disabled ? false : disabled}
                    p0Absent={this.state.p0Absent}
                    errorAction={() => this.setState({ showP0Absent: true })}
                  />

                  <PatientZeroPopup
                    totalPlayers={this.state.totalPlayers}
                    value={this.state.patientZero}
                    status="Online"
                  />

                  <PatientZeroPopup
                    totalPlayers={this.state.sickPlayers}
                    value={this.state.patientZero}
                    status="Sick"
                  />

                  <PatientZeroPopup
                    value={this.state.meetLength}
                    status="Players Met"
                  />
                </Message>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              {/* Game Property Sliders */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Game Properties
                  </Header>

                  <br />
                  <SliderWithField
                    field="Chance of Immunity"
                    label="%"
                    min={0}
                    max={100}
                    value={this.state.immuneProbability}
                    onChange={value =>
                      this.setState({ immuneProbability: value })
                    }
                    leftText="0%"
                    rightText="100%"
                    disabled={p0Disabled ? false : disabled}
                  />

                  <SliderWithField
                    field="Chance of Infection"
                    label="%"
                    min={0}
                    max={100}
                    value={this.state.infectionRate}
                    onChange={value => this.setState({ infectionRate: value })}
                    leftText="0%"
                    rightText="100%"
                    disabled={p0Disabled ? false : disabled}
                  />

                  <SliderWithField
                    field="Incubation Time"
                    label="s"
                    min={30}
                    max={600}
                    value={this.state.averageLatency}
                    onChange={value => this.setState({ averageLatency: value })}
                    leftText="30s"
                    rightText="600s"
                    disabled={p0Disabled ? false : disabled}
                  />
                  <Checkbox
                    toggle
                    disabled={p0Disabled ? false : disabled}
                    label="Allow Immune Carriers"
                    checked={this.state.hasImmuneCarriers}
                    onChange={(event, data) => {
                      console.log(this.state.hasImmuneCarriers)
                      const hasImmuneCarriers = data.checked;
                      this.setState({ hasImmuneCarriers });
                    }} />
                </Message>
              </Grid.Column>

              {/* Infection Rate Graph */}
              <Grid.Column>
                <Message
                  style={{
                    marginLeft: "0.7em",
                    marginRight: "0.7em",
                    marginBottom: "1.0em"
                  }}
                >
                  <Header textAlign="left" as="h3">
                    Infection Rate Graph
                  </Header>

                  <br />
                  {gameInfo.gameState !== "initializing" && !p0Disabled && (
                    <StudentData
                      game={game}
                      gameCode={gameCode}
                      firestore={firestore}
                      numSickPlayers={this.state.sickPlayers.length}
                    />
                  )}
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Message>
      </div>
    );

    return (
      <TeacherControlScreen
        gameCode={gameCode}
        gameScreen={gameScreen}
        gameState={gameInfo.gameState}
        gameType={"Virus Game"}
        rejoinedPlayers={this.state.rejoinedPlayers}
        approveCallback={playerId => {
          this.approve(playerId);
        }}
        rejectCallback={playerId => {
          this.reject(playerId);
        }}
        handleDoc={() => {
          window.open(
            "https://education.mit.edu/wp-content/uploads/2019/07/pSimsVirusGettingStarted.pdf",
            "_blank"
          );
        }}
        handleHome={() => {
          this.setState({ showHomeConfirmation: true });
        }}
        handleLogout={() => {
          this.setState({ showLogoutConfirmation: true });
        }}
        handleStateButton={
          <VirusTeacherButtons
            name={name}
            gameInfo={gameInfo}
            gameCode={this.props.gameCode}
            game={this.props.game}
            firestore={this.props.firestore}
            patientZero={this.state.patientZero}
            setShowP0Error={this.setShowP0Error}
            setShowPlayerError={this.setShowPlayerError}
            immuneProbability={this.state.immuneProbability}
            infectionRate={this.state.infectionRate}
            averageLatency={this.state.averageLatency}
            hasImmuneCarriers={this.state.hasImmuneCarriers}
            checked={this.state.checked}
            players={this.props.players}
          />
        }
        handleRestartButton={
          <VirusTeacherRestart
            firestore={this.props.firestore}
            game={this.props.game}
            gameCode={this.props.gameCode}
            gameInfo={this.props.gameInfo}
            players={this.props.players}
            meetings={this.props.meetings}
            p0={p0}
            p0Idle={this.state.p0Idle}
            setP0AbsentFalse={this.setP0AbsentFalse}
            showP0Absent={this.state.showP0Absent}
            setShowP0AbsentFalse={this.setShowP0AbsentFalse}
            resetMeetLength={this.resetMeetLength}
            setResetting={this.setResetting}
          />
        }
        totalPlayers={this.state.totalPlayers}
      />
    );
  }
}

export default VirusTeacherGameScreen;
