import React from 'react';
import QRCode from 'qrcode.react';

// Description: renders qr code with id passed in props
// Parent: QrComponents
// Children: None
// Props Used:
//      includeMargin (Bool)
//      myId (String)
//      size (Int)

const Writerqr = props => <QRCode style={{height: '100%'}} value={props.value} size={props.size || 128} level={'Q'} includemargin={(props.includemargin || false).toString()}/>;

export default Writerqr;
