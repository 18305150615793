import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import BasicGreyPage from '../components/BasicGreyPage';
import { connect } from 'react-redux';

// Displays not found page error and directs the user back to the home/choose screen
// Top level component
// No children but forwards to Choose

const NotFoundPage = (props) => {
  const center = (
    <div>
      <Header as="h2">Page not found.</Header>
      <Button onClick={() => props.history.push('/')} content="Back to Home" negative />
    </div>
  );

  return <BasicGreyPage center={center} />
}
    
export default connect()(NotFoundPage);