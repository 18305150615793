import React, { Component } from 'react';
import Slider from 'rc-slider';
import { Header, Grid, Input } from 'semantic-ui-react';
import 'rc-slider/assets/index.css';
import '../sliderstyles.scss';

// slider with a label and state controlled field
class SliderWithField extends Component {
    // handles change of input field
    onTextChange = (evt, data) => {
        this.props.onChange(this.limitSlider(data.value));
    }

    // handles change of slider
    onSliderChange = (value) => {
        this.props.onChange(this.limitSlider(value));
    }

    // limits the slider value according to its min and max
    limitSlider = (value) => {
        return Math.min(Math.max(value, this.props.min), this.props.max);
    }

    render() {
        const {
            min, max, field, label, leftText, rightText, value, disabled, step=5
        } = this.props;

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={9} mobile={8} textAlign="left" style={{alignSelf: 'center'}} >
                            <Header as="h4" style={{ fontWeight: 'normal' }}>{field}</Header>
                        </Grid.Column>

                        <Grid.Column width={5} mobile={7}>
                            <Input 
                                fluid 
                                type="number" 
                                value={value} 
                                onChange={this.onTextChange} 
                                labelPosition="right" 
                                label={label} 
                                disabled={disabled}
                                readOnly={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                
                    {!disabled && 
                        <Grid.Row className="slider-row">
                            <Grid.Column width={1}>
                                <Header as="h5" style={{fontWeight: 'normal'}}>{leftText}</Header>
                            </Grid.Column>
                            <Grid.Column width={13} mobile={12} style={{paddingRight: '0'}}>
                                <Slider min={min} max={max} value={value} step={step} onChange={this.onSliderChange} />
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Header as="h5" style={{ fontWeight: 'normal' }}>{rightText}</Header>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </div>
        )
    }
}

export default SliderWithField;