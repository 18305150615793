import { createSlice } from '@reduxjs/toolkit';

/*
Structure of the world slice of the global state (Redux)
Used for player

state: {
	currentMonth: number,
	gameStatus: string,
	qrOn: boolean,
	players: {
		name: string,
		id: string
	}[],
	planets: {
		name: string,
		gasComp: [key: string]: number, // key is gas name
		numRegions: number,
		regions: {
			animals: [key: string]: { // key is animal name
				amount: number (decimal, not integer),
				suitability: number, 
				lastUpdated: number
			}, 
			rain: number[], 
			temp: number[]
		}[],
		style: {
			x: number,
			y: number,
			size: number,
			color: string
		}
	}[]
}
*/

const slice = createSlice({
	name: 'world',
	initialState: {
		currentMonth: 0,
		gameStatus: undefined,
		qrOn: false,
		sendDataOn: true,
		storeOn: true,
		players: [],
		planets: []
	},
	reducers: {
		setMonth: (state, action) => {
			// payload: number
			console.log(`[world/setMonth] setting current month to ${action.payload}`);
			state.currentMonth = action.payload;
		},

		setGameStatus: (state, action) => {
			// payload: any
			console.log(`[world/setGameStatus] setting game status to ${action.payload}`);
			state.gameStatus = action.payload;
		},

		setQr: (state, action) => {
			// payload: boolean
			console.log(`[world/setQr] ${action.payload ? 'enabling' : 'disabling'} QR navigation`);
			state.qrOn = action.payload;
		},

		setStore: (state, action) => {
			// payload: boolean
			console.log(`[world/setStore] ${action.payload ? 'enabling' : 'disabling'} store`);
			state.storeOn = action.payload;
		},

		setSendData: (state, action) => {
			// payload: boolean
			console.log(`[world/setSendData] ${action.payload ? 'enabling' : 'disabling'} sendData`);
			state.sendDataOn = action.payload;
		},

		setPlayers: (state, action) => {
			/* payload: {
				name: string,
				id: string
			}[] */
			console.log(`[world/setPlayers] setting players to ${action.payload}`);
			state.players = action.payload
		},

		setPlanets: (state, action) => {
			/* payload: {
				name: string,
				gasComp: [key: string]: number, 
				numRegions: number,
				regions: {
					animals: [key: string]: {
						amount: number (decimal, not integer),	
						suitability: number, 
						lastUpdated: number
					}, 
					rain: number[], 
					temp: number[]
				}[],
				style: {
					x: number,
					y: number,
					size: number,
					color: string
				}
			}[] */
			console.log('[world/setPlanets] setting planets', action.payload);
			state.planets = action.payload;
		},

		addAnimal: (state, action) => {
			/* payload: {
				planet: number,
				region: number,
				animalName: string,
				animalNums: {
					amount: number, 
					suitability: number,
					lastUpdated: number
				}
			} */
			const { planet, region, animalName, animalNums } = action.payload;
			const regionObj = state.planets[planet].regions[region];
			console.log(`[world/addAnimal] adding animal ${animalName}`);
			regionObj.animals[animalName] = animalNums;
		},

		updateAnimal: (state, action) => {
			/* payload: {
				planet: number, 
				region: number, 
				animalName: string, 
				change: number
			} */
			const { planet, region, animalName, change } = action.payload;
			console.log(
				`[world/updateAnimal] changing ${animalName}'s population by ${change} on planet ${state.planets[planet].name} region ${region}`
			);

			/* how animals are stored:
				1) amount - amount on planet/region when placed
				2) suitability - the percentage score for how habitable the planet and region is for the animal
				3) lastUpdated - month the population was last updated
			*/
			const animal = state.planets[planet].regions[region].animals[animalName];
			animal.amount += change;
			animal.lastUpdated = state.currentMonth;
		},

		removeAnimal: (state, action) => {
			// payload: string (animal name)
			console.log(`[world/removeAnimal] removing all traces of $${action.payload}`);
			for (const planet of state.planets)
				for (const region of planet.regions) delete region.animals[action.payload];
		}
	}
});

export const worldActions = slice.actions;

export default slice.reducer;
