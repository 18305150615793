import React from 'react';
import Chart from 'react-apexcharts';

// Description: Generates a bar graph from provided data.
//
const BarGraph = props => {
	const { title, subtitle, xtitle, ytitle, categories, series } = props;

	return (
		<Chart
			options={{
                chart: {
                    id: 'bar',
                    zoom: {
                        enabled: true
                    }
                },
                title: {
					text: title
				},
                subtitle: {
					text: subtitle
				},
                xaxis: {
                    categories: categories,
                    title: {
                        text: xtitle
                    },
                    tickPlacement: 'on'
                },
                yaxis: {
                    title: {
                        text: ytitle
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                    },
                },
                dataLabels: {
                    enabled: false
                },
			}}
			series={series}
			type="bar"
			width={'100%'}
			height={'100%'}
		/>
	);
}

export default BarGraph;
