import React, { Component } from 'react';
import { Header, Button, Progress } from 'semantic-ui-react';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import Readerqr from '../../../core/components/Readerqr';

// Description: holds the qr scanner and code components
// Parent: TocPlayer
// Children: Readerqr
// Props Used:
//      boat (Object)
//      currentConfiguration 
//      db (Object)
//      doneLoading (Function)
//      fishCaught (Int)
//      game (String)
//      gameCode (String)
//      generationPause (Bool)
//      players (String)
//      timeLeft (Int)
//      uid (String)

class QrComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pauseScan: false,
            camera: false,
            readerLoaded: false,
            errorQR: false,
            showScienceResult: false,
            showScanDialogue: false,
            tickingTime: 5,
            fishGain: 0,
            researchTime: 10,
            researchMode: false
        };
        this.meetingTimer = null;
        this.pauseTime = 5;
        this.scanEnergyGain = 5;
    };

    closeScanDialog = () => {
        this.setState({ showScanDialogue: false });
    };

    closeScienceDialog = () => {
        this.setState({ showScienceResult: false });
    };

    closeQRDialog = () => {
        this.setState({ errorQR: false });
    };

    timer = () => {
        this.meetingTimer = setTimeout(() => {
            this.setState({ tickingTime: this.state.tickingTime - 1 });
            if (this.state.tickingTime <= 0) {
                this.setState({
                    tickingTime: this.pauseTime,
                    pauseScan: false
                });
            } else {
                this.timer();
            }
        }, 1000);
    };

    openCamera = () => {
        this.setState({
            camera: true,
            readerLoaded: false,
        });
        setTimeout(() => {
            this.setState({ readerLoaded: true });
        }, 2000);
    };

    defaultScreen = () => {
        this.setState({ camera: false });
    };


    handleScan = stationId => {
        if (stationId && !this.state.pauseScan) {

            this.defaultScreen();

            const stationRef = this.props.db
                .collection(this.props.game)
                .doc(this.props.gameCode);

            const playerRef = this.props.db
                .collection(this.props.players)
                .doc(this.props.uid);

            this.props.db.runTransaction(transaction => {
                return transaction.get(stationRef).then(doc => {
                    const station = doc.data().configurations[
                        this.props.currentConfiguration
                    ].stations[stationId];



                    if (station != null) {

                        let currentTime = new Date().getTime();
                        const numFish = station.amount;
                        let fishTaken = 5;
                        let speed = 2;
                        if (this.props.boat.size === "medium") {
                          fishTaken = 10;
                          speed = 3;
                        } else if (this.props.boat.size === "large") {
                          fishTaken = 20;
                          speed = 4;
                        }
                        if (this.props.boat.trawler) {
                          speed *= 1.5;
                        }
                        const fisherySize = 100;
                        let time = Math.round(fishTaken/(speed * numFish / fisherySize));
                        if (time > this.props.timeLeft) {
                            fishTaken = Math.floor(this.props.timeLeft / time * fishTaken);
                            time = this.props.timeLeft;
                        }

                        if (this.state.researchMode) {
                          fishTaken = 0;
                          this.setState({researchTime: time, showScienceResult: true});
                          time = 1;
                        }
                        if (fishTaken > numFish) {
                          fishTaken = numFish
                        }

                        let newAmount = station.amount - fishTaken;

                        this.setState({ pauseScan: true, fishGain: fishTaken });
                        this.timer();

                        if (!this.state.researchMode) {
                    
                            this.setState({ showScanDialogue: true});
                        
                          }

                        transaction.update(playerRef, {
                            fishCaught: this.props.fishCaught + fishTaken,
                            lastStation: stationId,
                            timeLeft: this.props.timeLeft - time
                        });


                        transaction.update(stationRef, {
                            [`configurations.${
                                this.props.currentConfiguration
                            }.stations.${stationId}`]: {
                                name: station.name,
                                rate: station.rate,
                                limit: station.limit,
                                amount: newAmount,
                                risk: station.risk,
                                lastFeedTime: currentTime,
                            },
                        });
                    } else {
                        // Prevents Firebase error when scanning player QR code instead of station QR code
                        transaction.update(stationRef, {});
                        this.setState({ errorQR: true });
                    }
                });
            });
        }
    };

    render() {
        return (
            <div>
                {this.state.errorQR && (
                    <SingleButtonDialog
                        description="This QR Code is not part of the game!"
                        buttonText="OK"
                        buttonAction={this.closeQRDialog}
                        width="420px"
                    />
                )}

                {this.state.showScienceResult && (
                    <SingleButtonDialog
                        description={
                            <div>
                                {'It would have taken ' +
                                this.state.researchTime +
                                ' seconds to fish here with your boat'}
                                <br/>
                            </div>
                        }
                        buttonText="Close"
                        buttonAction={this.closeScienceDialog}
                        width="420px"
                    />
                )}

                {this.state.showScanDialogue && (
                    <SingleButtonDialog
                        description={
                            <div>
                                {'You caught ' +
                                this.state.fishGain +
                                ' fish :)'}
                                <br/>
                            </div>
                        }
                        buttonText="Close"
                        buttonAction={this.closeScanDialog}
                        width="420px"
                    />
                )}


                {!this.props.generationPause ? (
                    <div>
                        <Button.Group fluid widths={2}>
                            <Button
                                onClick={
                                    this.state.camera && this.state.readerLoaded
                                        ? this.defaultScreen
                                        : null
                                }
                                size="medium"
                                color={!this.state.camera ? 'green' : 'grey'}
                                className={!this.state.camera ? 'active' : ''}
                                content="Default"
                                ref={button => (this.buttonElement = button)}
                            />
                            <Button
                                onClick={
                                    !this.state.camera && !this.state.pauseScan
                                        ? this.openCamera
                                        : null
                                }
                                size="medium"
                                icon="camera"
                                color={this.state.camera ? 'green' : 'grey'}
                                className={this.state.camera ? 'active' : ''}
                                content="Scan"
                            />
                        </Button.Group>

                        {this.state.camera ? (
                            <div>
                              <Readerqr
                                  handleScan={this.handleScan}
                                  doneLoading={this.props.doneLoading}
                                  handleError={this.props.doneLoading}
                              />
                              <br />
                              {this.props.boat.research ? (
                                <Button.Group>
                                  <Button
                                      onClick={() =>
                                        this.setState({researchMode: true})
                                      }
                                      size="medium"
                                      color={!this.state.researchMode ? 'grey' : 'blue'}
                                      content="Research"
                                  />
                                  <Button
                                    onClick={() =>
                                      this.setState({researchMode: false})
                                    }
                                    size="medium"
                                    color={this.state.researchMode ? 'grey' : 'blue'}
                                    content="Fish"
                                  />
                                </Button.Group>
                              ) : ""
                              }
                            </div>
                        ) : (
                            <div>
                                <Progress
                                    percent={
                                        (this.state.tickingTime /
                                            this.pauseTime) *
                                        100
                                    }
                                    size="big"
                                    color="blue"

                                />
                                <div>{this.state.tickingTime}</div>
                                {this.state.pauseScan ? (
                                    <Header> Scan Paused! </Header>
                                ) : (
                                    <Header> You can scan :) </Header>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <Header> Scan Locked! </Header>
                )}
            </div>
        );
    }
}

export default QrComponents;
