import React, { Component } from 'react';
import firebase from 'firebase';
import TeacherRestart from '../../../core/components/TeacherRestart.js';
import YesNoDialog from '../../../core/components/YesNoDialog';

const { firestore } = firebase;
const db = firestore();

// Description: Handles logic for replaying, reinitializing, and clearing the game
// Parent: VirusTeacherGameScreen
// Children: TeacherRestart
// Props Used:
//      firestore
//      game
//      gameCode
//      gameInfo
//      players
//      meetings
//      p0
//      setP0AbsentFalse
//      showP0Absent
//      setShowP0AbsentFalse
//      resetMeetLength
//      setResetting
//      p0Idle

class VirusTeacherRestart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // handles pausing the game upon button press
  handlePause = () =>
  // this.clearTimer();

    this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: 'paused',
          lastPause: this.props.firestore.FieldValue.serverTimestamp()
        }
      )
      .then((response) => {
        this.props.firestore.update(
          {
            collection: 'games',
            doc: this.props.gameCode
          },
          {
            gameState: 'paused'
          }
        );
      });

  handleInit = () => this.props.firestore
    .update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        gameState: 'initializing',
        lastPause: this.props.firestore.FieldValue.serverTimestamp()
      }
    )
    .then((response) => {
      this.props.firestore.update(
        {
          collection: 'games',
          doc: this.props.gameCode
        },
        {
          gameState: 'initializing'
        }
      );
    });

  // Clears everything and allows for new class to play
  handleReset = async () => {
    // this.clearTimer();
    this.props.setResetting(true);
    const { gameCode, gameInfo } = this.props;
    const { gameParams } = gameInfo;
    this.props.setP0AbsentFalse();

    let batch = db.batch();
    let batchSize = 0;

    /**
     * add to batch size counter and commits and reinitializes batch if
     * it is too large
     *
     * @param {*} numChangesAddedToBatch in accordance with Firestore batch documentation
     * this number should not be greater than 500
     */
    const addAndCheckBatch = async (numChangesAddedToBatch) => {
      this.props.setResetting(true);
      if (batchSize + numChangesAddedToBatch >= 500) {
        batch.commit();
        batch = db.batch();
        batchSize = 0;
      }
      batchSize += numChangesAddedToBatch;
    };

    console.log('updating');

    batch.update(db.collection('vgames').doc(gameCode), {
      gameState: 'initializing',
      gameParams: { ...gameParams, patientZero: '' },
      lastPause: this.props.firestore.FieldValue.serverTimestamp(),
      data: []
    });

    batch.update(db.collection('games').doc(gameCode), {
      gameState: 'initializing',
      players: []
    });

    await addAndCheckBatch(2);

    await db
      .collection('vplayers')
      .where('currentGameId', '==', gameCode)
      .get()
      .then(async (querySnapshot) => {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            batch.delete(db.collection('vplayers').doc(doc.id));
            batch.delete(db.collection('status').doc(doc.id));
            await addAndCheckBatch(2);
          })
        );
      });

    await db
      .collection('vgamemeetings')
      .where('gameId', '==', gameCode)
      .get()
      .then(async (querySnapshot) => {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            batch.delete(db.collection('vgamemeetings').doc(doc.id));
            await addAndCheckBatch(1);
          })
        );
      });

    await batch.commit();
    this.props.setResetting(false);
  };

  handleReassignRestart = (reassign = true, newP0 = true) => {
    // true is Reassign function, false is Restart function
    // Reassign =  Clears meetings and allows for paremeter reset
    // Restart = Clears meetings and play again
    //     For Restart - newP0 = true reassigns P0, false leaves p0 alone
    const promise1 = reassign === true ? this.handleInit() : null;
    const promise2 = this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        clock: this.props.firestore.FieldValue.serverTimestamp(),
        pausedTime: 0,
        data: [0],
        gameRestart: true,
        p0Restart: newP0,
        gameState: reassign ? 'initializing' : 'paused'
      }
    );

    const promise3 = new Promise((resolve) => {
      // this.setState({ resetting: true }); //STATE - need to pass up
      this.props.setResetting(true);
      this.props.firestore
        .get({
          collection: this.props.players,
          where: ['currentGameId', '==', this.props.gameCode],
          orderBy: [['displayName', 'asc']]
        })
        .then((allPlayersQuery) => {
          Promise.all([
            allPlayersQuery.forEach((doc) => {
              if (!reassign && doc.data().patientZero && !newP0) {
                // reset p0 status when restart without new P0 but not reassign
                // on reassign or newp0 this is skipped since there will be a new P0
                return this.props.firestore.update(
                  {
                    collection: this.props.players,
                    doc: doc.id
                  },
                  {
                    sickState: false
                    // sickTime should be same as first round
                    // sickTime: Math.round(currentGameParams.averageLatency * getRandomInt(90, 110)
                    // / 100 * 1.5)
                  }
                );
              } else {
                const sickTime = -1;
                const sickState = false;
                return this.props.firestore.update(
                  {
                    collection: this.props.players,
                    doc: doc.id
                  },
                  {
                    patientZero: false,
                    sickTime,
                    sickState
                  }
                );
              }
            })
          ]);
        });
    });

    // need to erase all meetings here
    const promise4 = new Promise((resolve) => {
      this.props.firestore
        .get({
          collection: this.props.meetings,
          where: ['gameId', '==', this.props.gameCode],
          orderBy: [['addedAt', 'desc']]
        })
        .then((querydata) => {
          Promise.all([
            querydata.docs.forEach((doc) => this.props.firestore.delete({
              collection: this.props.meetings,
              doc: doc.id
            }))
          ]).then((result) => {
            this.props.setResetting(false);
            this.props.resetMeetLength();
            // this.setState({ resetting: false });
            // this.setState({ meetLength: 0 }); //Set State pass up
          });
        });
    });

    return Promise.all([promise1, promise2, promise3, promise4]);
  };

  handleReassign = () => {
    this.handleReassignRestart(true, true);
  };

  handleRestart = () => {
    this.handleReassignRestart(false, false);
  };

  // handleRestart = (newp0) => {
  //   this.handleReassignRestart(false, newp0);
  // };

  render() {
    return (
      <div>
        {this.props.showP0Absent && (
          <YesNoDialog
            header="Patient Zero Absent"
            description={(
              <div>
                {this.props.p0 === undefined
                  ? 'A player'
                  : `${this.props.p0.name}, the patient zero, has left the game. Please restart with a new patient zero or have them rejoin the game.`}
                <br />
                <br />
                Press the red icon next to the patient zero selector to see this message again.
              </div>
            )}
            yesText="Reassign"
            yesAction={() => {
              // this.setState({ p0Absent: false, showP0Absent: false });
              this.props.setP0AbsentFalse();
              this.handleReassign();
            }}
            noText="Close"
            noAction={() => {
              this.props.setShowP0AbsentFalse();
            }}
            // this.setState({ showP0Absent: false })}
          />
        )}

        {this.props.p0Idle && (
          <YesNoDialog
            header="Patient Zero Idle"
            description={(
              <div>
                {this.props.p0 === undefined
                  ? 'A player'
                  : `${this.props.p0.name}, the patient zero, is currently idle. You can now choose to pick a new patient zero if you need to.`}
                <br />
              </div>
            )}
            yesText="Reassign"
            yesAction={() => {
              this.props.setP0AbsentFalse();
              this.handleRestart(true);
            }}
            noText="Close"
            noAction={() => {
              // this.setState({ p0Idle: false });
              this.props.setP0IdleFalse();
              // this.clearTimer();
              // this.setTimer();
            }}
          />
        )}
        <br />
        <TeacherRestart
          rerun={this.handleRestart}
          reinitialize={this.handleReassign}
          clear={this.handleReset}
          game={this.props.game}
          gameState={this.props.gameInfo.gameState}
        />
      </div>
    );
  }
}

export default VirusTeacherRestart;
