import React from 'react';
import { Dimmer, Icon } from 'semantic-ui-react';
import '../dimloaderstyles.scss';

// circle with icon and text
const DimIcon = (props) => {
    const {
        message,
        iconName
    } = props;

    return (
        <div>
            <Dimmer active page>
                <div style={{textAlign: 'center'}}>
                    <div className="circle" >
                        <Icon className="dimicon" color="blue" name={iconName} />
                    </div>    
                    
                    <br />
                    
                    <div style={{maxWidth: '225px'}}>
                        <h1 color="white" style={{fontWeight: "normal"}}>{message}</h1>
                    </div>
                </div>
            </Dimmer>
            
        </div>
    );
}

export default DimIcon;