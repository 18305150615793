import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

// Description: displays a message notifying the player that they have un/successfully met another player
// Parent: VirusPlayer
// Children: None
// Props Used: 
//      clearMessage (Function)
//      name (String)
//      success (Boolean)

class MeetingNotification extends Component {
  handleDismiss = () => {
    this.props.clearMessage();
  };
  
  render() {
    setTimeout(() => {
      this.props.clearMessage();
    }, 3000);
    if (this.props.success) {
      return (
        <Message
          className="meeting-notif"
          onDismiss={this.handleDismiss}
          header="Meeting Successful!"
          content={`You met ${this.props.name}`}
          success
          floating
        />
      );
    } else {
      return (
        <Message 
          className="meeting-notif" 
          onDismiss={this.handleDismiss} 
          header="Duplicate Meeting!" 
          content={`You have already met ${this.props.name}`}
          warning floating 
        />
      );
    }
  }
}

MeetingNotification.defaultProps = {
  name: 'Player'
};

export default MeetingNotification;
