import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { setGameState, setGameCode, setDBNames, setGameType } from '../actions/core';
import TeacherLogout from '../components/TeacherLogout';
import StudentLogout from '../components/StudentLogout';
import SingleButtonDialog from '../components/SingleButtonDialog';
import DimLoader from '../components/DimLoader';
import { connect } from 'react-redux';
import Rejoin from '../components/Rejoin';
import setOnlineStatus from '../../helpers/setOnlineStatus';

class RejoinContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ready: true 
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.auth.isEmpty || this.props.players === '') {
      this.props.setGameState('empty'); // not logged in.  can log in as player
    } else if (this.props.auth.isAnonymous) {
      this.props.firestore.get({
        collection: this.props.players,
        where: ['uid', '==', this.props.auth.uid]
      }).then((doc) => {
        //On reload, if the player is currently rejoining, return to the waiting to approve screen
        if (!doc.empty) {
          if (doc.docs[0].data().rejoin) {
            this.props.setGameState('loading');
            setOnlineStatus(this.props);
          }
          else {
            this.props.setGameState('empty');
          }
        }
        else {
          this.props.firestore.get({
            collection: this.props.players,
            doc: this.props.auth.uid
          }).then((doc) => {
            if (!doc.empty) {
              if (this.props.gameState !== "force") this.props.setGameState('forRejoin');
            } else {
              this.props.setGameState('empty');
            }
          });
        }
      });

    } else {
      this.props.setGameState('other'); // logged in as teacher or incorrectly
    }
  }

  componentCleanup = () => {
    if (this.props.gameType !== "lgame") {
      this.props.setGameCode('');
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  // after logging in set the state.
  loggedIn = () => {
    this.props.setGameState('anonymous');
  }

  // after logging out set the state
  loggedOut = () => {
    this.props.setGameState('empty');
  }

  render() {
    if (this.props.gameState === 'anonymous') {
      return <Redirect to="/player" />;
    }

    return (
      <div style={{height: '100%'}}>
        <Rejoin
          done={this.loggedIn}
          cancel={() => this.props.history.push('/')}
          game={this.props.game}
          gameType={this.props.gameType}
          firebase={this.props.firebase}
          firestore={this.props.firestore}
          gameState={this.props.gameState}
          gameCode={this.props.gameCode}
          setGameState={this.props.setGameState}
          setGameCode={this.props.setGameCode}
          players={this.props.players}
          meetings={this.props.meetings}
          auth={this.props.auth}
          setDBNames={this.props.setDBNames}
          setGameType={this.props.setGameType}
        />

        {this.props.gameState === "forRejoin" &&
          <StudentLogout
            firebase={this.props.firebase}
            setGameState={this.props.setGameState}
            history={this.props.history}
          />
        }

        {this.props.gameState === "other" && 
          <TeacherLogout 
            firebase={this.props.firebase}
            setGameState={this.props.setGameState}
            history={this.props.history}
          />
        }

        {this.props.gameState === "loading" && 
          <DimLoader message="Waiting to be approved" />
        }

        {this.props.gameState === "denied" && 
          <SingleButtonDialog
            description="Request to rejoin denied."
            buttonText="Back to Home"
            buttonAction={() => this.props.history.push("/")}
          />
        }

        {this.props.gameState === "error" &&
          <SingleButtonDialog
            description="Someone with this name is already rejoining."
            buttonText="Back to Home"
            buttonAction={() => this.props.history.push("/")}
          />
        }

        {this.props.gameState === "force" && 
          <SingleButtonDialog
            description="You must rejoin the game via the rejoin page."
            buttonText="Close"
            buttonAction={() => this.props.setGameState('empty')}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gameState: state.core.gameState,
    gameCode: state.core.gameCode,
    gameType: state.core.gameType,
    game: state.core.game,
    meetings: state.core.meetings,
    players: state.core.players,
    auth: state.firebase.auth
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGameState: (newState) => dispatch(setGameState(newState)),
    setGameCode: (newCode) => dispatch(setGameCode(newCode)),
    setGameType: (type) => dispatch(setGameType(type)),
    setDBNames: (names) => dispatch(setDBNames(names))
  };
}

const enhance = compose(
  firebaseConnect(), // withFirebase can also be used
  firestoreConnect(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(RejoinContainer);