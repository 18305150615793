import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveGraph } from '../../playerApi';

import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import PieGraph from './PieChart';
import Histogram from './Histogram';
import { Button, Grid, Header } from 'semantic-ui-react';
import PartialAnimalCard from './PartialAnimalCard';
import { selectColor } from '../../constants';

/**
 * The screen where a player can generate a graph based on collected data
 * Parent: GraphingScreen
 * Children: LineGraph, BarGraph, PieGraph, Histogram
 */

const INITIAL_STATE = {
	screen: 'selectData',
	graph: '',
	planet: -1,
	region: -1,
	sensorIndex: -1,
	regionsList: [],
	sensorsInRegion: [],
	sensor: null,
	xData: [],
	yData: [],
	flipped: null,
	title: '', //titles now only contain planet and region info
	subtitle: '', //subtitles will contain info about axis and graph type
	xLabel: '',
	yLabel: '',
	graphSaved: false
};

//new format have extraneous info in graphing page, will remove later
const GRAPH_TITLE_TEMPLATES = {
	line: {
		gas: 'Amount vs. Gases Line Graph',
		weather: '$0 vs. $1 Line Graph'
	},
	bar: {
		gas: 'Amount vs. Gases Bar Graph',
		weather: '$0 vs. $1 Bar Graph'
	},
	pie: {
		gas: 'Atmospheric Composition by Gas Pie Graph',
		weather: '$0 by $1 Pie Graph'
	},
	hist: {
		gas: 'Amount of Gas Hist Graph',
		weather: 'Frequency of $0'
	}
};

const AXIS_LABELS = {
	gas: {
		name: 'Gases',
		measurements: 'Amount in percentages'
	},
	temp: {
		name: 'Temperature',
		measurements: 'Temperature in °C'
	},
	rain: {
		name: 'Rain',
		measurements: 'Rain in cm'
	}
};

class CreateGraphs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...INITIAL_STATE,
			planetList: Array.from(new Set(props.sensors.map(sensor => sensor.planet)))
		};
	}

	// finds available regions based on planet selected
	selectPlanet = planet => {
		if (planet === this.state.planet) return;
		//updates sensors shown when different planet is selected
		const regionSensors = (this.state.region !== -1) ? this.props.sensors.filter(sensor => sensor.planet === planet && sensor.region === this.state.region) : [];
		this.setState({
			planet: planet,
			regionsList: Array.from(
				new Set(
					this.props.sensors.filter(sensor => sensor.planet === planet).map(sensor => sensor.region)
				)
			),
			sensorsInRegion: Array.from( new Set(regionSensors)),
			sensorIndex: -1
		});
	};

	selectRegion = region => {
		if (region === this.state.region) return;
		const sensors = this.props.sensors.filter(
			sensor => sensor.planet === this.state.planet && sensor.region === region
		);
		this.setState({
			region: region,
			sensorsInRegion: sensors,
			sensorIndex : -1
		});
	};

	chooseData = sensorIndex => this.setState({ sensorIndex: sensorIndex, sensor: this.state.sensorsInRegion[sensorIndex] });

	chooseGraph = graphType => this.setState({ graph: graphType, flipped: null, title: '' });

	getGraphLabels = () => {
		if (this.state.graph === '') return [];

		let sensor = this.state.sensor;

		const isGas = sensor.dataType === 'gas',
			axisLabels = AXIS_LABELS[sensor.dataType],
			dataTypeLabel = isGas ? axisLabels.name : 'Months',
			measurementsLabel = axisLabels.measurements,
			template = GRAPH_TITLE_TEMPLATES[this.state.graph][isGas ? 'gas' : 'weather'],
			graphs = [];

		// let titlePrefix = template.replace('$0', axisLabels.name).replace('$1', 'Months'),
		// 	titleSuffix = ` on Planet ${this.state.planet} Region ${this.state.region}`;
		let planetIndex = this.state.planet;
		let titlePrefix = `Planet ${this.props.planets[planetIndex].name} Region ${this.state.region + 1}`,
			titleSuffix = template.replace('$0', axisLabels.name).replace('$1', 'Months');
		titleSuffix = titleSuffix + ` ${sensor.startMonth} - ${sensor.endMonth}`

		graphs.push({
			title: `${titlePrefix}`,
			subtitle: `${titleSuffix}`,
			xLabel: dataTypeLabel,
			yLabel: measurementsLabel
		});
		if (!isGas) {
			titleSuffix = template.replace('$0', 'Months').replace('$1', axisLabels.name);
			titleSuffix = titleSuffix + ` ${sensor.startMonth} - ${sensor.endMonth}`
			graphs.push({
				title: `${titlePrefix}`,
				subtitle: `${titleSuffix}`,
				xLabel: measurementsLabel,
				yLabel: dataTypeLabel
			});
		}

		return graphs;
	};

	selectAxes = (flipped, labels) =>
		this.setState({
			flipped: flipped,
			title: labels.title,
			subtitle: labels.subtitle,
			xLabel: labels.xLabel,
			yLabel: labels.yLabel
		});

	isDataReady = () =>{ 
		console.log(this.state.sensorIndex);
		return this.state.planet !== -1 && this.state.region !== -1 && this.state.sensorIndex !== -1;
		};

	isGraphReady = () => this.state.graph !== '' && this.state.title !== '';

	// prepares data to pass into graphing skeletons
	createGraph = () => {
		if (this.isGraphReady()) {
			const data = this.state.sensor.data;
			let xData;
			let yData;

			if (this.state.flipped) {
				// flipped axes
				const flippedData = {};
				Object.keys(data).forEach(key => {
					flippedData[data[key]] = parseInt(key);
				});
				xData = Object.keys(flippedData).map(x => parseInt(x));
				yData = [{ data: Object.values(flippedData) }];
			} else {
				// normal axes
				xData = Object.keys(data);
				if (this.state.sensor.dataType !== 'gas') {
					xData = xData.map(x => parseInt(x));
				}
				yData = [{ data: Object.values(data) }];
			}

			this.setState({
				screen: 'viewGraph',
				xData: xData,
				yData: yData,
				graphSaved: false
			});
		}
	};

	handleSaveButton = async () => {
		await saveGraph({
			graphType: this.state.graph,
			dataType: this.state.sensor.dataType,
			title: this.state.title,
			subtitle: this.state.subtitle,
			x: {
				label: this.state.xLabel,
				data: this.state.xData
			},
			y: {
				label: this.state.yLabel,
				data: this.state.yData
			}
		});
		this.setState({ graphSaved: true });
	};

	render() {
		// Prevents players from getting confused of the empty graph page
		if(this.props.sensors.length === 0) {
			return (
				<div>
					You have not collected any sensor data.
					<br /> Go to a planet to deploy sensors.
				</div>
			)
		}

		switch (this.state.screen) {
			case 'selectData':
				return (
					<>
						<div style={{ margin: '10px' }}>
							<Header size="medium">Select Planet</Header>
							<Grid columns={3}>
								{this.state.planetList.map(planet => (
									<Grid.Column style={{ padding: '8px' }}>
										<Button
											size="small"
											content={this.props.planets[planet].name}
											onClick={() => this.selectPlanet(planet)}
											color = {this.state.planet === planet ? selectColor:null}
											fluid
											active={this.state.planet === planet}
										/>
									</Grid.Column>
								))}
							</Grid>
							{/* Regions in columns of 3 */}
							<Header size="medium">Select Region</Header>
							<Grid columns={3}>
								{this.state.regionsList.map(region => (
									<Grid.Column style={{ padding: '8px' }}>
										<Button
											size="medium"
											content={region+1}
											onClick={() => this.selectRegion(region)}
											color = {this.state.region === region ? selectColor:null}
											fluid
											active={this.state.region === region}
										/>
									</Grid.Column>
								))}
							</Grid>

							<Header size="medium">Select Data To Graph</Header>
							<div>
								{this.state.sensorsInRegion.map((sensor, index) => (
									<Button
										style={{ marginBottom: '5px' }}
										size="medium"
										content={`${sensor.dataType} month ${sensor.startMonth}-${sensor.endMonth}`}
										onClick={() => this.chooseData(index)}
										color = {this.state.sensorIndex === index ? selectColor : null}
										fluid
										value={index}
										active={this.state.sensorIndex === index}
									/>
								))}
							</div>
						</div>

						{/* Next Button to next screen */}
						<Button
							size="big"
							color= "green"
							content="Next"
							disabled={!this.isDataReady()}
							onClick={() => this.setState({ screen: 'selectGraph' })}
							fluid
						/>
					</>
				);
			case 'selectGraph':
				const graphLabels = this.getGraphLabels();
				return (
					<>
						<div>
							<Header size="medium">Choose Graph Type</Header>
							<div style={{ display: 'flex', margin: '5px' }}>
								<Button
									size="small"
									content="Line"
									onClick={() => this.chooseGraph('line')}
									fluid
									active={this.state.graph === 'line'}
									color = {this.state.graph === 'line' ? selectColor : null}
									value={'line'}
									icon="chart line"
								/>
								<Button
									size="small"
									content="Bar"
									onClick={() => this.chooseGraph('bar')}
									fluid
									active={this.state.graph === 'bar'}
									color = {this.state.graph === 'bar' ? selectColor : null}
									value={'bar'}
									icon="chart bar outline"
								/>
							</div>
							<div style={{ display: 'flex', margin: '5px' }}>
								<Button
									size="small"
									content="Pie"
									onClick={() => this.chooseGraph('pie')}
									fluid
									active={this.state.graph === 'pie'}
									color = {this.state.graph === 'pie' ? selectColor : null}
									value={'pie'}
									icon="chart pie"
								/>
								<Button
									size="small"
									content="Histogram"
									onClick={() => this.chooseGraph('hist')}
									fluid
									active={this.state.graph === 'hist'}
									color = {this.state.graph === 'hist' ? selectColor : null}
									value={'hist'}
									icon="chart bar"
								/>
							</div>
						</div>

						<Header size="medium">Select Axes</Header>
						{graphLabels.map((graph, index) => (
							<Button
								style={{ marginBottom: '5px' }}
								size="medium"
								content={graph.subtitle}
								fluid
								onClick={() => this.selectAxes(index, graph)}
								active={this.state.flipped === index}
								color = {this.state.flipped === index ? selectColor : null}
							/>
						))}
						<br />
						<Button.Group fluid>
							<Button
								size="big"
								content="Back"
								color="red"
								icon="arrow left"
								onClick={() => this.setState({ screen: 'selectData' })}
							/>
							<Button
								size="big"
								color="green"
								content="Create"
								icon="plus square"
								disabled={!this.isGraphReady()}
								onClick={this.createGraph}
							/>
						</Button.Group>
					</>
				);
			case 'viewGraph':
				let graph = '';
				switch (this.state.graph) {
					case 'line':
						graph = (
							<LineGraph
								categories={this.state.xData}
								series={this.state.yData}
								xtitle={this.state.xLabel}
								ytitle={this.state.yLabel}
								title={this.state.title}
								subtitle={this.state.subtitle}
							/>
						);
						break;
					case 'bar':
						graph = (
							<BarGraph
								categories={this.state.xData}
								series={this.state.yData}
								xtitle={this.state.xLabel}
								ytitle={this.state.yLabel}
								title={this.state.title}
								subtitle={this.state.subtitle}
							/>
						);
						break;
					case 'pie':
						graph = (
							<PieGraph
								labels={this.state.xData}
								series={this.state.yData[0].data}
								title={this.state.title}
								subtitle={this.state.subtitle}
							/>
						);
						break;
					case 'hist':
						graph = (
							<Histogram
								data={this.state.yData[0].data}
								xtitle={this.state.yLabel}
								title={this.state.title}
								subtitle={this.state.subtitle}
							/>
						);
						break;
					default:
						break;
				}

				return (
					<>
						<PartialAnimalCard sensorType = {this.state.sensor.dataType}/>	

						<div style={{ height: '35vh', width: '100%' }}>{graph}</div>

						<Button.Group fluid>
							<Button
								size="big"
								color="red"
								icon="redo"
								content="Create Another"
								onClick={() => this.setState({ ...INITIAL_STATE })}
							/>

							<Button
								size="big"
								color="green"
								content={this.state.graphSaved ? 'Saved' : 'Save '}
								icon={this.state.graphSaved ? 'check' : 'save'}
								disabled={this.state.graphSaved}
								onClick={this.handleSaveButton}
							/>
						</Button.Group>
					</>
				);
			default:
				return;
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.contracts.animals,
	sensors: state.planetGame.player.collectedSensors,
	planets: state.planetGame.world.planets
});

export default connect(mapStateToProps)(CreateGraphs);
