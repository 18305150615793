import React, { Component } from "react";
import { Header, Button, Progress, Card, Image } from "semantic-ui-react";
import SingleButtonDialog from "../../../core/components/SingleButtonDialog";
//import Readerqr from '../../../core/components/Readerqr';
import firebase from "firebase";
import boat from "./assets/fishing-boat.svg";

// Description: holds the qr scanner and code components
// Parent: TocPlayer
// Children: Readerqr
// Props Used:
//      boat (Object)
//      currentConfiguration
//      db (Object)
//      doneLoading (Function)
//      fishCaught (Int)
//      game (String)
//      gameCode (String)
//      generationPause (Bool)
//      players (String)
//      timeLeft (Int)
//      uid (String)

class RemoteComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pauseScan: false,
      camera: false,
      readerLoaded: false,
      errorQR: false,
      showScienceResult: false,
      showScanDialogue: false,
      tickingTime: 5,
      fishGain: 0,
      researchTime: 10,
      researchMode: false,
      stations: null,
      pauseTime: 5,
      starting: true,
      paused: false,
    };
    this.meetingTimer = null;
    this.scanEnergyGain = 5;
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);
    this.setState({ camera: true, starting: false });
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentCleanup = () => {
    //console.log('cleanup');
  };

  closeScanDialog = () => {
    this.setState({ showScanDialogue: false });
  };

  closeScienceDialog = () => {
    this.setState({ showScienceResult: false });
  };

  closeQRDialog = () => {
    this.setState({ errorQR: false });
  };

  timer = () => {
    this.meetingTimer = setTimeout(() => {
      this.setState({ tickingTime: this.state.tickingTime - 1 });
      if (this.state.tickingTime <= 0) {
        // console.log('ticked');
        this.setState({
          tickingTime: 0,
          pauseScan: false,
          camera: true,
        });
      } else {
        this.timer();
      }
    }, 1000);
  };

  defaultScreen = () => {
    this.setState({ camera: false });
  };

  handleScan = (stationId) => {
    if (stationId && !this.state.pauseScan) {
      this.defaultScreen();

      const stationRef = this.props.db
        .collection(this.props.game)
        .doc(this.props.gameCode);

      const playerRef = this.props.db
        .collection(this.props.players)
        .doc(this.props.uid);

      this.props.db.runTransaction((transaction) => {
        return transaction.get(stationRef).then((doc) => {
          const station =
            doc.data().configurations[this.props.currentConfiguration].stations[
              stationId
            ];

          if (station !== null) {
            let currentTime = new Date().getTime();
            const numFish = station.amount;
            let fishTaken = 5;
            let speed = 2;
            if (this.props.boat.size === "medium") {
              fishTaken = 10;
              speed = 3;
            } else if (this.props.boat.size === "large") {
              fishTaken = 20;
              speed = 4;
            }
            if (this.props.boat.trawler) {
              speed *= 1.5;
            }
            const fisherySize = 100;
            let time = Math.round(
              fishTaken / ((speed * numFish) / fisherySize)
            );
            if (time > this.props.timeLeft) {
              fishTaken = Math.floor((this.props.timeLeft / time) * fishTaken);
              time = this.props.timeLeft;
            }

            if (this.state.researchMode) {
              fishTaken = 0;
              this.setState({ researchTime: time, showScienceResult: true });
              time = 1;
            }
            if (fishTaken > numFish) {
              fishTaken = numFish;
            }

            var traveltime = 5;
            if (stationId !== this.props.lastStation) {
              traveltime = 10;
            }
            let newAmount = station.amount - fishTaken;
            this.setState({
              tickingTime: traveltime,
              pauseTime: traveltime,
              pauseScan: true,
              camera: false,
              fishGain: fishTaken,
            });
            this.timer();

            if (!this.state.researchMode) {
              this.setState({ showScanDialogue: true });
            }

            transaction.update(playerRef, {
              fishCaught: this.props.fishCaught + fishTaken,
              lastStation: stationId,
              timeLeft: this.props.timeLeft - time,
            });

            if (this.props.lastStation != stationId) {
              console.log("change station");
              if (
                this.props.lastStation &&
                this.props.lastStation !== 0 &&
                Object.keys(this.props.stations).includes(
                  this.props.lastStation
                )
              ) {
                //check to make sure this.props.lastStation is a current station
                transaction.update(stationRef, {
                  [`configurations.${this.props.currentConfiguration}.stations.${this.props.lastStation}.currentPlayers`]:
                    firebase.firestore.FieldValue.arrayRemove(this.props.uid),
                });
              }

              var newPlayers = station.currentPlayers || [];
              //var newPlayers = this.props.stations[stationId].currentPlayers;
              newPlayers.push(this.props.uid);
              //console.log(newPlayers)
              transaction.update(stationRef, {
                [`configurations.${this.props.currentConfiguration}.stations.${stationId}`]:
                  {
                    name: station.name,
                    rate: station.rate,
                    limit: station.limit,
                    amount: newAmount,
                    risk: station.risk,
                    lastFeedTime: currentTime,
                    currentPlayers: newPlayers, //firebase.firestore.FieldValue.arrayUnion(this.props.uid)
                  },
              });
            } else {
              console.log("no change");
              transaction.update(stationRef, {
                [`configurations.${this.props.currentConfiguration}.stations.${stationId}`]:
                  {
                    name: station.name,
                    rate: station.rate,
                    limit: station.limit,
                    amount: newAmount,
                    risk: station.risk,
                    lastFeedTime: currentTime,
                    currentPlayers: station.currentPlayers, //firebase.firestore.FieldValue.arrayUnion(this.props.uid)
                  },
              });
            }
          } else {
            // Prevents Firebase error when scanning player QR code instead of station QR code
            transaction.update(stationRef, {});
          }
        });
      });
    }
  };

  render() {
    return (
      <div>
        {this.state.showScienceResult && (
          <SingleButtonDialog
            description={
              <div>
                {"It would have taken " +
                  this.state.researchTime +
                  " seconds to fish here with your boat"}
                <br />
              </div>
            }
            buttonText="Close"
            buttonAction={this.closeScienceDialog}
            width="420px"
          />
        )}

        {this.state.showScanDialogue && (
          <SingleButtonDialog
            description={
              <div>
                {this.state.fishGain > 0
                  ? "You caught " + this.state.fishGain + " fish :)"
                  : "You did not catch any fish"}
                <br />
              </div>
            }
            buttonText="Close"
            buttonAction={this.closeScanDialog}
            width="420px"
          />
        )}

        {!this.props.generationPause ? (
          <div>
            {this.state.camera ? (
              <div>
                <Header id="playerHeader">
                  {this.props.timeLeft > 0
                    ? "Let's Go Fishing"
                    : "Season Over. Please Wait."}
                </Header>

                <Card.Group centered itemsPerRow={2}>
                  {Object.keys(this.props.stations).map((station, i) => {
                    return (
                      <Card key={station}>
                        <Card.Header>
                          <h3>{this.props.stations[station].name}</h3>
                        </Card.Header>
                        <Image
                          size="tiny"
                          centered
                          src={"/toc/ocean-" + (i + 1) + ".jpg"}
                        />
                        <Card.Content>
                          <Button onClick={() => this.handleScan(station)}>
                            Fish Here
                            <img
                              src={boat}
                              style={{ height: "75px", padding: "5px" }}
                              alt="Boat"
                            />
                          </Button>
                          <Card.Description>
                            Boats Here:{" "}
                            {this.props.stations[station].currentPlayers.length}
                          </Card.Description>
                        </Card.Content>
                        {this.props.lastStation === station ? (
                          <Card.Content extra>
                            <Card.Meta>Current Location</Card.Meta>
                          </Card.Content>
                        ) : (
                          ""
                        )}
                      </Card>
                    );
                  })}
                </Card.Group>
                <br />
                {this.props.boat.research ? (
                  <Button.Group>
                    <Button
                      onClick={() => this.setState({ researchMode: true })}
                      size="medium"
                      color={!this.state.researchMode ? "grey" : "blue"}
                      content="Research"
                    />
                    <Button
                      onClick={() => this.setState({ researchMode: false })}
                      size="medium"
                      color={this.state.researchMode ? "grey" : "blue"}
                      content="Fish"
                    />
                  </Button.Group>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div>
                <Progress
                  percent={
                    (this.state.tickingTime / this.state.pauseTime) * 100
                  }
                  size="big"
                  color="blue"
                />
                {/*<div>{this.state.tickingTime}</div>*/}
                {this.state.pauseScan ? (
                  <Header> Fishing Timeout! </Header>
                ) : (
                  <Header> You can fish :) </Header>
                )}
              </div>
            )}
          </div>
        ) : (
          <Header> Scan Locked! </Header>
        )}
      </div>
    );
  }
}

export default RemoteComponents;
