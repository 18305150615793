import React from 'react';
import { Header, Message } from 'semantic-ui-react';

// Description: Displays Game State
// Parent: TocGameInformation, VirusTeacherGameScreen
// Children: None
// Props Used: 
//      content
//      gameCode (String)
//      gameState (String)

const GameInformation = (props) => {
    const { gameCode, gameState, content } = props;

    return (
        <div>
            <Header textAlign="left" as="h5" style={{ fontWeight: 'normal', marginBottom: '0.3em' }}>Game Code</Header>
            <Header textAlign="left" as="h3" style={{ marginTop: '0px' }}>{gameCode}</Header>

            <Message style={{ marginLeft: '0.7em', marginRight: '0.7em' }}>
                {gameState === "initializing" ?
                    <Header textAlign="left" as="h3" color="red">Game not started</Header>
                : gameState === "running" ?
                    <Header textAlign="left" as="h3" color="green">Game running</Header>
                :
                    <Header textAlign="left" as="h3" color="blue">Game paused</Header>
                }
                <br />
                {content}
            </Message>
        </div>
    ); 
};

export default GameInformation;