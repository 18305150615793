import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Grid, Image} from 'semantic-ui-react';
import backgroundImage from '../../art assets/UI grid.png';
import animalHead from '../../art assets/Head_TempMedLow.png';



class AnimalCardJIT extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }


    render(){
        const animal = this.props.animal;
        let name = animal.name;
        let gasTol = animal.gasTol;
        let rainTol = animal.rainTol;
        let tempTol = animal.tempTol;

        return(
            <div>
                <Grid columns={2} height={10} celled={"true"}>
                    <Grid.Column rows ={2} width={5}>
                        <Grid.Row textAlign={"left"}>
                                {name}
                        </Grid.Row>
                        <Grid.Row>
                            <Image src={backgroundImage} centered size="medium">
                                <Image src={animalHead} size="small"></Image>
                            </Image>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column rows={3} verticalAlign={"middle"} floated={"left"}>
                        
                        <Grid.Row columns={2}>
                            <Grid.Column textAlign={"left"}>
                                Gas Needs:
                            </Grid.Column>
                            <Grid.Column >
                                {Object.entries(gasTol).map((entry, index) =>(
                                    <li key={index}>{`${entry[0]}: ${entry[1][0]}% to ${entry[1][1]}%`}</li>
                                ))}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row textAlign={"justified"}> 
                            Rain Needs: {rainTol[0]} to {rainTol[1]}cm
                        </Grid.Row>
                        <Grid.Row textAlign={"justified"}>
                            Temperature Needs: {tempTol[0]} to {tempTol[1]}°C
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

}//END AnimalCardJIT

const mapStateToProps = (state, ownProps) => ({
    contracts: state.planetGame.player.animals,
    animal: state.planetGame.contracts.animals[ownProps.animalName]
  
});

export default connect(mapStateToProps)(AnimalCardJIT);
