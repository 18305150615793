import { createSlice } from "@reduxjs/toolkit";
import { GAME_STATUS} from "../constants";

const INITIAL_STATE = {
    id: '',
	name: '',
    shape: '',
	shownInfo: ''
};

const slice = createSlice({
    name: 'player',
    initialState: {...INITIAL_STATE},
    reducers: {
        setId: (state, action) => {
			// payload: string
			console.log(`[game/setId] setting player ID to ${action.payload}`);
			state.id = action.payload;
		},

        setName: (state, action) => {
			// payload: string
			console.log('[player/setName] setting name to', action.payload);
			state.name = action.payload;
		},

        setData: (state, action) => {
			/* payload: {
				id: string,
				name: string,
				shape: string
			} */
			console.log('[infoplayer/setData] setting player data to', action.payload ?? ' initial state');
			const {
				id,
				name,
				shape,
				shownInfo,
			} = action.payload;
			state.id = id;
			state.name = name;
			state.shape = shape;
			state.shownInfo = shownInfo;
		},

        reset: (state, action) => {
			// payload: none
			console.log('[player/reset] resetting player info in Redux state');
			const { id, name, ...rest } = INITIAL_STATE;
			for (const [key, value] of Object.entries(rest))
				state[key] = value;
		}
    }
});

export const infoPlayerActions = slice.actions;

export default slice.reducer;