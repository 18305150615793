const setDBNames = (gameType) => {
  var dbNames = {};

  switch(gameType) {
    case 'pgame':
      dbNames = { game: 'pgames', players: 'pplayers', meetings: 'pgamemeetings' };
      break;
    
    case 'vgame':
      dbNames = { game: 'vgames', players: 'vplayers', meetings: 'vgamemeetings' };
      break;
    
    case 'tgame':
      dbNames = { game: 'tgames', players: 'tplayers', meetings: 'tgamemeetings' };
      break;
    case 'lgame':
        dbNames = { game: 'lgames', players: 'lplayers', meetings: 'lgamemeetings' };
        break;
    case 'infogame':
        dbNames = { game: 'infogames', players: 'infoplayers', meetings: 'infogamemeetings' };
        break;

    default: 
      dbNames = { game: 'empty', players: 'empty', meetings: 'empty' }
  }
  return dbNames;
}

export default setDBNames;