import * as types from '../actions/messages';

// Messages Reducer

const defaultState = {
  currentMessage: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: action.payload
      };
    case types.CLEAR_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: {}
      };
    default:
      return state;
  }
};
