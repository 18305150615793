import React from 'react';
import Chart from 'react-apexcharts'

// Description: Converts a bar graph into a histogram from provided data
//

const barToHistogram = (data) => {
    let info = {};
    for (let i = 0; i < data.length; i++) {
        let num = data[i].toString();
        if (num in info) {
            info[num] = info[num] + 1;
        } else {
            info[num] = 1;
        }
    }

    const categories = Object.keys(info).map(x => parseInt(x));
    const frequency = Object.values(info);
    let min = categories[0];
    let max = categories[categories.length-1];
    let binSize = Math.ceil((max - min)/(Math.ceil(Math.log2(data.length)) + 1));
    let newCategories = [`${min} - ${min+binSize}`];
    let newFrequency = [0];
    let index = 0;
    let lastBin = min;
    for (let i = 0; i < categories.length; i++) {
        if (categories[i] >= lastBin && categories[i] < lastBin + binSize) {
            newFrequency[index] = newFrequency[index] + frequency[i];
        } else {
            lastBin = lastBin+binSize;
            newCategories.push(`${lastBin} - ${lastBin+binSize}`);
            newFrequency.push(frequency[i]);
            index++;
        }
    }
    return [newCategories, newFrequency];
}

const Histogram = props => {
    const { title, subtitle, xtitle, data } = props;
    const info = barToHistogram(data);
    const categories = info[0];
    const frequency = info[1];

    return (
        <Chart 
            options={{
                chart: {
                    id: 'histogram',
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '100%'
                    }
                },
                title: {
					text: title
				},
                subtitle: {
					text: subtitle
				},
                xaxis: {
                    categories: categories,
                    title: {
                        text: xtitle
                    },
                    tickPlacement: 'between'
                },
                yaxis: {
                    title: {
                        text: "Frequency"
                    },
                    forceNiceScale: true,
                    labels: {
						formatter: function (value) {
						if (value % 1 === 0) {return value;}
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
            }}
            series={[
				{
					name: 'number',
					data: frequency
				}
			]}
            type="bar"
            width={'100%'}
            height={'100%'}
        />
    );

}

export default Histogram;