import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import Writerqr from '../../../core/components/Writerqr.js';
import Readerqr from '../../../core/components/Readerqr';
import TradeDialog from './TradeDialog';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';

// Description: 
// Parent: TocPlayer
// Children: None
// Props Used:
//      db (Object) [Firebase]
//      doneLoading (Function) [Not passed]
//      fishCaught (Int)
//      players (String)
//      uid (String)

class TradeQRComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 10,
            fishToGive: 0,
            showTradeDialog: false,
            tradingPlayerId: '',
            errorQR: false,
            tradeError: false,
        }
    };

    closeQRDialog = () => {
        this.setState({ errorQR: false });
    };

    closeTraderErrorDialog = () => {
        this.setState({ tradeError: false })
    }

    // tick down time on the qr reader timer set back to qr code when done
    readWait = () => {
        this.meetingTimer = setTimeout(() => {
        this.setState({ time: this.state.time - 1 });
        if (this.state.time <= 0) {
            this.setState({ pauseScan: false });
        } else {
            this.readWait();
        }
        }, 1000);
    }

    openCamera = () => {
        this.setState({
            camera: true,
            readerLoaded: false,
        });
        setTimeout(() => {
            this.setState({ readerLoaded: true });
        }, 2000);
    };

    defaultScreen = () => {
        this.setState({ camera: false });
    };

    handleScan = otherPlayerId => {
        console.log('trade scan occured');
        if (otherPlayerId && !this.state.pauseScan) {
            if (this.props.fishCaught <= 0) {
                this.setState({ tradeError: true });
                this.defaultScreen();
                return;
            }
            
            this.props.db
                .collection(this.props.players)
                .doc(otherPlayerId)
                .get().then(doc => {
                    if (doc.exists) {
                        this.defaultScreen();
                        this.setState({
                            pauseScan: true,
                            time: 2,
                            tradingPlayerId: otherPlayerId,
                            showTradeDialog: true,
                        });
                        this.readWait();
                    } else {
                        // TODO: Have a different error for attempting to trade 
                        // with station
                        this.setState({ errorQR: true });
                        this.defaultScreen();
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });  
        }
    }

    handleTrade = fishToGive => {
        this.setState({ showTradeDialog: false })
        if (fishToGive > this.props.fishCaught) {
            this.setState({ tradeError: true });                   
        } else {
            const currPlayerRef = this.props.db
                .collection(this.props.players)
                .doc(this.props.uid);
            
            const otherPlayerRef = this.props.db
                .collection(this.props.players)
                .doc(this.state.tradingPlayerId);
            
            this.props.db.runTransaction(transaction => {
                return transaction.get(otherPlayerRef).then(doc => {
                    const otherPlayerNumFish = doc.data().fishCaught;
                    let fishGiven = fishToGive;

                    transaction.update(currPlayerRef, {
                        fishCaught: this.props.fishCaught - fishGiven,
                    });

                    transaction.update(otherPlayerRef, {
                        fishCaught: otherPlayerNumFish + fishGiven,
                    });
                });
            });
        }
    }
    
    render() {
        return(     
            <div>
                {this.state.showTradeDialog && (
                    <TradeDialog
                        yesAction={this.handleTrade}
                        noAction={() => {
                            this.setState({
                                fishToGive: 0,
                                tradingPlayerId: '',
                                showTradeDialog: false,
                        })}}
                        fishCaught={this.props.fishCaught}
                    />
                )}

                {this.state.errorQR && (
                    <SingleButtonDialog
                        description="This QR Code is not part of the game!"
                        buttonText="OK"
                        buttonAction={this.closeQRDialog}
                        width="420px"
                    />
                )}

                {this.state.tradeError && (
                    <SingleButtonDialog
                        description="You do not have enough fish to give away!" // subject to change
                        buttonText="OK"
                        buttonAction={this.closeTraderErrorDialog}
                        width="420px"
                    />
                )}

                <hr />
                <Button.Group fluid widths={2}>
                    <Button
                        onClick={
                            this.state.camera && this.state.readerLoaded
                                ? this.defaultScreen
                                : null
                        }
                        size="medium"
                        color={!this.state.camera ? 'green' : 'grey'}
                        className={!this.state.camera ? 'active' : ''}
                        content="Default"
                        ref={button => (this.buttonElement = button)}
                    />
                    <Button
                        onClick={
                            !this.state.camera && !this.state.pauseScan
                                ? this.openCamera
                                : null
                        }
                        size="medium"
                        icon="camera"
                        color={this.state.camera ? 'green' : 'grey'}
                        className={this.state.camera ? 'active' : ''}
                        content="Scan"
                    />
                </Button.Group>
                <div>
                    {this.state.camera ? (
                        <Readerqr
                            handleScan={this.handleScan}
                            doneLoading={this.props.doneLoading}
                            handleError={this.props.doneLoading}
                        />
                        ) : (
                        <div style={{marginTop: '15px'}}> 
                            <Writerqr 
                                value={this.props.uid || ''} 
                                size={250}  
                            />
                        </div>
                    )}
                </div>
            </div>   
        );
    }
}

export default TradeQRComponents;