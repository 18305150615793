import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlayerTimer from './PlayerTimer';

import {
	Button,
	Header,
	Modal,
	Accordion,
	Icon,
	List,
	Menu,
	Tab
} from 'semantic-ui-react';
import QrReader from 'react-qr-reader';

import PlanetCard from './PlanetCard';
import PlanetCircle from './PlanetCircle';

/**
 * The main navigation screen for a player to view and visit planets
 * Parent: PlanetPlayerGameScreen
 * Children: None
 *
 * @param {function} setPlanet function to change the display to a planet
 * @param {boolean} qrOn whether or not the teacher has toggled the QR mode on
 */

class PlanetNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toPlanet: -1, // -1: nothing scanned, nonnegative: popup appears
			activeItem: 'scan'
		};
	}
	//switches tabs in qr mode between scan and planet info
	handleItemClick = (e, { name }) => this.setState({ activeItem: name });


	handleScan = scanId => {
		// scanId: the planet's name
		if (scanId !== null && scanId < this.props.planets.length) {
			console.log(`Scanned: ${scanId}`);
			const planetIndex = parseInt(scanId); //.findIndex(planet => planet.name === scanId);
			if (planetIndex !== -1){
				this.setState({ toPlanet: planetIndex });
				//set planet here instead (direct travel)
				this.props.setPlanet(this.state.toPlanet);
			}
			else console.log('[PlanetNavigation] Scanned code is invalid');
		}
	};

	handleError = error => console.error(error);

	visitToPlanet = planetIndex => this.setState({ toPlanet: planetIndex });

	handleClick = index => {
		this.visitToPlanet(index);
	};

	render() {
		const { animals, player, planets, qrOn, setPlanet } = this.props;

		const {activeItem} = this.state;

		const map = 
			<div className="map-container">
				{planets.map((planet, index) => (
					<PlanetCircle
						planetIndex={index}
						key={index}
						handleClick={() => this.handleClick(index)}
					/>
				))}
			</div>

		const panes = [
			{
				menuItem: 'Scan',
				render: () => (
					<QrReader
						onScan={this.handleScan}
						onError={this.handleError}
						facingMode="environment"
						constraints={{ aspectRatio: 1, facingMode: { ideal: 'environment' }, delay: 500 }}
					/>
				)
			},
		
			{
				menuItem: 'Map',
				render: () => (
					<div className="planet-nav"> {map} </div>
				)
			}
		];

		const qr = (
			<div className="qr-container">
				<Tab panes={panes} menu={{color:'grey', inverted: true}} />
			</div>
		)

		let planetName = (this.state.toPlanet !== -1)? this.props.planets[this.state.toPlanet].name : "";

		return (
			<>
				<Header>{`Welcome, Captain! ${
					qrOn || this.state.qrOverride ? 'Scan' : 'Click'
				} to travel to a planet
				${ 	
					qrOn || this.state.qrOverride ? '\nClick the Map tab to view planet info' : ''}`
				}
				</Header>
				<PlayerTimer></PlayerTimer>
				{
					qrOn ? (
						<div style={{ width: '100%', height: '100%', margin: 'auto' }}>{qr}</div>
					) : (
						<div className="planet-nav"> {map} </div>
					)
				}

				
				<Header>
					Sensors Left: {player.sensorsCapacity - player.activeSensors.length}
				</Header>
				<Accordion>
					<Accordion.Title
						active={this.state.animalMenuOpen}
						index={0}
						onClick={() => {
							this.setState({
								animalMenuOpen: !this.state.animalMenuOpen
							});
						}}
					>
						<Header><Icon name="dropdown" />Animals on Ship</Header>
					</Accordion.Title>
					<Accordion.Content active={this.state.animalMenuOpen}>
						<List bulleted>
							{Object.values(animals).map((animal, index) => (
								<List.Item key={index}>
									{animal.name}: {animal.populationInShip}
								</List.Item>
							))}
						</List>
					</Accordion.Content>
				</Accordion>

				<Modal
					dimmer="blurring"
					size="tiny"
					open={this.state.toPlanet !== -1}
					onClose={() => this.setState({ toPlanet: -1 })}
					
				>
					<Modal.Header>Investigate {planetName}...</Modal.Header>
					<Modal.Content
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<PlanetCard planetIndex={this.state.toPlanet} />
					</Modal.Content>
					
					
					<Modal.Actions>
						{qrOn || this.state.qrOverride ? "" :
							<Button 
								onClick={() => setPlanet(this.state.toPlanet)}
								color="green"
							>
								Travel
							</Button>
						}
						<Button
							onClick={() => {
								this.setState({ toPlanet: -1 });
							}}
						>
							Cancel
						</Button>
					</Modal.Actions>
					
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.contracts.animals,
	player: state.planetGame.player,
	planets: state.planetGame.world.planets,
	qrOn: state.planetGame.world.qrOn
});

export default connect(mapStateToProps)(PlanetNavigation);
