import React from 'react';
import { Icon, Header, Segment, Table } from 'semantic-ui-react';

// Description: scrolling list meant for displaying players, option to highlight most recent player
// Parent: MeetingScrollList
// Children: None
// Props Used:
//      list (Array)
//      showRecent (Bool)
//      showActive (Bool)

const ScrollList = (props) => {
    const {
        list, showRecent, showActive, filter
    } = props;

    return (
        <div>
            {filter === 'time' ? 
                <Segment textAlign="left" style={{maxHeight: '350px', overflowY: 'scroll'}}>
                    <Table basic='very' celled collapsing>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={11}>Player</Table.HeaderCell>
                                <Table.HeaderCell>Time Left</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
        
                        <Table.Body>
                            {list.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        <Header.Content>
                                            <span style={{fontWeight: 'normal'}}>
                                                {item.name}
                                                {(index === 0 && showRecent) && <span className="text-blue">&nbsp; (most recent)</span>}
                                            </span>
                                        </Header.Content>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.timeLeft}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment> :
                <Segment textAlign="left" style={{maxHeight: '350px', overflowY: 'scroll'}}>
                    {list.map((item, index) => (
                        <Header key={index} style={{marginTop: '0px'}} as="h5">
                            {showActive && 
                                <Icon style={{fontSize: '1em'}} name={item.active ? 'circle' : 'circle outline'} color={item.active ? 'green' : 'grey'} />
                            }
                            <Header.Content>
                                <span style={{fontWeight: 'normal'}}>
                                    {item.name}
                                    {(index === 0 && showRecent) && <span className="text-blue">&nbsp; (most recent)</span>}
                                </span>
                            </Header.Content>
                        </Header>
                    ))}
                </Segment>  
            }
        </div>
        
    )
}

export default ScrollList;