import { createSlice } from '@reduxjs/toolkit';
import { GAME_STATUS } from '../constants';

const INITIAL_STATE = {
    gameStatus: '',
	numGroups: 3,
	players: [],
};

const slice = createSlice({
    name: 'game',
    initialState: {...INITIAL_STATE},
    reducers: {
        setSetting: (state, action) => {
			/* payload: {
				setting: string,
				value: any
			} */
			const { setting, value } = action.payload;
			console.log(`[game/setSetting] setting ${setting} to ${value}`);
			state[setting] = value;
		},

        setGameStatus: (state, action) => {
            //action.payload: string
            console.log([`[game/setGameStatus] setting game status to ${action.payload}`]);
            state.gameStatus = action.payload;
        },

        setPlayers: (state, action) => {
			// payload: (player object)[]
			const players = action.payload.map(player => ({ ...player, name: player.displayName }));
			console.log('[game/setPlayers] setting list of players to', players);
			state.players = players;
		},

        reset: (state, action) => {
			// payload: none
			console.log('[game/reset] resetting settings');
			for (const setting in INITIAL_STATE)
				if (setting !== 'players') state[setting] = INITIAL_STATE[setting];
			state.gameStatus = GAME_STATUS.INITIALIZING;
		},
    }
});

export const gameActions = slice.actions;

export default slice.reducer;