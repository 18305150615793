import React, { Component } from 'react';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DimLoader from '../components/DimLoader';
import {
	setGameState,
	setGameCode,
	setDBNames,
	setGameType
} from '../actions/core';
import getDBNames from '../../helpers/getDBNames';
import { Redirect } from 'react-router-dom';
import TeacherCreateGame from '../components/TeacherCreateGame';
import VirusTeacherGameScreen from '../../virusgame/teacher/components/VirusTeacherGameScreen';
import TocTeacherGameScreen from '../../tocgame/teacher/components/TocTeacherGameScreen';
import PopulationTeacherGameScreen from '../../populationgame/teacher/components/PopulationTeacherGameScreen';
import PlanetTeacherGameScreen from '../../planetgame/view/teacher/PlanetTeacherGameScreen';
import InfoTeacherGameScreen from '../../infogame/teacher/InfoTeacherGameScreen';
// import { Button } from 'semantic-ui-react';

// Description: Container that controls all teacher info
// Parent: Top level forwarded to by Choose
// Children: TeacherCreateGame, PopulationTeacherGameScreen, ToCTeacherGameScreen, VirusTeacherGameScreen, PlanetTeacherGameScreen
// Props Used:
//		auth (Object)
//		firestore (Object)
//		firebase (Object)
//		game (string)
//		gameCode (String)
//		gameList (Object)
//		gameState (String)
//		gameType (String)
//		history (Object)
//		meetings (String)
//		players (String)
//		setDBNames (Function)
//		setGameCode (Function)
//		setGameState (Function)
//		setGameType (Function)

class TeacherContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gameInfo: {},
			myGamesList: [],
			isAdmin: false,
		};
	};

	UNSAFE_componentWillMount() {
		if (this.props.auth.isEmpty || this.props.auth.isAnonymous) {
			// should check if has account, if yes set to other
			this.props.history.push({
				pathname: '/'
			}); // go home
		} else if (
			this.props.auth.providerData &&
			this.props.auth.providerData[0].providerId === 'google.com'
		) {
			this.props.setGameState('google'); // not logged in.  wait on empty page
		} else {
			this.props.history.push({
				pathname: '/'
			}); // go home
		}
	}

	componentCleanup = () => {
		this.unsetGameInfoListener();
		this.props.setGameCode('');
	};

	componentDidMount() {
		window.addEventListener('beforeunload', this.componentCleanup);
		//can try adding in the unsafe_componentwillmount here
	}

	componentWillUnmount() {
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
	}

	//original
	// updates the game list and checks auth state
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.gameList !== null && nextProps.gameList !== undefined) {
			// const myGamesArray=Object.values(nextProps.gameList);
			// this would be easy if ordered listeners worked properly
			const myGamesArray = Object.keys(nextProps.gameList)
				.filter(
					item =>
						nextProps.gameList[item] !== null &&
						nextProps.gameList[item] !== undefined &&
						nextProps.gameList[item].owner === this.props.auth.uid
				)
				.map(key => ({
					id: key,
					...nextProps.gameList[key]
				}));
			const myGamesList = myGamesArray.map(game => {
				var type = '';
				switch (game.type) {
					case 'pgame':
						type = 'Population Game';
						break;

					case 'vgame':
						type = 'Virus Game';
						break;

					case 'tgame':
						type = 'Toc Game';
						break;
					
					case 'lgame':
						type = 'Planet Game';
						break;
					
					case 'infogame':
						type = 'Info Game';
						break;

					default:
						break;
				}
				return { text: game.id, value: game.id, description: type };
			});
			this.setState({ myGamesList });
		}
	}

	setGameInfoListener = () => {
		if (this.props.game === '' || this.props.gameCode === '') return;
		this.props.firestore.setListener(
			{
				collection: this.props.game,
				doc: this.props.gameCode
			},
			doc => {
				this.setState({ gameInfo: { ...doc.data() } });
			}
		);
	};

	unsetGameInfoListener = () => {
		if (this.props.game === '' || this.props.gameCode === '') return;
		this.props.firestore.unsetListener({
			collection: this.props.game,
			doc: this.props.gameCode
		});
	};

	handleGameChange = (event, data) => {
		this.unsetGameInfoListener();
		// this would be easy if ordered listeners worked properly
		const myGamesArray = Object.keys(this.props.gameList).map(key => ({
			id: key,
			...this.props.gameList[key]
		}));
		const gameType = myGamesArray.find(item => item.id === data.value).type;
		const dbNames = getDBNames(gameType);
		console.log(getDBNames(gameType));
		this.props.firestore
			.get({
				collection: dbNames.game,
				doc: data.value
			})
			.then(doc => {
				this.setState({ gameInfo: { ...doc.data() } });
				this.props.setGameType(gameType);
				this.props.setDBNames(dbNames);
				this.props.setGameCode(data.value);
				this.setGameInfoListener();
			})
			.catch(err => console.log(err));
	};

	exitGame = () => {
		this.props.setGameCode('');
	};

	checkIfAdmin = () => {
		this.props.firestore
			.get({
				collection: "admins",
				doc: this.props.auth.uid
			})
			.then(doc => {
				this.setState({ isAdmin: this.props.auth.email === doc.data().email })
			})
			.catch(err => console.log(err));
	}

	render() {
		if (this.props.gameState !== 'google') {
			return <DimLoader message="Logging In" />;
		}

		if (this.props.gameCode === '') {
			return (
				<TeacherCreateGame
					myGamesList={this.state.myGamesList}
					name={this.props.auth.displayName}
					history={this.props.history}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					auth={this.props.auth}
					handleGameChange={this.handleGameChange}
					isAdmin={this.state.isAdmin}
				/>
			);
		}

		if (this.props.gameType === 'vgame') {
			return (
				<VirusTeacherGameScreen
					name={this.props.auth.displayName}
					gameInfo={this.state.gameInfo}
					gameCode={this.props.gameCode}
					game={this.props.game}
					meetings={this.props.meetings}
					players={this.props.players}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					history={this.props.history}
					exitGame={this.exitGame}
				/>
			);
		}

		if (this.props.gameType === 'pgame') {
			return (
				<PopulationTeacherGameScreen
					name={this.props.auth.displayName}
					gameInfo={this.state.gameInfo}
					gameCode={this.props.gameCode}
					game={this.props.game}
					players={this.props.players}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					history={this.props.history}
					exitGame={this.exitGame}
				/>
			);
		}

		if (this.props.gameType === 'tgame') {
			return (
				<TocTeacherGameScreen
					name={this.props.auth.displayName}
					gameInfo={this.state.gameInfo}
					gameCode={this.props.gameCode}
					game={this.props.game}
					players={this.props.players}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					history={this.props.history}
					exitGame={this.exitGame}
				/>
			);
		}

		if (this.props.gameType === 'lgame') {
			return (
				<PlanetTeacherGameScreen
					name={this.props.auth.displayName}
					gameInfo={this.state.gameInfo}
					gameCode={this.props.gameCode}
					game={this.props.game}
					meetings={this.props.meetings}
					players={this.props.players}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					history={this.props.history}
					exitGame={this.exitGame}
				/>
			);
		}

		if (this.props.gameType === 'infogame') {
			return (
				<InfoTeacherGameScreen
					name={this.props.auth.displayName}
					gameInfo={this.state.gameInfo}
					gameCode={this.props.gameCode}
					game={this.props.game}
					players={this.props.players}
					firestore={this.props.firestore}
					firebase={this.props.firebase}
					history={this.props.history}
					exitGame={this.exitGame}
				/>
			);
		}

		return <Redirect to="/notfoundpage" />;
	}
}

const mapStateToProps = state => {
	return {
		gameState: state.core.gameState,
		gameType: state.core.gameType,
		gameCode: state.core.gameCode,
		game: state.core.game,
		meetings: state.core.meetings,
		players: state.core.players,
		auth: state.firebase.auth
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setGameState: newState => dispatch(setGameState(newState)),
		setGameCode: newCode => dispatch(setGameCode(newCode)),
		setGameType: type => dispatch(setGameType(type)),
		setDBNames: names => dispatch(setDBNames(names))
	};
};

const enhance = compose(
	firebaseConnect(), // withFirebase can also be used
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	firestoreConnect((props, store) => [
		{
			// Load  from Firestore from this id
			collection: 'games',
			where: ['owner', '==', `${props.auth.uid}`],
			orderBy: [['clock', 'desc']]
		}
	]),
	connect(({ firestore }, props) => ({
		gameList: firestore.data['games']
	}))
);

export default enhance(TeacherContainer);
