import React, {Component} from "react";
import {connect} from 'react-redux';
import { setSetting } from "../infoTeacherApi";

import { Header } from "semantic-ui-react";
import SliderWithField from "../../core/components/SliderWithField";

/**
 * The component displaying all the settings during initialization of a game
 * Parent: InfoTeacherGameScreen
 * Children: None
 *
 * @param {array} settings the setting options with associated min, max, default value, and label
 */

class InfoGameSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Header textAlign="left" as="h3">
                    Initial Settings
                </Header>
                {this.props.settings.map((setting, index) => (
                    <SliderWithField
                        key={index}
                        field={setting.label}
                        label="#"
                        min={setting.min}
                        max={setting.max}
                        step={setting.max > 50 ? 10 : 1} 
                        value={this.props[setting.name]}
                        leftText={setting.min}
                        rightText={setting.max}
                        onChange={value => setSetting(setting.name, value)}
                    />
                ))}
            </div>
        );
    }
}



const mapStateToProps = (state, ownProps) => ({
	numGroups: state.infoGame.game.numGroups,
});

export default connect(mapStateToProps)(InfoGameSettings);

