import React, { Component } from "react";
import TeacherButtons from "../../../core/components/TeacherButtons.js";
import { getRandomInt } from "../../../helpers/randomNums";

const topType = ["LongHairBigHair", "LongHairBob", "LongHairBun", "LongHairCurly", "LongHairCurvy", "LongHairDreads", "LongHairFro", "LongHairFroBand", "LongHairNotTooLong", "LongHairMiaWallace", "LongHairStraight", "LongHairStraight2", "LongHairStraightStrand", "ShortHairDreads01", "ShortHairDreads02", "ShortHairFrizzle", "ShortHairShaggyMullet", "ShortHairShortCurly", "ShortHairShortFlat", "ShortHairShortRound", "ShortHairShortWaved", "ShortHairSides", "ShortHairTheCaesar", "ShortHairTheCaesarSidePart"];
const hairColor = ["Auburn", "Black", "BlondeGolden", "Brown", "BrownDark", "PastelPink", "Platinum"];
const clotheType = ["CollarSweater", "GraphicShirt", "Hoodie", "Overall", "ShirtCrewNeck", "ShirtScoopNeck", "ShirtVNeck"];
const clotheColor = ["Blue01", "Blue02", "Blue03", "Gray01", "Gray02", "Heather", "PastelBlue", "PastelGreen", "PastelOrange", "PastelRed", "PastelYellow", "Pink", "Red", "White"];
const mouthType = ["Default", "Eating", "Smile", "Tongue", "Twinkle"];
const skinColor = ["Tanned", "Yellow", "Pale", "Light", "Brown", "DarkBrown", "Black"];

// Description: provides start, stop and initialization functions
// Parent: VirusTeacherGameScreen
// Children: TeacherButtons
// Props Used:
//      averageLatency (Int)
//      checked (Bool)
//      firestore (Object)
//      game (String)
//      gameCode (String)
//      gameInfo (Object)
//      immuneProbability (Int)
//      infectionRate (Int)
//      name (String)
//      patientZero (String)
//      players (String)
//      setShowP0Error
//      setShowPlayerError

class VirusTeacherButtons extends Component {
  // constructor(props) {
  //   super(props);
  // }

  //Set the game's parameter when the game initializes
  setGameParams = () => {
    return this.props.firestore.update(
      {
        collection: this.props.game,
        doc: this.props.gameCode
      },
      {
        gameState: "paused",
        clock: this.props.firestore.FieldValue.serverTimestamp(),
        lastPause: this.props.firestore.FieldValue.serverTimestamp(),
        pausedTime: 0,
        gameParams: {
          ...this.props.gameInfo.gameParams,
          patientZero: this.props.patientZero,
          immuneProbability: this.props.immuneProbability,
          infectionRate: this.props.infectionRate,
          averageLatency: this.props.averageLatency,
          hasImmuneCarriers: this.props.hasImmuneCarriers || false
        }
      }
    );
  };

  //Randomlizes all of player's attributes when initializing
  setPlayerAttributes = allPlayersQuery => {
    const currentGameParams = this.props.gameInfo.gameParams;
    const playerList = [];
    let immuneCount = 0;
    return Promise.all([
      allPlayersQuery.forEach(doc => {
        if (doc.data().patientZero) {
          // console.log(`patient zero ${doc.id}`);
          const avatar = [
            {
              topType: getRandomInt(0, topType.length),
              hairColor: getRandomInt(0, hairColor.length),
              clotheType: getRandomInt(0, clotheType.length),
              clotheColor: getRandomInt(0, clotheColor.length),
              mouthType: getRandomInt(0, mouthType.length),
              skinColor: getRandomInt(0, skinColor.length)
            }
          ];
          return this.props.firestore.update(
            {
              collection: this.props.players,
              doc: doc.id
            },
            {
              avatar,
              sickState: false,
              sickTime: Math.round(currentGameParams.averageLatency + 40)
            }
            //Sick Time previously: Math.round(currentGameParams.averageLatency * getRandomInt(90, 110) / 100 * 2)
          );
        } else {
          let immune = false;
          let avatar = [
            {
              topType: getRandomInt(0, topType.length),
              hairColor: getRandomInt(0, hairColor.length),
              clotheType: getRandomInt(0, clotheType.length),
              clotheColor: getRandomInt(0, clotheColor.length),
              mouthType: getRandomInt(0, mouthType.length),
              skinColor: getRandomInt(0, skinColor.length)
            }
          ];
          const sickTime = -1;
          const sickState = false;
          if (getRandomInt(1, 100) <= currentGameParams.immuneProbability) {
            immune = true;
            immuneCount += 1;
            avatar[0].hairColor = 7; // 7 is the index for red (@todo can change later)
          } else {
            playerList.push(doc.id);
          }

          return this.props.firestore.update(
            {
              collection: this.props.players,
              doc: doc.id
            },
            {
              immune,
              avatar,
              sickTime,
              sickState
            }
          );
        }
      })
      // all players here.
    ]).then(result => {
      // Make sure there are at least 2 immunes if there are at least 8 players
      const promises = [];
      if (
        this.props.immuneProbability !== 0 &&
        immuneCount < 2 &&
        allPlayersQuery.size >= 8
      ) {
        for (let i = 0; i <= 1 - immuneCount; i += 1) {
          var immuneIndex = getRandomInt(0, playerList.length - 1);
          var newImmune = playerList[immuneIndex];
          let avatar = [
            {
              topType: getRandomInt(0, topType.length),
              hairColor: 7, // 7 is the index for red (@todo can change later)
              clotheType: getRandomInt(0, clotheType.length),
              clotheColor: getRandomInt(0, clotheColor.length),
              mouthType: getRandomInt(0, mouthType.length),
              skinColor: getRandomInt(0, skinColor.length)
            }
          ];
          const immune = true;
          promises.push(
            this.props.firestore.update(
              {
                collection: this.props.players,
                doc: newImmune
              },
              { immune, avatar }
            )
          );
          playerList.splice(immuneIndex, 1);
        }
      }

      Promise.all(promises);
    });
  };

  // check that there is > 0 players and a patient zero, then submit the game
  handleInitialization = () => {
    return new Promise(resolve => {
      let noPlayers = true;
      this.props.firestore
        .get({
          collection: this.props.players,
          where: ["currentGameId", "==", this.props.gameCode]
        })
        .then(allPlayers => {
          allPlayers.forEach(doc => {
            noPlayers = false;
          });
          if (noPlayers) {
            //this.setState({ showPlayerError: true });
            this.props.setShowPlayerError();
            resolve(false);
            return;
          }
          //console.log(this.state.patientZero);
          if (this.props.patientZero === "") {
            //this.setState({ showP0Error: true });
            this.props.setShowP0Error();
            resolve(false);
            return;
          } else {
            //this.unsetp0MeetingsListener(); //these are undefined - in teacher monitor now
            //this.setp0MeetingsListener();
          }

          this.setGameParams()
            .then(response1 => {
              this.props.firestore
                .update(
                  {
                    collection: this.props.players,
                    doc: this.props.patientZero
                  },
                  {
                    patientZero: true,
                    immune: false
                  }
                )
                .then(response2 => {
                  this.handlePause().then(response3 => {
                    this.props.firestore
                      .get({
                        collection: this.props.players,
                        where: ["currentGameId", "==", this.props.gameCode],
                        orderBy: [["displayName", "asc"]]
                      })
                      .then(allPlayersQuery => {
                        this.setPlayerAttributes(allPlayersQuery).then(
                          result => {
                            resolve(true);
                          }
                        );
                      });
                  });
                });
            })
            .catch(err => console.log(err));
        });
    });
  };

  // handles running the game upon button press
  handleRunning = () => {
    const { gameInfo } = this.props;
    const p0Restart = this.props.gameInfo.p0Restart;
    const newPaused = Math.round(
      gameInfo.pausedTime + (Date.now() - gameInfo.lastPause.toMillis()) / 1000
    );

    if (this.props.checked) {
      // this.clearTimer();
      // this.setTimer();
    }
    return this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: "running",
          pausedTime: newPaused,
          gameParams: {
            patientZero: this.props.patientZero,
            immuneProbability: this.props.immuneProbability,
            infectionRate: this.props.infectionRate,
            averageLatency: this.props.averageLatency,
            hasImmuneCarriers: this.props.hasImmuneCarriers || false
          },
          p0Restart: false
        }
      )
      .then(() => {
        this.props.firestore
          .update(
            {
              collection: "games",
              doc: this.props.gameCode
            },
            {
              gameState: "running"
            }
          )
          .then(() => {
            if (p0Restart) {
              this.props.firestore
                .update(
                  {
                    collection: this.props.players,
                    doc: this.props.patientZero
                  },
                  {
                    patientZero: true,
                    sickState: false,
                    sickTime: Math.round(
                      this.props.gameInfo.gameParams.averageLatency + 40
                    )
                  }
                )
                .then(() => {
                  this.props.firestore
                    .get({
                      collection: this.props.players,
                      where: ["currentGameId", "==", this.props.gameCode],
                      orderBy: [["displayName", "asc"]]
                    })
                    .then(allPlayersQuery => {
                      this.setPlayerAttributes(allPlayersQuery);
                    });
                });

              //Sick Time previously: this.props.gameInfo.gameParams.averageLatency * Math.random(90,100)/100 * 1.5
            }
          });
      });
  };

  // handles pausing the game upon button press
  handlePause = () => {
    // this.clearTimer();

    return this.props.firestore
      .update(
        {
          collection: this.props.game,
          doc: this.props.gameCode
        },
        {
          gameState: "paused",
          lastPause: this.props.firestore.FieldValue.serverTimestamp()
        }
      )
      .then(response => {
        this.props.firestore.update(
          {
            collection: "games",
            doc: this.props.gameCode
          },
          {
            gameState: "paused"
          }
        );
      });
  };

  render() {
    return (
      <TeacherButtons
        name={this.props.name}
        gameInfo={this.props.gameInfo}
        handleInitialization={this.handleInitialization}
        handlePause={this.handlePause}
        handleRunning={this.handleRunning}
      />
    );
  }
}

export default VirusTeacherButtons;
