import React, { Component } from 'react';
import LineChart from '../../../core/components/LineChart';
import { Header } from 'semantic-ui-react';
import BasicGreyPage from '../../../core/components/BasicGreyPage';
import QrComponents from './QrComponents';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import LogoutConfirmation from '../../../core/components/LogoutConfirmation';
import YesNoDialog from '../../../core/components/YesNoDialog';
import DimIcon from '../../../core/components/DimIcon';
import SingleElementAccordion from '../../../core/components/SingleElementAccordion';
import firebase from 'firebase';
import { isLoaded } from 'react-redux-firebase';

const db = firebase.firestore();

class PopulationPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generationPause: false,
            movementLoss: false,
            predation: false,
            populationChange: null,
            playerData: null,
            gameState: null,
            gameCode: null,
            gameListener: false,
            currentConfiguration: '0',
            buttonPressed: false,
            showLogoutConfirmation: false,
            metabolismTimer: null
        };
        this.metabolismRate = 10;
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
        this.playerListen();
    }

    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        this.removeMetabolismTimer();
        this.props.firestore.unsetListener({
            collection: this.props.game,
            doc: this.state.gameCode,
        });
        this.props.firestore.unsetListener({
            collection: this.props.players,
            doc: this.props.auth.uid,
        });
    };

    playerListen = () => {
        this.props.firestore.setListener(
            {
                collection: this.props.players,
                doc: this.props.auth.uid,
            },
            playerdata => {
                if (
                    playerdata.data() !== null &&
                    playerdata.data() !== undefined
                ) {
                    let populationChange = null || this.state.populationChange;
                    const newPlayerData = playerdata.data();
                    if (newPlayerData.newGenerationFlag !== null) {
                        populationChange =
                            newPlayerData.population -
                            newPlayerData.newGenerationFlag;
                        this.props.firestore.update(
                            {
                                collection: this.props.players,
                                doc: this.props.auth.uid,
                            },
                            {
                                newGenerationFlag: null,
                            },
                        );
                    }
                    this.setState({
                        playerData: newPlayerData,
                        gameCode: newPlayerData.currentGameId,
                        populationChange,
                    });
                }
                if (!this.state.gameListener) {
                    this.setState({ gameListener: true });
                    this.gameListen(this.state.gameCode);
                }
            },
        );
    };

    gameListen = gameCode => {
        if(!gameCode){
            this.props.firebase.logout();
            this.props.loggedOut();
            return;
        } 
        this.props.firestore.setListener(
            {
                collection: this.props.game,
                doc: gameCode,
            },
            gamedata => {
                if (this.state.metabolismTimer !== null && 
                    (gamedata.data().gameState === 'paused' || gamedata.data().generation || gamedata.data().movementLoss)) {
                    this.removeMetabolismTimer();
                } else if (this.state.metabolismTimer === null && gamedata.data().gameState === 'running' 
                    && !gamedata.data().generation && !gamedata.data().movementLoss) {
                    this.addMetabolismTimer();
                }

                this.setState({
                    gameState: gamedata.data().gameState,
                    generationPause: gamedata.data().generation,
                    movementLoss: gamedata.data().movementLoss,
                    predation: gamedata.data().predation
                });
            },
        );
    };

    addMetabolismTimer = () => {
        const metabolismTimer = setInterval(() => {
            if (this.state.playerData.energy >= 1) {
                this.props.firestore.update(
                    {
                        collection: this.props.players,
                        doc: this.props.auth.uid,
                    },
                    {
                        energy: this.state.playerData.energy - 1,
                    },
                );
            }
        }, this.metabolismRate * 1000);
        this.setState({ metabolismTimer });
    };

    removeMetabolismTimer = () => {
        clearInterval(this.state.metabolismTimer);
        this.setState({ metabolismTimer: null });
    };

    render() {
        if (this.state.showLogoutConfirmation) {
            return (
                <LogoutConfirmation
                    logoutAction={() => {
                        this.setState({ buttonPressed: true });
                        this.props.firebase.logout();
                        this.props.loggedOut();
                    }}
                    backAction={() =>
                        this.setState({ showLogoutConfirmation: false })
                    }
                />
            );
        }

        if (!this.state.buttonPressed) {
            return (
                <YesNoDialog
                    header="Enter Game"
                    description={
                        'You are about to enter the game with code ' +
                        this.state.gameCode +
                        '.  Would you like to continue or log out?'
                    }
                    yesText="Continue"
                    yesAction={() => {
                        this.setState({ buttonPressed: true });
                    }}
                    noText="Log Out"
                    noAction={() =>
                        this.setState({ showLogoutConfirmation: true })
                    }
                />
            );
        }

        if (
            this.state.gameCode === '0' ||
            !isLoaded(this.props.auth) ||
            this.state.gameState === 'initializing' ||
            this.state.gameState === 'unknown'
        ) {
            return <DimIcon message="Waiting for Game to Start" iconName="pause" />;
        }

        if (this.state.gameState !== 'running') {
            return <DimIcon message="Game Paused by Teacher" iconName="pause" />;
        }

        const center = (
            <div>
                <QrComponents
                    game={this.props.game}
                    gameCode={this.state.gameCode}
                    players={this.props.players}
                    uid={this.props.auth.uid}
                    currentConfiguration={this.state.currentConfiguration}
                    generationPause={this.state.generationPause}
                    predation={this.state.predation}
                    movementLoss={this.state.movementLoss}
                    population={this.state.playerData.population}
                    energy={this.state.playerData.energy}
                    lastStation={this.state.playerData.lastStation}
                    db={db}
                />
                <Header>
                    {' '}
                    Energy:{' '}
                    {this.state.playerData
                        ? this.state.playerData.energy
                        : null}{' '}
                </Header>
                <Header>
                    {' '}
                    Population:{' '}
                    {this.state.playerData
                        ? this.state.playerData.population
                        : null}{' '}
                </Header>

                {this.state.generationPause && (
                    <SingleElementAccordion
                        title="Population Graph"
                        content={
                            <div style={{ height: '35vh', width: '100%'}}>
                                {/* Will no longer work, need to update props */}
                                <LineChart
                                    data={this.state.playerData !== null
                                        ? this.state.playerData.populationData
                                        : { '0': 0 }}
                                    layout={{
                                        xaxis: {
                                            title: {
                                                text: "Generation"
                                            }
                                        },
                                        yaxis: {
                                            title: {
                                                text: "Population Count"
                                            }
                                        }
                                    }}
                                    marker={{ color: "black" }}
                                    mode="lines+points"
                                />
                            </div>
                        }
                    />
                )}
            </div>
        );

        return (
            <div style={{ height: '100%' }}>
                {this.state.populationChange !== null && (
                    <SingleButtonDialog
                        description={
                            'Based on your performance, the population of your next generation ' +
                            (this.state.populationChange > 0
                                ? 'increased '
                                : 'decreased ') +
                            'by ' +
                            Math.abs(this.state.populationChange) +
                            '.'
                        }
                        buttonText="Close"
                        buttonAction={() =>
                            this.setState({ populationChange: null })
                        }
                    />
                )}

                <BasicGreyPage
                    top={<div style={{ marginBottom: '5vh' }} />}
                    center={center}
                    bottom={<div style={{ marginBottom: '15vh' }} />}
                />
            </div>
        );
    }
}

export default PopulationPlayer;
