import firebase from 'firebase';
import store from '../store/store';
import { worldActions } from './slices/worldSlice';
import { playerActions } from './slices/playerSlice';
import { contractActions } from './slices/contractsSlice';
import { GAME_STATUS, STORE_PRICES } from './constants';

/* 
TIP: to fold all the functions that your cursor is not in, 
—— press ctrl + K, then ctrl 1 ——
(to unfold all, ctrl + K, then ctrl + J) (cmd on Mac)
It'll make this file a lot easier to navigate. Trust me.
*/

/*
NOTE: -----Redux structure-----
The structure of the planetGame part of the Redux store follows the way the data is stored in 
the database.

You can see the specific structures of each slice (playerSlice, worldSlice, contractsSlice) 
in their respective files.
*/

// all unsubscribe functions to be called when leaving a page, typically for listeners
const unsubscribes = [];
window.onbeforeunload = () => {
	for (const unsubscribe of unsubscribes) unsubscribe();
	return null;
};

/*
NOTE: All functions are structured like so: 
1) perform the desired interacations with the database
2) update the Redux state accordingly ONLY IF the database was successfully updated
*/

// In the future this can indicate any provider that we support
const dbProvider = 'firebase';

/**************** Convenience ****************/

// Convenience for Redux
const dispatch = store.dispatch;
let state = store.getState();
let gameState = state.planetGame;
unsubscribes.push(
	store.subscribe(() => {
		state = store.getState();
		gameState = state.planetGame;
	})
);

// Convenience for Firebase
const firestore = firebase.firestore;
const games = firestore().collection('lgames');

/* 
NOTE: Population numbers in Firebase multiplied by 1000 so they're pseudo-decimal numbers
Exception: This doesn't apply to goalPopulation, which is a static value so no worries there.
Reasoning: if you have a one month difference and the (small) change in population is rounded, 
the values may never change (also Firebase does not have easy support for saving numbers 
that aren't integers)
*/
const POPULATION_MULTIPLIER = 1000;

// Frequent calls
const getGameCode = () => state.core.gameCode;

const getPlayerId = () => gameState.player.id;

export const getMonth = () => gameState.world.currentMonth;

/**************** Joining ****************/

/**
 * Adds a player into the game in the database and handles joining
 * @param {string} playerName the name for this player
 * @returns whether or not the player was successfully created
 */
export const joinPlayer = async playerName => {
	console.log('[playerApi] running createPlayer');
	// no longer needed for sensor number update, will delete after confirming
	// let sensorNum = 0
	// if (gameState.world.gameStatus !== GAME_STATUS.INITIALIZING) {
	// 	sensorNum = await games
	// 			.doc(getGameCode())
	// 			.get()
	// 			.then(doc => doc.get("numSensors"));
	// }
	
	// validate playerName
	if (
		playerName === undefined ||
		playerName === '' ||
		(await !games
			.doc(getGameCode())
			.collection('players')
			.where('displayName', '==', playerName)
			.get()
			.then(querySnapshot => querySnapshot.empty))
	)
		return false;

	const playerId = firebase.auth().currentUser.uid;
	const player = {
		displayName: playerName,
		coins: 0,
		contractsDone: 0,
		sensorsBought: 0,
		contractsBought: 0,
		animals: [],
		sensorsCapacity: 0, /*
			(await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => docSnapshot.get('numSensors'))) ?? 0, */
		activeSensors: [],
		collectedSensors: [],
		savedGraphs: [],
		approve: false,
		denied: false,
		rejoin: false
	};

	// update database
	switch (dbProvider) {
		case 'firebase':
			// add player to game
			let success =
				(await games
					.doc(getGameCode())
					.collection('players')
					.doc(playerId)
					.set(player)
					.then(() => true)
					.catch(error => console.error(error))) &&
				(await games
					.doc(getGameCode())
					.update({ players: firestore.FieldValue.increment(1) })
					.then(() => true)
					.catch(error => console.error(error)));

			// update separate list of players in game
			success =
				success === true &&
				(await firestore()
					.collection('games')
					.doc(getGameCode())
					.update({
						players: firestore.FieldValue.arrayUnion(playerId)
					})
					.then(() => true)
					.catch(error => console.error(error)));

			return success === true;
		default:
			return false;
	}
};

/**
 * Handles a player logging back into a game (by entering name and game code)
 * @param {string} playerName the name for this player
 * @returns whether or not the player was successfully created
 */
export const rejoinPlayer = async playerName => {
	console.log('[playerApi] running rejoinPlayer');
	if (playerName === undefined || playerName === '') return false;
	const playerId = firebase.auth().currentUser.uid;

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.where('displayName', '==', playerName)
				.get()
				.then(async querySnapshot => {
					if (!querySnapshot.empty)
						return await querySnapshot.docs[0].ref
							.update({
								uid: playerId,
								rejoin: true
							})
							.then(() => true);
				})
				.catch(error => console.error(error));

			return success === true;
		default:
			return false;
	}
};

/**
 * Gets the state (e.g. running, paused, etc.) of the current game
 */
export const getGameStatus = async () => {
	console.log('[playerApi] running getGameStatus');
	let state;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			state = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => docSnapshot.get('gameStatus'))
				.catch(error => console.error(error));
			break;
		default:
			state = undefined;
	}

	// perform Redux action
	dispatch(worldActions.setGameStatus(state));
};

/**
 * Attempt to rejoin the specified game code while player info is cached
 * @returns '' if login was successful, otherwise 'player' or 'code' to indicate where it failed
 */
export const attemptJoin = async () => {
	console.log('[playerApi] running attemptJoin');
	let id = '';

	switch (dbProvider) {
		case 'firebase':
			const currentId = firebase.auth().currentUser.uid;
			const exists = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => docSnapshot.exists);
			if (!exists) return 'code';

			await games
				.doc(getGameCode())
				.collection('players')
				.doc(currentId)
				.get()
				.then(docSnapshot => {
					// new player
					if (docSnapshot.exists) id = currentId;
				});
			if (id === '') {
				// rejoining player
				const querySnapshot = await games
					.doc(getGameCode())
					.collection('players')
					.where('rejoin', '==', true)
					.where('uid', '==', currentId)
					.get()
					.then(querySnapshot => querySnapshot);
				if (!querySnapshot.empty) id = querySnapshot.docs[0].id;
				else return 'player';
			}

			// at this point, id is guaranteed to be nonempty
			break;
		default:
			break;
	}

	dispatch(playerActions.setId(id));
	return 'valid';
};

/**
 * Set the listener for game status: 'initializing', 'paused', 'running' (once at game start)
 */
export const listenToGameStatus = async () => {
	console.log('[playerApi] running setGameStatusListener');

	switch (dbProvider) {
		case 'firebase':
			await games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update game state if changed
				const newGameStatus = docSnapshot.get('gameStatus');
				if (newGameStatus !== gameState.world.gameStatus) {
					if (gameState.world.gameStatus === GAME_STATUS.INITIALIZING) {
						// game finished initializing, get data now
						setUpGame();

						// now that numSensors exists as a field, update player's sensorsCapacity
						const newLimit = await games
							.doc(getGameCode())
							.get()
							.then(docSnapshot => docSnapshot.get('numSensors'));
						await games.doc(getGameCode()).collection('players').doc(getPlayerId()).update({
							sensorsCapacity: newLimit
						});
						dispatch(playerActions.setData({ ...gameState.player, sensorsCapacity: newLimit }));
					}
					dispatch(worldActions.setGameStatus(newGameStatus));
				}
			});
			return;
		default:
			return;
	}
};

/**
 * Get the current month number and sets listeners for game state information (month number and players)
 */
const setListeners = async () => {
	console.log('[playerApi] running setListeners');
	let month, qrOn, sendDataOn, storeOn, otherPlayers;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			// get month
			const data = await games
				.doc(getGameCode())
				.get()
				.then(docSnapshot => ({
					month: docSnapshot.get('currentMonth'),
					qrOn: docSnapshot.get('qrOn')
				}))
				.catch(error => console.error(error));
			month = data.month;
			qrOn = data.qrOn;
			sendDataOn = data.sendDataOn;
			storeOn = data.storeOn;

			// get players
			otherPlayers = await games
				.doc(getGameCode())
				.collection('players')
				.get()
				.then(colSnapshot =>
					colSnapshot.docs
						.filter(doc => doc.id !== getPlayerId())
						.map(doc => ({
							name: doc.get('displayName'),
							id: doc.id
						}))
				)
				.catch(error => console.error(error));

			// set listener for month and players
			const gameUnsub = games.doc(getGameCode()).onSnapshot(async docSnapshot => {
				// update month if changed
				const gameMonth = docSnapshot.get('currentMonth');
				if (gameMonth !== getMonth()) dispatch(worldActions.setMonth(gameMonth));

				// update players if changed
				const numPlayers = docSnapshot.get('players');
				if (numPlayers !== gameState.world.players.length - 1) {
					const playersList = await docSnapshot.ref
						.collection('players')
						.get()
						.then(colSnapshot =>
							colSnapshot.docs
								.filter(doc => doc.id !== getPlayerId())
								.map(doc => ({
									name: doc.get('displayName'),
									id: doc.id
								}))
						)
						.catch(error => console.error(error));
					dispatch(worldActions.setPlayers(playersList));
				}

				// update if QR mode / menu visiability changed
				const qrOn = docSnapshot.get('qrOn');
				const storeOn = docSnapshot.get('storeOn');
				const sendDataOn = docSnapshot.get('sendDataOn');
				if (qrOn !== gameState.world.qrOn) dispatch(worldActions.setQr(qrOn));
				if (storeOn !== gameState.world.storeOn) dispatch(worldActions.setStore(storeOn));
				if (sendDataOn !== gameState.world.sendDataOn) dispatch(worldActions.setSendData(sendDataOn));
			});

			// set listener for any incoming data
			const dataUnsub = games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.onSnapshot(docSnapshot => {
					const currentSensors = gameState.player.collectedSensors,
						updatedSensors = docSnapshot.get('collectedSensors');
					if (updatedSensors.length !== currentSensors.length) {
						for (const data of updatedSensors.filter(
							g1 => g1.origin !== undefined && currentSensors.find(g2 => g1.timeId === g2.timeId) === undefined
						))
							dispatch(playerActions.addData(data));
					}
				});

			unsubscribes.push(gameUnsub);
			unsubscribes.push(dataUnsub);
			break;
		default:
			month = 0;
			otherPlayers = [];
	}

	// perform Redux action
	dispatch(worldActions.setMonth(month));
	dispatch(worldActions.setQr(qrOn));
	dispatch(worldActions.setPlayers(otherPlayers));
};

/**
 * The function to call that will set up everything a player needs to start playing (once at game start)
 */
export const setUpGame = async () => {
	await getGameData();
	await setListeners();
	//update sensor capacity if number is incorrect
	if (gameState.player.sensorsCapacity !== gameState.world.numSensors) await addSensorNum();
	if (gameState.player.animals.length === 0) await addContract();
	if (gameState.player.coins === 0) await addCoin();
};

/**************** Getters ****************/

/**
 * Fetches the current player's data (when: once at game start)
 */
const getPlayerData = async () => {
	console.log('[playerApi] running getPlayerData');
	let data;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			data = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.get()
				.then(docSnapshot => ({
					id: docSnapshot.id,
					name: docSnapshot.get('displayName'),
					coins: docSnapshot.get('coins'),
					points: docSnapshot.get('contractsDone'),
					animals: docSnapshot.get('animals'),
					sensorsBought: docSnapshot.get('sensorsBought'),
					contractsBought: docSnapshot.get('constractsBought'),
					sensorsCapacity: docSnapshot.get('sensorsCapacity'),
					activeSensors: docSnapshot.get('activeSensors'),
					collectedSensors: docSnapshot.get('collectedSensors'),
					//sensorsCapacity: docSnapshot.get('sensorsCapacity'),
					savedGraphs: docSnapshot.get('savedGraphs')
				}))
				.catch(error => console.error(error));
			break;
		default:
			break;
	}

	// perform Redux action
	if (data !== undefined) dispatch(playerActions.setData(data));
};

/**
 * Fetches data of all planets (planet docs) (when: once at game start, since region data is fixed)
 */
const getPlanetsData = async () => {
	console.log('[playerApi] running getPlanetsData');
	let planets;

	// get from database
	switch (dbProvider) {
		case 'firebase':
			planets = await games
				.doc(getGameCode())
				.collection('planets')
				.get()
				.then(querySnapshot =>
					querySnapshot.docs.map(planetDoc => {
						// for each planet
						const planet = planetDoc.data();
						const regions = [];
						for (const regionIndex in Object.keys(planet.regions)) {
							const region = planet.regions[regionIndex];
							if (region.animals !== undefined) {
								for (const animal in region.animals)
									region.animals[animal].amount /= POPULATION_MULTIPLIER;
							} else region.animals = {};
							regions.push(region);
						}
						return {
							name: planet.name, // get basic info
							regions: regions,
							gasComp: planet.gasComp,
							numRegions: planet.numRegions,
							style: planet.style
						};
					})
				)
				.catch(error => console.error(error));
			break;
		default:
			planets = [];
	}

	// perform Redux action
	if (planets !== undefined) dispatch(worldActions.setPlanets(planets));
};

/**
 * Fetches data of all of the player's animals (animal docs) (when: once at game start)
 */
const getAnimalsData = async () => {
	console.log('[playerApi] running getAnimalsData');
	let animalPayloads;

	// update database
	switch (dbProvider) {
		case 'firebase':
			animalPayloads = await games
				.doc(getGameCode())
				.collection('animals')
				.get()
				.then(colSnapshot =>
					colSnapshot.docs
						.filter(doc => doc.get('playerId') === getPlayerId())
						.map(doc => {
							const data = doc.data();
							data.populationInShip /= POPULATION_MULTIPLIER;
							return data;
						})
				)
				.catch(error => console.error(error));
			break;
		default:
			return;
	}

	// perform Redux action
	if (animalPayloads !== undefined)
		for (const payload of animalPayloads) dispatch(contractActions.addAnimal(payload));
};

/**
 * Fetches all data for the current game (when: once at game start)
 */
const getGameData = async () => {
	dispatch(playerActions.reset());
	dispatch(contractActions.reset());
	await getPlayerData();
	await getPlanetsData();
	await getAnimalsData();
};

/**
 * Refreshes an animal's population at a region, updating it if time has passed
 * since last update (when: depositing/extracting animals)
 * @param {string} animalName the specified animal
 * @param {number} planet the planet index
 * @param {number} region the region index
 */
export const getAnimalPopulation = async (animalName, planet, region) => {
	console.log('[playerApi] running getAnimalPopulation');
	console.log(`* Vars: ${animalName} on planet ${planet} region ${region}`);
	const animalNums = gameState.world.planets[planet].regions[region].animals[animalName];
	console.log(`* [playerApi] returned ${animalNums} for animalNums`);
	if (animalNums === undefined) return;

	if (animalNums.lastUpdated !== getMonth()) {
		// compute all existing populations because time passed
		let newPopulation = computePopulation(
			animalNums.amount,
			animalNums.suitability,
			getMonth() - animalNums.lastUpdated
		);
		if (newPopulation < 1) newPopulation = 0;

		// update database
		switch (dbProvider) {
			case 'firebase':
				const success = await games
					.doc(getGameCode())
					.collection('planets')
					.doc(planet.toString())
					.update({
						[`regions.${region}.animals.${animalName}.amount`]:
							newPopulation * POPULATION_MULTIPLIER,
						[`regions.${region}.animals.${animalName}.lastUpdated`]: getMonth()
					})
					.then(() => true)
					.catch(error => console.error(error));
				if (success === true) break;
				else return;
			default:
				return;
		}

		// perform Redux action
		dispatch(
			worldActions.updateAnimal({
				planet: planet,
				region: region,
				animalName: animalName,
				change: newPopulation - animalNums.amount
			})
		);
	}
};

/**
 * HELPER // Compute new populations based on old population, suitability, time passed
 * @param {number} prevPopulation
 * @param {number} score
 * @param {number} monthsDiff
 * @returns the new population with the given parameters
 */
const computePopulation = (prevPopulation, score, monthsDiff) => {
	let scale;
	if (score >= 85) {
		scale = 2.5;
	} else if (score >= 65) {
		scale = 1.5;
	} else if (score >= 40) {
		scale = 1;
	} else if (score >= 15) {
		scale = 0.5;
	} else {
		scale = 0;
	}
	return prevPopulation * scale ** (monthsDiff / 12);
};

// export const computeReward = async (animalName) => {
// 	const animal = gameState.contracts.animals[animalName],
// 		coinsGained = Math.round(
// 			50 + animal.goalPopulation / 20 + (animal.populationInShip - animal.goalPopulation) / 40
// 		);
// 	console.log(`animalName: ${animalName}`);
// 	return coinsGained
// };

/**************** Setters ****************/

/**
 * Change an animal's population in a region (meaning positive = placing down)
 * @param {string} animalName the specified animal
 * @param {number} planet the planet index
 * @param {number} region the region index
 * @param {number} amount the amount to change the region population by
 */
export const updateAnimalPopulation = async (animalName, planet, region, amount) => {
	/* 
	NOTE: it is important to ensure that the database in use stores population values in non-integer animal amounts
	(i.e. support not only 1 zebra, 2 zebras, but 1.25 zebras, 2.394 zebras, etc.) in order for the population to
	properly grow over time.
	However, it is equally important to ensure that any values that fall between 0 and 1 animals should be rounded
	down to 0 automatically to prevent those numbers from growing into values greater than 1 (i.e. 0 should remain 0)
	This must be implemented in each switch case/for each database independently.
	*/
	console.log('[playerApi] running updateAnimalPopulation');
	if (animalName === '') return;

	const searchForNums = gameState.world.planets[planet].regions[region].animals[animalName],
		animalFound = searchForNums !== undefined,
		animalNums = searchForNums ?? {
			// this object only needs to be used when adding an animal to a region
			amount: amount,
			suitability: computeSuitability(
				gameState.contracts.animals[animalName],
				gameState.world.planets[planet].regions[region],
				gameState.world.planets[planet].gasComp
			),
			lastUpdated: getMonth()
		},
		regionPopulation = searchForNums?.amount ?? 0,
		shipPopulation = gameState.contracts.animals[animalName].populationInShip;

	// update database
	switch (dbProvider) {
		case 'firebase':
			// first, modify animal's numbers in that region
			let success = await games
				.doc(getGameCode())
				.collection('planets')
				.doc(planet.toString())
				.get()
				.then(docSnapshot => {
					if (!animalFound) {
						// animal does not exist in this region, so add it
						animalNums.amount *= POPULATION_MULTIPLIER;
						docSnapshot.ref.update({
							[`regions.${region}.animals.${animalName}`]: animalNums
						});
						animalNums.amount = amount;
					} else {
						// update animal amount, ensuring that the amount
						// 1) does not reach negative values and
						// 2) cannot increase from 0 (in the case where it's between 0 and 1)
						docSnapshot.ref.update({
							[`regions.${region}.animals.${animalName}.amount`]:
								regionPopulation + amount < 1
									? 0
									: firestore.FieldValue.increment(amount * POPULATION_MULTIPLIER)
						});
					}
					return true; // indicates successful update
				})
				.catch(error => console.error(error));

			// then, modify animal's ship population (ensuring the amount remains nonnegative)
			success =
				success === true &&
				(await games
					.doc(getGameCode())
					.collection('animals')
					.doc(animalName)
					.update({
						populationInShip: firestore.FieldValue.increment(
							Math.max(-amount, -shipPopulation) * POPULATION_MULTIPLIER
						)
					})
					.then(() => true)
					.catch(error => console.error(error)));

			// update Redux only if Firebase interaction was successful
			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux actions
	if (!animalFound) {
		dispatch(
			worldActions.addAnimal({
				planet: planet,
				region: region,
				animalName: animalName,
				animalNums: animalNums
			})
		);
	} else {
		dispatch(
			worldActions.updateAnimal({
				planet: planet,
				region: region,
				animalName: animalName,
				change: amount
			})
		);
	}

	dispatch(
		contractActions.updateShipPopulation({
			animalName: animalName,
			change: -amount
		})
	);
};

/**
 * HELPER // Returns [number, number, number, number] based on twelve monthly measurements
 */
const seasonalAverages = data => {
	const averages = [];
	for (let i = 0; i < 4; i++) {
		averages.push(Math.floor((data[3 * i] + data[3 * i + 1] + data[3 * i + 2]) / 3));
	}
	return averages;
};

/**
 * HELPER // Compute how fit an animal is to live in a region
 * @returns demical value indicating score (0-100)
 */
const computeSuitability = (animal, regionData, gasComp) => {
	/* params:
	animal: {
		gasTol: [key: string]: number[], 
		rainTol: number[], 
		tempTol: number[], 
		...
	},
	regionData: {
		rain: number[],
		temp: number[],
		...
	},
	gasComp: { [key: string]: number } 
	*/

	/////////////////////// computational helpers ///////////////////////
	// returns whether or not a value is within a range (inclusive)
	const withinRange = (number, range) => number >= range[0] && number <= range[1];

	// returns decimal value (0 to 1)
	const gasSuitability = (gasTol, gasData) => {
		let score = 0;
		for (const gas in gasTol) {
			if (gasData[gas] === undefined) continue;
			else if (withinRange(gasData[gas], gasTol[gas])) score += 1;
			else if (withinRange(gasData[gas], [gasTol[0] - 15, gasTol[1] + 15])) score += 0.5;
		}
		return score / Object.keys(gasTol).length;
	};

	// returns decimal value (0 to 1) (used for rain and temp data because computation is the same)
	const weatherSuitability = (tol, data) =>
		data.filter(value => withinRange(value, tol)).length * 0.25;

	/////////////////////// overall computation ///////////////////////
	const gasScore = gasSuitability(animal.gasTol, gasComp),
		tempScore = weatherSuitability(animal.tempTol, seasonalAverages(regionData.temp)),
		rainScore = weatherSuitability(animal.rainTol, seasonalAverages(regionData.rain));
	// the multipliers are the weights
	return gasScore * 50 + tempScore * 30 + rainScore + 20;
};

/**
 * Update a player's sensor capacity to the current game's sensor count
 */
export const addSensorNum = async () => {
	console.log('[playerApi] running checkSensorNum');
	if(gameState.world.gameStatus !== GAME_STATUS.INITIALIZING) {
		let sensorNum = 0
		sensorNum = await games
				.doc(getGameCode())
				.get()
				.then(doc => doc.get("numSensors"));
	
		// update database
		switch (dbProvider) {
			case 'firebase':
				const success = await games
					.doc(getGameCode())
					.collection('players')
					.doc(getPlayerId())
					.update({
						sensorsCapacity: sensorNum,
					})
					.then(() => true)
					.catch(error => console.error(error));

				if (success === true) break;
				else return;
			default:
				return;
		}
		
		// perform Redux action
		dispatch(playerActions.updateSensorNum(sensorNum));
	}
};

/**
 * Add a contract to a player's list of contracts
 * @param {boolean} paid bought in store or not (if so, deduct cost from player's balance)
 */
export const addContract = async (paid = false) => {
	console.log('[playerApi] running addContract');
	let animal = generateContract();

	// update database
	switch (dbProvider) {
		case 'firebase':
			// first, add the contract
			animal.populationInShip *= POPULATION_MULTIPLIER;
			let success = await games
				.doc(getGameCode())
				.collection('animals')
				.doc(animal.name)
				.set(animal)
				.then(() => true)
				.catch(error => console.error(error));
			
			

			// then, give the player the contract, updating number of purchased contracts if paid for
			success =
				success === true &&
				(await games
					.doc(getGameCode())
					.collection('players')
					.doc(getPlayerId())
					.update({
						animals: firestore.FieldValue.arrayUnion(animal.name),
						coins: firestore.FieldValue.increment(paid ? -(STORE_PRICES.ADD_CONTRACT /* * (1 + player.contractsBought/2) */) : 0),
						contractsBought : firestore.FieldValue.increment(1)
					})
					.then(() => true)
					.catch(error => console.error(error)));

			// update Redux only if database interaction was successful
			if (success === true) {
				animal.populationInShip /= POPULATION_MULTIPLIER;
				break;
			} else return;
		default:
			return;
	}

	// perform Redux actions
	dispatch(contractActions.addAnimal(animal));
	dispatch(playerActions.addAnimal({ name: animal.name, paid: paid }));
};

/**
 * Update a player's coin count to the current game's initial coin count
 */
export const addCoin = async() => {
	console.log('[playerApi] running addCoin');
	if(gameState.world.gameStatus !== GAME_STATUS.INITIALIZING) {
		let startCoin = 0
		startCoin = await games
				.doc(getGameCode())
				.get()
				.then(doc => doc.get("startingCoin"));
	
		// update database
		switch (dbProvider) {
			case 'firebase':
				const success = await games
					.doc(getGameCode())
					.collection('players')
					.doc(getPlayerId())
					.update({
						coins: startCoin,
					})
					.then(() => true)
					.catch(error => console.error(error));

				if (success === true) break;
				else return;
			default:
				return;
		}
		
		// perform Redux action
		dispatch(playerActions.updateCoin(startCoin));
	}
};

/**
 * HELPER // Generate new animal to add to a player
 * @param {object} player an object containing the id, name, and list of animals of the player to receive this contract (only needed for teacherApi)
 * @returns an object representing an animal document
 */
export const generateContract = (player = undefined) => {
	/////////////////////// helpers ///////////////////////
	// split up for the purpose of Prettier not expanding the entire array of names
	const adjs1 = ['Mountain', 'Dwarf', 'Purple', 'Jumping', 'Snow', 'Red', 'Vegan', 'Fruit'],
		adjs2 = ['Emperor', 'Northern', 'Goblin', 'Fruit', 'Honey', 'Toxic', 'Blue', 'Fire'],
		adjs3 = ['Immortal', 'Freshwater', 'Sea', 'Hungry', 'Striped', 'Long', 'Pale', 'Giant'],
		adjs = adjs1.concat(adjs2).concat(adjs3);
	const nouns1 = ['Monkey', 'Unicorn', 'Dolphin', 'Axolotl', 'Spider', 'Crab', 'Penguin'],
		nouns2 = ['Llama', 'Hummingbird', 'Squid', 'Dodo', 'Lion', 'MobsterLobster', 'Gecko', 'Panda'],
		nouns3 = ['Zebra', 'Orangutan', 'Elephant', 'Flamingo', 'Leopard', 'Raccoon', 'Walrus', 'Goat'],
		nouns = nouns1.concat(nouns2).concat(nouns3);

	const randomName = () => `${adjs[randomInt(adjs.length)]} ${nouns[randomInt(nouns.length)]}`;

	// lists temp/rain scores only
	const scoreDistOptions = [
		[0.5, 1],
		[0.75, 0.75],
		[0.75, 1],
		[1, 0.25],
		[1, 0.5],
		[1, 0.75],
		[1, 1]
	];

	const generateScoreDist = () => {
		const option = scoreDistOptions[randomInt(scoreDistOptions.length)];
		/* 
		Because the weight of the gas score is 50% of the overall score, it's practically impossible
		for an animal to be "thriving" without full gas suitability (bare minimum being 0.85/1).
		*/
		return {
			gas: 1,
			rain: option[0],
			temp: option[1]
		};
	};

	const generateGasTol = gases => {
		// NOTE: For why score isn't a parameter, see the comment above (in generateScoreDist)

		// Select two random gases
		const selectedGases = new Set(),
			gasNames = Object.keys(gases);
		while (selectedGases.size < 2) {
			selectedGases.add(gasNames[randomInt(gasNames.length)]);
		}

		// Compute gas tolerance
		const gasTol = {};
		for (const gas of selectedGases) {
			const gasPercent = gases[gas];
			const lower = Math.min(randomInt(gasPercent), 75);
			gasTol[gas] = [lower, randomIntRange(Math.max(lower + 25, gasPercent), 101)];
		}

		return gasTol;
	};

	const generateWeatherTol = (data, targetScore, min, max) => {
		// number of seasonal measures the overall range should include (never zero)
		const count = targetScore * 4;
		// thresholds: [min - 1, a, b, c, d, max + 1]
		const thresholds = data.concat([min - 1, max + 1]).sort((a, b) => a - b),
			startThreshold = randomInt(5 - count);

		return [
			randomIntRange(thresholds[startThreshold] + 1, thresholds[startThreshold + 1]),
			randomIntRange(thresholds[startThreshold + count] + 1, thresholds[startThreshold + count + 1])
		];
	};

	/////////////////////// overall process ///////////////////////
	// choose a random planet
	const planetIndex = randomInt(Object.keys(gameState.world.planets).length),
		planet = gameState.world.planets[planetIndex],
		regionObj = planet.regions[randomInt(planet.numRegions)];
	console.log(
		`[playerApi/generateContract] Generating contract using planet ${planet.name} and region (data)`,
		regionObj
	);

	// generate animal with tolerance based on the properties of the randomly selected planet/region
	const scoreDist = generateScoreDist();
	const { id, name, animals } = player ?? {
		id: getPlayerId(),
		name: gameState.player.name,
		animals: gameState.player.animals
	};
	let animalName;
	do {
		animalName = `${name}'s ${randomName()}`;
	} while (animals.indexOf(animalName) !== -1);
	return {
		name: animalName,
		planetRef: planetIndex,
		playerId: id,
		goalPopulation: 200 + 50 * randomInt(5),
		populationInShip: 100,
		gasTol: generateGasTol(planet.gasComp),
		rainTol: generateWeatherTol(seasonalAverages(regionObj.rain), scoreDist.rain, 0, 225),
		tempTol: generateWeatherTol(seasonalAverages(regionObj.temp), scoreDist.temp, -75, 175)
	};
};

/**
 * HELPERS // Returns a random integer
 * @param {number} min integer lower bound
 * @param {number} max integer upper bound
 * @returns a randomly selected integer between 0/min (inc) and max (exc)
 */
const randomIntRange = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const randomInt = max => Math.floor(Math.random() * max);

/**
 * Handles the completion of a contract and removes all traces of the animal (to avoid future errors,
 * assuming this specific animal name will not reappear)
 * @param {string} animalName the specified animal
 */
export const completeContract = async animalName => {
	console.log('[playerApi] running completeContract');
	const animal = gameState.contracts.animals[animalName],
		coinsGained = Math.round(
			50 + animal.goalPopulation / 20 + (animal.populationInShip - animal.goalPopulation) / 40
		);
	console.log(`animalName: ${animalName}`);
	// const coinsGained = computeReward(animalName)

	// update database
	switch (dbProvider) {
		case 'firebase':
			// first, update player's info for contract completion
			let success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({
					animals: firestore.FieldValue.arrayRemove(animalName),
					coins: firestore.FieldValue.increment(coinsGained),
					contractsDone: firestore.FieldValue.increment(1)
				})
				.then(() => true)
				.catch(error => console.error(error));

			// then, remove animal doc
			success =
				success === true &&
				(await games
					.doc(getGameCode())
					.collection('animals')
					.doc(animalName)
					.delete()
					.then(() => true)
					.catch(error => console.error(error)));

			// then, remove all traces of animal still on any planets
			const updateObjs = {};
			gameState.world.planets.forEach((planet, pIndex) => {
				const update = {};
				planet.regions.forEach((region, rIndex) => {
					if (region.animals[animalName] !== undefined)
						update[[`region.${rIndex}.animals.${animalName}`]] = firestore.FieldValue.delete(); // mark to delete
				});
				if (Object.keys(update).length > 0) updateObjs[pIndex] = update;
			});
			console.log('updateObjs produced:', updateObjs);
			for (const index in updateObjs) {
				const update = updateObjs[index];
				success =
					success === true &&
					(await games
						.doc(getGameCode())
						.collection('planets')
						.doc(index.toString())
						.update(update)
						.then(() => true)
						.catch(error => console.error(error)));
			}

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux actions
	dispatch(
		playerActions.completeContract({
			animalName: animalName,
			coinsGained: coinsGained,
			pointsGained: 1
		})
	);
	dispatch(contractActions.removeAnimal(animalName));
	dispatch(worldActions.removeAnimal(animalName));

	if (gameState.player.animals.length === 0) await addContract();
};

/**
 * Add a sensor to a player's list of sensors
 * @param {number} planet index of the planet to place the sensor in
 * @param {number} region index of the region to place the sensor in
 * @param {string} dataType the type of data this sensor collects
 */
export const placeSensor = async (planet, region, dataType) => {
	console.log('[playerApi] running placeSensor');
	const sensor = {
		timeId: Date.now(),
		planet: planet,
		region: region,
		dataType: dataType,
		startMonth: getMonth()
	};

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({ activeSensors: firestore.FieldValue.arrayUnion(sensor) })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux actions
	dispatch(playerActions.placeSensor(sensor));
};

/**
 * Retrieve a sensor and its data, removing it from the list of active sensors
 * @param {timeId} timeId the timeID of the sensor object to be retrieved
 */
export const retrieveSensor = async timeId => {
	console.log('[playerApi] running retrieveSensor');
	const sensor = gameState.player.activeSensors.find(sensor => sensor.timeId === timeId),
		sensorData =
			sensor.dataType === 'gas'
				? gameState.world.planets[sensor.planet].gasComp
				: getSensorData(
						gameState.world.planets[sensor.planet].regions[sensor.region][sensor.dataType],
						sensor.startMonth,
						getMonth()
				  ),
		collectedSensor = {
			...sensor,
			endMonth: getMonth(), // NOTE: should this have a -1? (exclusive endpoint) or +1? (what happens when start = end?)
			data: sensorData
		};

	// update database
	switch (dbProvider) {
		case 'firebase':
			// remove from active sensors
			let success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({ activeSensors: firestore.FieldValue.arrayRemove(sensor) })
				.then(() => true)
				.catch(error => console.error(error));

			// add to collected sensors
			success =
				success === true &&
				(await games
					.doc(getGameCode())
					.collection('players')
					.doc(getPlayerId())
					.update({
						collectedSensors: firestore.FieldValue.arrayUnion(collectedSensor)
					})
					.then(() => true)
					.catch(error => console.error(error)));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux actions
	dispatch(playerActions.retrieveSensor(collectedSensor));
};

/**
 * HELPER // Returns a list of the collected data from a sensor after retrieval
 * @param {number[]} data the temperature/rain data of the region the sensor was in
 * @param {number} startMonth month of placement (included in data)
 * @param {number} endMonth month of retrieval (excluded in data)
 * @returns array of collected data
 */
const getSensorData = (data, startMonth, endMonth) => {
	if (endMonth < data.length) return data.slice(startMonth, endMonth + 1);
	else {
		// cycle through the existing data in case endMonth goes over the amount of generated data
		const collectedData = data.slice(startMonth, endMonth + 1);
		while (collectedData.length < endMonth - startMonth) {
			collectedData.push(
				...data.slice(0, Math.min(data.length, endMonth - startMonth - collectedData.length))
			);
		}
		return collectedData;
	}
};

/**
 * Increases the sensors capacity and purchased sensor count for the player by 1 and deducts the cost from the player's balance
 */
export const incSensorsCap = async () => {
	console.log('[playerApi] running incSensorsCap');

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({
					sensorsCapacity: firestore.FieldValue.increment(1),
					coins: firestore.FieldValue.increment(-(STORE_PRICES.ADD_SENSOR /* * (1 + player.sensorsBought/2) */)),
					sensorsBought: firestore.FieldValue.increment(1)
				})
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(playerActions.incSensorsCap());
};

/**
 * Saves a graph created using data from a collected sensor
 * @param {object} graph the graph object to save to the database and Redux
 */
export const saveGraph = async data => {
	console.log('[playerApi] running saveGraph');
	const graph = { ...data, timeId: Date.now() };

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({ savedGraphs: firestore.FieldValue.arrayUnion(graph) })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(playerActions.addGraph(graph));
};

/**
 * Deletes the saved graph with the specified ID.
 * @param {string} timeId the id
 */
export const discardGraph = async timeId => {
	console.log('[playerApi] running deleteGraph');
	const graph = gameState.player.savedGraphs.find(graph => graph.timeId === timeId);

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(getPlayerId())
				.update({ savedGraphs: firestore.FieldValue.arrayRemove(graph) })
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}

	// perform Redux action
	dispatch(playerActions.discardGraph(timeId));
};

/**
 * Send data to another player
 * @param {string} sensorId the ID of the sensor that carries the data to be sent
 * @param {string} recipientId the recipient player's ID
 * @returns
 */
export const sendData = async (sensorId, recipientId) => {
	console.log('[playerApi] running sendData');
	const sensor = gameState.player.collectedSensors.find(sensor => sensor.timeId === sensorId);

	// update database
	switch (dbProvider) {
		case 'firebase':
			const success = await games
				.doc(getGameCode())
				.collection('players')
				.doc(recipientId)
				.update({
					collectedSensors: firestore.FieldValue.arrayUnion({
						...sensor,
						timeId: Date.now(), // ensure that every sensor ID is unique
						origin: {
							playerId: getPlayerId(),
							playerName: gameState.player.name
						}
					})
				})
				.then(() => true)
				.catch(error => console.error(error));

			if (success === true) break;
			else return;
		default:
			return;
	}
};
