import React, { Component } from 'react';
import { getMonth } from '../../playerApi';
import { Header } from 'semantic-ui-react';

/**
 * Maintain a visible month timer on the planet navigation page
 * Parent: PlanetNavigation
 * Children: None
 *
 */
class PlayerTimer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentMonth: getMonth()
        }
    }

    componentDidMount() {
        this.interval = setInterval(
            () => this.updateMonthCount(),
            1000
        );    
    }

    componentWillUnmount() {
        clearInterval(this.interval);
      }

    updateMonthCount = () => {
        if(getMonth() !== this.state.currentMonth){
            this.setState({currentMonth: getMonth()});
        }
    }

    render() {
        return(
            <Header as='h3'>{`Current Month: ${this.state.currentMonth}`}</Header>
        )   
    }
}

export default PlayerTimer;