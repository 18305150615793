import React, { Component } from "react";
import { Message, Label} from "semantic-ui-react";
import JoinRequestList from "./JoinRequestList";
import PlayerListPopup from './PlayerListPopup';

// Description: Displays student info and handles rejoin requests
// Parent: TeacherControlScreen
// Children: None
// Props Used:
//      approveCallback (Function)
//      rejectCallback (Function)
//      rejoinedPlayers (Array)
//      totalPlayers (Array)

class TeacherGameInfo extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    render(){
        return(
        <div>
           <Message style={{ marginLeft: "0.7em", marginRight: "0.7em" }}>
                <br/>
                <br/>
                <Label attached="top" as="h3" color="black" size="big">
                    Student Information
                </Label>

                <p align="left">
                    <b>
                    Players:
                    </b>
                </p>
                <PlayerListPopup
                    list={this.props.totalPlayers}
                    filter="total"
                />
                <p align="left">
                    <b>
                    Join Requests:
                    </b>
                </p>
                <JoinRequestList 
                    rejoinedPlayers={this.props.rejoinedPlayers}
                    approveCallback={this.props.approveCallback}
                    rejectCallback={this.props.rejectCallback} 
                />
            </Message>
        </div>
        )
    }
}

export default TeacherGameInfo;