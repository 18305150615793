import { createSlice } from '@reduxjs/toolkit';
import { STORE_PRICES } from '../constants';

/*
Structure of the player slice of the global state (Redux)
Used for player

state: {
	id: string,
	name: string,
	coins: number,
	points: number,
	sensorsCapacity: number,
	animals: string[],
	activeSensors: {
		timeId: string
		planet: number,
		region: number,
		dataType: string,
		startMonth: number
	}[],
	collectedSensors: {
		timeId: string,
		planet: number,
		region: number,
		dataType: string,
		startMonth: number,
		endMonth: number,
		data: number[],
		origin?: { 			// field only exists if a sensor was received from someone else
			playerId: string,
			playerName: string
		}
	}[],
	savedGraphs: {
		timeId: string,
		graphType: string,
		dataType: string,
		title: string,
		x: {
			label: string,
			data: number[]
		},
		y: {
			label: string,
			data: number[]
		}
	}[]
}
*/

const INITIAL_STATE = {
	id: '',
	name: '',
	coins: 0,
	points: 0,
	sensorsCapacity: 0,
	sensorsBought: 0,
	constractsBought: 0,
	animals: [],
	activeSensors: [],
	collectedSensors: [],
	savedGraphs: []
};

const slice = createSlice({
	name: 'player',
	initialState: { ...INITIAL_STATE },
	reducers: {
		setId: (state, action) => {
			// payload: string
			console.log(`[game/setId] setting player ID to ${action.payload}`);
			state.id = action.payload;
		},

		setData: (state, action) => {
			/* payload: {
				id: string,
				name: string,
				coins: number,
				points: number,
				sensorsCapacity: number,
				animals: string[],
				activeSensors: {
					timeId: string
					planet: number,
					region: number,
					dataType: string,
					startMonth: number,
				}[],
				collectedSensors: {
					timeId: string,
					planet: number,
					region: number,
					dataType: string,
					startMonth: number,
					endMonth: number,
					data: number[],
					origin?: {
						playerId: string,
						playerName: string
					}
				}[],
				savedGraphs: {
					timeId: string
					graphType: string,
					dataType: string,
					title: string,
					x: {
						label: string,
						data: number[]
					},
					y: {
						label: string,
						data: number[]
					}
				}[]
			} */
			console.log('[player/setData] setting player data to', action.payload ?? ' initial state');
			const {
				id,
				name,
				coins,
				points,
				animals,
				sensorsBought,
				constractsBought,
				sensorsCapacity,
				activeSensors,
				collectedSensors,
				savedGraphs
			} = action.payload;
			state.id = id;
			state.name = name;
			state.coins = coins;
			state.points = points;
			state.animals = animals ?? [];
			state.sensorsBought = sensorsBought;
			state.constractsBought = constractsBought;
			state.sensorsCapacity = sensorsCapacity ?? 4;
			state.activeSensors = activeSensors ?? [];
			state.collectedSensors = collectedSensors ?? [];
			state.savedGraphs = savedGraphs ?? [];
		},

		addAnimal: (state, action) => {
			/* payload: {
				name: string,
				paid: boolean
			} */
			const { name, paid } = action.payload;
			console.log(`[player/addAnimal] pushing ${name} to list of animals`);
			state.animals.push(name);
			if (paid) state.coins -= STORE_PRICES.ADD_CONTRACT;
		},

		completeContract: (state, action) => {
			/* payload: {
				animalName: string, 
				coinsGained: number, 
				pointsGained: number
			} */
			const { animalName, coinsGained, pointsGained } = action.payload;
			console.log(
				`[player/completeContract] completed ${animalName}, gained ${coinsGained} coins & ${pointsGained} points`
			);
			state.animals.splice(state.animals.indexOf(animalName), 1);
			state.coins += coinsGained;
			state.points += pointsGained;
		},

		setName: (state, action) => {
			// payload: string
			console.log('[player/setName] setting name to', action.payload);
			state.name = action.payload;
		},

		placeSensor: (state, action) => {
			/* payload: {
				timeId: number,
				planet: number,
				region: number,
				dataType: string,
				startMonth: number,
			} */
			console.log('[player/placeSensor] adding sensor', action.payload);
			state.activeSensors.push(action.payload);
		},

		retrieveSensor: (state, action) => {
			/* payload: {
				timeId: number,
				planet: number,
				region: number,
				dataType: string,
				startMonth: number,
				endMonth: number,
				data: number[],
			} */
			const { timeId } = action.payload;
			console.log(`[player/retrieveSensor] retrieving sensor with timeId ${timeId}`);

			state.activeSensors.splice(
				state.activeSensors.findIndex(sensor => sensor.timeId === timeId),
				1
			);
			state.collectedSensors.push(action.payload);
		},

		addData: (state, action) => {
			/* payload: {
				timeId: string,
				planet: number,
				region: number,
				dataType: string,
				startMonth: number,
				endMonth: number,
				data: number[],
				origin?: {
					playerId: string,
					playerName: string
				}
			} */
			const data = action.payload;
			console.log(`[player/receiveData] received from ${data.origin.playerName} the sensor data`, data);
			state.collectedSensors.push(data);
		},

		incSensorsCap: (state, action) => {
			// payload: none
			console.log(`[player/incSensorsCap] increasing capacity to ${state.sensorsCapacity + 1}`);
			state.sensorsCapacity += 1;
			state.coins -= (STORE_PRICES.ADD_SENSOR * (1 + state.sensorsBought/2));
			state.sensorsBought += 1;
		},

		updateSensorNum: (state, action) => {
			// payload: number
			console.log(`[player/updateSensorNum] change sensor capacity to ${action.payload}`);
			state.sensorsCapacity = action.payload;
		},

		updateCoin: (state, action) => {
			// payload: number
			console.log(`[player/updateCoin] change coin count to ${action.payload}`);
			state.coins = action.payload;
		},

		addGraph: (state, action) => {
			/* payload: {
				timeId: string,
				graphType: string,
				dataType: string,
				title: string,
				x: {
					label: string,
					data: number[]
				},
				y: {
					label: string,
					data: number[]
				}
			} */
			console.log('[player/addGraph] adding', action.payload, 'to saved graphs');
			state.savedGraphs.push(action.payload);
		},

		discardGraph: (state, action) => {
			// payload: string
			console.log(`[player/discardGraph] discarding graph with id ${action.payload}`);
			state.savedGraphs.splice(
				state.savedGraphs.findIndex(graph => graph.timeId === action.payload),
				1
			);
		},

		reset: (state, action) => {
			// payload: none
			console.log('[player/reset] resetting player info in Redux state');
			const { id, name, ...rest } = INITIAL_STATE;
			for (const [key, value] of Object.entries(rest))
				state[key] = value;
		}
	}
});

export const playerActions = slice.actions;

export default slice.reducer;
