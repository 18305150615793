import React, { Component } from 'react';
import { connect } from 'react-redux';
import { completeContract} from '../../playerApi';

import { Card, Label, Button, Image, Grid, Modal } from 'semantic-ui-react';

import backgroundImage from '../../art assets/UI grid.png';
import animalHead from '../../art assets/Head_TempMedLow.png';
/**
 * A card displaying the information about a contract's animal
 * Parent: Contracts
 * Children: None
 *
 * @param {string} animalName the name of the animal of this contract
 */

class AnimalCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const animal = this.props.animal;
		// const [open, setOpen] = React.useState(false)
		// const coinsReceived = await computeReward(animal.name)
		const contentStr = "You received a new contract and some coins to spend in the shop."
		const contentNoContract = "You received some coins to spend in the shop."
		let complete =
			animal.populationInShip < animal.goalPopulation ? (
				<Button 
					disabled
					onClick={() => completeContract(animal.name)}
					size="large"
					positive
					content="Not enough animals on ship."
				/>
			) : (
				<Modal
					header = "You Completed a Contract!"
					content = {(this.props.animals.length === 1) ? contentStr : contentNoContract}
					trigger = 
						{<Button
							// onClick={() => completeContract(animal.name)}
							size="large"
							icon="check circle outline"
							positive
							content="Complete Contract"
						/>}
					actions={[{ key: 'done', content: 'Close', positive: true, onClick: () => completeContract(animal.name)}]}
				>
					
				</Modal>
				
				// <Button
				// 	onClick={() => completeContract(animal.name)}
				// 	size="large"
				// 	icon="check circle outline"
				// 	positive
				// 	content="Complete Contract"
				// />
			);

		return (
			<Card color="red">
				<Label as="a" color="blue" ribbon="right" />
				<Card.Content align="center">
					<Card.Header align="left">Animal Contract: {animal.name}</Card.Header>
						<Image src={backgroundImage} centered size="medium">
						<Image src={animalHead} size="small"></Image>
						</Image>

					<Card.Description align="center">
						<Grid columns={2}>
							<Grid.Column>
								<div align="left">
									<b>In Ship: {animal.populationInShip}</b>
								</div>
							</Grid.Column>
							<Grid.Column>
								<div align="left">
									<b>Goal: {animal.goalPopulation}</b>
								</div>
							</Grid.Column>
						</Grid>
						{/* <div align="left">
							<b>Have in Ship: {animal.populationInShip}</b>
						</div>

						<div align="right">
							<b>Goal: {animal.goalPopulation}</b>
						</div> */}
					</Card.Description>

					<div>-------------------------------------------------</div>
					<Card.Description>
						<Grid columns={2}>
							<Grid.Column verticalAlign={"middle"} width={6}>
								<div align="left">
									<b>Gas Needs:</b>
								</div>
							</Grid.Column>
							<Grid.Column textAlign={"left"} width={10}>
								{Object.entries(animal.gasTol).map((entry, index) => (
									<li key={index}>{`${entry[0]}: ${entry[1][0]}% to ${entry[1][1]}%`}</li>
								))}
							</Grid.Column>
						</Grid>

						<div> ------------------------------------------------- </div>
						<Grid columns={2}>
							<Grid.Column verticalAlign={"middle"} width={6}>
							<div align="left">
								<b>Temperature Needs:</b>
							</div>
							</Grid.Column>
							<Grid.Column verticalAlign={"middle"} textAlign={"left"} width={10}>
								<li>{`${animal.tempTol[0]}°C to ${animal.tempTol[1]}°C`}</li>
							</Grid.Column>
						</Grid>

						<div> ------------------------------------------------- </div>
						<Grid columns={2}>
							<Grid.Column verticalAlign={"middle"} width={6}>
								<div align="left">
									<b>Rain Needs:</b>
								</div>
							</Grid.Column>
							<Grid.Column verticalAlign={"middle"} textAlign={"left"} width={10}>
								<li>{`${animal.rainTol[0]} cm to ${animal.rainTol[1]} cm`}</li>
							</Grid.Column>
						</Grid>

						<br />
					</Card.Description>
					{/* Buttons go here */}
					{complete}
				</Card.Content>
			</Card>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	animals: state.planetGame.player.animals,
	animal: state.planetGame.contracts.animals[ownProps.animalName]
});

export default connect(mapStateToProps)(AnimalCard);
