import React, { Component } from 'react';
import { Header, Button, Progress } from 'semantic-ui-react';
import SingleButtonDialog from '../../../core/components/SingleButtonDialog';
import Readerqr from '../../../core/components/Readerqr';
import { getRandomInt } from '../../../helpers/randomNums';

class QrComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noFood: false,
            pauseScan: false,
            camera: false,
            readerLoaded: false,
            errorQR: false,
            showScanDialogue: false,
            tickingTime: 5,
            energyGain: 0,
            energyLoss: 0,
            populationLoss: 0
        };
        this.meetingTimer = null;
        this.movingEnergy = -2;
        this.pauseTime = 5;
        this.scanEnergyGain = 5;
    };

    closePredationDialog = () => {
        this.setState({ populationLoss: 0 });
    };

    closeFoodDialog = () => {
        this.setState({ noFood: false });
    };

    closeScanDialog = () => {
        this.setState({ showScanDialogue: false });
    };

    closeQRDialog = () => {
        this.setState({ errorQR: false });
    };

    timer = () => {
        this.meetingTimer = setTimeout(() => {
            this.setState({ tickingTime: this.state.tickingTime - 1 });
            if (this.state.tickingTime <= 0) {
                this.setState({
                    tickingTime: this.pauseTime,
                    pauseScan: false
                });
            } else {
                this.timer();
            }
        }, 1000);
    };

    openCamera = () => {
        this.setState({
            camera: true,
            readerLoaded: false,
        });
        setTimeout(() => {
            this.setState({ readerLoaded: true });
        }, 2000);
    };

    defaultScreen = () => {
        this.setState({ camera: false });
    };

    replenishFood = (lastFeedTime, currentTime, stationRate) => {
        if (!lastFeedTime) {
            return 0;
        } else {
            //Doing this scalar conversion will convert it to seconds
            const timeDifference = (currentTime - lastFeedTime) / 1000;

            const replenishAmount = stationRate * timeDifference;
            return Math.round(replenishAmount);
        }
    };

    handleScan = stationId => {
        if (stationId && !this.state.pauseScan) {
            this.setState({ pauseScan: true });
            this.timer();
            this.defaultScreen();

            const stationRef = this.props.db
                .collection(this.props.game)
                .doc(this.props.gameCode);

            const playerRef = this.props.db
                .collection(this.props.players)
                .doc(this.props.uid);

            this.props.db.runTransaction(transaction => {
                return transaction.get(stationRef).then(doc => {
                    const station = doc.data().configurations[
                        this.props.currentConfiguration
                    ].stations[stationId];



                    if (station != null) {
                        // handle predation calculations
                        if (this.props.predation && getRandomInt(1, 100) <= station.risk){
                            const newPop = Math.max(0, this.props.population - 3);
                            this.setState({ populationLoss: this.props.population - newPop });
                            transaction.update(playerRef, {
                                population: newPop,
                            });
                        }


                        let currentTime = new Date().getTime();

                        const regenFood = this.replenishFood(
                            station.lastFeedTime,
                            currentTime,
                            station.rate,
                        );

                        let currentAmount = station.amount + regenFood;

                        if (currentAmount > station.limit) {
                            currentAmount = station.limit;
                        }

                        var movement = 0;
                        if (station != null && this.props.lastStation !== null 
                            && stationId !== this.props.lastStation && this.props.movementLoss){
                            movement = this.movingEnergy;
                        }

                        if (currentAmount - this.props.population >= 0) {
                            currentAmount -= this.props.population;

                            this.setState({
                                showScanDialogue: true,
                                energyGain: this.scanEnergyGain,
                                energyLoss: Math.abs(movement)
                            });

                            transaction.update(playerRef, {
                                energy: this.props.energy + this.scanEnergyGain + movement,
                                lastStation: stationId
                            });
                        } else {
                            let foodGain = Math.round(
                                this.scanEnergyGain *
                                    (currentAmount /
                                        this.props.population),
                            );
                            if (foodGain === 0) {
                                this.setState({
                                    noFood: true,
                                    showScanDialogue: false,
                                    energyLoss: Math.abs(movement)
                                });
                                transaction.update(playerRef, {
                                    energy: this.props.energy + movement,
                                    lastStation: stationId
                                });
                            } else {
                                currentAmount = 0;
                                this.setState({
                                    showScanDialogue: true,
                                    energyGain: foodGain,
                                    energyLoss: Math.abs(movement)
                                });
                                transaction.update(playerRef, {
                                    energy: this.props.energy + foodGain + movement,
                                    lastStation: stationId
                                });
                            }
                        }

                        transaction.update(stationRef, {
                            [`configurations.${
                                this.props.currentConfiguration
                            }.stations.${stationId}`]: {
                                name: station.name,
                                rate: station.rate,
                                limit: station.limit,
                                amount: currentAmount,
                                risk: station.risk,
                                lastFeedTime: currentTime,
                            },
                        });
                    } else {
                        this.setState({ errorQR: true });
                    }
                });
            });
        }
    };

    render() {
        return (
            <div>
                {this.state.errorQR && (
                    <SingleButtonDialog
                        description="This QR Code is not part of the game!"
                        buttonText="OK"
                        buttonAction={this.closeQRDialog}
                        width="420px"
                    />
                )}

                {this.state.populationLoss !== 0 && (
                    <SingleButtonDialog
                        description={'You lost ' + this.state.populationLoss + ' fish due to predators!'}
                        buttonText="Close"
                        buttonAction={this.closePredationDialog}
                        width="420px"
                    />
                )}

                {this.state.showScanDialogue && (
                    <SingleButtonDialog
                        description={
                            <div>
                                {'You ate food! Plus ' +
                                this.state.energyGain +
                                ' energy :)'}
                                <br/>
                                <br />
                                {this.state.energyLoss !== 0 &&
                                    'You also lost ' + this.state.energyLoss + ' energy!'
                                }
                            </div>
                        }
                        buttonText="Close"
                        buttonAction={this.closeScanDialog}
                        width="420px"
                    />
                )}

                {this.state.noFood && (
                    <SingleButtonDialog
                        description={
                            <div>
                            {'No food left at this station!'}
                            <br />
                            <br />
                            {this.state.energyLoss !== 0 &&
                                'You also lost ' + this.state.energyLoss + ' energy!'
                            }
                            </div>
                        }
                        buttonText="Close"
                        buttonAction={this.closeFoodDialog}
                        width="420px"
                    />
                )}

                {!this.props.generationPause ? (
                    <div>
                        <Button.Group fluid widths={2}>
                            <Button
                                onClick={
                                    this.state.camera && this.state.readerLoaded
                                        ? this.defaultScreen
                                        : null
                                }
                                size="medium"
                                color={!this.state.camera ? 'green' : 'grey'}
                                className={!this.state.camera ? 'active' : ''}
                                content="Default"
                                ref={button => (this.buttonElement = button)}
                            />
                            <Button
                                onClick={
                                    !this.state.camera && !this.state.pauseScan
                                        ? this.openCamera
                                        : null
                                }
                                size="medium"
                                icon="camera"
                                color={this.state.camera ? 'green' : 'grey'}
                                className={this.state.camera ? 'active' : ''}
                                content="Scan"
                            />
                        </Button.Group>
                        {this.state.camera ? (
                            <Readerqr
                                handleScan={this.handleScan}
                                doneLoading={this.props.doneLoading}
                                handleError={this.props.doneLoading}
                            />
                        ) : (
                            <div>
                                <Progress
                                    percent={
                                        (this.state.tickingTime /
                                            this.pauseTime) *
                                        100
                                    }
                                    size="small"
                                />
                                {this.state.pauseScan ? (
                                    <Header> Scan Paused! </Header>
                                ) : (
                                    <Header> You can scan :) </Header>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <Header> Scan Locked! </Header>
                )}
            </div>
        );
    }
}

export default QrComponents;